import React from 'react';
import { Container } from '@hometap/htco-components';

import './HeaderAndTextHero.scss';

const HeaderAndTextHero = ({ heading, text, hasDivider, image, imageAlt }) => {
  return (
    <div>
      <div className="HeaderTextHeroInfoContainer">
        <Container row justify="space-between" className="HeaderTextHeroContainer">
          <Container grid="8" className="HeaderTextHeroLeft">
            <div className="HeaderTextHeroLeftContainer">
              <h4>{heading}</h4>
              <div className="HeaderTextHeroText">{text}</div>
            </div>
          </Container>
          <Container grid="4">
            <div className="HeaderTextHeroImageWrapper">
              <div>
                <img src={image} alt={imageAlt} />
              </div>
            </div>
          </Container>
        </Container>
      </div>

      {hasDivider && (
        <div className="HeaderTextHeroDividerContainer">
          <hr className="HeaderTextHeroDivider" />
        </div>
      )}
    </div>
  );
};

export default HeaderAndTextHero;
