import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import cx from 'classnames';

import Configurator from 'components/Configurator/Configurator';
import AddressDropdownHeader from 'components/Headers/AddressDropdownHeader/AddressDropdownHeader';
import useNavigatorContext from 'components/Layouts/DashboardLayout/hooks/useDashboardTabsContext';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import homeIcon from 'images/dashboard/home.svg';
import { formatFullAddress } from 'utils/address';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import useUserTracksContext from 'hooks/useUserTracksContext';
import Drift from 'react-driftjs';

import './DashboardLayout.scss';
import { useCurrentHome } from 'hooks/useCurrentHome';

const DashboardLayout = ({ children }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [scrollDir, setScrollDir] = useState(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { tabPropsList, shouldShowTabs } = useNavigatorContext();
  const { home, loading: loadingHome, userCanAccessHome } = useCurrentHome();
  const { loading: tracksLoading, pendingTracks } = useUserTracksContext();
  const history = useHistory();
  const { page } = useParams();
  const location = useLocation();

  const [headerTotalHeight, setHeaderTotalHeight] = useState(0);

  const isHedPath = useRouteMatch(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`);
  const isResourcePost = useRouteMatch(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}/:postSlug`);
  const isHedExactPath = isHedPath?.isExact;

  const { street, unit, city, state, zip_code } = home?.address || {};
  let showMobileAddress = !!home;
  if (isHedExactPath) showMobileAddress = false;
  if ((isHedPath && showMobileAddress && !home?.hed_debts_confirmed) || isResourcePost) {
    showMobileAddress = false;
  }
  const unconfirmedUserSSN = pendingTracks?.find(track => track?.membership?.status === 'pending');

  useEffect(() => {
    if (tracksLoading || loadingHome || !page) return;

    const homeEquityOrMaintenancePage =
      page === HOMEOWNER_DASHBOARD.HOME_EQUITY || page === HOMEOWNER_DASHBOARD.MAINTENANCE;
    const isNotPrimaryUser = userCanAccessHome === false;
    const shouldRedirect = homeEquityOrMaintenancePage && isNotPrimaryUser;

    if (shouldRedirect) {
      return history.push(getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS));
    }
  }, [tracksLoading, loadingHome, page, userCanAccessHome, history]);

  useEffect(() => {
    if (unconfirmedUserSSN) {
      return history.push(`/track/${unconfirmedUserSSN.id}/co-applicant/`);
    }
  }, [unconfirmedUserSSN, history]);

  useLayoutEffect(() => {
    const selector = document.querySelector('.DashboardLayoutAddressHeader .htco-HeaderContainer');
    if (selector) {
      setHeaderTotalHeight(selector.offsetHeight);
    }
  }, [isHidden]);

  useEffect(() => {
    let pageStartsWithoutNav = isHidden;
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setScrollDir(prevScrollPos > currentScrollPos ? 'up' : 'down');
      setPrevScrollPos(currentScrollPos);
      if (headerTotalHeight < currentScrollPos) {
        pageStartsWithoutNav = false;
      }
      if (scrollDir === 'down' && headerTotalHeight < currentScrollPos) {
        setIsHidden(true);
      }
      if (scrollDir === 'up' && !pageStartsWithoutNav) {
        setIsHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, location, isHidden, scrollDir, headerTotalHeight]);

  useEffect(() => {
    const handleWheel = event => {
      if (event.deltaY < 0 && window.scrollY === 0) {
        setIsHidden(false);
      }
    };
    document.body.addEventListener('wheel', handleWheel);
    return () => {
      document.body.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const startYRef = useRef(null);

  const handleTouchStart = event => {
    startYRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = event => {
    if (startYRef.current < event.touches[0].clientY && window.scrollY === 0) {
      setIsHidden(false);
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  const driftId = process.env.REACT_APP_DRIFT_WIDGET_ID;

  return (
    <>
      <AddressDropdownHeader
        tabProps={shouldShowTabs ? tabPropsList : null}
        className={cx('DashboardLayoutAddressHeader', { isHidden })}
      />
      <main>
        <div className={cx('DashboardLayout', { FeaturedOffersBackground: page === 'featured-offers' })}>
          <div className="PageBodyCenteredContainer">
            {showMobileAddress && (
              <div className="DashboardCurrentMobileHomeAddress">
                <img src={homeIcon} alt="home icon" />
                <span>{formatFullAddress(street, unit, city, state, zip_code)}</span>
              </div>
            )}
          </div>
          {children}
        </div>
        {driftId && <Drift appId={driftId} />}
        <Configurator />
      </main>
    </>
  );
};

export default DashboardLayout;
