import useApplicationContext from '../hooks/useApplicationContext';
import BoolField from '../components/BoolField/BoolField';

import { APPLICATION_FORM_FIELD, GET_PROPERTY_DEMO_DATA } from '../constants/formFields';
import DemoButton from 'components/DemoButton/DemoButton';

const PropertyTitleFormFields = () => {
  const { registerApplicationFormField, handleApplicationFormFieldChange } = useApplicationContext();

  const handleDemoClick = demoData => {
    for (const field in demoData) {
      handleApplicationFormFieldChange(demoData[field], field);
    }
  };

  return (
    <div className="ApplicationInfoFormFields">
      <BoolField
        labelWidth="100%"
        labelGrow={false}
        align="left"
        required
        baseRegisterField={registerApplicationFormField(APPLICATION_FORM_FIELD.isOwnedByTrust)}
      />
      <DemoButton demoData={GET_PROPERTY_DEMO_DATA} onClickAction={handleDemoClick} />
    </div>
  );
};

export default PropertyTitleFormFields;
