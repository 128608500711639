import React from 'react';
import InquiryFormRow from './InquiryFormRow';

const InquiryOutcomeUnsuccessfulRiskFactors = ({ riskFailFactors = [] }) => {
  if (!riskFailFactors || !riskFailFactors.length) return null;
  return (
    <>
      <InquiryFormRow>Key factors that adversely affected your credit score:</InquiryFormRow>
      <InquiryFormRow>
        <ul className="bulleted-list">
          {riskFailFactors.map((reason, i) => {
            return <li key={i}>{reason[0].toUpperCase() + reason.slice(1).toLowerCase()}</li>;
          })}
        </ul>
      </InquiryFormRow>
    </>
  );
};

export default InquiryOutcomeUnsuccessfulRiskFactors;
