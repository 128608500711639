import { Button, MuiSelect, Modal, MuiTextInput, Grid } from '@hometap/htco-components';
import { parseDataToAddressString } from 'utils/googleAutocompleteAddress';
import useStates from 'hooks/useStates';
import './OnboardingAddressModal.scss';

const OnboardingAddressModal = ({ open, onNext, handleClose, form }) => {
  const { isFormValid, formData, registerField, handleSubmit, updateFormData } = form;
  const { statesForDropdown } = useStates();

  const onSubmit = () => {
    const { street, city, state, zip_code } = formData;
    const address = parseDataToAddressString({ street, city, state, zip_code });
    updateFormData({ address });
    onNext();
  };

  const valid = () => {
    const { zip_code } = formData;
    const zipCodeValid = zip_code && zip_code.length === 5;
    return isFormValid && zipCodeValid;
  };

  return (
    <Modal open={open} onClose={handleClose} data-testid="address-modal">
      <h1>Enter your address</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid xs={12} sm={6}>
            <MuiTextInput
              name="street"
              label="Street Address"
              data-testid="street"
              maxLength={50}
              required
              width="100%"
              {...registerField('street')}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MuiTextInput
              name="unit"
              label="Unit (optional)"
              data-testid="unit"
              maxLength={50}
              width="100%"
              {...registerField('unit')}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <MuiTextInput
              name="city"
              label="City"
              data-testid="city"
              maxLength={50}
              required
              width="100%"
              {...registerField('city')}
            />
          </Grid>
          <Grid xs={12} sm={6} className="OnboardingAddressModalState" data-testid="state">
            <MuiSelect
              name="state"
              label="State"
              required
              options={statesForDropdown}
              {...registerField('state')}
              width="100%"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MuiTextInput
              name="zip_code"
              label="Zip Code"
              type="number"
              inputMode="number"
              data-testid="zip_code"
              maxLength={5}
              required
              mask="00000"
              validator={val => val.length !== 5 && 'Zip code must be 5 digits'}
              {...registerField('zip_code')}
              width="100%"
            />
          </Grid>
          <Grid xs={12}>
            <Button
              className="OnboardingAddressModalSubmit"
              disabled={!valid()}
              width="100%"
              type="submit"
              data-testid="submit-button"
            >
              Submit Address
            </Button>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default OnboardingAddressModal;
