import { apiWithAuth } from 'utils/api';

// IMPORTANT: Be sure to always call `viewOffer` when a homeowner views a Rundown. This is necessary
// for a legal audit trail.

export const fetchOffer = async id => {
  return await apiWithAuth.v1.get(`/offers/${id}`);
};

export const acceptOffer = async id => {
  return await apiWithAuth.v1.post(`/offers/${id}/actions/accept`);
};

export const declineOffer = async id => {
  return await apiWithAuth.v1.post(`/offers/${id}/actions/decline`);
};

export const viewOffer = async id => {
  await apiWithAuth.v1.post(`/offers/${id}/actions/view/`);
};
