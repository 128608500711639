// import { Button } from '@hometap/htco-components';
// import React from 'react';
// import ReactDOMServer from 'react-dom/server';
import './sanitySerializers.scss';

export const serializers = {
  types: {
    // commenting out bodyButton to avoid sending users outside the HED until we find a better solution.
    // bodyButton: ({ value }) => {
    //   const { label, url } = value;
    //   if (!label || !url) {
    //     console.error('BodyButton missing label or url', value);
    //     return null;
    //   }
    //   const buttonComponent = (
    //     <div className="BodyButton">
    //       <Button theme="secondary" href={url}>
    //         {label}
    //       </Button>
    //     </div>
    //   );
    //   // For now, we need to do this to render an HTML (e.g., not React) version of this component.
    //   return ReactDOMServer.renderToStaticMarkup(buttonComponent);
    // },
    // other serializers...
  },
};
