import React from 'react';
import { Button } from '@hometap/htco-components';

import { getDashboardUrl } from 'utils/links';

const InquiryCTAOutcomeButton = ({ onClickResendMagicLink, isAdditionalTrack }) => (
  <>
    {isAdditionalTrack ? (
      <Button href={getDashboardUrl()} className="InquiryOutcomeDashboardButton">
        Return to dashboard
      </Button>
    ) : (
      <>
        Didn’t get the link?{' '}
        <Button theme="link" onClick={onClickResendMagicLink}>
          Resend magic link
        </Button>
      </>
    )}
  </>
);

export default InquiryCTAOutcomeButton;
