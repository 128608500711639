import React from 'react';
import cx from 'classnames';
import ResourcesSection from './ResourcesSection';
import {
  FEATURED_RESOURCE,
  FEATURED_RESOURCES_LIST,
  FINANCING_MY_GOALS_LIST,
  GROW_MY_HOME_VALUE_AND_EQUITY_LIST,
} from 'apps/dashboard/content/resourceArticles';
import FeaturedResources from './FeaturedResources';
import Footer from 'components/Footer/Footer';
import { useSanityData } from 'apps/dashboard/hooks';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import './ResourcesController.scss';

const ResourcesController = () => {
  const { protectInvestmentCards } = useSanityData();

  return (
    <ScrollToTop>
      <div className="ResourcesController">
        <div className={cx('PageBodyCenteredContainer', 'ResourcesControllerFeaturedResources')}>
          <h2 className="ResourcesControllerHeader"> Featured resources </h2>
          <FeaturedResources featuredResource={FEATURED_RESOURCE} resourcesList={FEATURED_RESOURCES_LIST} />
        </div>

        <div className={cx('ResourcesControllerSectionWrapper', 'DashboardContainerDark')}>
          <ResourcesSection title="Financing my goals" resources={FINANCING_MY_GOALS_LIST} />
          <ResourcesSection title="Grow my home value and equity" resources={GROW_MY_HOME_VALUE_AND_EQUITY_LIST} />
          <ResourcesSection title="Protect my investment" resources={protectInvestmentCards} />
          <Footer theme="dark" />
        </div>
      </div>
    </ScrollToTop>
  );
};

export default ResourcesController;
