import React, { useContext, useEffect, useState } from 'react';
import { useAsync } from '@hometap/htco-components';
import { STAGES } from 'apps/dashboard/constants';
import { fetchInvestmentSummary } from 'apps/settlement/data/settlementRequests';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { prepareInvestmentLiens } from 'apps/dashboard/utils/dataUtils';
import { useCurrentHome } from 'hooks/useCurrentHome';

// Hook to get the current user's active investment for HED if they have one

const Context = React.createContext({});

const useHedInvestmentContext = () => useContext(Context);
export default useHedInvestmentContext;

export const HedInvestmentProvider = ({ children }) => {
  const { execute, loading, error } = useAsync(fetchInvestmentSummary);
  const { tracks } = useUserTracksContext();
  const { home } = useCurrentHome();
  const [investment, setInvestment] = useState(null);

  useEffect(() => {
    if (!tracks?.length || !home?.id) return;

    const getActiveInvestmentSummary = async tracks => {
      const activeInvestmentTrack = tracks.find(
        track => !!track.active_investment_id && track.current_stage === STAGES.INVESTED,
      );
      if (!activeInvestmentTrack) return;

      const investmentSummary = await execute(activeInvestmentTrack.active_investment_id);
      if (investmentSummary?.effective_date && investmentSummary?.beginning_home_value) {
        return investmentSummary;
      }
    };

    const processTracksAndGetInvestmentSummary = async (homeId, tracks = []) => {
      const homeTracks = tracks?.filter(track => track?.home?.id === homeId);
      if (!homeTracks.length) {
        setInvestment(null);
        return;
      }

      const activeInvestmentSummary = await getActiveInvestmentSummary(homeTracks, homeId);
      if (!activeInvestmentSummary) {
        setInvestment(null);
        return;
      }

      const editedLienReviews = prepareInvestmentLiens(activeInvestmentSummary);
      activeInvestmentSummary.editedLienReviews = editedLienReviews;
      setInvestment(activeInvestmentSummary);
    };

    processTracksAndGetInvestmentSummary(home.id, tracks);
  }, [tracks, execute, home?.id]);

  const contextData = { investment, loading, error };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
