import { useEffect } from 'react';
import { useAsync } from '@hometap/htco-components';
import { apiWithAuth } from 'utils/api';

const createAndFetchShareFractions = async (home_value, investment_amount, product_id) => {
  const body = { home_value, investment_amount };
  if (product_id) {
    body.product_id = product_id;
  }
  return await apiWithAuth.v1.post('/pricing/share-fractions', body);
};

const useShareFractions = (homeValue, investmentAmount, productId) => {
  const { execute: fetchFractions, results: shareFractions } = useAsync(createAndFetchShareFractions);

  useEffect(() => {
    if (homeValue && investmentAmount && productId) {
      fetchFractions(homeValue, investmentAmount, productId);
    }
  }, [fetchFractions, homeValue, investmentAmount, productId]);

  return { shareFractions };
};

export default useShareFractions;
