import { memo } from 'react';
import { Accordion } from '@hometap/htco-components';

import './FAQList.scss';

const FAQList = memo(({ heading, faqs, footer }) => (
  <div className="FAQContainer">
    <h2 className="FAQHeading">{heading}</h2>
    {faqs.map(faq => (
      <Accordion header={faq.question} key={faq.question} theme="with-plus">
        {faq.answer}
      </Accordion>
    ))}
    {footer}
  </div>
));

export default FAQList;
