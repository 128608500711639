import { useParams } from 'react-router-dom';
import { TrackProvider } from 'hooks/useTrack';
import { ActiveTrackCheck } from 'components/TrackRoute/TrackRoute';

const TrackDetailsProviderWrapper = ({ children }) => {
  const { trackId } = useParams();

  return (
    <TrackProvider trackId={trackId}>
      <ActiveTrackCheck>{children}</ActiveTrackCheck>
    </TrackProvider>
  );
};

export default TrackDetailsProviderWrapper;
