import React from 'react';
import { IconButton } from '@hometap/htco-components';
import { getPrimaryAppTrackDetailsUrl } from 'utils/links';
import useTrack from 'hooks/useTrack';
import './ModalPageHeader.scss';

const ModalPageHeader = ({ children, onClose }) => {
  const { track } = useTrack();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (track) {
      window.location = getPrimaryAppTrackDetailsUrl(track.id);
    } else {
      window.history.back();
    }
  };

  return (
    <div className="ModalPageHeader">
      {children}
      <IconButton className="ModalPageHeaderCloseButton" icon="icon-cancel" theme="primary" onClick={handleClose} />
    </div>
  );
};

export default ModalPageHeader;
