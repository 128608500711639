import { faker } from '@faker-js/faker';

export const APPLICANT_FORM_FIELD = {
  email: 'email',
  phone: 'phone_number',
  preferredContactMethod: 'preferred_contact_method',
  isTrackAddressMailingAddress: 'is_track_address_mailing_address',
  street: 'street',
  unit: 'unit',
  city: 'city',
  state: 'state',
  zipCode: 'zip_code',
  hasHadBankruptcyFiling: 'has_had_bankruptcy_filing',
  hasBeenConvictedOfFelony: 'has_been_convicted_of_felony',
  hasReceivedPreForeclosureNotice: 'has_received_pre_foreclosure_notice',
  hasCompletedPreForeclosureSale: 'has_completed_pre_foreclosure_sale',
  hasTransferredTitleDueToForeclosure: 'has_transferred_title_due_to_foreclosure',
  employmentStatus: 'employment_status',
  totalAnnualIncome: 'total_annual_income',
  hasOtherApplicants: 'has_other_applicants',
  firstName: 'first_name',
  lastName: 'last_name',
  middleName: 'middle_name',
  ssn: 'ssn',
  maritalStatus: 'marital_status',
  relationshipToPrimary: 'relationship_to_primary',
};

export const NON_APPLICANT_MODEL_FORM_FIELD_KEYS = ['hasOtherApplicants'];

export const APPLICATION_FORM_FIELD = {
  otherLienDetails: 'other_description',
  hasLiens: 'has_liens',
  lienTypes: 'lien_types',
  isOwnedByTrust: 'is_owned_by_trust',
  consentGiven: 'consent_given',
  hasHoa: 'has_hoa',
  hoaContactName: 'hoa_contact_name',
  hoaContactPhone: 'hoa_contact_phone',
  hoaStreet: 'hoa_street',
  hoaUnit: 'hoa_unit',
  hoaCity: 'hoa_city',
  hoaState: 'hoa_state',
  hoaZipCode: 'hoa_zip_code',
};

export const NON_APPLICATION_MODEL_FORM_FIELD_KEYS = ['hasLiens'];

export const GET_PROPERTY_DEMO_DATA = () => {
  const demoData = {
    // default to true because form doesn't allow user to automatically proceed if false
    isOwnedByTrust: true,
    hasLiens: true,
    hasHoa: true,
    hoaContactName: faker.name.fullName(),
    hoaContactPhone: faker.phone.number('###-###-####'),
    hoaStreet: faker.address.street(),
    hoaUnit: faker.address.secondaryAddress(),
    hoaCity: faker.address.city(),
    hoaState: faker.address.stateAbbr(),
    hoaZipCode: faker.address.zipCode('#####'),
    lienTypes: ['mortgage'],
  };

  const mappedObject = {};
  Object.keys(demoData).forEach(key => {
    mappedObject[APPLICATION_FORM_FIELD[key]] = demoData[key];
  });
  return mappedObject;
};

export const APPLICANT_DEMO_DATA = ({ isPrimaryApplicant }) => {
  const data = {
    email: faker.internet.email(null, null, 'hometap.com'),
    phone: faker.phone.number('###-###-####'),
    preferredContactMethod: 'email',
    isTrackAddressMailingAddress: faker.datatype.boolean(),
    street: faker.address.street(),
    unit: faker.address.secondaryAddress(),
    city: faker.address.city(),
    state: faker.address.stateAbbr(),
    zipCode: faker.address.zipCode('#####'),
    hasHadBankruptcyFiling: faker.datatype.boolean(),
    hasBeenConvictedOfFelony: faker.datatype.boolean(),
    hasReceivedPreForeclosureNotice: faker.datatype.boolean(),
    hasCompletedPreForeclosureSale: faker.datatype.boolean(),
    hasTransferredTitleDueToForeclosure: faker.datatype.boolean(),
    employmentStatus: 'employed',
    totalAnnualIncome: faker.finance.amount(0, 5000000, 0),
    hasOtherApplicants: false,
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    middleName: faker.name.middleName(),
    ssn: '987-65-4321',
    maritalStatus: faker.helpers.arrayElement(['married', 'single']),
    relationshipToPrimary: 'spouse',
  };

  const mappedObject = {};
  Object.keys(data).forEach(key => {
    mappedObject[APPLICANT_FORM_FIELD[key]] = data[key];
  });
  return { ...mappedObject, isPrimaryApplicant };
};

export const APPLICANT_MAILING_ADDRESS_FIELDS = [
  APPLICANT_FORM_FIELD.isTrackAddressMailingAddress,
  APPLICANT_FORM_FIELD.city,
  APPLICANT_FORM_FIELD.state,
  APPLICANT_FORM_FIELD.zipCode,
  APPLICANT_FORM_FIELD.street,
  APPLICANT_FORM_FIELD.unit,
];
