import PropTypes from 'prop-types';

const NavImageAndTitle = ({ titleIndicator, title }) => {
  return (
    <div className="flex items-center gap-3">
      {titleIndicator && titleIndicator}
      {title && <div className="w-full leading-[26px]">{title}</div>}
    </div>
  );
};

NavImageAndTitle.propTypes = {
  titleIndicator: PropTypes.node,
  title: PropTypes.node,
};

export default NavImageAndTitle;
