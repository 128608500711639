import { ModalConfirm } from '@hometap/htco-components';
import { APPRAISAL_TYPE } from 'utils/constants/appraisalTypes';

const AppraisalSelectionModal = ({
  selectedAppraisalType,
  onSubmitAppraisalType,
  isSubmitting,
  isOpen,
  onClose,
  virtualValuationDollars,
}) => {
  const appraisalTypeContentMap = {
    [APPRAISAL_TYPE.VIRTUAL]: {
      heading: 'Virtual appraisal agreement:',
      body: (
        <div>
          By selecting a virtual appraisal,{' '}
          <strong>
            I’m agreeing to an estimated valuation{' '}
            {virtualValuationDollars ? `at ${virtualValuationDollars}` : 'that is to be determined'}
          </strong>
          , which is calculated using data from multiple industry-proven external models.
          <br />
          <br />I verify that my property is not undergoing any renovations, and I understand that Hometap reserves the
          right to order a traditional appraisal in order to determine eligibility.
        </div>
      ),
    },
    [APPRAISAL_TYPE.TRADITIONAL]: {
      heading: 'Traditional appraisal agreement:',
      body: 'By selecting a traditional appraisal, I’m waiving my rights to a virtual appraisal in the future and agreeing to the valuation from an in-home appraiser.',
    },
  };

  const { heading, body } = appraisalTypeContentMap[selectedAppraisalType] || {};

  return (
    <ModalConfirm
      onClose={onClose}
      header={heading}
      onConfirm={onSubmitAppraisalType}
      open={isOpen}
      confirmText={isSubmitting ? 'Submitting...' : 'I agree'}
    >
      {body}
    </ModalConfirm>
  );
};

export default AppraisalSelectionModal;
