import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Badge } from '@hometap/htco-components';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import { CATEGORY_COLOR_MAP } from 'apps/dashboard/constants/resources';
import ResourcesFooter from './ResourcesFooter';

import './ResourcesCard.scss';

const ResourcesCard = ({ isLoading, title, excerpt, coverImage, category, type, length, urlSlug, isExternal }) => {
  const ResourceCard = () => (
    <div className="ResourcesCard">
      <div>
        <div className="ResourcesCardFeaturedImageContainer">
          {isLoading ? (
            <div className={cx('ResourcesCardFeaturedImage', { isLoading })} />
          ) : (
            <img className="ResourcesCardFeaturedImage" src={coverImage} alt={title} />
          )}
        </div>
        {category && (
          <Badge
            label={category}
            theme={CATEGORY_COLOR_MAP[category]}
            className={cx('ResourcesBadge', 'ResourcesCardBadge', {
              isLoading,
            })}
          />
        )}
        <div>
          <h4 className={cx('ResourceCardTitle', { isLoading })}>{excerpt}</h4>
        </div>
      </div>

      <ResourcesFooter
        isLoading={isLoading}
        featuredResource={{ title, excerpt, coverImage, category, type, length, urlSlug }}
      />
    </div>
  );

  return isExternal || isLoading ? (
    <a href={urlSlug} className={cx('ResourcesCardLink', { isDisabled: isLoading })} data-testid="resources-card-link">
      <ResourceCard />
    </a>
  ) : (
    <Link to={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}/${urlSlug}`} className="ResourcesCardLink">
      <ResourceCard />
    </Link>
  );
};

export default ResourcesCard;
