import React from 'react';
import { Button, MuiTextInput, SelectionSet } from '@hometap/htco-components';
import { CallToAction, Panel } from '../template/OnboardingTemplate';
import financialGoals from '../../../../../images/inquiry/onboarding_goals.png';
import financialGoals2x from '../../../../../images/inquiry/onboarding_goals-2x.png';

import './OnboardingGoals.scss';

const otherSelectionValue = 'other';
const ONBOARDING_GOALS_DEFAULTS = [
  {
    icon: 'icon-asset-class',
    label: 'Just curious',
    value: 'curious',
  },
  {
    icon: 'icon-renovate',
    label: 'Plan a renovation',
    value: 'renovation',
  },
  {
    icon: 'icon-learning',
    label: 'Get guidance on home maintenance',
    value: 'home_maintenance',
  },
  {
    icon: 'icon-money-payment',
    label: 'Reduce homeownership expenses',
    value: 'home_expenses',
  },
  {
    icon: 'icon-hand-money',
    label: 'Access my home equity',
    value: 'access_equity',
  },
  {
    icon: 'icon-arrow-increase',
    label: 'Maximize my home equity',
    value: 'maximize_equity',
  },
  {
    icon: 'icon-realestate',
    label: 'Prepare to buy/sell/move',
    value: 'buy_sell_move',
  },
  {
    icon: 'icon-retire',
    label: 'Plan for retirement',
    value: 'retirement',
  },
  {
    icon: 'icon-other',
    label: 'Other',
    value: otherSelectionValue,
    description: 'You tell us!',
  },
];

const OnboardingGoals = ({ form, onPrev, onNext, hasOtherGoal, setHasOtherGoal }) => {
  const { formData, registerField } = form;
  const onboarding_goal = 'onboarding_goal';
  const fieldProps = registerField(onboarding_goal);
  let disableNextButton = !formData?.onboarding_goal?.length;

  if (hasOtherGoal) {
    disableNextButton = !formData?.onboarding_goal_other?.length;
  }

  const handleSelectionChange = (value, ...args) => {
    if (value === otherSelectionValue) {
      fieldProps.onChange(otherSelectionValue, ...args);
      setHasOtherGoal(true);
    } else {
      fieldProps.onChange(value, ...args);
      setHasOtherGoal(false);
    }
  };

  const handleOnPrev = () => {
    fieldProps.onChange('', onboarding_goal);
    if (hasOtherGoal) {
      setHasOtherGoal(false);
      return;
    }
    onPrev();
  };

  return (
    <div className="OnboardingGoals" data-testid="goals-page">
      <Panel gridSize={12}>
        <div className="OnboardingGoalsTop">
          <div className="OnboardingGoalsTopTitle">
            <h1>What's your primary goal?</h1>
            <div className="OnboardingGoalsSubtitle" data-testid="subtitle">
              Tell us a bit about what brings you to our doorstep.
            </div>
          </div>
          <img
            className="OnboardingGoalsTopImage"
            src={financialGoals}
            srcSet={`${financialGoals2x} 2x`}
            alt="onboarding goals"
          />
        </div>
        {hasOtherGoal ? (
          <div className="OnboardingGoalsOther">
            <MuiTextInput
              label="Other (You tell us!)"
              name="onboarding_goal_other"
              required
              width="100%"
              maxLength={255}
              {...registerField('onboarding_goal_other')}
            />
            <span className="OnboardingGoalsOtherInputLabel">
              Let us know how you’d like to use the Home Equity Dashboard
            </span>
          </div>
        ) : (
          <div className="OnboardingGoalsSelectionSetWrapper">
            <SelectionSet
              name="onboarding_goal"
              labelWidth="100%"
              labelGrow={false}
              {...fieldProps}
              onChange={(value, ...args) => handleSelectionChange(value, ...args)}
              options={ONBOARDING_GOALS_DEFAULTS.slice(0, 5)}
            />
            <SelectionSet
              name="onboarding_goal"
              labelWidth="100%"
              labelGrow={false}
              {...fieldProps}
              onChange={(value, ...args) => handleSelectionChange(value, ...args)}
              options={ONBOARDING_GOALS_DEFAULTS.slice(-4)}
            />
          </div>
        )}

        <CallToAction>
          <div className="OnboardingLinkButton">
            <Button theme="link" onClick={handleOnPrev} data-testid="back-button">
              Back
            </Button>
          </div>
          <Button onClick={onNext} disabled={disableNextButton} data-testid="next-button">
            Next
          </Button>
        </CallToAction>
      </Panel>
    </div>
  );
};

export default OnboardingGoals;
