import { InputMask } from '@hometap/htco-components';

export const SHARED_INPUT_PROPS = {
  className: 'mb-6',
  width: '100%',
  required: true,
};

export const TEXT_INPUTS = [
  {
    label: 'Rate',
    mask: InputMask.extend(InputMask.PERCENT_MASK, { max: 0.9999, min: 0.0001 }),
    name: 'rate',
    endAdornment: '%',
    type: 'number',
    inputMode: 'decimal',
    placeholder: ' ',
  },
  {
    label: 'Current balance',
    name: 'estimated_lien_balance',
    mask: InputMask.MONEY_USD_MASK,
    startAdornment: '$',
    type: 'number',
    inputMode: 'decimal',
    placeholder: ' ',
  },
  {
    label: 'Monthly payment',
    mask: InputMask.MONEY_USD_MASK,
    startAdornment: '$',
    name: 'monthly_payment',
    type: 'number',
    inputMode: 'decimal',
    placeholder: ' ',
  },
];
