import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import house from 'images/equity-planner/onboarding/three-houses-desktop.png';

const PageSEO = ({
  children,
  meta,
  links,
  lang,
  title,
  description,
  canonicalUrl,
  opengraphTitle,
  opengraphDescription,
  opengraphImageUrl,
  opengraphType,
}) => {
  const canonicalPage = canonicalUrl
    ? [
        {
          rel: 'canonical',
          href: canonicalUrl,
        },
      ]
    : [];

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={canonicalPage.concat(links)}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: opengraphTitle,
          },
          {
            property: `og:description`,
            content: opengraphDescription,
          },
          {
            property: `og:type`,
            content: opengraphType,
          },
          {
            property: `og:image`,
            content: opengraphImageUrl,
          },
        ].concat(meta)}
      />
      {children}
    </div>
  );
};

PageSEO.defaultProps = {
  lang: `en`,
  meta: [],
  links: [],
  title: 'Hometap | Access Your Home Equity | No Monthly Payments',
  description:
    'Hometap provides homeowners with an opportunity to tap into their home’s equity without monthly payments or having to take out another loan.',
  opengraphTitle: 'Hometap | Access Your Home Equity | No Monthly Payments',
  opengraphDescription:
    'Hometap provides homeowners with an opportunity to tap into their home’s equity without monthly payments or having to take out another loan.',
  // TODO: Should this point to somewhere else?
  opengraphImageUrl: house,
  opengraphType: 'website',
};

PageSEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  opengraphTitle: PropTypes.string,
  opengraphDescription: PropTypes.string,
  opengraphImageUrl: PropTypes.string,
  opengraphType: PropTypes.string,
};

export default PageSEO;
