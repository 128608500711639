// Note that this file is very similar to its counterpart in staff_portal. Update appropriately.

import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import cx from 'classnames';
import { SlideSide } from '@hometap/htco-components';
import env from 'utils/env';
import logo from 'images/hometap-logomark.svg';
import { getPortalsUrl } from 'utils/links';
import './Configurator.scss';

const matchPathWithOptionalTrailingSlash = (pathname, path) => {
  // matchPath doesn't support optional parameters, so try with and without the slash
  let match = matchPath(pathname, { path: `${path}/` });
  if (!match) {
    match = matchPath(pathname, { path });
  }
  return match;
};

const Configurator = ({ children }) => {
  // TODO: Persist state of Open/Closed in a cookie to persist through refresh and redirect to MPA
  // pages (which would also need to respect the state in the cookie)
  const [open, setOpen] = useState(false);

  // Note the below differs slightly from staff_portal, which uses pathMatch (reactrouter v6) and
  // path /tracks/ rather than /track/
  const match = matchPathWithOptionalTrailingSlash(useLocation().pathname, '/track/:trackId');
  const trackId = match?.params?.trackId || null;

  let configuratorUrl = `${getPortalsUrl()}configurator/settings/`;
  if (trackId) {
    configuratorUrl += `track/${trackId}/`;
  }

  // Configurator iframe calls postMessage when it wants the page to redirect or copy something to
  // clipboard. We handle those events here. MPA pages handle these as well.
  useEffect(() => {
    const handleMessageAction = event => {
      if (event.data.source !== 'configurator') {
        return;
      }
      if (event.data.action === 'redirect') {
        if (event.data.destination === '.') {
          window.location.reload();
        } else {
          window.location = event.data.destination;
        }
      } else if (event.data.action === 'copy') {
        navigator.clipboard.writeText(event.data.content);
      }
    };
    window.addEventListener('message', handleMessageAction);
    return () => window.removeEventListener('message', handleMessageAction);
  }, []);

  if (env.isProd() || env.isStage()) {
    return null;
  }

  const toggle = (
    <button className={cx('ConfiguratorToggle', { ClosedConfiguratorToggle: !open })} onClick={() => setOpen(!open)}>
      <img src={logo} alt="Hometap logo" />
    </button>
  );

  return (
    <>
      {/* Having a separate toggle here than the one in SlideSide is hacky but works */}
      {!open && toggle}
      <SlideSide
        className="Configurator"
        maxWidth={430}
        visible={open}
        isOverlay={true}
        displayBackdrop={true}
        direction="right"
      >
        <div className="ConfiguratorLeft">{toggle}</div>
        <div className="ConfiguratorRight">
          <iframe
            className="ConfiguratorIframe"
            title="configurator"
            src={configuratorUrl}
            width="100%"
            style={{ border: 'none' }}
          />
        </div>
      </SlideSide>

      {children}
    </>
  );
};

export default Configurator;
