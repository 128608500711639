import React from 'react';
import cx from 'classnames';

import './PullQuote.scss';

const PullQuote = ({ text, name, title, type = 'big' }) => {
  return (
    <div className={cx('PullQuote', type === 'small' && 'isSmallQuote')}>
      <div className={cx('PullQuoteText', type === 'small' && 'isSmallText')}>{text}</div>
      <div className="PullQuoteName">{name}</div>
      <div className="PullQuoteTitle">{title}</div>
    </div>
  );
};

export default PullQuote;
