import React from 'react';
import { Button, Container, DropdownSelector, Icon, useForm } from '@hometap/htco-components';
import { DEBT_TYPE_MAP } from '../../constants/debtCalculator';

import './DebtSelector.scss';

const DebtSelector = ({ onDropdownSubmit, title, buttonText, onSelectDebtType, onCancel, allowCancel }) => {
  const { registerField, handleSubmit, formData } = useForm();

  const debtDropdown = Object.values(DEBT_TYPE_MAP).map(({ label, value, icon }) => {
    return {
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name={icon} size="lg" style={{ paddingRight: '12px' }} /> {label}
        </div>
      ),
      value,
    };
  });
  return (
    <div>
      <h3 className="DebtSelectorTitle">{title}</h3>
      <form onSubmit={handleSubmit(onDropdownSubmit)} className="DebtSelectorForm">
        <Container row justify="space-between">
          <Container grid="12">
            <DropdownSelector
              placeholder="Choose debt"
              required={true}
              options={debtDropdown}
              {...registerField('typeOfDebt')}
              styles={{
                placeholder: () => {
                  return { color: '#366ced' };
                },
                singleValue: () => {
                  return { color: '#366ced' };
                },
              }}
            />
          </Container>
          <Container grid="12" align="center" justify="center">
            <Button
              type="submit"
              className="DebtSelectorButton"
              onClick={() => onSelectDebtType && onSelectDebtType(!!formData.typeOfDebt)}
            >
              {buttonText}
            </Button>
            {!allowCancel && (
              <Button theme="link" className="DebtSelectorButton" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </Container>
        </Container>
      </form>
    </div>
  );
};

export default DebtSelector;
