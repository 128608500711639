import React from 'react';
import { toast } from 'react-toastify';
import { Toast } from '@hometap/htco-components';

const toastDefaultOptions = {
  autoClose: 5000,
  closeButton: false,
  icon: false,
  hideProgressBar: true,
};

export const TOAST_TYPE = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

/**
 * @param {'info'|'success'|'warning'|'error'} [type]
 * @param {string|function} [title] - title of the notification
 * @param {string|function} [description] - description of the notification
 * @param {object} [options] - all react-toastify toast options can be passed from https://fkhadra.github.io/react-toastify/api/toast
 * @param {string|function} [toastContent] - ReactNode | ((props) => ReactNode
 */
export const showNotification = ({ type, title, description, options = {}, toastContent }) => {
  toast(toastContent || <Toast type={type} title={title} description={description} />, {
    type,
    ...toastDefaultOptions,
    ...options,
  });
};
