const USE_CASE_VALUES = {
  BUSINESS: 'use_case_business',
  DEBT: 'use_case_debts',
  EDUCATION: 'use_case_education',
  PROPERTY: 'use_case_buy_home',
  RENOVATE: 'use_case_renovate',
  RETIRE: 'use_case_retirement',
  DIVERSIFY: 'use_case_diversify',
  EMERGENCY: 'use_case_emergency',
  DIVORCE: 'use_case_divorce',
  CAR: 'use_case_finance_car',
  FAMILY: 'use_case_fund_family',
  OTHER: 'use_case_other',
};

export const USE_CASES = [
  {
    key: 'Invest in your business',
    value: USE_CASE_VALUES.BUSINESS,
    icon: 'icon-equity',
    image: `https://hometap.dream.press/wp-content/uploads/invest-in-business-1.jpeg`,
  },
  {
    key: 'Pay off debt',
    value: USE_CASE_VALUES.DEBT,
    icon: 'icon-compensation',
    image: `https://hometap.dream.press/wp-content/uploads/UseCase_Lg_debt.jpg`,
  },
  {
    key: 'Fund an education',
    value: USE_CASE_VALUES.EDUCATION,
    icon: 'icon-learning',
    image: `https://hometap.dream.press/wp-content/uploads/UseCase_Lg_education.jpg`,
  },
  {
    key: 'Buy another property',
    value: USE_CASE_VALUES.PROPERTY,
    icon: 'icon-house',
    image: `https://hometap.dream.press/wp-content/uploads/UseCase-second-prop-1.jpeg`,
  },
  {
    key: 'Renovate your home',
    value: USE_CASE_VALUES.RENOVATE,
    icon: 'icon-renovate',
    image: `https://hometap.dream.press/wp-content/uploads/use_case_emergency.jpeg`,
  },
  {
    key: 'Retire comfortably',
    value: USE_CASE_VALUES.RETIRE,
    icon: 'icon-retire',
    image: `https://hometap.dream.press/wp-content/uploads/use_case_retirement.jpeg`,
  },
  {
    key: 'Diversify your portfolio',
    value: USE_CASE_VALUES.DIVERSIFY,
    icon: 'icon-portfolio',
    image: `https://hometap.dream.press/wp-content/uploads/diversify_lg.png`,
  },
  {
    key: 'Pay for emergency expenses',
    value: USE_CASE_VALUES.EMERGENCY,
    icon: 'icon-healthcare',
    image: `https://hometap.dream.press/wp-content/uploads/emergency_lg-1.png`,
  },
  {
    key: 'Settle a divorce',
    value: USE_CASE_VALUES.DIVORCE,
    icon: 'icon-divorce',
    image: `https://hometap.dream.press/wp-content/uploads/use_case_divorce.png`,
  },
  {
    key: 'Finance a car',
    value: USE_CASE_VALUES.CAR,
    icon: 'icon-car',
    image: `https://hometap.dream.press/wp-content/uploads/finance_car.png`,
  },
  {
    key: 'Fund your family',
    value: USE_CASE_VALUES.FAMILY,
    icon: 'icon-fund-fam',
    image: `https://hometap.dream.press/wp-content/uploads/life-event_lg.png`,
  },
  {
    key: 'Other',
    value: USE_CASE_VALUES.OTHER,
    icon: 'icon-other',
    image: `https://hometap.dream.press/wp-content/uploads/use_case_other.png`,
  },
];

export const USE_CASE_TEXT = {
  [USE_CASE_VALUES.BUSINESS]: {
    outcomeText: 'investing in your business',
    outcomeTextMaxWidth: 'lg',
    infoText: 'invest in your business',
  },
  [USE_CASE_VALUES.DEBT]: { outcomeText: 'paying off debt', outcomeTextMaxWidth: 'sm', infoText: 'pay off debt' },
  [USE_CASE_VALUES.EDUCATION]: {
    outcomeText: 'funding an education',
    outcomeTextMaxWidth: 'sm',
    infoText: 'fund an education',
  },
  [USE_CASE_VALUES.PROPERTY]: {
    outcomeText: 'buying another property',
    outcomeTextMaxWidth: 'lg',
    infoText: 'buy another property',
  },
  [USE_CASE_VALUES.RENOVATE]: {
    outcomeText: 'renovating your home',
    outcomeTextMaxWidth: 'sm',
    infoText: 'renovate your home',
  },
  [USE_CASE_VALUES.RETIRE]: {
    outcomeText: 'retiring comfortably',
    outcomeTextMaxWidth: 'sm',
    infoText: 'retire comfortably',
  },
  [USE_CASE_VALUES.DIVERSIFY]: {
    outcomeText: 'diversifying your portfolio',
    outcomeTextMaxWidth: 'lg',
    infoText: 'diversify your portfolio',
  },
  [USE_CASE_VALUES.EMERGENCY]: {
    outcomeText: 'paying for emergency expenses',
    outcomeTextMaxWidth: 'lg',
    infoText: 'pay for emergency expenses',
  },
  [USE_CASE_VALUES.DIVORCE]: {
    outcomeText: 'settling a divorce',
    outcomeTextMaxWidth: 'sm',
    infoText: 'settle a divorce',
  },
  [USE_CASE_VALUES.CAR]: { outcomeText: 'financing a car', outcomeTextMaxWidth: 'sm', infoText: 'finance a car' },
  [USE_CASE_VALUES.FAMILY]: {
    outcomeText: 'funding your growing family',
    outcomeTextMaxWidth: 'lg',
    infoText: 'fund your growing family',
  },
  [USE_CASE_VALUES.OTHER]: { outcomeText: '', outcomeTextMaxWidth: 'sm', infoText: 'achieve your financial goals' },
};
