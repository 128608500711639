export const showRenovation = renovation => {
  renovation.hidden = false;
};

export const hideRenovation = renovation => {
  renovation.hidden = true;
};

export const getVisibleRenovations = scenario => {
  return scenario?.renovations?.filter(renovation => !renovation.hidden);
};
