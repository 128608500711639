import React, { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Icon, Button, NotFoundBlock, Paper, FormError, useAsync } from '@hometap/htco-components';
import { Helmet } from 'react-helmet-async';
import InvestmentInfo from './components/SettlementInvestmentInfo.js';
import SettlementForm from './components/SettlementForm';
import { fetchInvestmentSummary, fetchScenarios, sendCalculatorFormEvent } from './data/settlementRequests';
import SettlementResult from './components/SettlementResult';
import { getDashboardUrl } from 'utils/links';
import { getDateDifference, formatDate } from 'utils/date';
import SettlementDisclaimer from './components/SettlementDisclaimer';
import './SettlementCalculator.scss';

const SettlementCalculatorController = () => {
  const { id } = useParams();
  const [estimatedSettlementDate, setEstimatedSettlementDate] = useState({});
  const investmentSummaryAsync = useAsync(fetchInvestmentSummary, { immediate: true, params: [id] });
  const investment = investmentSummaryAsync.results;
  const scenarioAsync = useAsync(fetchScenarios);
  const scenarios = scenarioAsync.results?.scenarios ? scenarioAsync.results.scenarios[0] : {};

  const handleSubmitForm = async formData => {
    const effectiveDate = investment.effective_date;
    const estimatedSettlementDate = moment(formData.date, 'MM-DD-YYYY');
    setEstimatedSettlementDate(estimatedSettlementDate);
    const effectivePeriodLength = getDateDifference(effectiveDate, estimatedSettlementDate, 'days');
    const request = {
      scenarios: [
        {
          idx: 0,
          starting_home_value: investment.beginning_home_value,
          investment_amount: parseInt(investment.investment_amount),
          effective_period: {
            length: effectivePeriodLength,
            unit: 'days',
          },
          appreciated_home_value: Math.round(formData.estimatedHomeValue),
          product_id: investment.product_id,
        },
      ],
    };
    const settlementCalcData = await scenarioAsync.execute(request);
    const settlementExpectedShare =
      settlementCalcData?.scenarios?.length && settlementCalcData.scenarios[0].hometap_share?.dollars;
    sendCalculatorFormEvent(formatDate(estimatedSettlementDate), formData.estimatedHomeValue, settlementExpectedShare);
  };

  const handleReset = () => {
    scenarioAsync.setResults({});
  };

  // TODO: Remove once the navigation has been built in React, currently there needs to be a way
  // to get back to the main track page, and we don't have access to the track in the current context
  const handleBack = () => {
    window.history.back();
  };

  const hometapShare = scenarios?.hometap_share;

  if (investmentSummaryAsync.error) {
    return <NotFoundBlock buttonLink={getDashboardUrl()} />;
  }

  return (
    <div className="SettlementCalculator">
      <Helmet>
        <title>Settlement Calculator | Hometap</title>
      </Helmet>
      <div className="SettlementCalculatorContainer">
        <Button theme="link" className="SettlementCalculatorButton" href="#" onClick={handleBack}>
          <Icon name="angle-left" /> Back
        </Button>

        <Paper theme="elevated" className="SettlementCalculatorWrapper">
          <h1 className="SettlementCalculatorTitle">Settlement Calculator</h1>
          <div className="SettlementCalculatorSubTitle">
            Curious about how much it might cost you to settle your Investment? Simply plug in your details below to
            find out.
          </div>
          <InvestmentInfo
            hometapShare={hometapShare}
            effectiveDate={investment?.effective_date}
            expirationDate={investment?.maturity_date}
            beginningHomeValue={investment?.beginning_home_value}
            investmentAmount={investment?.investment_amount}
            settlementDate={estimatedSettlementDate}
            appreciatedHomeValue={scenarios?.appreciated_home_value}
          />
          {hometapShare ? (
            <SettlementResult
              onBackButton={handleReset}
              settlementAmount={Math.round(scenarios?.hometap_share.dollars)}
            />
          ) : (
            <>
              <SettlementForm
                onSubmit={handleSubmitForm}
                isFetching={scenarioAsync.loading}
                expirationDate={investment?.maturity_date}
              />
              {scenarioAsync?.error && (
                <FormError
                  className="SettlementCalculatorFormError"
                  error="We had an issue calculating your settlement amount. Please try again or contact your Investment Manager for support."
                />
              )}
            </>
          )}
        </Paper>
        {hometapShare && <SettlementDisclaimer />}
      </div>
    </div>
  );
};

export default SettlementCalculatorController;
