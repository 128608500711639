import moment from 'moment';

import TrackDetailsTodoCard from '../TrackDetailsTodoCard/TrackDetailsTodoCard';

import './TrackDetailsStageSection.scss';
import { Badge, useBoolState, useWindowSize } from '@hometap/htco-components';
import cx from 'classnames';
import DocumentsUploadController from 'apps/documents/DocumentsUploadController';
import { useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat';
import useSearchParams from 'hooks/useSearchParams';
import useTrack from 'hooks/useTrack';
import { PLACEHOLDER_TODO } from 'apps/track-details/utils/constants';

const TrackDetailsStageSection = ({ isActive, sectionContext = {}, fetchAsyncTodos, sectionConfigData, todos }) => {
  const { title: staticTitle, getSubtitle, badge, showWithoutOtherStages, getTitleOverride } = sectionConfigData;

  const { fetch: fetchTrack } = useTrack();

  const { trackId } = useParams();
  const isTodoModalOpen = useBoolState();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useSearchParams();
  const selectedTodoId = query.get('task_id');

  const handleTodoModalClose = () => {
    isTodoModalOpen.setFalse();
    navigate(location.pathname);
  };

  const getNotes = (todo, includeUrlParameter) => {
    if (todo?.notes !== todo?.description) {
      if (includeUrlParameter) {
        return `&notes=${todo?.notes}`;
      }
      return todo?.notes;
    }
    if (todo?.variables?.todo_description !== todo?.description) {
      if (includeUrlParameter) {
        return `&notes=${todo?.variables?.todo_description}`;
      }
      return todo?.variables?.todo_description;
    }
    return '';
  };

  const handleTodoModalOpen = todo => {
    isTodoModalOpen.setTrue();
    const personIdAssociatedWithTodo = todo?.variables?.person_id;
    const personString = personIdAssociatedWithTodo ? `&person_id=${personIdAssociatedWithTodo}` : '';
    const description = todo?.description;
    const notes = getNotes(todo, true);
    navigate({
      pathname: location.pathname,
      search: `?title=${todo.title}&task_id=${todo.id}&kind=${todo.variables.doc_kind}${personString}&description=${description}${notes}`,
    });
  };

  const activeTodos = todos
    .filter(({ isActive, isComplete }) => isActive && !isComplete)
    // Sort ESTIMATE TODOs first, then gov_id TODOs, then others
    .sort((lhsTodo, rhsTodo) => {
      if (lhsTodo.title === rhsTodo.title) {
        return 0;
      }
      if (lhsTodo.title === PLACEHOLDER_TODO.ESTIMATE.title) {
        return -1;
      } else if (rhsTodo.title === PLACEHOLDER_TODO.ESTIMATE.title) {
        return 1;
      }
      return lhsTodo.variables?.doc_kind === 'gov_id' ? -1 : 1;
    });

  const inactiveTodos = todos.filter(({ isActive }) => !isActive);
  const orderedTodos = [...activeTodos, ...inactiveTodos];
  const subtitle = getSubtitle(sectionContext);
  const title = (getTitleOverride && getTitleOverride(sectionContext)) || staticTitle;

  const { isScreenSize } = useWindowSize('sm');

  return (
    <section className={cx('TrackDetailsStageSection', { showWithoutOtherStages, isActive })}>
      <header className="TrackDetailsStageSectionHeader">
        <div className="TrackDetailsStageSectionHeaderMeta">
          {badge && (
            <Badge
              className="TrackDetailsStageSectionBadge"
              label={badge.text}
              theme={isActive ? badge.theme : 'neutralLight50'}
              large={!isScreenSize('tablet')}
            />
          )}
          {isActive && !showWithoutOtherStages && (
            <span className="TrackDetailsStageSectionLastUpdated">
              Last updated{' '}
              {sectionContext.track?.investment_manager && (
                <span className="TrackDetailsStageSectionUpdatedBy">
                  by {sectionContext.track.investment_manager.first_name}{' '}
                </span>
              )}
              {moment().format('M/D/YY')}{' '}
            </span>
          )}
        </div>
        <div>
          <h2 className="TrackDetailsStageSectionTitle">{title}</h2>
          {isActive && <div className="TrackDetailsStageSectionSubtitle">{subtitle}</div>}
        </div>
      </header>

      {!!orderedTodos.length && (
        <>
          <hr className={cx('TrackDetailsStageSectionSeparator', { showWithoutOtherStages })} />
          <ul>
            {orderedTodos.map((todo, i) => {
              return (
                <li key={`${todo.title}-${i}`}>
                  <TrackDetailsTodoCard
                    todo={todo}
                    notes={getNotes(todo, false)}
                    // Only show the active state if the to-do is active and the section is active
                    isActive={!!todo.isActive && isActive}
                    showWithoutOtherStages={showWithoutOtherStages}
                    onSetIsTodoModalOpen={handleTodoModalOpen}
                  />
                </li>
              );
            })}
          </ul>
        </>
      )}

      {isTodoModalOpen.value && (
        <DocumentsUploadController
          key={selectedTodoId}
          asModalProps={{
            open: isTodoModalOpen.value,
            width: 1130,
            onClose: handleTodoModalClose,
          }}
          onUpload={async () => {
            // this prevents an unnecessary fetch for the track if the todo is a legacy hub task
            const areAllTodosLegacy = todos.every(todo => todo.is_legacy_hub_task);
            const updatedTodosList = await fetchAsyncTodos(trackId);
            const areAllTodosComplete = !updatedTodosList?.length;
            // if all todos are complete and not legacy, fetch the track to update the stage in the UI
            if (areAllTodosComplete && !areAllTodosLegacy) await fetchTrack();

            navigate(location.pathname);
            isTodoModalOpen.setFalse();
          }}
        />
      )}
    </section>
  );
};

export default TrackDetailsStageSection;
