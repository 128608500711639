import React from 'react';
import { Button } from '@hometap/htco-components';
import InquiryFormRow from './InquiryFormRow';
import InquiryRiskFactors from './InquiryRiskFactors';

const RiskAA = ({ riskFailFactors, onBack }) => {
  const todayDate = new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  return (
    <div className="DQAdverseActionWrapper">
      <div className="DQBackButtonWrapper">
        <Button theme="link" className="DQLinkBack" onClick={onBack}>
          Back
        </Button>
      </div>
      <InquiryFormRow>
        <h1 className="DQHeader2" data-testid="dq-header-2">
          At this time, Hometap is unable to make an investment in your home.
        </h1>
      </InquiryFormRow>
      <InquiryFormRow>
        <div className="DQSubtitleHeader">We’d like to help you understand the possible reasons for that.</div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div>
          In making investment decisions, Hometap considers information obtained by a “soft credit pull” and/or a “hard
          credit pull” through a consumer credit reporting agency and considers information we collect from other third
          parties. A Hometap Investment is not a credit product, but Hometap’s decision was based in whole or in part of
          information obtained from the consumer credit reporting agency, Experian, on {<span>{todayDate}</span>}.
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div>
          We encourage everyone to check their credit report to ensure that the information is accurate and up to date.
          You can do that for free,{' '}
          <Button href="http://www.annualcreditreport.com" theme="link" rel="noreferrer" target="_blank">
            here
          </Button>
          .
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        You have the right under the Fair Credit Reporting act to make a written request, within 60 days, for a
        disclousure of the nature of the information about you that we received from Experian. You also have the right
        to know the information contained in your file at the consumer credit reporting agency. Experian played no part
        in our decision and is unable to supply specific reasons why we have denied your request. You also have a right
        to a free copy of your credit report from Experian. If you request it no later than 60 days after you receive
        this notice from:
      </InquiryFormRow>
      <InquiryFormRow>
        <div className="DQAdverseActionAddress">
          <div>Experian</div>
          <div>P.O. Box 9701</div>
          <div>Allen, TX 75013</div>
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        The consumer report received included your credit score, which is a number that reflects the information in your
        consumer report. Credit scores range from 300-850. Your credit score can change, depending on how the
        information in your consumer report changes.
      </InquiryFormRow>
      <InquiryRiskFactors riskFailFactors={riskFailFactors} />
      <InquiryFormRow>
        If you believe that any information contained in the report is inaccurate or incomplete, you have the right to
        dispute the matter with the reporting agency.
      </InquiryFormRow>
      <InquiryFormRow>
        If you have any questions regarding this notice, please contact us at homeowners@hometap.com.
      </InquiryFormRow>
    </div>
  );
};

export default RiskAA;
