import { Container, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';

import { emailValidator, phoneValidator } from 'utils/validators';

import useApplicantForm from '../../hooks/useApplicantForm';
import { APPLICANT_FORM_FIELD } from '../../constants/formFields';
import useApplicationContext from 'apps/application/hooks/useApplicationContext';

import './ContactInfoForm.scss';

const preferredContactMethodOptions = [
  { label: 'Email', value: 'email' },
  { label: 'Text Message', value: 'sms' },
  { label: 'Phone Call', value: 'phone' },
];

const ContactInfoForm = () => {
  const { registerApplicantField, applicantFormData } = useApplicantForm();
  const { applicants } = useApplicationContext();
  const { applicantId } = useParams();

  const validateEmail = val => {
    const applicantsWithEmail = applicants.filter(({ email, isNew, id }) => {
      const isCurrentApplicantPersisted = !isNew && id === applicantId;
      if (isCurrentApplicantPersisted) {
        return false;
      }
      return !isNew && email.toLowerCase() === val.toLowerCase();
    });

    return emailValidator(val) || (applicantsWithEmail?.length && 'An applicant with this email already exists');
  };

  return (
    <div className="ApplicationInfoFormFields">
      <Container row className="ApplicationContactInfoContainer">
        <Container grid="8">
          <MuiTextInput
            className="EmailAddressInput"
            label="Email Address"
            {...registerApplicantField(APPLICANT_FORM_FIELD.email)}
            validator={validateEmail}
            disabled={applicantFormData?.isPrimaryApplicant}
            maxLength={254}
            width="100%"
            required
          />
        </Container>
        <Container grid="4" className="ApplicationPhoneNumberInput">
          <MuiTextInput
            required
            label="Phone Number"
            mask="000-000-0000"
            validator={phoneValidator}
            width="100%"
            {...registerApplicantField(APPLICANT_FORM_FIELD.phone)}
          />
        </Container>
      </Container>
      <Container>
        <MuiSelect
          className="PreferredMethodInput"
          label="Preferred Contact Method"
          options={preferredContactMethodOptions}
          width="100%"
          required
          {...registerApplicantField(APPLICANT_FORM_FIELD.preferredContactMethod)}
        />
      </Container>
    </div>
  );
};

export default ContactInfoForm;
