import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, SelectionSet, useAsync } from '@hometap/htco-components';
import { CallToAction, ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import {
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import { patchInquiry } from '../../inquiry/data/inquiryRequests';
import house from '../../../images/inquiry/property_use.png';
import './InquiryPropertyUse.scss';

const InquiryPropertyUse = ({ inquiryId, form, step, onNext }) => {
  const { formData, handleSubmit, registerField } = form;
  const { street, residence_type } = formData;

  const { results, loading, execute: updateInquiry } = useAsync(patchInquiry);

  const canContinueToTheNextPage = residence_type;
  canContinueToTheNextPage && scrollIntoViewByElementId(CONTINUE_BUTTON_ID);

  useEffect(() => {
    if (results) {
      onNext(step + 1);
    }
  }, [results, onNext, step]);

  const onSubmit = () => {
    const { residence_type } = formData;
    const updateApi = handleSubmit(() => updateInquiry(inquiryId, { residence_type }));
    updateApi();
  };

  return (
    <div className="PropertyUse" data-testid="inquiry_property_use_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.PROPERTY_USE]} />
      <ProgressiveUITemplate>
        <ContentPanel>
          <h1>Tell us about your property</h1>
          <div className="PropertyUseSubtitle">
            How do you use the property at <span className="PropertyUseAddress">{street}?</span>
          </div>
          <div className="InquirySelectionSet">
            <SelectionSet
              name="propertyUseOptions"
              labelWidth="100%"
              labelGrow={false}
              options={[
                {
                  label: 'It’s my primary residence',
                  icon: 'house-3',
                  value: 'pr',
                },
                {
                  label: 'It’s a vacation home',
                  icon: 'house-nature',
                  value: 'va',
                },
                {
                  label: 'I rent it out',
                  icon: 'house-rent',
                  value: 're',
                },
              ]}
              {...registerField(FIELDS.RESIDENCE_TYPE)}
            />
          </div>
          <CallToAction>
            <Button id={CONTINUE_BUTTON_ID} disabled={!canContinueToTheNextPage} onClick={onSubmit} loading={loading}>
              Continue
            </Button>
          </CallToAction>
        </ContentPanel>
        <ImageContentPanel imageSource={house} imgAlt="A house with people unpacking boxes" includeTrustPilot={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryPropertyUse;
