import React from 'react';
import cx from 'classnames';
import { Container } from '@hometap/htco-components';

const InquiryFormRow = ({ className = '', alignment, ...props }) => {
  const childrenCount = React.Children.count(props.children);

  if (childrenCount === 2) {
    return (
      <Container row className={cx('InquiryContainerRow', className)}>
        <Container grid="6" className="InquiryContainerColLeft">
          {props.children[0]}
        </Container>
        <Container grid="6" className="InquiryContainerColRight">
          {props.children[1]}
        </Container>
      </Container>
    );
  }
  return (
    <Container row className={cx('InquiryContainerRow', className)}>
      <Container grid="12">{props.children}</Container>
    </Container>
  );
};
export default InquiryFormRow;
