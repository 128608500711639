export const scrollIntoViewByElementId = elemId => {
  document.getElementById(elemId).scrollIntoView({
    behavior: 'smooth',
  });
};

/**
 * Verify whether the document can be tagged with a read receipt
 * @param {Document} document the document to be checked for a read receipt
 * @returns whether the document can be tagged with a read receipt
 */
export const readReceiptEligibleDocumentKinds = document => {
  // since some document kinds are kind_other and some are kind we need a way to handle that
  const eligibleKindOther = ['Investment Disclosure'];
  const eligibleKind = ['Investment Disclosure'];
  return eligibleKindOther.includes(document?.kind_other) || eligibleKind.includes(document?.kind);
};
