import { motion } from 'framer-motion';

const variantsNext = {
  hidden: {
    opacity: 0.5,
    x: '100vw',
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  dismiss: {
    opacity: 0,
    x: '-10vw',
    transition: {
      duration: 0.2,
    },
  },
};
const variantsBack = {
  hidden: {
    ...variantsNext.hidden,
    x: '-100vw',
  },
  show: variantsNext.show,
  dismiss: {
    ...variantsNext.dismiss,
    x: '10vw',
  },
};

const OnboardTransition = ({ children, forward = true }) => {
  const variants = forward ? variantsNext : variantsBack;
  return (
    <motion.div className="OnboardTransition" initial="hidden" animate="show" exit="dismiss" variants={variants}>
      {children}
    </motion.div>
  );
};

export default OnboardTransition;
