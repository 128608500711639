import { useWindowSize } from '@hometap/htco-components';
import PricingMatrixTable from './components/PricingMatrixTable/PricingMatrixTable';

import './PricingMatrix.scss';
import PricingMatrixCard from './components/PricingMatrixCard/PricingMatrixCard';

const PricingMatrix = ({ rows, data }) => {
  const { isScreenSize } = useWindowSize({ delay: 250 });

  return isScreenSize('sm') ? (
    data.map((el, i) => {
      return <PricingMatrixCard rows={rows} cardData={el} key={`pricing-matrix-${i}`} />;
    })
  ) : (
    <PricingMatrixTable rows={rows} data={data} />
  );
};

export default PricingMatrix;
