import { useRef, useState, useEffect } from 'react';
import Slide from '@mui/material/Slide';
import { Grid, InputMask, MuiTextInput, Button, MuiSelect } from '@hometap/htco-components';
import { LIEN_FIELDS, SCENARIO_PERIOD_OPTIONS } from 'apps/dashboard/constants';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { buildSelfReportedLienRequestData } from 'apps/dashboard/data';
import { FORM_TITLES, PROFILE_INSTANCES } from 'apps/dashboard/constants/buildYourProfile';
import debtInfo from 'images/dashboard/debt-info.jpg';
import { positiveValueValidator } from 'utils/validators';
import { useCurrentHome } from 'hooks/useCurrentHome';
import './Debt.scss';

const Debt = ({ onNext, onBack, onConfirm, form, instance }) => {
  const { formData } = form;
  const { registerField, isFormValid } = form;

  const [curLienStep, setCurLienStep] = useState(0);
  const [prevLienStep, setPrevLienStep] = useState(0);

  const { home } = useCurrentHome();
  const containerRef = useRef(null);
  const { currentScenario, isFutureView, addLien, updateLien, getEquityScenarioLiensAsync } =
    useEquityScenarioContext();

  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;
  const getEquityScenarioLiens = getEquityScenarioLiensAsync?.execute;
  const isLoading = getEquityScenarioLiensAsync.loading;
  const liens = formData?.liens;

  useEffect(() => {
    const getLiens = async () => {
      await getEquityScenarioLiens(home?.id, currentScenario?.id);
    };
    if (home?.id && currentScenario?.id) {
      getLiens();
    }
  }, [home?.id, currentScenario?.id, getEquityScenarioLiens]);

  const getTitle = () => {
    const titleConfig = FORM_TITLES.find(lien => lien.lien_type === liens?.[curLienStep]);
    return instance === PROFILE_INSTANCES.EDIT_PROFILE ? titleConfig?.editTitle : titleConfig?.title;
  };

  const updateLienStages = onNext => {
    const increment = onNext ? curLienStep + 1 : curLienStep - 1;
    setPrevLienStep(curLienStep);
    setCurLienStep(increment);
  };

  const singleLien = liens?.length === 1;

  const onDebtNext = async lienType => {
    const existingLien = equityScenarioLiens?.find(lien => lien.lien_type === lienType);
    const requestData = buildSelfReportedLienRequestData({ lienType, ...form?.formData });
    const scenario = { ...currentScenario };

    if (existingLien) {
      await updateLien(scenario, existingLien?.id, requestData);
    } else {
      await addLien(scenario, requestData);
    }

    await getEquityScenarioLiensAsync?.execute(home?.id, currentScenario?.id);

    onNext(lienType);
    if (!singleLien) {
      updateLienStages(true);
    }
  };

  const onDebtBack = lienType => {
    const index = liens.indexOf(lienType);

    // The homeowner is transitioning from one lien info page to the other
    if (index > 0) {
      return updateLienStages(false);
    }
    setPrevLienStep(0);
    onBack(false);
  };

  const getDebtInfoDirection = lien => {
    const isForward = prevLienStep < curLienStep;
    const isBack = prevLienStep > curLienStep;
    const lienStep = liens.indexOf(lien);

    // The logic below ensures that both the old and new pages transition in to the left.
    // The "direction" property indicates how the page will enter, so for the page the homeowner clicks
    // "Back" or "Next" on needs to be set to the opposite to ensure the correct "exit" direction

    if (isBack) {
      return lienStep > curLienStep ? 'left' : 'right';
    }

    if (isForward || !prevLienStep) {
      return lienStep < curLienStep ? 'right' : 'left';
    }
  };

  const getTermField = () => {
    const mortgage = equityScenarioLiens?.find(lien => lien.lien_type === 'mortgage_loan');
    const hasLienKindOther = mortgage?.lien_kind === LIEN_FIELDS.LIEN_KIND_OTHER;
    return hasLienKindOther ? 'mortgage_loan_lien_kind_other' : 'mortgage_loan_lien_kind';
  };

  // Manually validating the fields are populated, because useForm's `isFormValid` doesn't always seem up to date
  const enableNext = () => {
    const currentLienType = liens?.[curLienStep];
    const formDataKeys = [
      `${currentLienType}_rate`,
      `${currentLienType}_last_known_current_balance`,
      `${currentLienType}_monthly_payment`,
    ];

    const hasRequiredFields = formDataKeys.every(key => !!formData?.[key]);

    if (currentLienType === 'mortgage_loan') {
      return (
        hasRequiredFields &&
        (formData?.[`${currentLienType}_lien_kind`] || formData?.[`${currentLienType}_lien_kind_other`])
      );
    }

    return hasRequiredFields;
  };

  const renderLienForms = ({ containerRef }) => {
    return (
      <Grid className="DashboardBuildProfileLeftPanelWrapper" sm={12} md={6}>
        <div className="DebtContainer">
          <div className="DebtHeader">
            <h3>{getTitle()}</h3>
            {liens?.length > 1 && (
              <div className="DebtCount">
                <span className="DebtStep">0{curLienStep + 1}</span>&nbsp;/&nbsp;
                <span className="DebtTotal">0{liens.length}</span>
              </div>
            )}
          </div>
          {isFutureView && <span className="DebtDescription">These will be added to your future equity scenario.</span>}
          <div className="DebtForm">
            {liens?.map((lien, i) => {
              return (
                <Slide
                  key={`${lien}_${i}`}
                  direction={getDebtInfoDirection(lien)}
                  in={curLienStep === i}
                  timeout={400}
                  mountOnEnter
                  unmountOnExit
                  easing={{
                    enter: 'linear',
                    exit: 'linear',
                  }}
                  container={containerRef.current}
                  appear={i !== 0}
                >
                  <div className="DashboardBuildProfileLeftPanel" data-testid={`${lien}_info`}>
                    {lien === 'mortgage_loan' && (
                      <MuiSelect
                        name="mortgage_loan_lien_kind"
                        label="Term"
                        aria-label="Term"
                        data-testid="mortgage_loan_term"
                        width="100%"
                        options={SCENARIO_PERIOD_OPTIONS}
                        required
                        {...registerField(getTermField())}
                      />
                    )}
                    <MuiTextInput
                      name={`${lien}_rate`}
                      label="Rate"
                      aria-label="Rate"
                      type="number"
                      inputMode="decimal"
                      data-testid={`${lien}-rate`}
                      width="100%"
                      mask={InputMask.extend(InputMask.PERCENT_MASK, { max: 0.9999, min: 0.0001 })}
                      endAdornment="%"
                      unmask="typed"
                      placeholder=" "
                      required
                      {...registerField(`${lien}_rate`)}
                    />
                    <MuiTextInput
                      name={`${lien}_last_known_current_balance`}
                      label="Current balance"
                      aria-label="Current balance"
                      type="number"
                      inputMode="decimal"
                      data-testid={`${lien}-last-known-current-balance`}
                      mask={InputMask.MONEY_USD_MASK}
                      startAdornment="$"
                      unmask="typed"
                      required
                      width="100%"
                      placeholder=" "
                      validator={positiveValueValidator}
                      {...registerField(`${lien}_last_known_current_balance`)}
                    />
                    <MuiTextInput
                      name={`${lien}_monthly_payment`}
                      label="Monthly payment"
                      aria-label="Monthly payment"
                      type="number"
                      inputMode="decimal"
                      data-testid={`${lien}-monthly-payment`}
                      helperText="Only include principal and interest"
                      mask={InputMask.MONEY_USD_MASK}
                      startAdornment="$"
                      unmask="typed"
                      required
                      width="100%"
                      placeholder=" "
                      validator={positiveValueValidator}
                      {...registerField(`${lien}_monthly_payment`)}
                    />
                  </div>
                </Slide>
              );
            })}
          </div>
          <Grid className="DebtCTA" container alignItems="center">
            <Grid sm={6} md={6}>
              {onBack && (
                <Button
                  className="DebtBack"
                  theme="link"
                  onClick={() => onDebtBack(liens[curLienStep])}
                  data-testid="back-button"
                >
                  Back
                </Button>
              )}
            </Grid>
            <Grid sm={6} md={6}>
              <div className="DebtNext">
                <Button
                  size="large"
                  loading={isLoading}
                  disabled={!isFormValid || !enableNext() || isLoading}
                  onClick={() => onDebtNext(liens[curLienStep])}
                  data-testid="next-button"
                >
                  {singleLien ? 'Save' : 'Next'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <div className="w-full">
      <Grid className="Debt" container columnSpacing={{ sm: 2 }} data-testid="debt-info">
        {renderLienForms({ containerRef })}
        <Grid
          className="DashboardBuildProfileStepImage"
          sm={12}
          md={6}
          display="flex"
          justifyContent="right"
          alignItems="center"
        >
          <img className="DashboardBuildProfileImage" src={debtInfo} alt="home-equity" />
        </Grid>
      </Grid>
    </div>
  );
};

export default Debt;
