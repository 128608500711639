import React, { useState } from 'react';
import { Button, IconButton, Modal, SlideDown, Tooltip, useWindowSize } from '@hometap/htco-components';
import DebtListItem from './debtListItem/DebtListItem';
import DebtFormController from '../../controllers/DebtFormController';

import './DebtList.scss';

const DebtList = ({ debts, onDebtSubmit, onDebtDelete, onFinish }) => {
  const DEBT_MAX_COUNT = 5;
  const [open, setOpen] = useState(false);
  const { isScreenSize } = useWindowSize();
  const [isSlideDownVisible, setIsSlideDownVisible] = useState(() => !isScreenSize('md'));
  const hasReachedDebtLimit = debts.length >= DEBT_MAX_COUNT;
  const onModalOpen = () => setOpen(true);
  const onModalClose = () => setOpen(false);

  if (!isSlideDownVisible && !isScreenSize('md')) {
    setIsSlideDownVisible(true);
  }

  return (
    <div>
      {isScreenSize('md') && (
        <div className="DebtChartDebtListHeading">
          <strong>Your Debts</strong>
          <IconButton
            onClick={() => setIsSlideDownVisible(!isSlideDownVisible)}
            icon={isSlideDownVisible ? 'minus' : 'plus'}
            size="lg"
            data-testid="debt-expand-collapse"
          />
        </div>
      )}
      <div>
        <SlideDown
          visible={isSlideDownVisible}
          maxHeight={130 * debts.length}
          className="DebtChartDebtListItemsSlideDown"
        >
          <div className="DebtChartDebtListItems">
            {debts.map((debt, i) => (
              <DebtListItem key={i} debtInfo={debt} onDebtDelete={onDebtDelete} index={i} />
            ))}
          </div>
        </SlideDown>
      </div>

      <div className="DebtChartDebtListButtons">
        <Tooltip
          className="DebtChartErrorWrapper"
          content="Sorry, we can only calculate five debts at a time."
          action={hasReachedDebtLimit ? 'hover' : null}
        >
          <Button label="+ Add debt" theme="secondary" onClick={onModalOpen} disabled={hasReachedDebtLimit} />
        </Tooltip>
        <Modal open={open} onClose={onModalClose} width={530} allowClose={false}>
          <DebtFormController
            onDebtSubmit={onDebtSubmit}
            onCancel={onModalClose}
            isModalOpen={open}
            title="What type of loan would you like to add?"
            buttonText="Add my debt"
            debts={debts}
          />
        </Modal>
        <Button label="I’m finished" onClick={onFinish} />
      </div>
    </div>
  );
};

export default DebtList;
