import cx from 'classnames';
import { useRef, useState } from 'react';
import { motion, useAnimation, useCycle } from 'framer-motion';
import { Outlet } from 'react-router-dom-v5-compat';
import { useWindowSize } from '@hometap/htco-components';

import SectionLoadingWrapper from 'components/SectionLoadingWrapper/SectionLoadingWrapper';
import AddNewPropertyModal from 'components/modals/AddNewPropertyModal/AddNewPropertyModal';
import NavPrimaryMenu from 'components/nav/NavPrimaryMenu/NavPrimaryMenu';
import PrimaryMobileHeader from 'components/Headers/PrimaryMobileHeader/PrimaryMobileHeader';
import NavHomePickerDropdown from 'components/nav/NavHomePickerDropdown/NavHomePickerDropdown';

import { ANIMATION_VARIANT, headerVariants, sidebarVariants } from './animationVariants';
import usePrimaryNavItems from './hooks/usePrimaryNavItems';
import { useCurrentHome, useUserHomes } from 'hooks/useCurrentHome';

const PrimaryLayout = () => {
  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize('sm') || isScreenSize('md');

  const [isNewPropertyModalOpen, setNewPropertyModalOpen] = useState(false);
  const [isNavPrimaryMenuMounted, setNavPrimaryMenuMounted] = useState(!isMobile);
  const { data: homes } = useUserHomes();
  const { loading, home, setHomeById } = useCurrentHome();
  const [isMobileNavOpen, cycleIsMobileNavOpen] = useCycle(false, true);

  const mobileHeaderControls = useAnimation();
  const mobileNavMenuControls = useAnimation();

  const handleLocationChange = () => {
    if (isMobileNavOpen && isMobile) {
      cycleIsMobileNavOpen(false);
      mobileNavMenuControls.start(ANIMATION_VARIANT.sidebarClosed);
      mobileHeaderControls.start(ANIMATION_VARIANT.sidebarClosed);
    }
  };

  const { navSectionGroups, openSection, navLinks, onToggleOpenSection } = usePrimaryNavItems({
    isMobile,
    onLocationChange: handleLocationChange,
  });

  let SideBarHeader = NavHomePickerDropdown[isMobile ? 'Mobile' : 'Desktop'];
  if (isMobile && !isMobileNavOpen) {
    SideBarHeader = null;
  } else if (!isMobileNavOpen && !isMobile) {
    mobileNavMenuControls.start(ANIMATION_VARIANT.sidebarOpen);
  }

  const pageRef = useRef();
  return (
    <SectionLoadingWrapper loading={loading}>
      {isNewPropertyModalOpen && <AddNewPropertyModal setIsOpen={setNewPropertyModalOpen} />}

      <div className="relative h-screen overflow-x-hidden overflow-y-scroll sm:flex sm:overflow-x-auto" ref={pageRef}>
        {isMobile && (
          <motion.div
            initial={ANIMATION_VARIANT.sidebarClosed}
            animate={isMobile && mobileHeaderControls}
            className="sticky top-0 border-0 border-b border-solid border-neutral-light-100 bg-white"
            variants={headerVariants}
          >
            <PrimaryMobileHeader
              pageRef={pageRef}
              onSlideDown={() => mobileHeaderControls.start(ANIMATION_VARIANT.hidden)}
              onSlideUp={() => mobileHeaderControls.start(ANIMATION_VARIANT.show)}
              title={home?.address.street}
              onIconClick={() => {
                mobileNavMenuControls.start(ANIMATION_VARIANT.sidebarOpen);
                mobileHeaderControls.start(ANIMATION_VARIANT.sidebarOpen);
                setNavPrimaryMenuMounted(true);
                cycleIsMobileNavOpen(true);
              }}
            />
          </motion.div>
        )}

        <motion.aside
          className={cx(
            'left-0 top-0 flex-shrink-0 border border-y-0 border-l-0 border-r-0 border-solid border-neutral-light-100 bg-white sm:sticky sm:h-screen sm:w-96 sm:border-r',
            { 'absolute inset-0': isMobile && isMobileNavOpen },
          )}
          initial={isMobile ? ANIMATION_VARIANT.sidebarClosed : ANIMATION_VARIANT.sidebarOpen}
          animate={mobileNavMenuControls}
          variants={sidebarVariants}
        >
          {SideBarHeader && (
            <div className={cx('border-0 border-b border-solid border-neutral-light-100', { 'p-4': !isMobile })}>
              <SideBarHeader
                homes={homes}
                selectedHome={home}
                onSelectHome={setHomeById}
                onAddNewProperty={setNewPropertyModalOpen}
                onIconClick={() => {
                  mobileNavMenuControls.start(ANIMATION_VARIANT.sidebarClosed);
                  mobileHeaderControls.start(ANIMATION_VARIANT.sidebarClosed);
                  cycleIsMobileNavOpen(false);
                }}
              />
            </div>
          )}

          {isNavPrimaryMenuMounted && (
            <NavPrimaryMenu
              className={cx({ hidden: isMobile && !isMobileNavOpen })}
              openSection={openSection}
              onToggleOpenSection={onToggleOpenSection}
              navSectionGroups={navSectionGroups}
              navLinks={navLinks}
            />
          )}
        </motion.aside>

        <div className="w-full">
          {!isMobile && (
            <div className="h-[87px] border-0 border-b border-solid border-neutral-light-100 bg-white p-2">
              {/** TODO: replace this with the desktop header */}
              <PrimaryMobileHeader title="123 main street" />
            </div>
          )}

          <Outlet />
        </div>
      </div>
    </SectionLoadingWrapper>
  );
};

export default PrimaryLayout;
