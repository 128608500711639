import React from 'react';
import cx from 'classnames';
import { Button } from '@hometap/htco-components';

import './PromotionalOfferCard.scss';

const themeMap = {
  primary: 'isPrimary',
  secondary: 'isSecondary',
};

const PromotionalOfferCard = ({ theme, title, text, extraText, linkText, href, image, disclaimer, isLoading }) => {
  return (
    <div className="PromotionalOfferCard">
      <div className={cx('PromotionalOfferCardContainer', themeMap[theme], { isLoading: isLoading })}>
        {!isLoading && (
          <>
            <div>
              {image && (
                <div className="PromotionalOfferCardImageContainer">
                  <img src={image} alt="promotional offer" className="PromotionalOfferCardImage" />
                </div>
              )}
              <h2 className="PromotionalOfferCardTitle">{title}</h2>
              {text && (
                <p className="PromotionalOfferCardText">
                  {text} {extraText && <span className="PromotionalOfferCardExtraText">{extraText}</span>}
                </p>
              )}
              <Button size="small" className="PromotionalOfferCardLink" href={href} target="_blank">
                {linkText}
              </Button>
            </div>
            {disclaimer && <div className="PromotionalOfferCardDisclaimer">{disclaimer}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default PromotionalOfferCard;
