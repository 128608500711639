import { Grid } from '@hometap/htco-components';
import scenarioImg from 'images/equity-planner/dashboard/scenario-empty.png';

const ScenarioEmptyPanel = () => {
  return (
    <div className="ScenarioEmptyContainer">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid sm={12} md={6} lg={6} className="ScenarioEmptyLeft">
          <h1>
            Explore what your equity can do <span className="ScenarioEmptyHighlightText">for you</span>
          </h1>
          <p>Select a scenario below that aligns with your goals and compare against the current state of your home.</p>
        </Grid>
        <Grid sm={12} md={6} lg={6} alignItems="center" className="ScenarioEmptyRight">
          <img src={scenarioImg} alt="empty scenario" className="ScenarioEmptyImg" />
        </Grid>
      </Grid>
    </div>
  );
};

export default ScenarioEmptyPanel;
