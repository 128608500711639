import { mapKeys, pickBy } from 'lodash';
import { apiNoAuth } from 'utils/api';
import {
  hasValidHomeEquityLineOfCreditData,
  hasValidHomeEquityLoanData,
  hasValidMortgageData,
  hasValidOtherLien,
} from '../utils/dataUtils';
import { getVersion } from 'utils/env';

export const createAccount = async data => {
  return await apiNoAuth.post(`/auth/users/`, data);
};

export const buildSelfReportedLienRequestData = formData => {
  const { lienType } = formData;

  const validateLienTypes = lienData => {
    const VALIDATION_RULES = {
      mortgage_loan: hasValidMortgageData,
      home_equity_loan: hasValidHomeEquityLoanData,
      home_equity_line_of_credit: hasValidHomeEquityLineOfCreditData,
      judgement_tax_or_other: hasValidOtherLien,
    };
    return VALIDATION_RULES[lienData.lien_type](lienData);
  };

  // Find form data properties that are associated with the lien type
  const containsMatchingProperty = pickBy(formData, (value, key) => key.includes(lienType));

  // Remove the lien type from the string (ex: mortgage_loan_rate --> rate)
  const formatKey = (value, key) => key.replace(`${lienType}_`, '');
  const lienData = {
    lien_type: lienType,
    ...mapKeys(containsMatchingProperty, formatKey),
  };

  if (lienData.lien_kind_other && !lienData.lien_kind) {
    lienData.lien_kind = 'lien_kind_other';
  }

  if (validateLienTypes(lienData)) {
    return lienData;
  }
};

export const buildAccountCreateRequestData = formData => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    street,
    unit,
    city,
    state,
    zip_code,
    tracking_data,
    onboarding_goal,
    onboarding_goal_other,
  } = formData;

  const data = {
    first_name,
    last_name,
    email,
    phone_number,
    homeowner_goal_onboarding: onboarding_goal,
    home: {
      address: { street, unit, city, state, zip_code },
    },
    tracking_data,
  };

  if (onboarding_goal === 'other') {
    data.homeowner_goal_onboarding_other = onboarding_goal_other;
  }

  return data;
};

export const buildSegmentData = formData => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    street,
    unit,
    city,
    state,
    zip_code,
    onboarding_goal,
    onboarding_goal_other,
    tracking_data,
  } = formData;

  // append "account_" to tracking data for product
  const trackingDataAppended = {};
  Object.keys(tracking_data).forEach(key => {
    const appendedKey = `account_${key}`;
    trackingDataAppended[appendedKey] = tracking_data[key];
  });

  const segmentData = {
    first_name,
    last_name,
    email,
    phone_number,
    street,
    unit,
    city,
    state,
    zip_code,
    onboarding_goal,
    onboarding_goal_other,
    ...trackingDataAppended,
    version_number: getVersion(),
  };

  return segmentData;
};
