import { Button } from '@hometap/htco-components';
import useAccessHomeEquityCTA from 'apps/dashboard/utils/useAccessHomeEquityCTA';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

const AccessHomeEquityCTA = ({ size = 'small', className, onClick }) => {
  const { accessEquityHeaderCta } = useFlags();
  const { hasTappableEquity, openAccessEquityWindow } = useAccessHomeEquityCTA();

  return (
    hasTappableEquity &&
    accessEquityHeaderCta && (
      <Button
        data-testid="access-equity-cta"
        className={twMerge(cx('mx-4 inline-block', className))}
        size={size}
        onClick={() => {
          onClick && onClick();
          openAccessEquityWindow();
        }}
      >
        Access equity
      </Button>
    )
  );
};

export default AccessHomeEquityCTA;
