import useApplicantForm from '../hooks/useApplicantForm';
import { APPLICANT_FORM_FIELD } from '../constants/formFields';
import BoolField from '../components/BoolField/BoolField';

const OtherApplicantsForm = () => {
  const { registerApplicantField } = useApplicantForm();

  return (
    <div className="ApplicationInfoFormFields">
      <BoolField
        required
        trueLabel="Yes. I need to add another applicant."
        falseLabel="No. There are no other applicants."
        baseRegisterField={registerApplicantField(APPLICANT_FORM_FIELD.hasOtherApplicants)}
        labelWidth="100%"
      />
    </div>
  );
};

export default OtherApplicantsForm;
