import AccessHomeEquityCTA from 'components/AccessHomeEquityCTA/AccessHomeEquityCTA';
import { useEffect, useRef, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

const usePrimaryNavItems = ({ isMobile, onLocationChange }) => {
  const location = useLocation();
  const previousLocation = useRef(null);

  useEffect(() => {
    if (location.pathname !== previousLocation.current) {
      onLocationChange(location.pathname);
    }

    previousLocation.current = location.pathname;
  }, [location, onLocationChange]);

  const navLinks = [
    {
      path: '/nav/investments',
      title: 'Investments',
      icon: 'compensation',
      active: !!matchPath(location.pathname, { path: '/nav/investments', exact: true }),
    },
    {
      path: '/nav/equity',
      title: 'Home equity forecast',
      icon: 'normal-appreciation',
      active: !!matchPath(location.pathname, { path: '/nav/equity', exact: true }),
    },
  ];

  const renovationsSection = {
    section: 'Scenario planner',
    icon: 'renovate',
    items: [
      { path: '/nav/1', title: 'nav 1', active: !!matchPath(location.pathname, { path: '/nav/1', exact: true }) },
      { path: '/nav/2', title: 'nav 2', active: !!matchPath(location.pathname, { path: '/nav/2', exact: true }) },
    ],
  };

  const resourcesSection = {
    section: 'Resources',
    icon: 'bookmark',
    items: [
      {
        path: '/nav/something',
        title: 'Renovation',
        active: !!matchPath(location.pathname, { path: '/nav/something', exact: true }),
      },
      {
        path: '/nav/something-else',
        title: 'Financing',
        active: !!matchPath(location.pathname, { path: '/nav/something-else', exact: true }),
      },
    ],
  };

  const mobileAccountSection = {
    section: 'Account',
    visible: isMobile,
    extraContent: () => (
      <AccessHomeEquityCTA
        onClick={onLocationChange}
        className="m-0 mt-3 w-full p-0 !text-base xs:mx-auto xs:w-1/2"
        size="large"
      />
    ),
    icon: 'avatar-1',
    items: [
      {
        path: '/nav/account',
        title: 'Renovation',
        active: !!matchPath(location.pathname, { path: '/nav/account', exact: true }),
      },
      {
        path: '/nav/account-else',
        title: 'Financing',
        active: !!matchPath(location.pathname, { path: '/nav/account-else', exact: true }),
      },
    ],
  };

  const isNavItemVisible = item => item.visible ?? true;
  const getActiveSection = section => section?.items.some(item => item.active);
  const removeInvisibleSections = section => section.filter(isNavItemVisible);

  const navSectionGroups = [[renovationsSection, resourcesSection], [mobileAccountSection]];
  const visibleGroups = navSectionGroups.map(removeInvisibleSections).filter(item => item.length);

  const [openSection, setOpenSection] = useState(visibleGroups.flat().find(getActiveSection)?.section || null);
  return { navLinks, navSectionGroups: visibleGroups, openSection, onToggleOpenSection: setOpenSection };
};

export default usePrimaryNavItems;
