import { IconButton } from '@hometap/htco-components';
import logo from 'images/hometap-logomark.svg';
import { useEffect, useRef } from 'react';

const PrimaryMobileHeader = ({ title, onIconClick, pageRef, onSlideUp, onSlideDown }) => {
  // ref prevents adding event listener on every render
  const lastScrollTopRef = useRef(0);
  const page = pageRef?.current;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = pageRef.current.scrollTop;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop > lastScrollTop && currentScrollTop > 50) {
        onSlideDown();
      } else {
        onSlideUp();
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    if (!page) return;
    page.addEventListener('scroll', handleScroll);

    return () => {
      page.removeEventListener('scroll', handleScroll);
    };
  }, [page, onSlideUp, onSlideDown, pageRef]);

  return (
    <header className="flex items-center justify-between px-6">
      <div className="flex items-center">
        <div className="border-0 border-r border-solid border-neutral-light-100 p-6 pl-0 outline-offset-[-1px]">
          <IconButton className="m-0 h-auto w-auto font-bold text-neutral-dark-75" icon="menu" onClick={onIconClick} />
        </div>
        <strong className="p-1 pl-[20px] leading-[26px]">{title}</strong>
      </div>

      <img className="w-[48px]" src={logo} alt="hometap logo" />
    </header>
  );
};

export default PrimaryMobileHeader;
