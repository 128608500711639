import { convertDateStringToHumanReadableMonth } from 'utils/date';
import { useMemo } from 'react';

const QuarterlyStatementHeader = ({ homeAddress, quarter, investmentId, applicants }) => {
  const homeOwnerNames = (applicants || []).map(applicant => applicant.full_name).join(', ');
  const startDate = quarter && convertDateStringToHumanReadableMonth(quarter.start);
  const endDate = quarter && convertDateStringToHumanReadableMonth(quarter.end);

  const addressLine = useMemo(
    () =>
      homeAddress &&
      [homeAddress.street, homeAddress.unit, homeAddress.city, homeAddress.state, homeAddress.zip_code]
        .filter(Boolean)
        .join(', '),
    [homeAddress],
  );
  return (
    <div className="block justify-between gap-10 border-0 border-solid border-neutral-light-100 sm:flex">
      <div>
        <h1 className="mb-1">Quarterly statement</h1>
        <div
          className="text-[22px] text-base/[1.5] leading-[34px] text-neutral-dark-75"
          data-testid="quarterly_statement_quarter_bounds"
        >
          {startDate} – {endDate}
        </div>
      </div>
      <div className="mt-4 sm:mt-0 sm:text-right">
        <div data-testid="quarterly_statement_home_address" className="pb-2 font-bold">
          {addressLine}
        </div>
        <div className="mb-2 whitespace-nowrap">
          <span>Investment ID:</span>&nbsp;
          <span data-testid="quarterly_statement_track_id">{investmentId}</span>
        </div>
        <div data-testid="quarterly_statement_applicants">{homeOwnerNames}</div>
      </div>
    </div>
  );
};

export default QuarterlyStatementHeader;
