import React from 'react';
import { Button } from '@hometap/htco-components';
import './RenovationFinanceCard.scss';

const RenovationFinanceCard = ({
  label,
  description,
  ctaButtonText,
  secondaryButtonText,
  onCtaButtonClick,
  onLearnMoreClick,
  tag,
  fees,
  timeToSettle,
  monthlyPayment,
  interest,
}) => {
  return (
    <div className="RenovationFinanceCard">
      {tag && <div className="RenovationFinanceCardTag">{tag}</div>}
      <div className="RenovationFinanceCardContent">
        <div className="RenovationFinanceCardLabel">{label}</div>
        <p className="RenovationFinanceCardDescription">{description}</p>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Fees:</span>
          <span className="RenovationFinanceCardStatsInfo">{fees}</span>
        </div>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Time to settle:</span>
          <span className="RenovationFinanceCardStatsInfo">{timeToSettle}</span>
        </div>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Monthly payments:</span>
          <span className="RenovationFinanceCardStatsInfo">{monthlyPayment}</span>
        </div>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Interest:</span>
          <span className="RenovationFinanceCardStatsInfo">{interest}</span>
        </div>
      </div>
      <div className="RenovationFinanceCardButtonsWrapper">
        {ctaButtonText && <Button theme="primary" label={ctaButtonText} size="small" onClick={onCtaButtonClick} />}
        <Button theme="secondary" label={secondaryButtonText} size="small" onClick={onLearnMoreClick} />
      </div>
    </div>
  );
};

export default RenovationFinanceCard;
