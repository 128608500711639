import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { fetchPrequalInvestmentRange } from 'apps/dashboard/data/pricing';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import { useHomeValuation } from './useHomeValuation';
import { useQuery } from '@tanstack/react-query';
import { useCurrentHome } from 'hooks/useCurrentHome';

const useHedPrequalRange = () => {
  const { home } = useCurrentHome();
  const { homeValuation } = useHomeValuation();
  const { currentScenario } = useEquityScenarioContext();

  const { data, isLoading, error } = useQuery({
    queryFn: async () => {
      const body = {
        home_value: homeValuation.value,
        state: home.operationalState.abbreviation,
        effective_period: {
          length: 10,
          unit: 'years',
        },
      };
      const filteredLiens =
        currentScenario?.liens?.filter(lien => lien.lien_type !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT) ||
        [];

      if (filteredLiens.length) {
        body.loan_balance = filteredLiens.reduce((sum, lien) => {
          const balance = lien.estimated_lien_balance || lien.current_balance;
          sum = Number(sum) + Number(balance);
          return sum;
        }, 0);
      } else {
        body.loan_balance = 0;
      }
      return await fetchPrequalInvestmentRange(body);
    },
    queryKey: [
      'hed-prequal-range',
      {
        scenarioId: currentScenario?.id,
        homeValue: homeValuation?.value,
        operationalState: home?.operationalState?.abbreviation,
      },
    ],
    enabled: !!home && !!home.operationalState && !!homeValuation && !!currentScenario,
  });

  return { prequalRange: data, preQualLoading: isLoading, preQualError: error };
};

export default useHedPrequalRange;
