import { useAsync } from '@hometap/htco-components';

import { createApplicationConsent, fetchApplication, updateApplication } from '../data/applicationRequests';

const useApplication = applicationId => {
  const fetchApplicationAsync = useAsync(fetchApplication);
  const updateApplicationAsync = useAsync(updateApplication, { executeThrow: true });
  const consentApplicationAsync = useAsync(createApplicationConsent, { executeThrow: true });
  const application = fetchApplicationAsync.results;

  const updateApplicationAndRefetch = async data => {
    const updatedApplication = await updateApplicationAsync.execute({ applicationId, data }).catch(error => {
      throw error;
    });
    await fetchApplicationAsync.execute({ applicationId });
    return updatedApplication;
  };

  const consentApplication = async () => {
    return await consentApplicationAsync.execute({ applicationId }).catch(error => {
      throw error;
    });
  };

  return {
    application,
    consentApplication,
    updateApplicationAndRefetch,
    isLoading: fetchApplication.loading || updateApplicationAsync.loading || consentApplicationAsync.loading,
    fetchLoading: fetchApplication.loading,
    executeFetchApplication: fetchApplicationAsync.execute,
    fetchError: fetchApplicationAsync.error,
  };
};

export default useApplication;
