import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { Button, MenuDropdown, MenuDropdownItem } from '@hometap/htco-components';
import { getDateDifference } from 'utils/date';
import { currency, percent } from 'utils/numbers';
import { buildNewHEDUrl, editHomeProfileUrl, getNewHEDUrl } from 'utils/links';
import { EQUITY_SCENARIO_LIEN_TYPE, LIEN_FIELDS, SCENARIO_PERIOD_OPTIONS } from '../../../constants';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { getRenovationSummary, parseRenovationKind } from '../equity-renovations/data/renovationUtils';
import { deleteRenovation } from '../equity-renovations/data/requests';
import { FINANCE_CALCULATOR_TYPE } from '../equity-finance/data/financeData';
import './HomeInfoCards.scss';
import { useCurrentHome } from 'hooks/useCurrentHome';

const LIEN_TITLE_MAP = {
  [EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE]: 'Mortgage',
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN]: 'Home Equity Loan',
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT]: 'Home Equity Line of Credit',
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT]: 'Home Equity Investment',
  [EQUITY_SCENARIO_LIEN_TYPE.OTHER]: 'Judgement, Tax, or Other Lien',
};

const ScenarioActionsDropdown = ({
  onToggleScenario,
  onDeleteScenario,
  onEditScenario,
  isCardEnabled,
  activeCardId,
  setActiveCardId,
  cardId,
}) => {
  const actions = [
    { label: 'Edit details', onClick: onEditScenario },
    { label: isCardEnabled ? 'Hide from chart' : 'Show on chart', onClick: onToggleScenario },
    { label: 'Delete from scenario', onClick: onDeleteScenario },
  ].filter(action => !!action.onClick);

  if (!actions.length) return null;

  const onMenuChange = () => {
    setActiveCardId(cardId);
  };

  return (
    <MenuDropdown
      onMenuChange={onMenuChange}
      isMenuOpen={activeCardId && activeCardId === cardId}
      size="2x"
      theme="dark"
    >
      {actions.map(({ onClick, label }, i) => (
        <MenuDropdownItem key={`${label}-${i}`} onClick={onClick} href={null}>
          {label}
        </MenuDropdownItem>
      ))}
    </MenuDropdown>
  );
};

const ScenarioCard = ({
  title,
  content,
  cta,
  exploreOptionsCtaClick,
  className,
  isCardEnabled,
  onToggleScenario,
  onDeleteScenario,
  onEditScenario,
  cardId,
  setActiveCardId,
  activeCardId,
}) => {
  return (
    <div className={cx('PropertyScenarioCard', className)}>
      <div className="PropertyDetailsContent">
        <div className={cx('PropertyScenarioCardTitle', { disableTitle: !isCardEnabled })}>{title}</div>
        <div className={cx('PropertyScenarioCardDetails', { disableDetails: !isCardEnabled })}>{content}</div>
        {cta && (
          <Button
            className="PropertyScenarioCardCTA"
            size="small"
            icon="icon-arrow-forward"
            theme="link"
            onClick={exploreOptionsCtaClick}
          >
            {cta}
          </Button>
        )}
      </div>
      <ScenarioActionsDropdown
        onToggleScenario={onToggleScenario}
        onDeleteScenario={onDeleteScenario}
        onEditScenario={onEditScenario}
        isCardEnabled={isCardEnabled}
        cardId={cardId}
        setActiveCardId={setActiveCardId}
        activeCardId={activeCardId}
      />
    </div>
  );
};

export const LienCard = ({ lien, exploreOptionsCtaClick, ...props }) => {
  const hasLienKindOther = lien.lien_kind === LIEN_FIELDS.LIEN_KIND_OTHER;
  const lienKind = hasLienKindOther ? lien.lien_kind_other : lien.lien_kind;
  let term = SCENARIO_PERIOD_OPTIONS.find(option => option.value === lienKind);

  // For external liens (such as Plaid) we receive a variety of lien kinds,
  // so if we don't find it in our internal SCENARIO_PERIOD_OPTIONS we label it by the lien_kind_other field
  if (hasLienKindOther && !term) {
    term = { label: lien.lien_kind_other };
  }

  const history = useHistory();

  const { currentScenario, futureScenario, deleteLien, hideLien, unhideLien, isFutureView } =
    useEquityScenarioContext();

  const contentText = `${percent(lien.rate, '0.0[00]')}, ${currency(lien.estimated_lien_balance)}, ${currency(
    lien.monthly_payment,
  )}`;
  const cardContent =
    lien.lien_type === EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE ? `${term?.label}, ${contentText}` : contentText;

  const financeOptionMap = {
    [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN]: FINANCE_CALCULATOR_TYPE.HEL,
    [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT]: FINANCE_CALCULATOR_TYPE.HELOC,
  };
  const financeOption = financeOptionMap[lien.lien_type] || FINANCE_CALCULATOR_TYPE.HEI;

  const handleEditScenario = () => {
    // Go to the add debts flow for the future scenario
    if (lien?.lien_type && isFutureView) {
      return history.push(getNewHEDUrl('/explore-financing'), { lienType: lien.lien_type, stage: 2 });
    }

    // Go to the edit home profile flow for the current scenario
    return history.push(editHomeProfileUrl(), { lienType: lien.lien_type, step: 3, noConfirm: true });
  };

  const handleDeleteLienFromScenario = () => {
    deleteLien(currentScenario?.id, lien.id);
  };

  const handleToggleScenario = () => {
    const scenarioToUpdate = isFutureView ? futureScenario : currentScenario;

    if (!lien.hidden) {
      hideLien(scenarioToUpdate?.id, lien);
    } else {
      unhideLien(scenarioToUpdate?.id, lien);
    }
  };

  const title = LIEN_TITLE_MAP[lien.lien_type];

  return (
    <ScenarioCard
      className="WithLien"
      title={title}
      content={cardContent}
      onToggleScenario={handleToggleScenario}
      onDeleteScenario={isFutureView && handleDeleteLienFromScenario}
      onEditScenario={handleEditScenario}
      isCardEnabled={!lien?.hidden}
      exploreOptionsCtaClick={e => exploreOptionsCtaClick(e, financeOption)}
      {...props}
    />
  );
};

export const RenoCard = ({ renovation, toggleChartRenovations, ...props }) => {
  const { project, finishLevel, addedValue, costRecouped } = getRenovationSummary(renovation);
  const { home } = useCurrentHome();
  const { futureScenario, changeScenarioRenovations, isFutureView } = useEquityScenarioContext();
  const history = useHistory();

  const content = [finishLevel, currency(addedValue), costRecouped && percent(costRecouped)].filter(Boolean).join(', ');

  const handleDeleteRenoFromScenario = async () => {
    const remainingRenos = futureScenario?.renovations?.filter(({ id }) => id !== renovation.id);
    changeScenarioRenovations([...remainingRenos]);
    await deleteRenovation(home?.id, futureScenario?.id, renovation.id);
  };

  const handleEditScenario = () => {
    return history.push(
      buildNewHEDUrl({
        renoType: parseRenovationKind(renovation.renovation_kind).project.toLowerCase(),
        renoId: renovation.id,
      }),
    );
  };

  const handleToggleScenario = () => {
    toggleChartRenovations(renovation);
    changeScenarioRenovations(futureScenario?.renovations);
  };
  return (
    <ScenarioCard
      title={project}
      content={content}
      onToggleScenario={handleToggleScenario}
      onDeleteScenario={isFutureView && handleDeleteRenoFromScenario}
      onEditScenario={handleEditScenario}
      className="WithReno"
      isCardEnabled={!renovation?.hidden}
      isFuture={isFutureView}
      {...props}
    />
  );
};

export const InvestmentCard = ({ investment, exploreOptionsCtaClick, showHEI, toggleChartHEI, ...props }) => {
  const { futureScenario, isFutureView, deleteLien } = useEquityScenarioContext();

  const term = getDateDifference(investment.effective_date, investment.maturity_date, 'years');
  const content = `${term} years, ${currency(investment.investment_amount)}`;

  const lien = isFutureView
    ? futureScenario?.liens?.find(lien => lien.lien_type === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT)
    : null;

  const handleDeleteLienFromScenario = () => {
    deleteLien(futureScenario?.id, lien.id);
  };

  const handleToggleScenario = () => {
    return toggleChartHEI(!showHEI);
  };

  return (
    <ScenarioCard
      title="Home Equity Investment"
      content={content}
      cardType="hei"
      className="WithHEI"
      investment={investment}
      onToggleScenario={handleToggleScenario}
      onDeleteScenario={lien && isFutureView && handleDeleteLienFromScenario}
      isCardEnabled={showHEI}
      exploreOptionsCtaClick={e => exploreOptionsCtaClick(e, 'hei')}
      {...props}
    />
  );
};
