import useSearchParams from 'hooks/useSearchParams';

const useQueryParamData = fields => {
  const query = useSearchParams();

  const data = {};
  for (const [paramKey, paramValue] of query.entries()) {
    if (fields.includes(paramKey)) {
      data[paramKey] = paramValue;
    }
  }

  return data;
};

const useHomeEquityQueryParamData = fields => {
  const query = useSearchParams();

  const data = {};
  for (const [paramKey, paramValue] of query.entries()) {
    if (fields.includes(paramKey)) {
      if (paramKey === 'liens') {
        const decodedLienTypes = decodeURIComponent(paramValue);
        const lienTypeArray = decodedLienTypes.split(',');
        data.liens = lienTypeArray;
      } else {
        data[paramKey] = paramValue;
      }
    }
  }

  return data;
};

export default useQueryParamData;
export { useHomeEquityQueryParamData };
