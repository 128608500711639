import { Button, Icon, useAsync, useWindowSize } from '@hometap/htco-components';
import { onSaveError } from 'apps/application/utils';
import useTrack from 'hooks/useTrack';
import { useNavigate, Link } from 'react-router-dom-v5-compat';
import cx from 'classnames';

import './TrackDetailsTodoCard.scss';
import { TASK_DEFINITION_KEY } from '../../../../utils/constants/taskDefinitionKeys';

const TimeToCompleteSection = ({ shouldHideMobile, timeToComplete }) => {
  if (!timeToComplete) return null;

  return (
    <small className={cx('TrackDetailsCardTimeToComplete', { shouldHideMobile })}>
      <Icon name="clock" /> &nbsp;
      {timeToComplete}
    </small>
  );
};

const TrackDetailsTodoCard = ({ todo, notes, isActive, showWithoutOtherStages, onSetIsTodoModalOpen }) => {
  const { title, cta = {}, icon, description, timeToComplete } = todo;
  const { onClickAsyncFunc, ...ctaButtonProps } = cta;
  const navigate = useNavigate();
  const asyncState = useAsync(cta?.onClickAsyncFunc, { executeThrow: true });
  const { fetch: fetchTrack } = useTrack();
  const { isScreenSize } = useWindowSize({ delay: 1000 });
  const showNotes = todo.task_definition_key === TASK_DEFINITION_KEY.UPLOAD_FILE && notes;
  return (
    <div className={cx('TrackDetailsTodoCard', { isActive, showWithoutOtherStages })}>
      <div className="TrackDetailsTodoCardContentContainer">
        <div className="TrackDetailsTodoCardDescriptionContainer">
          <div className="TrackDetailsTodoCardIconContainer">
            <Icon className="TrackDetailsTodoCardIcon" name={isActive && icon ? icon : 'lock'} />
            <TimeToCompleteSection timeToComplete={timeToComplete} />
          </div>
          <div className="TrackDetailsCardDescription">
            <p className="TrackDetailsCardDescriptionTitle">{title}</p>
            <p className="TrackDetailsCardDescriptionSubtitle">{description || todo?.notes || todo?.subtitle}</p>
            {showNotes && (
              <div className="TrackDetailsTodoNoteWrapper">
                <p className="TrackDetailsTodoNoteTitle">Note</p>
                <p className="TrackDetailsTodoNoteSubtitle">{notes}</p>
              </div>
            )}
            <TimeToCompleteSection shouldHideMobile timeToComplete={timeToComplete} />
          </div>
        </div>
        {cta && isActive && (
          <Button
            className="TrackDetailsTodoCardCTA sm_cobrowsing_disabled_field"
            size={isScreenSize('xs') ? 'small' : 'normal'}
            href={cta.href}
            loading={asyncState.loading}
            onClick={async () => {
              if (cta.href || cta.to) return;

              const isAsyncFunc = !!cta.onClickAsyncFunc;
              if (isAsyncFunc) {
                // pass history to allow for redirect after async function success
                return await asyncState.execute({ navigate, fetchTrack }).catch(onSaveError());
              }

              return onSetIsTodoModalOpen(todo);
            }}
            {...ctaButtonProps}
          >
            {/* Button component doesn't support SPA routing */}
            {cta.to && <Link className="TrackDetailsTodoCardCTALink" to={cta.to} />}
            {cta.text}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TrackDetailsTodoCard;
