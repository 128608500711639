import Configurator from 'components/Configurator/Configurator';
import ModalPageHeader from './ModalPageHeader';
import useCurrentUser from 'hooks/useCurrentUser';

const ModalPageLayout = ({ onModalClose, headerContent, children }) => {
  const { isClient } = useCurrentUser();

  return (
    <>
      {isClient && <ModalPageHeader onClose={onModalClose}>{headerContent}</ModalPageHeader>}
      <main>
        {children}
        <Configurator />
      </main>
    </>
  );
};

export default ModalPageLayout;
