import React from 'react';
import { CardGroup, ContentHero, CTABlock, InfoCard, Paper, PointCard } from '@hometap/htco-components';
import cx from 'classnames';
import { USE_CASE_TEXT, USE_CASES } from '../../../constants/useCases';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import whyHometap from '../../../images/why-hometap.jpg';
import compare from '../../../images/compare.jpg';
import homeownerGuide from '../../../images/homeowner_guide.jpg';

import {
  checkTermResult,
  doesExpenseAnswerQualify,
  doesRainyDayFundAnswerQualify,
  getAlternativeOptionsLabels,
  shouldAlternativesResultShow,
} from '../../../utils/fitQuiz/fitQuizDataUtils';
import {
  RESULT_MAYBE,
  RESULT_MONTHY_EXPENSE_MAYBE,
  RESULT_RAINY_DAY_FUND_MAYBE,
  RESULT_TERM_MAYBE,
} from '../../../utils/fitQuiz/fitQuizResultsMaybe';
import {
  RESULT_ALTERNATIVE_YES,
  RESULT_MONTHY_EXPENSE_YES,
  RESULT_RAINY_DAY_FUND_YES,
  RESULT_TERM_YES,
  RESULT_YES,
} from '../../../utils/fitQuiz/fitQuizResultsYes';

import './ResultYesMaybe.scss';

const ResultYesMaybe = ({ confidence, formData }) => {
  const INQUIRY_URL = '/inquiry/info/property/';

  const { use_case, term, monthly_expenses_concern, rainy_day_fund, alternatives_considered, first_name } = formData;
  const result = confidence === 'high' ? RESULT_YES : RESULT_MAYBE;
  const contentHeroData = result[use_case];
  const [contentHeroImage] = USE_CASES.filter(item => item.value === use_case).map(item => item.image);

  //Results Term Data
  const pointCardTerm = checkTermResult(term) ? RESULT_TERM_MAYBE : RESULT_TERM_YES;
  const pointCardTermData = pointCardTerm === null ? null : pointCardTerm[term];
  const pointCardTermIcon = pointCardTerm === RESULT_TERM_YES ? 'check-filled' : 'question-filled';

  //Monthly expenses Data
  const pointCardExpenses = doesExpenseAnswerQualify(monthly_expenses_concern)
    ? RESULT_MONTHY_EXPENSE_YES
    : RESULT_MONTHY_EXPENSE_MAYBE;
  const pointCardExpensesData = pointCardExpenses[monthly_expenses_concern];
  const pointCardExpensesIcon = pointCardExpenses === RESULT_MONTHY_EXPENSE_YES ? 'check-filled' : 'question-filled';

  //Rainy Day Data
  const pointCardRainyDayData = doesRainyDayFundAnswerQualify(rainy_day_fund)
    ? RESULT_RAINY_DAY_FUND_YES
    : RESULT_RAINY_DAY_FUND_MAYBE;
  const pointCardRainyDayIcon =
    pointCardRainyDayData === RESULT_RAINY_DAY_FUND_YES ? 'check-filled' : 'question-filled';

  //Results Alternatives Data
  const pointCardAlternativesData = shouldAlternativesResultShow(alternatives_considered)
    ? RESULT_ALTERNATIVE_YES
    : null;

  const cardHeaderText = USE_CASE_TEXT[use_case].outcomeText
    ? `Because you’re most interested in ${USE_CASE_TEXT[use_case].outcomeText}, Hometap might work for you if…`
    : 'Hometap might work for you if…';

  const ldClient = useLDClient();
  const buttonCTA = 'Get an Estimate';
  const buttonOnClick = () => {
    ldClient.track('Fit Quiz Success Page CTA Custom Clicks');
    ldClient.flush().then(() => {
      window.location = INQUIRY_URL;
    });
  };

  return (
    <div className="ResultYesMaybe">
      <div className="ResultYesMaybeContentHero">
        <ContentHero
          title={(first_name ? `${first_name}, ` : '') + contentHeroData.title}
          image={contentHeroImage}
          buttonLabel={buttonCTA}
          buttonOnClick={buttonOnClick}
          linkLabel="Schedule a call with an Investment Guide."
          linkDestination="https://calendly.com/investment-guide"
        >
          {contentHeroData.text}
          <br />
          <br />
          So what’s next? We just need a little more information to put together a no-commitment Hometap Investment
          Estimate specific to your property.
        </ContentHero>
      </div>

      <Paper theme="medium">
        <div
          className={cx('ResultYesMaybePointCards', {
            maxWidthLg: USE_CASE_TEXT[use_case].outcomeTextMaxWidth === 'lg',
          })}
        >
          <CardGroup cardSize={PointCard.size} title={cardHeaderText} titleAlignment="left">
            {/* Results Alternatives */}
            {pointCardAlternativesData && (
              <PointCard title="You’ve considered the following alternatives:" icon="check-filled">
                {getAlternativeOptionsLabels(alternatives_considered || []).map((data, key) => {
                  return (
                    <span className="ResultYesMaybePointItem" key={key}>
                      <strong>- {data}</strong>
                    </span>
                  );
                })}
                <br />
                {pointCardAlternativesData.text}
              </PointCard>
            )}
            {/* Rainy Day */}
            <PointCard title={pointCardRainyDayData.title} icon={pointCardRainyDayIcon}>
              <br />
              {pointCardRainyDayData.text}
            </PointCard>
            {/* Monthly expenses */}
            <PointCard title={pointCardExpensesData.title} icon={pointCardExpensesIcon}>
              <br />
              {pointCardExpensesData.text}
            </PointCard>
            {/* Results Term */}
            {pointCardTermData && (
              <PointCard title={pointCardTermData.title} icon={pointCardTermIcon}>
                <br />
                {pointCardTermData.text}
              </PointCard>
            )}
          </CardGroup>
        </div>
      </Paper>

      <CTABlock title="Ready to get started with Hometap?" buttonLabel="Get an Estimate" buttonLink={INQUIRY_URL} />

      <Paper theme="medium" className="ResultYesMaybeInfoCards">
        <CardGroup cardSize={InfoCard.size} preventOrphans title="Learn more about Hometap">
          <InfoCard
            linkLabel="DOWNLOAD GUIDE"
            description="Download our Homeowner’s Guide for more detail on how Hometap works."
            link="https://www.hometap.com/documents/hometap-homeowners-guide-to-hometap.pdf"
            image={homeownerGuide}
          />
          <InfoCard
            linkLabel="HOMEOWNER REVIEWS"
            description="Find out why homeowners are choosing Hometap. "
            link="https://www.hometap.com/reviews/"
            image={whyHometap}
          />
          <InfoCard
            linkLabel="DOWNLOAD GUIDE"
            description="Learn how Hometap compares to home loans, HELOCs and home equity investments."
            link="https://www.hometap.com/documents/hometap-tapping-into-your-home-equity.pdf"
            image={compare}
          />
        </CardGroup>
      </Paper>
    </div>
  );
};

export default ResultYesMaybe;
