import React from 'react';
import { Container, KPIBar, Paper, useWindowSize } from '@hometap/htco-components';
import formatCSV from '../../utils/formatCSV';
import { NUMBER_FORMATS } from 'apps/htdc/utils/formatters';
import ExportLink from 'components/ExportLink/ExportLink';
import { trackExportLink } from '../../utils/debtCalculatorTracks';
import DebtBarChart from '../debtBarChart/debtBarChart';
import DebtCTA from '../debtCTA/DebtCTA';
import DebtDisclaimer from '../debtDisclaimer/DebtDisclaimer';

import './DebtResults.scss';

const DebtResults = ({ debts, totalInterestPayments, totalBalance, totalMonthlyPayment, payOffDate }) => {
  const { isScreenSize } = useWindowSize();
  const kpiTopMargin = isScreenSize('sm') ? '11px' : '70px';

  const principalBalance = debts.reduce((acc, cur) => {
    acc = acc + cur.balance;
    return acc;
  }, 0);
  const csvData = formatCSV({ debts, principalBalance, totalInterestPayments, totalMonthlyPayment, payOffDate });

  return (
    <Paper theme="medium">
      <Container
        grid={12}
        className="DebtCalcResultKPIContainer"
        style={{ padding: '0 10px', marginTop: kpiTopMargin, marginBottom: '11px' }}
      >
        <Container grid={12} row justify="center" style={{ padding: '0 10px' }}>
          <div className="DebtCalcResultKPIBar">
            <KPIBar
              kpis={[
                { label: 'Total principal payments:', value: NUMBER_FORMATS.currency(principalBalance, 0) },
                { label: 'Total interest payments:', value: NUMBER_FORMATS.currency(totalInterestPayments, 0) },
                { label: 'Total monthly payments:', value: NUMBER_FORMATS.currency(totalMonthlyPayment, 0) },
                {
                  label: (
                    <span>
                      You will be debt free by<sup>*</sup>
                    </span>
                  ),
                  value: payOffDate,
                },
              ]}
            />
          </div>
        </Container>
      </Container>

      <Container
        grid={12}
        className="DebtCalcResultChartContainer"
        style={{ padding: '0 10px', marginTop: '0', marginBottom: '0' }}
      >
        <Container grid={12} row justify="center" style={{ padding: '0 10px' }}>
          <div className="DebtCalcResultBarChartContainer">
            <div className="DebtCalcResultExportLink">
              <ExportLink
                onClick={() =>
                  trackExportLink({
                    debts,
                    totalBalance,
                    totalInterestPayments,
                    totalMonthlyPayment,
                    payOffDate,
                    totalPrincipalBalance: principalBalance,
                  })
                }
                data={csvData}
                fileName={`debt-total-${new Date().toLocaleDateString()}`}
              />
            </div>
            <div className="DebtCalcResultBarChart">
              <DebtBarChart debts={debts} height={400} />
            </div>
          </div>
          <div className="DebtCalcResultCTAContainer">
            <DebtCTA />
          </div>
          <div className="DebtCalcResultDisclaimerContainer">
            <DebtDisclaimer />
          </div>
        </Container>
      </Container>
    </Paper>
  );
};

export default DebtResults;
