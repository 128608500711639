import moment from 'moment';

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return moment(date).format(format);
};

export const getDateDifference = (firstDate, secondDate, unit = 'days') => {
  // dates must be moment date objects or formatted YYYY-MM-DD
  const dateDifference = moment(secondDate).diff(moment(firstDate), unit);
  if (dateDifference < 0) {
    return 1;
  }
  return dateDifference;
};

// dateString has format: "2022-02-11" -> Output: "2022-02-11T00:00:00-05:00"
export const convertDateStringToDateTimeEST = dateString => `${dateString}T00:00:00-05:00`;

// dateString has format: "2022-02-11" -> Output: "February 11, 2022" or "Feb. 11, 2022"
export const convertDateStringToHumanReadable = (dateString, truncateMonth = false) => {
  const date = new Date(Date.parse(convertDateStringToDateTimeEST(dateString))); // create a new Date object from the date string
  const options = { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'America/New_York' }; // formatting options
  const formatter = new Intl.DateTimeFormat('en-US', options); // create new formatter with the specified options
  let formattedDate = formatter.format(date); // format the date using the formatter
  if (truncateMonth) {
    formattedDate = moment(convertDateStringToDateTimeEST(dateString)).format('MMMM D, YYYY');
  }
  return formattedDate;
};

export const convertDateStringToHumanReadableMonth = dateString => {
  return moment(convertDateStringToDateTimeEST(dateString)).format('MMMM D, YYYY');
};

// get difference in years between two date strings in format "yyyy-mm-dd"
export const getYearsBetweenDates = (higherDate, lowerDate, roundUp = false) => {
  const date1 = moment(higherDate);
  const date2 = moment(lowerDate);
  if (roundUp) {
    // Convert days to years and round up to the nearest whole year
    const diffDays = date1.diff(date2, 'days');
    return Math.ceil(diffDays / 365);
  }
  return date1.diff(date2, 'years');
};

export const getYearFromDateString = date => {
  const momentDate = moment(date, 'YYYY-MM-DD');
  return momentDate.year();
};

/**
 * Takes a date object or date string and returns it as a slash-formatted date string
 * Examples:
 *   Input: new Date("2022-02-02") -> Output: "2/2/22"
 *   Input: "2024-02-25" -> Output: "2/25/24"
 */
export const getSlashFormattedShortDate = date => {
  return moment(date).format('M/D/YY');
};

export const getDateTimeParts = (dateTime, formatter = 'MM/DD/YY h:mma', timeSeparator = ' ') => {
  const formattedDate = moment(dateTime).format(formatter);

  const [date, time] = formattedDate.split(timeSeparator);
  return { date, time };
};

// returns true if the current (local) date is after the given date
export const isTodayAfterDate = date => {
  return moment().isAfter(moment(date).endOf('day'));
};

export const addToDateString = (dateString, amount, period) => {
  return moment(convertDateStringToDateTimeEST(dateString)).add(amount, period).format('YYYY-MM-DD');
};
