import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Paper, Tooltip } from '@hometap/htco-components';
import { formatInlineAddress } from 'utils/address';
import { currency } from 'utils/numbers';
import IMAGE_HOUSE from 'images/rundown-investment-calculator-house.svg';

import './RundownHeader.scss';

const paragraph = `Use our home equity investment calculator to see how much you can access and what your Hometap Investment
            could look like over time.`;

const RundownHeader = ({ rundown }) => {
  return (
    <Paper theme="plain" className="RundownHeaderPaper">
      <div className="RundownHeaderContainer">
        <div className="RundownHeaderHeroTextContainer">
          <h1 className="RundownHeaderHeroDescription">{rundown.description}</h1>
          <h2 className="RundownHeaderHeroAddress">{formatInlineAddress(rundown.address)}</h2>
          <span className="RundownHeaderHeroHomeValueText">Current Home Value</span>
          <div className="RundownHeaderHeroHomeValueContainer">
            <h2 className="RundownHeaderHeroHomeValue">{currency(rundown.home_value)}</h2>
            <Tooltip
              position="bottom"
              content={
                rundown.is_offer
                  ? 'This number is based on your recent home valuation.'
                  : 'This number is based on a recent home valuation, or you provided it to Hometap.'
              }
            >
              <Icon name="icon-info" />
            </Tooltip>
          </div>
          <p className="RundownHeaderParagraphDesktop">{paragraph}</p>
        </div>

        <div className="RundownHeaderHeroImageContainer">
          <img className="RundownHeaderHeroImage" src={IMAGE_HOUSE} alt="house" />
        </div>
        <p className="RundownHeaderParagraphMobile">{paragraph}</p>
      </div>
    </Paper>
  );
};

RundownHeader.propTypes = {
  rundown: PropTypes.shape({
    description: PropTypes.string,
    address: PropTypes.object,
    home_value: PropTypes.number,
  }).isRequired,
};

export default RundownHeader;
