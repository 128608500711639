import './SidebarHeaderNav.scss';

const SidebarHeaderNav = ({ configuredNavItems }) => {
  const NavItem = ({ item }) => {
    if (item.visible === false) return null;

    const isFunctionComponent = typeof item.component === 'function';
    const renderedItem = isFunctionComponent ? item.component() : item.component;
    return <li className="HeaderNavItemContainer">{renderedItem}</li>;
  };

  const renderedMobileNavItems = Object.keys(configuredNavItems).map((key, i) => {
    const section = configuredNavItems[key];
    if (!Object.keys(section).length || !section.items.length) return null;

    return (
      <li className="HeaderNavSectionContainer" key={`nav-${key}-${i}`}>
        <ul className="HeaderNavSection">
          {section.title ? <p className="HeaderNavTitle">{section.title}</p> : null}{' '}
          {section.items?.map((item, i) => (
            <NavItem key={`header-${section}-${i}`} item={item} />
          ))}
        </ul>
      </li>
    );
  });

  return <ul className={'SidebarHeaderNav'}>{renderedMobileNavItems}</ul>;
};

export default SidebarHeaderNav;
