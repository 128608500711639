import { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { Accordion, Button, Icon, MuiSelect, SelectionSet, Tooltip, useWindowSize } from '@hometap/htco-components';
import HomeEquityCard from 'components/HomeEquityCard/HomeEquityCard';
import { currency } from 'utils/numbers';
import { useHistory, useParams } from 'react-router-dom';
import { getNewHEDUrl } from 'utils/links';
import { getRenovationFinishLevelLabel, getRenovationSummary } from './data/renovationUtils';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

const RenovationCalculator = ({
  project,
  currentRenovation,
  renovationProjectOptions,
  projectTemplates,
  selectedTemplate,
  setSelectedTemplate,
  renovationSaveLoading,
  renovationSaveError,
  onChangeRenovation,
  onSaveRenovation,
}) => {
  const { isScreenSize } = useWindowSize();
  const { renovationId } = useParams();
  const history = useHistory();
  const showAccordion = isScreenSize('md');

  const handleSave = async () => {
    try {
      await onSaveRenovation();
      history.push(getNewHEDUrl());
    } catch {
      // Error will be displayed using useAsync error field
    }
  };

  const templatesByFinishLevel = useMemo(
    () =>
      (projectTemplates || []).reduce((acc, tpl) => {
        acc[tpl.finish_level] = tpl;
        return acc;
      }, {}),
    [projectTemplates],
  );
  const finishLevelOptions = useMemo(
    () => Object.keys(templatesByFinishLevel).map(value => ({ value, label: getRenovationFinishLevelLabel(value) })),
    [templatesByFinishLevel],
  );
  const handleFinishLevelSelected = useCallback(
    finishLevel => setSelectedTemplate(templatesByFinishLevel[finishLevel]),
    [setSelectedTemplate, templatesByFinishLevel],
  );
  const hasFinishLevelOptions = finishLevelOptions.length > 0;

  const costRecouped = currentRenovation && getRenovationSummary(currentRenovation).costRecouped;
  const renovationCostRecouped = costRecouped ? (costRecouped * 100).toFixed(1) : null;

  const formElement = (
    <form>
      <MuiSelect
        label="Type of Renovation"
        className={cx('RenovationCalculatorRenoSelector', { hasNoDetails: !projectTemplates?.length })}
        data-testid="renovation-type-selector"
        width="100%"
        value={project.toLowerCase()}
        options={renovationProjectOptions}
        onChange={onChangeRenovation}
        disabled={!!renovationId}
      />

      {hasFinishLevelOptions && (
        <SelectionSet
          label={
            <>
              <span className="RenovationCalculatorQualityLabelText">Material type</span>
              <Tooltip
                action="hover"
                position="bottom"
                className="RenovationCalculatorQualityLabelIcon"
                content="Explore the recommended finishes and materials for your desired budget and renovation level, from the more economical “value” choices to mid-range “quality” supplies."
              >
                <Icon name="icon-info" />
              </Tooltip>
            </>
          }
          name="renovationQuality"
          className="RenovationCalculatorQualitySelector"
          data-testid="renovation-quality-selector"
          options={finishLevelOptions}
          value={selectedTemplate?.finish_level}
          onChange={handleFinishLevelSelected}
        />
      )}

      {currentRenovation && (
        <div>
          {currentRenovation.estimated_cost && (
            <HomeEquityCard
              className="RenovationCalculatorCostCard"
              title="Average Cost in your Area"
              data-testid="renovation-cost"
              value={currency(currentRenovation.estimated_cost)}
              ctaText=""
              shouldPercentageDrop={true}
              toolTipContent="This pricing refers to the average cost of materials and labor in your area."
              onLinkButtonClick={() => {}}
            />
          )}
          <HomeEquityCard
            className="RenovationCalculatorCostCard"
            title="Average Value added"
            data-testid="renovation-value-added"
            value={currency(currentRenovation.added_value)}
            percentage={renovationCostRecouped}
            appreciationDirection="appreciation"
            ctaText=""
            shouldPercentageDrop={true}
            toolTipContent="This value is calculated by comparing similar renovations that were recently completed in your area."
            onLinkButtonClick={() => {}}
          />
        </div>
      )}
      <Button
        width="100%"
        loading={renovationSaveLoading}
        data-testid="add-renovation-to-chart-button"
        disabled={!hasFinishLevelOptions || renovationSaveLoading}
        onClick={handleSave}
        className="RenovationCalculatorButton"
      >
        {!renovationId ? 'Add to' : 'Update'} my equity chart
      </Button>
      {!!renovationSaveError && (
        <ErrorMessage fullWidth className="RenovationCalculatorError">
          There was an issue saving this renovation, please try again soon.
        </ErrorMessage>
      )}
    </form>
  );

  return (
    <div className="RenovationCalculator">
      {showAccordion ? (
        <Accordion isOpen header="Adjust Renovation DETAILS" children={formElement} />
      ) : (
        <>
          <h6>Renovation Details</h6>
          {formElement}
        </>
      )}
    </div>
  );
};

export default RenovationCalculator;
