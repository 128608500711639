import React from 'react';
import './InquiryFormRow.scss';

const InquiryFormRow = ({ children }) => {
  return (
    <div className={'InquiryDQRow'} data-testid="dq-row">
      {children}
    </div>
  );
};
export default InquiryFormRow;
