import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Icon, MuiTextInput, MuiSSNInput, SlideDown, useAsync } from '@hometap/htco-components';
import { CallToAction, ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import {
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
} from '../constants/progressiveInquiryConstants';
import { patchInquiry } from '../../inquiry/data/inquiryRequests';
import './InquiryHomeowner.scss';
import yellowHouse from '../../../images/equity-planner/onboarding/yellow-house.png';

const InquiryHomeowner = ({ isLoggedIn, inquiryId, form, step, onNext }) => {
  const { formData, registerField, handleSubmit, isFormValid, updateFormData } = form;

  const [ssnError, setSsnError] = useState(false);

  const { results, loading, execute: updateInquiry } = useAsync(patchInquiry);

  useEffect(() => {
    if (results) {
      onNext(step + 1);
    }
  }, [results, onNext, step]);

  const onSubmit = () => {
    const { first_name, last_name, ssn } = formData;
    const updateApi = handleSubmit(() => updateInquiry(inquiryId, { first_name, last_name, ssn }));
    updateApi();
  };

  const [visible, setVisible] = useState(false);
  const onToggle = () => {
    setVisible(v => !v);
  };

  const { street } = formData;
  return (
    <div className="HomeownerInfo" data-testid="inquiry_homeowner_info_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.INFO]} />
      <ProgressiveUITemplate>
        <ContentPanel>
          <h1>
            <span className="InquiryEmphasisGreen">Good news!</span> We’re investing in properties like yours.{' '}
          </h1>
          <p className="InquiryBodyText HomeownerInfoBodyText">
            If you’d like to see a no-obligation Investment Estimate for{' '}
            <span className="InquiryEmphasisBold">{street}</span>, we just need a little information about the owner of
            the property.
          </p>
          <div className="HomeownerInfoFields">
            <MuiTextInput
              required
              label="Legal First Name"
              width="100%"
              disabled={isLoggedIn && formData?.first_name}
              maxLength={30}
              {...registerField(FIELDS.FIRST_NAME)}
            />
            <MuiTextInput
              required
              label="Legal Last Name"
              width="100%"
              disabled={isLoggedIn && formData?.last_name}
              maxLength={30}
              {...registerField(FIELDS.LAST_NAME)}
            />
            <MuiSSNInput
              required
              label="Social Security Number"
              helperText="e.g ###-##-####"
              width="100%"
              type="number"
              value={formData.ssn || ''}
              onChange={val => updateFormData({ ssn: val })}
              onError={isError => {
                setSsnError(isError);
              }}
            />
          </div>
          <div className="HomeownerInfoWhySSN">
            <div onClick={onToggle} onKeyDown={onToggle} role="button" tabIndex="0" className="HomeownerInfoSSNTitle">
              Why do we need this information?
            </div>
            <SlideDown visible={visible}>
              <div className="HomeownerInfoSSNDescription">
                We need to confirm your identity before we can prequalify you for a Hometap Investment. Your social
                security number lets us verify that you’re you.
              </div>
            </SlideDown>
          </div>
          <CallToAction>
            <Button
              id={CONTINUE_BUTTON_ID}
              disabled={!isFormValid || ssnError || !formData.ssn}
              onClick={data => onSubmit(data)}
              loading={loading}
            >
              You’re almost there!
            </Button>
          </CallToAction>
          <div className="HomeownerInfoEncrypted">
            <Icon name="icon-lock" size="lg" />
            <p>
              All of your data is AES-256 encrypted and stored securely. We do not sell your personally identifiable
              information. For more information about our security, please check out our{' '}
              <a
                href="https://www.hometap.com/legal/?section=privacy-policy#privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </ContentPanel>
        <ImageContentPanel imageSource={yellowHouse} imgAlt="yellow house" />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryHomeowner;
