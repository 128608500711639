import React, { useRef, useEffect } from 'react';
import cx from 'classnames';

const TrustPilot = ({ spacingTop, spacingBottom, divider = false }) => {
  // Reference TrustPilot div
  const ref = useRef(null);
  useEffect(() => {
    // Load from ref once available
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      style={{ paddingTop: `${spacingTop}px`, paddingBottom: 25 }}
      className={cx({ InquiryTrustPilotDivider: divider })}
    >
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="5c0d6caf5a7b380001a9fb98"
        data-style-height="20px"
        data-style-width="100%"
        data-theme="light"
        data-text-color="#152033"
      >
        <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </div>
  );
};
export default TrustPilot;
