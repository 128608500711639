const formatCookieDataForApi = (cookieList = [], cookieData = {}, prefixProperties = [], prefix) => {
  return Object.keys(cookieData).reduce((utmData, cookieKey) => {
    if (cookieList.includes(cookieKey)) {
      if (prefixProperties.includes(cookieKey)) {
        utmData[`${prefix}${cookieKey}`] = cookieData[cookieKey];
      } else {
        utmData[cookieKey] = cookieData[cookieKey];
      }
    }
    return utmData;
  }, {});
};

export default formatCookieDataForApi;
