import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button, MuiSelect, useWindowSize } from '@hometap/htco-components';
import { FINANCE_CALCULATOR_TYPE } from './data/financeData';
import SelectedFinanceCalculator from './SelectedFinanceCalculator';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import './FinanceCalculator.scss';

const FinanceCalculator = ({
  selectedFinanceOption,
  financeOptions,
  calculatorData,
  onSelectFinance,
  onAddToEquityChart,
  isRequestLoading,
  onGetPreQualified,
  onCalculatorDataChange,
  loanDetails,
  setLoanDetails,
}) => {
  const { futureScenario } = useEquityScenarioContext();
  const { isScreenSize } = useWindowSize();
  const [shouldButtonDisabled, setShouldButtonDisabled] = useState(true);
  const shouldShowAccordion = isScreenSize('md');
  const { value } = selectedFinanceOption;

  const handleSelectFinance = selectedFinance => {
    const selectedOption = financeOptions.find(option => option.value === selectedFinance);
    onSelectFinance(selectedOption);
  };

  const checkFutureScenarioForCurrentOption = () => {
    const financeMapping = {
      HEL: 'home_equity_loan',
      HELOC: 'home_equity_line_of_credit',
      HEI: 'home_equity_investment',
    };

    return futureScenario?.liens?.find(lien => lien.lien_type === financeMapping[value]);
  };

  const FinancingCalculatorForm = (
    <form>
      <MuiSelect
        label="Financial product"
        className="FinancingCalculatorSelector"
        width="100%"
        value={value}
        options={financeOptions}
        onChange={newValue => handleSelectFinance(newValue)}
      />

      <SelectedFinanceCalculator
        calculatorData={calculatorData}
        currentCalculator={selectedFinanceOption}
        onCalculatorDataChange={onCalculatorDataChange}
        setShouldButtonDisabled={setShouldButtonDisabled}
        loanDetails={loanDetails}
        setLoanDetails={setLoanDetails}
      />

      <div className="FinancingCalculatorFooter">
        <Button
          width="100%"
          onClick={onAddToEquityChart}
          loading={isRequestLoading}
          disabled={value !== FINANCE_CALCULATOR_TYPE.HEI && shouldButtonDisabled}
        >
          {checkFutureScenarioForCurrentOption() ? 'Update my equity chart' : 'Add to my equity chart'}
        </Button>

        {value === FINANCE_CALCULATOR_TYPE.HEI && (
          <Button width="100%" onClick={onGetPreQualified} theme="secondary">
            Get an estimate
          </Button>
        )}
      </div>
    </form>
  );

  return (
    <div className="FinancingCalculator">
      {shouldShowAccordion ? (
        <Accordion isOpen header="Adjust Financing DETAILS" children={FinancingCalculatorForm} />
      ) : (
        <>
          <div className="FinancingCalculatorHeader">Financing calculator</div>
          {FinancingCalculatorForm}
        </>
      )}
    </div>
  );
};

const financeOptionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  calculatorCardFooterCopy: PropTypes.string.isRequired,
});

FinanceCalculator.propTypes = {
  selectedFinanceOption: financeOptionType.isRequired,
  financeOptions: PropTypes.arrayOf(financeOptionType).isRequired,
  calculatorData: PropTypes.object.isRequired,
  onSelectFinance: PropTypes.func.isRequired,
  onAddToEquityChart: PropTypes.func,
  onGetPreQualified: PropTypes.func,
  onCalculatorDataChange: PropTypes.func.isRequired,
  loanDetails: PropTypes.object,
  setLoanDetails: PropTypes.func,
};

export default FinanceCalculator;
