import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProgressBar, useAsync, useBoolState, useForm } from '@hometap/htco-components';
import OnboardTransition from 'apps/dashboard/components/equity-planner-onboarding/components/OnboardTransition.js';
import ReInquiryAddressPickerModal from 'apps/re-inquiry/components/ReInquiryAddressPickerModal';
import { fetchEquityPlannerHomes } from 'apps/dashboard/data';
import useQueryParamData from 'hooks/useQueryParamData';
import useLogAsyncError from 'hooks/useLogAsyncError';
import useCurrentUser from 'hooks/useCurrentUser';
import useConfigurationOptions from 'hooks/useConfigurationOptions';
import { scrollTop } from 'utils/domScripts';
import { formatFullAddress } from 'utils/address';
import { showNotification } from 'utils/toasts';
import {
  DQ_STEPS,
  PROGRESSIVE_FORM_FIELDS,
  PROGRESSIVE_STEP_NAMES,
  PROGRESSIVE_UI_STEPS,
} from '../constants/progressiveInquiryConstants';
import InquiryOutcomeSuccessful from 'apps/inquiry/components/outcome/InquiryOutcomeSuccessful';
import { resendPassword, sendInquiryConvertedSegmentEvents } from 'apps/inquiry/data/inquiryRequests';
import useInitialInquiryData from 'apps/inquiry/hooks/useInitialInquiryData';
import {
  InquiryCreate,
  InquiryHomeowner,
  InquiryProperty,
  InquiryPropertyType,
  InquiryPropertyUse,
  InquiryMortgageBalance,
  InquiryPropertyUseCase,
  InquiryReceiveFunds,
  InquiryDQ,
} from './index';
import { demoProgressiveInquiryPropertyData } from 'apps/inquiry/utils/inquiryDemoData';
import DemoButton from 'components/DemoButton/DemoButton';
import { RouterPrompt } from './RouterPrompt';
import useUserTracksContext from 'hooks/useUserTracksContext';

import 'apps/inquiry/Inquiry.scss';

const QUALIFIED_STEP = 9;
const DEQUALIFIED_STEP = 8;

const ProgressiveInquiryUI = () => {
  const { step: initialStep, ...initialFormData } = useQueryParamData(Object.values(PROGRESSIVE_FORM_FIELDS));
  const { inquiryCreatedTrackingData, inquiryConvertedTrackingData } = useInitialInquiryData();
  const fetchEquityPlannerProperties = useAsync(fetchEquityPlannerHomes);
  const fetchActiveTracks = useUserTracksContext();

  const { user, isStaff } = useCurrentUser();
  const isLoggedIn = !!user;

  if (user) {
    const { first_name, last_name, email } = user;
    initialFormData.first_name = first_name;
    initialFormData.last_name = last_name;
    initialFormData.email = email;
  }

  const { dropdownConfigurationOptions, asyncError } = useConfigurationOptions({
    names: ['home_property_type', 'inquiry_when_interested', 'inquiry_use_case'],
  });

  const [currentStep, setCurrentStep] = useState(0);
  const onStep = i => currentStep === i;

  const [DQStep, setDQStep] = useState();
  const [inquiryId, setInquiryId] = useState();
  const [resentMagicLink, setResentMagicLink] = useState(false);
  const [outcomeCode, setOutcomeCode] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [manuallyEnteredValidAddress, setManuallyEnteredValidAddress] = useState(false);
  const [autoFillValidAddress, setAutoFillValidAddress] = useState(false);
  const isModalOpen = useBoolState(false);
  const hasModalBeenOpened = useBoolState(false);

  useLogAsyncError('Fetch property types failed', asyncError);

  const resendPasswordAsync = useAsync(resendPassword);
  const flags = useFlags();

  const updateStep = (route, index) => {
    setCurrentStep(() => {
      const queryParams = window.location.search;
      const url = `${route}${queryParams}`;
      window.history.pushState(null, route, url);
      return index;
    });
  };

  const goToStep = (index, id) => {
    const route = Object.values(PROGRESSIVE_UI_STEPS)[index];
    if (!route) return;
    if (!inquiryId && id) {
      setInquiryId(id);
    }

    if (0 < index < 8) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
    updateStep(route, index);
  };

  const onDequalify = dqType => {
    const route = DQ_STEPS[dqType];
    if (!route) return;
    setDQStep(dqType);
    updateStep(route, DEQUALIFIED_STEP);
    setShowPrompt(false);
  };

  const onQualify = (outcomeCode, maxAmount) => {
    const route = PROGRESSIVE_UI_STEPS[PROGRESSIVE_STEP_NAMES.SUCCESSFUL_OUTCOME];
    if (!route) return;
    setOutcomeCode(outcomeCode);
    if (maxAmount) {
      setMaxAmount(maxAmount);
    }
    updateStep(route, QUALIFIED_STEP);

    const { first_name, last_name, email, phone_number, how_heard, how_heard_choice_details } = form?.formData;
    sendInquiryConvertedSegmentEvents({
      first_name,
      last_name,
      email,
      phone_number,
      how_heard,
      how_heard_choice_details,
      isAdditionalTrack: isLoggedIn,
      adTrackingData: inquiryConvertedTrackingData,
      flags,
    });
    setShowPrompt(false);
  };

  const onClickResendMagicLink = async () => {
    await resendPasswordAsync.execute(form?.formData?.email);

    if (resendPasswordAsync.error === null) {
      setResentMagicLink(true);
      scrollTop();
    } else {
      showNotification({
        type: 'error',
        title: 'Failed to send email',
        description: 'Failed to send magic link to email. Please retry.',
      });
    }
  };

  const form = useForm({ ...initialFormData });

  const [showPrompt, setShowPrompt] = useState(false);

  const handleAutoFillValidAddress = formData => {
    form.updateFormData(formData);
    setAutoFillValidAddress(true);
  };

  const handleManuallyEnteredValidAddress = formData => {
    isModalOpen.setFalse();
    form.updateFormData(formData);
    setManuallyEnteredValidAddress(true);
  };

  const onSelectEquityPlannerHome = equityPlannerHome => {
    const { street, unit, city, state, zip_code } = equityPlannerHome.address;
    const formattedAddress = formatFullAddress(street, unit, city, state, zip_code);
    handleManuallyEnteredValidAddress({ address: formattedAddress, ...equityPlannerHome.address });
    hasModalBeenOpened.setTrue();
  };

  const onCloseReinquiryAddressPickerModal = () => {
    isModalOpen.setFalse();
    hasModalBeenOpened.setTrue();
  };

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const [activeTracks, equityPlannerHomes] = await Promise.all([
          fetchActiveTracks.execute(),
          fetchEquityPlannerProperties.execute(),
        ]);
        if (activeTracks?.length > 0 || equityPlannerHomes?.length > 0) {
          isModalOpen.setTrue();
        }
      } catch (err) {
        console.error('Failed to fetch user tracks', err);
      }
    };

    if (user && !isStaff && !isModalOpen.value && !fetchActiveTracks.results && !fetchEquityPlannerProperties.results) {
      fetchTracks();
    }
  }, [user, isStaff, isModalOpen, fetchActiveTracks, fetchEquityPlannerProperties]);

  const { home_property_type, inquiry_use_case, inquiry_when_interested } = dropdownConfigurationOptions || {};

  const getDemoData = () => demoProgressiveInquiryPropertyData(form?.formData);

  return (
    <>
      <RouterPrompt when={showPrompt} />
      <ProgressBar steps={8} curStep={currentStep + 1} />
      <AnimatePresence initial={false}>
        <ReInquiryAddressPickerModal
          onClose={onCloseReinquiryAddressPickerModal}
          open={isModalOpen.value && !hasModalBeenOpened.value}
          homeownerTracks={fetchActiveTracks.results || []}
          homeownerEquityPlannerHomes={fetchEquityPlannerProperties.results || []}
          onSelectEquityPlannerHome={onSelectEquityPlannerHome}
          isInquiry={true}
        />
        {onStep(0) && (
          <OnboardTransition key={0} forward={true}>
            <InquiryProperty
              form={form}
              trackingData={{ ...inquiryCreatedTrackingData, ...inquiryConvertedTrackingData }}
              step={0}
              onNext={goToStep}
              onDequalify={onDequalify}
              autoFillValidAddress={autoFillValidAddress}
              manuallyEnteredValidAddress={manuallyEnteredValidAddress}
              onValidAddressChange={() => {
                setAutoFillValidAddress(false);
              }}
            />
          </OnboardTransition>
        )}
        {onStep(1) && (
          <OnboardTransition key={1} forward={true}>
            <InquiryPropertyType
              inquiryId={inquiryId}
              form={form}
              step={1}
              onNext={goToStep}
              propertyTypes={home_property_type}
              onDequalify={onDequalify}
              utmSource={inquiryCreatedTrackingData?.tracking_data_utm_source}
            />
          </OnboardTransition>
        )}
        {onStep(2) && (
          <OnboardTransition key={2} forward={true}>
            <InquiryPropertyUse inquiryId={inquiryId} form={form} step={2} onNext={goToStep} />
          </OnboardTransition>
        )}
        {onStep(3) && (
          <OnboardTransition key={3} forward={true}>
            <InquiryMortgageBalance inquiryId={inquiryId} form={form} step={3} onNext={goToStep} />
          </OnboardTransition>
        )}
        {onStep(4) && (
          <OnboardTransition key={4} forward={true}>
            <InquiryPropertyUseCase
              inquiryId={inquiryId}
              inquiryUseCases={inquiry_use_case}
              form={form}
              step={4}
              onNext={goToStep}
            />
          </OnboardTransition>
        )}
        {onStep(5) && (
          <OnboardTransition key={5} forward={true}>
            <InquiryReceiveFunds
              inquiryId={inquiryId}
              whenInterestedOptions={inquiry_when_interested}
              form={form}
              step={5}
              onNext={goToStep}
            />
          </OnboardTransition>
        )}
        {onStep(6) && (
          <OnboardTransition key={6} forward={true}>
            <InquiryHomeowner isLoggedIn={isLoggedIn} inquiryId={inquiryId} form={form} step={6} onNext={goToStep} />
          </OnboardTransition>
        )}
        {onStep(7) && (
          <OnboardTransition key={7} forward={true}>
            <InquiryCreate
              isLoggedIn={isLoggedIn}
              inquiryId={inquiryId}
              form={form}
              step={7}
              onNext={goToStep}
              onQualify={onQualify}
              onDequalify={onDequalify}
            />
          </OnboardTransition>
        )}
        {onStep(DEQUALIFIED_STEP) && (
          <OnboardTransition key={DEQUALIFIED_STEP} forward={true}>
            <InquiryDQ step={DQStep} />
          </OnboardTransition>
        )}
        {onStep(QUALIFIED_STEP) && (
          <OnboardTransition key={QUALIFIED_STEP} forward={true}>
            <InquiryOutcomeSuccessful
              upToAmount={maxAmount}
              name={form?.formData?.first_name}
              email={form?.formData?.email}
              resentMagicLink={resentMagicLink}
              onClickResendMagicLink={onClickResendMagicLink}
              isAdditionalTrack={isLoggedIn}
              isPartnerOutcome={false}
              outcomeCode={outcomeCode}
              inquiryId={inquiryId}
              utmSource={inquiryCreatedTrackingData?.tracking_data_utm_source}
            />
          </OnboardTransition>
        )}
      </AnimatePresence>
      <DemoButton
        className="DemoButtonWrapperInquiry"
        demoData={getDemoData}
        onClickAction={handleAutoFillValidAddress}
      />
    </>
  );
};

export default ProgressiveInquiryUI;
