import React, { useContext, useEffect, useState } from 'react';
import { apiNoAuth } from 'utils/api';
import { useAsync } from '@hometap/htco-components';
import segment, { browserIdentify } from 'utils/segment';
import sentry from 'utils/sentry';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import md5 from 'md5';

export const fetchCurrentUser = async () => {
  return await apiNoAuth.get('/auth/current-user');
};

const Context = React.createContext({});

// Hook to get current user data, returns "contextData" below
const useCurrentUser = () => useContext(Context);
export default useCurrentUser;

// Provider which fetches the current logged in user
// and passes that data through to context.
export const CurrentUserProvider = ({ children }) => {
  const {
    loading: loadingUser,
    results,
    setResults,
    error,
    execute,
  } = useAsync(fetchCurrentUser, {
    immediate: true,
    defaultLoading: true,
  });
  const user = results;
  const ldClient = useLDClient();
  const flags = useFlags();
  // Set LaunchDarkly loading flag to true by default to avoid situation when user is loaded but
  // LaunchDarkly identify request is not sent yet. If we rendered this hook than we are expecting
  // that user is authenticated. So it is better to wait until the LD flags for the user are at least requested.
  const [loadingFlags, setLoadingFlags] = useState(true);

  useEffect(() => {
    if (user) {
      sentry.setUser(user);
      if (user.user_type === 'client' && !!Object.keys(flags).length) {
        browserIdentify(user.email, {
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          flags,
        });
      } else if (user.user_type === 'reviewer' || user.user_type === 'admin') {
        // Disable segment for reviewer access to homeowner portal
        // Since it goes to different project and currently there are
        // no Internal events for this project
        segment.disable();
      }
      // LaunchDarkly identify
      if (ldClient) {
        const loggedInUserContext = {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          key: md5(user.email),
          has_tracks: user.has_tracks,
        };
        const anonContext = ldClient.getContext();

        setLoadingFlags(true);

        ldClient
          .identify(loggedInUserContext, anonContext)
          .then(() => setLoadingFlags(false))
          .catch(() => {
            // Allow to render with previous LD flag set. Otherwise, user will be left on the blank screen.
            setLoadingFlags(false);
            sentry.logError('Failed to identify user with LaunchDarkly');
          });
      }

      return;
    }

    if (ldClient || !loadingUser) {
      // If LaunchDarkly initialized before user is loaded or user is not authenticated unset loadingFlags flag
      setLoadingFlags(false);
    }
  }, [ldClient, user, loadingUser, flags]);

  const loading = loadingUser || loadingFlags;

  const contextData = {
    loading,
    user,
    setUser: setResults,
    error,
    fetch: execute,
    isClient: user?.user_type === 'client',
    isStaff: user?.user_type === 'reviewer' || user?.user_type === 'admin',
  };

  return <Context.Provider value={contextData}>{loading ? null : children} </Context.Provider>;
};
