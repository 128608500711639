import env from './env';

export const getPortalsUrl = (path = '') => {
  // Remove slash if "path" starts with a slash because, the REACT_APP_BACKEND_BASE var should
  // already end in a slash.
  if (path[0] && path[0] === '/') {
    path = path.slice(1, path.length);
  }
  if (process.env.REACT_APP_BACKEND_BASE) {
    return process.env.REACT_APP_BACKEND_BASE + path;
  }

  return env.getBackendUrlForEnv() + path;
};

export const getDashboardUrl = () => {
  return HOMEOWNER_DASHBOARD.ROOT;
};

export const getPrimaryAppTrackDetailsUrl = trackId => {
  return getPortalsUrl(`/track/${trackId}/`);
};

export const getCoAppTrackDetailsUrl = trackId => {
  return `/track/${trackId}/co-applicant/`;
};

export const getRundownOrOfferUrl = rundownOrOffer => {
  // We explicitly route through HUB because that is currently how we save
  // `last_viewed_by_client` which we need to track that they have seen it so
  // that no edits get made to it
  return getPortalsUrl(`track/${rundownOrOffer?.track_id}/review/${rundownOrOffer?.id}`);
};

export const getAllDocumentsUrl = trackId => `/track/${trackId}/documents`;

export const getInvestmentStatementUrl = ({ trackId, investmentId, statementId }) => {
  return `/track/${trackId}/investments/${investmentId}/statements/${statementId}`;
};

// Note this URL should only be shown if investment has all these fields set:
// - effective_date
// - beginning_home_value
// - investment_amount
export const getSettlementCalculatorUrl = investmentId => {
  return `/investments/${investmentId}/settlement-calculator`;
};

export const getInquiryUrl = () => {
  return '/inquiry/data/property';
};

export const getNewInquiryUrl = () => {
  return '/inquiry/info/property';
};

export const getInvestmentDashboardUrl = () => {
  return '/dashboard';
};

export const getLogoutUrl = () => {
  return getPortalsUrl('auth/logout');
};

export const getLoginUrl = () => {
  return getPortalsUrl('auth/login/');
};

export const getEquityDashboardUrl = () => {
  return getPortalsUrl('dashboard/home-equity');
};

export const getLoginUrlWithEquityDashboardNextStep = () => {
  return `${getLoginUrl()}?next=/dashboard/home-equity`;
};

export const HOMEOWNER_DASHBOARD = {
  ROOT: '/dashboard',
  INVESTMENTS: 'investments',
  HOME_EQUITY: 'home-equity',
  MAINTENANCE: 'maintenance',
  FEATURED_OFFERS: 'featured-offers',
  RESOURCES: 'resources',
  SUPPORT: 'support',
  ACCOUNT: 'account',
  SCENARIOS: 'scenarios',
  PROFILE: 'profile',
  RENOVATIONS: 'renovations',
  HOME_PROFILE: 'home-profile',
  EDIT_PROFILE: 'edit-home-profile',
  BUILD_PROFILE: 'build-home-profile',
};

export const getNewDashboardUrl = path => `${HOMEOWNER_DASHBOARD.ROOT}/${path}`;

export const getNewHEDUrl = (path = '') => getNewDashboardUrl(`${HOMEOWNER_DASHBOARD.HOME_EQUITY}${path}`);

export const editHomeProfileUrl = () => {
  return getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.EDIT_PROFILE}`);
};

export const buildHomeProfileUrl = () => {
  return getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.BUILD_PROFILE}`);
};

export const buildNewHEDUrl = ({ renovations, renoType, renoId } = {}) => {
  let path = '';
  if (renoType || renovations) {
    path += `/${HOMEOWNER_DASHBOARD.RENOVATIONS}`;
    if (renoType) {
      path += `/${renoType}`;
    }
    if (renoId) {
      path += `/${renoId}`;
    }
  }
  return getNewHEDUrl(path);
};

// Redirect to dashboard URL
export const redirectToDashboardUrl = () => {
  window.location.href = getDashboardUrl();
};
