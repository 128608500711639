import { useAsync } from '@hometap/htco-components';
import { apiNoAuth } from 'utils/api';

const fetchStates = () => {
  return apiNoAuth.get(`/core/configurations/states/`);
};

const useStates = () => {
  const { results } = useAsync(fetchStates, {
    immediate: true,
  });

  const statesForDropdown = results?.map(state => ({ label: state.abbreviation, value: state.abbreviation }));
  const operationalStatesList = results?.filter(state => state.status === 'Operational');

  return { statesForDropdown, operationalStatesList };
};

export default useStates;
