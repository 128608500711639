import React, { useState } from 'react';
import { uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { useCookies } from 'react-cookie';
import { Modal, SelectionSet, Button } from '@hometap/htco-components';

import useInitialInquiryData from 'apps/inquiry/hooks/useInitialInquiryData';
import { getNewInquiryUrl } from 'utils/links';

import ReInquiryAddress from './ReInquiryAddress';
import { isActiveAndPrimary } from '../data/reInquiryUtils';
import { MEMBERSHIP_ROLES, SELECTOR_VALUES } from '../constants/ReInquiryConstants';
import { COOKIES } from 'apps/dashboard/constants';

import '../ReInquiry.scss';

const getUniqueTrackAddresses = tracks => {
  const addresses = tracks?.map(track => {
    return track?.home?.address;
  });
  return uniqBy(addresses, 'business_key');
};

const getUniqueHomeAddresses = homes => {
  const addresses = homes?.map(home => {
    return home?.address;
  });
  return uniqBy(addresses, 'business_key');
};

const ReInquiryAddressPickerModal = ({
  className,
  open,
  onClose,
  homeownerTracks,
  homeownerEquityPlannerHomes,
  onSelectEquityPlannerHome,
  isInquiry = false,
}) => {
  const [selected, setSelected] = useState('');
  const { setSessionPropertyData } = useInitialInquiryData();
  const [, setCookie] = useCookies([COOKIES.DISABLE_PROPERTY_MODAL]);
  const history = useHistory();

  const handleSelectedKey = selectedKey => {
    if (selectedKey === SELECTOR_VALUES.NEW) {
      if (isInquiry) {
        return onClose();
      }
      setSessionPropertyData({});
      setCookie(COOKIES.DISABLE_PROPERTY_MODAL, true, { path: '/' });
      return history.push(getNewInquiryUrl());
    }

    if (homeownerEquityPlannerHomes?.length) {
      const equityPlannerHome = homeownerEquityPlannerHomes.find(home => home.address.business_key === selectedKey);
      if (equityPlannerHome) {
        return onSelectEquityPlannerHome(equityPlannerHome);
      }
    }

    const tracks = homeownerTracks.filter(track => track.home.address.business_key === selectedKey);
    const activeTracks = tracks.filter(isActiveAndPrimary);

    // if there’s an active track for that address, direct to that track's “inner index” page
    if (activeTracks.length > 0) {
      return window.htap.redirectToTrackInnerIndex(activeTracks[0]);
    }

    // if the user was a co-applicant for that track - direct to the Inquiry form with the address pre-filled
    const [reinquirableTrack] = tracks || [];
    if (reinquirableTrack.membership.role === MEMBERSHIP_ROLES.COAPPLICANT) {
      const { address, property_type } = reinquirableTrack.home;
      setSessionPropertyData({ ...address, property_type });
      return history.push(getNewInquiryUrl());
    }

    return window.htap.redirectToReInquiryReview(selectedKey);
  };

  return (
    <Modal className={cx('ReInquiryAddressPicker', className)} open={open} onClose={onClose}>
      <h2>We’re glad you’re back!</h2>
      <p>Which property would you like to use to reopen your application?</p>
      {/* Modal component selects first child input element found for a11y, causing unwanted focusing on open */}
      <input type="hidden" tabIndex={0} />
      <SelectionSet
        labelWidth="100%"
        onChange={setSelected}
        value={selected}
        options={[
          ...getUniqueTrackAddresses(homeownerTracks).map(address => {
            return {
              label: <ReInquiryAddress address={address} />,
              value: address.business_key,
            };
          }),
          ...getUniqueHomeAddresses(homeownerEquityPlannerHomes).map(address => {
            return {
              label: <ReInquiryAddress address={address} />,
              value: address.business_key,
            };
          }),
          { label: 'I’d like to apply with a new property', value: SELECTOR_VALUES.NEW },
        ]}
      />
      <Button
        disabled={!selected}
        label="Continue"
        onClick={() => handleSelectedKey(selected)}
        data-testid="re-inquiry-continue-button"
      />
    </Modal>
  );
};

export default ReInquiryAddressPickerModal;
