import { useCallback, useEffect, useRef, useState } from 'react';

const useComputedState = (computeState, deps) => {
  const [internalState, setInternalState] = useState(computeState);
  const isInitialized = useRef(false);
  const isDirty = useRef(false);

  const setState = useCallback(newState => {
    setInternalState(newState);
    isDirty.current = true;
  }, []);

  const reset = useCallback(() => {
    // Schedule state recalculation on next deps change
    isDirty.current = false;
  }, []);

  useEffect(
    () => {
      if (!isInitialized.current) {
        // Do not recalculate value after first render
        isInitialized.current = true;
        return;
      }

      if (!isDirty.current) {
        // Do not recompute state if it was set manually until reset is called
        setInternalState(computeState());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  return [internalState, setState, reset];
};

export default useComputedState;
