import { SelectionSet } from '@hometap/htco-components';

import useApplicationContext from '../hooks/useApplicationContext';
import { APPLICATION_FORM_FIELD } from '../constants/formFields';

export const lienOptions = [
  { label: 'Mortgage', value: 'mortgage' },
  { label: 'Home Equity Loan', value: 'home_equity_loan' },
  { label: 'Home Equity Line of Credit (HELOC)', value: 'heloc' },
  { label: 'Private Mortgage', value: 'private_mortgage' },
  { label: 'Judgement', value: 'judgement' },
  { label: 'Tax Lien', value: 'tax' },
  { label: 'Universal Commercial Code (UCC)', value: 'ucc' },
  { label: 'Partial Claim', value: 'partial_claim' },
  { label: 'Home Equity Investment (HEI)', value: 'hei' },
  { label: 'Other', value: 'other' },
];

const LienTypesFormFields = () => {
  const { registerApplicationFormField } = useApplicationContext();

  return (
    <div className="ApplicationInfoFormFields">
      <SelectionSet
        labelWidth="100%"
        labelGrow={false}
        required
        multiple
        options={lienOptions}
        {...registerApplicationFormField(APPLICATION_FORM_FIELD.lienTypes)}
      />
    </div>
  );
};

export default LienTypesFormFields;
