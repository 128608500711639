export const RESULT_YES = {
  use_case_business: {
    title: `Hometap looks like a great fit to help you fund your business!`,
    text: `For some people, taking a home equity investment can be a smart way to add some financial security to their business and accelerate their growth. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_education: {
    title: `Hometap looks like a great fit to help you fund an education!`,
    text: `For some people, taking a home equity investment can be a smart way to pay for college without the burden of interest-heavy student loans. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_renovate: {
    title: `Hometap looks like a great fit to help you fund your home renovation.`,
    text: `For some people, taking a home equity investment can be a smart way to pay for that dream kitchen, new deck, or roof replacement. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_diversify: {
    title: `Hometap looks like a great fit to help you diversify your portfolio!`,
    text: `For some people, taking a home equity investment can be a smart way to access the wealth they’ve built up in their home to begin investing in other properties, asset classes, or the stock market. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_divorce: {
    title: `Hometap looks like a great fit to help you fund your divorce!`,
    text: `For some people, taking a home equity investment can be a smart way to pay off expensive legal fees or other unexpected cots of divorce. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_debts: {
    title: `Hometap looks like a great fit to help you pay off debt!`,
    text: `For some people, taking a home equity investment can be a smart way to pay off credit cards, student loans, or other high interest debts. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_buy_home: {
    title: `Hometap looks like a great fit to help you buy another property!`,
    text: `For some people, taking a home equity investment can be a smart way to afford a down payment on that rental property or vacation home they’ve always wanted. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_retirement: {
    title: `Hometap looks like a great fit to help you fund your retirement!`,
    text: `For some people, taking a home equity investment can be a smart way to boost your retirement savings so you can comfortably age in place. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_emergency: {
    title: `Hometap looks like a great fit to help you pay for emerency expenses!`,
    text: `For some people, taking a home equity investment can be a smart way to avoid the financial hardships that come with losing a job, medical emergencies, or other unexpected life events. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_finance_car: {
    title: `Hometap looks like a great fit to help you finance a car!`,
    text: `For some people, taking a home equity investment can be a smart way to afford a new or used car without the burden of interest-heavy auto loans. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
  use_case_fund_family: {
    title: `Hometap might be a fit to help you fund your growing family.`,
    text: `For some people, taking a home equity investment can be a smart way to handle the financial needs of a new child. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_other: {
    title: `Hometap looks like a great fit to help you achieve your financial goals!`,
    text: `For some people, taking a home equity investment can be a smart way to fund the financial goals or needs they have -- without having to sell their home or take on debt. Based on the information you provided, we think Hometap could be a good option for you too!`,
  },
};

export const RESULT_TERM_YES = {
  'In 1-5 yrs': {
    title: `You plan to sell your home in 1 - 5 years.`,
    text: `Hometap investments have a 10 year term, at which point our investment must be settled through a sale, with savings or by refinancing. Since you plan to stay in your home for 1 - 5 years, Hometap might be a good option for you!`,
  },
  'In 6-10 yrs': {
    title: `You plan to sell your home in 6 - 10 years.`,
    text: `Hometap investments have a 10 year term, at which point our investment must be settled through a sale, with savings or by refinancing. Since you plan to stay in your home for 6 - 10 years, Hometap might be a good option for you!`,
  },
};

export const RESULT_MONTHY_EXPENSE_YES = {
  yes: {
    title: `Covering your monthly expenses keeps you up at night.`,
    text: `Don’t worry, you’re not alone. The good news is that with a Hometap Investment, you’ll be able to tap into your home’s equity witth no additional monthly payments. Many of the homeowners we work with use their Hometap Investment to pay down prior debts, allowing them to reduce their existing monthly expenses and regain financial flexibility.`,
  },
};

export const RESULT_RAINY_DAY_FUND_YES = {
  title: `Your "rainy day" fund is not where you’d like it to be.`,
  text: `While saving for a “rainy day” may not seem like the most exciting way to allocate your hard-earned home equity, in the face of a true financial emergency, you’ll be happy you did. Hometap might be a great solution to help you handle life’s unexpected expenses without going into debt.`,
};

export const RESULT_ALTERNATIVE_YES = {
  text: `At Hometap, we always encourage the homeowners we work with to explore all of their options to ensure they’re making the most informed decision. Unlike a loan or other alternative financing solutions, Hometap gives you the funds you need without any debt, monthly payments, interest, or hidden fees.`,
};
