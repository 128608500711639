import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import PropTypes from 'prop-types';
import { Icon, Button, Grid, useWindowSize } from '@hometap/htco-components';
import helpfulButton from 'images/equity-planner/dashboard/icons/helpful_button.png';
import unhelpfulButton from 'images/equity-planner/dashboard/icons/unhelpful_button.png';
import ConfettiExplosion from 'react-confetti-explosion';
import { themeMap, themeHighlightTextMap } from './constants/smartFactConstants';
import './SmartCardCarousel.scss';

/*
  This Carousel was made with Embla Carousel. Docs are here: https://www.embla-carousel.com/guides/
*/

const highlightText = (text, theme) => {
  // Matches dollar amounts, percentages (including decimals), percentage ranges, and the phrase "up to"
  const regex = /(\$\d+(?:,\d{3})*(?:\.\d{1,2})?|\d+(?:\.\d+)?(?:-\d+(?:\.\d+)?)?%|up to)/gi;
  const parts = text.split(regex);
  const matches = new Set(text.match(regex)); // Use a Set for unique matches

  // Combine parts and matches into an array of elements
  const combined = [];
  parts.forEach((part, i) => {
    if (matches.has(part)) {
      // If part is a match, style it
      combined.push(
        <span key={`match-${i}`} className={cx(themeHighlightTextMap[theme])}>
          {part}
        </span>,
      );
      matches.delete(part); // Remove the match to avoid duplication
    } else {
      // If part is not a match, include it as normal
      combined.push(part);
    }
  });

  return combined;
};

const DotButton = ({ selected }) => (
  <button className={cx('embla__dot', { is_selected: !!selected })} type="button" tabIndex="-1" />
);

const SmartCardFinalCard = () => {
  return (
    <div className="SmartCardFinalCard">
      <Icon name="icon-check" size="2x" />
      <h2>You’re all caught up!</h2>
      <p>Come back soon for your next set of Hometap Insights.</p>
    </div>
  );
};

const SmartCardCarouselCard = ({ id, theme, header, hasCta, ctaText, onCtaClick, onHelpful, onUnhelpful, onNext }) => {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const classes = cx('SmartCardCarouselCard', themeMap[theme], 'hasMultipleCards');
  const styledHeader = highlightText(header, theme);
  const handleFeedbackSubmitted = callback => {
    setFeedbackSubmitted(true);
    callback();
  };
  return (
    <div className={classes} onClick={onNext} onKeyDown={onNext} role="button" tabIndex="0" smart-card-id={id}>
      <div className="SmartCardCarouselCardInnerContainer">
        <Grid container columnSpacing={2}>
          <Grid sm={12} md={8} lg={9}>
            <div className="InsightsTitle">Hometap Insights</div>
            <h2 className="InsightsText">{styledHeader}</h2>
            {hasCta && (
              <Button theme="link" className="SmartCardCarouselCtaBtn min-w-0" onClick={onCtaClick}>
                <span>{ctaText}</span>
                <Icon name="arrow-forward" className="IconProperties" />
              </Button>
            )}
          </Grid>
          <Grid sm={12} md={4} lg={3}>
            <div className="SmartCardCarouselCardReviewSection">
              {feedbackSubmitted ? (
                <span className="SmartCardCarouselFeedback">Thanks for the feedback!</span>
              ) : (
                <>
                  <span>Was this helpful?</span>
                  <button
                    onClick={event => handleFeedbackSubmitted(() => onHelpful(event))}
                    aria-label="thumbs up"
                    className="SmartCardButtonImageWrapper"
                  >
                    <img src={helpfulButton} alt="thumbs up" />
                  </button>
                  <button
                    onClick={event => handleFeedbackSubmitted(() => onUnhelpful(event, id))}
                    aria-label="thumbs down"
                    className="SmartCardButtonImageWrapper"
                  >
                    <img src={unhelpfulButton} alt="thumbs down" />
                  </button>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const SmartCardCarousel = ({ className, cards = [], handleClosing }) => {
  const editedCards = [...cards, { theme: 'FINAL' }];
  // watchResize was causing an error in the latest embla release, using an earlier version of embla seems to have fixed
  const { isScreenSize } = useWindowSize();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: isScreenSize('sm'),
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const onLastCard = selectedIndex === editedCards?.length - 1;

  useEffect(() => {
    if (onLastCard) {
      handleClosing();
    }
    // eslint-disable-next-line
  }, [onLastCard]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);

    return () => {
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, setScrollSnaps, onSelect]);

  const withStopPropagation = handler => {
    return (event, ...args) => {
      event.stopPropagation();
      if (handler) {
        handler(event, ...args);
      }
    };
  };

  return (
    <div className={cx('SmartCardCarousel', className)}>
      <div className="SmartCardCarouselConfetti">
        {onLastCard ? (
          <ConfettiExplosion zIndex={10000000} colors={['#366CED', '#7070FF', '#25BE8A', '#70DAFF', '#35B2AD']} />
        ) : null}
      </div>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {editedCards.map((card, i) => (
              <div className="embla__slide" key={card?.theme + i}>
                {card.theme === 'FINAL' ? (
                  <SmartCardFinalCard />
                ) : (
                  <SmartCardCarouselCard
                    id={card?.id}
                    theme={card?.theme}
                    header={card?.header}
                    hasCta={card?.hasCta}
                    ctaText={card?.ctaText}
                    onCtaClick={withStopPropagation(card?.onCtaClick)}
                    onHelpful={withStopPropagation(card?.onHelpful)}
                    onUnhelpful={card?.onUnhelpful}
                    onNext={scrollNext}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        {cards.length > 1 && (
          <div className="SmartCardCarouselNavContainer hasMultipleCards">
            <button
              className={cx('SmartCardCarouselButton', 'embla__prev', { hideCarouselButton: selectedIndex < 1 })}
              onClick={scrollPrev}
            >
              <Icon name="chevron2-left" size="sm" />
            </button>
            <div className="embla__dots">
              {scrollSnaps.map((_, index) => (
                <DotButton key={index} selected={index === selectedIndex} />
              ))}
            </div>
            <button
              className={cx('SmartCardCarouselButton', 'embla__next', {
                hideCarouselButton: selectedIndex === cards.length,
              })}
              onClick={scrollNext}
            >
              <Icon name="chevron2-right" size="sm" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

SmartCardCarousel.propTypes = {
  className: PropTypes.string,
  handleClosing: PropTypes.func,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      theme: PropTypes.oneOf(['teal', 'lightBlue', 'purple']),
      header: PropTypes.string.isRequired,
      hasCta: PropTypes.bool,
      ctaText: PropTypes.string,
      onCtaClick: PropTypes.func,
      onHelpful: PropTypes.func,
      onUnhelpful: PropTypes.func,
    }),
  ),
};

export default SmartCardCarousel;
