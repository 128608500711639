import { Container, Icon, IconButton } from '@hometap/htco-components';
import PropTypes from 'prop-types';

import './NotificationBanner.scss';

/**
 * Renders a full width banner with an icon, title, supporting text, action button, and dismiss button.
 */
const NotificationBanner = ({ title, supportingText, icon, rightSideContent, onDismiss }) => {
  return (
    <div className="NotificationBanner">
      <Container
        className="NotificationBannerContentContainer"
        row
        respondAt="sm"
        justify="space-between"
        align="center"
      >
        <span className="NotificationBannerContentContainerSection">
          {icon && (
            <span className="NotificationBannerIconContainer">
              <Icon name={icon} />
            </span>
          )}
          <span className="NotificationBannerTextContainer">
            <strong>{title}</strong>
            <span>{supportingText}</span>
          </span>
        </span>
        {rightSideContent && (
          <span className="NotificationBannerContentContainerSection">
            <span className="NotificationBannerActionsContainer">
              {rightSideContent}
              <IconButton onClick={onDismiss} className="NotificationBannerDismissButton" icon="close" />
            </span>
          </span>
        )}
      </Container>
    </div>
  );
};

NotificationBanner.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  supportingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  rightSideContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onDismiss: PropTypes.func.isRequired,
};

export default NotificationBanner;
