import { RouterPrompt } from 'apps/progressive-inquiry/components/RouterPrompt';
import { useRef } from 'react';
import PropTypes from 'prop-types';

import BackToDashBoardButton from './BackToDashboardButton';

import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';

const ProgressiveFormSlide = forwardRef(({ slideProps = {}, children }, ref) => {
  const {
    timeout = 350,
    mountOnEnter = true,
    unmountOnExit = true,
    easing = { enter: 'linear', exit: 'linear' },
  } = slideProps;

  return (
    <Slide
      timeout={timeout}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      easing={easing}
      container={ref.current}
      {...slideProps}
    >
      <div className="absolute left-0 right-0 mx-auto my-0 flex w-full max-w-screen-lg flex-col items-center justify-center p-mobile-padding-sm md:p-desktop-padding-sm lg:px-desktop-padding-lg">
        {children}
      </div>
    </Slide>
  );
});

const ProgressiveFormSlides = ({ slides, shouldShowPrompt = false, canGoBackToDashboard = false }) => {
  const containerRef = useRef(null);

  return (
    <>
      <RouterPrompt when={shouldShowPrompt} />
      <div ref={containerRef} className="mx-auto my-0">
        {slides.map(({ slideProps, component }, i) => {
          return (
            <ProgressiveFormSlide key={`slide-${i}`} ref={containerRef} slideProps={slideProps}>
              {canGoBackToDashboard && <BackToDashBoardButton />}
              {component}
            </ProgressiveFormSlide>
          );
        })}
      </div>
    </>
  );
};

export default ProgressiveFormSlides;

ProgressiveFormSlides.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      slideProps: PropTypes.object,
      component: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
  canGoBackToDashboard: PropTypes.bool,
  shouldShowPrompt: PropTypes.bool.isRequired,
};
