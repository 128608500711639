import PricingMatrixHeading from '../PricingMatrixHeading/PricingMatrixHeading';
import cx from 'classnames';

import './PricingMatrixCard.scss';
import PricingMatrixValue from '../PricingMatrixValue/PricingMatrixValue';

const PricingMatrixCard = ({ rows, cardData }) => {
  const { title, iconName, subtitle, buttonProps } = cardData.heading;
  const formattedRows = rows.map(({ key, label }, i) => {
    return (
      <div className="PricingMatrixRow PricingMatrixCardRow" key={`${key}-${i}`}>
        <span>{label}</span> <PricingMatrixValue valueData={cardData[key]} />
      </div>
    );
  });

  return (
    <div className={cx('PricingMatrixCard', { isPreferred: cardData.isPreferred })}>
      <header>
        <PricingMatrixHeading
          title={title}
          iconName={iconName}
          subtitle={subtitle}
          buttonProps={buttonProps}
          badge={cardData.badge}
        />
      </header>
      {formattedRows}
    </div>
  );
};
export default PricingMatrixCard;
