import { useAsync } from '@hometap/htco-components';
import { useEffect } from 'react';
import apiWithAuth from 'utils/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentHome } from 'hooks/useCurrentHome';

const fetchHouseCanaryLiens = async ({ homeId }) => {
  return await apiWithAuth.v1.get(`homes/${homeId}/house-canary-liens/`, { params: { purpose: 'HED' } });
};

const useHouseCanaryLiens = ({ shouldFetch } = { shouldFetch: true }) => {
  const { home } = useCurrentHome();
  const flags = useFlags();
  const isHCFeatureOn = !!flags.useHCinHedOnboarding;
  const canFetch = isHCFeatureOn && shouldFetch;

  const {
    loading,
    execute: fetchHCLiens,
    ...fetchHouseCanaryLiensAsync
  } = useAsync(fetchHouseCanaryLiens, {
    defaultLoading: canFetch,
  });

  useEffect(() => {
    if (home?.id && canFetch) {
      fetchHCLiens({ homeId: home.id });
    }
  }, [home, fetchHCLiens, canFetch]);

  return { fetchAsync: fetchHouseCanaryLiensAsync, refetch: fetchHCLiens };
};

export default useHouseCanaryLiens;
