import React from 'react';

import ResultsController from './controllers/ResultController';
import PageSEO from '../components/PageSEO';
import Layout from '../components/Layout';

const FitQuizResults = () => {
  return (
    <PageSEO
      description="View your fit quiz results!"
      title="Fit Quiz Results"
      opengraphTitle="Hometap | Is Hometap right for me?"
      opengraphDescription="View your fit quiz results!"
    >
      <Layout>
        <ResultsController />
      </Layout>
    </PageSEO>
  );
};

export default FitQuizResults;
