import React, { useState } from 'react';
import { Button, Checkbox, useForm, useAsync } from '@hometap/htco-components';

import { getVersion } from 'utils/env';
import { applicationConsentLanguage } from '../../application/utils';
import { giveCoApplicantConsent } from '../data/coApplicantRequests';

const CoApplicantConsentForm = ({ applicant, onConsentSuccess }) => {
  const [isChecked, setIsChecked] = useState(false);

  const { execute, loading } = useAsync(giveCoApplicantConsent, { immediate: false });
  const { handleSubmit, registerField } = useForm();

  const onSubmit = async () => {
    const hasConsented = await execute(applicant.id, {
      client_portal_version: getVersion(),
    });

    if (hasConsented) {
      return onConsentSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="CoApplicantConsentForm">
      <div className="CoApplicantConsentFormContent">
        <div className="CoApplicantConsentFormConsentBox">
          <Checkbox
            onClick={() => setIsChecked(!isChecked)}
            checked={isChecked}
            required
            label={<b>By checking this box:</b>}
            // This is just to have the required functionality work as expected.
            // We don't do anything with this field name in any requests.
            {...registerField('verified', 'checked')}
          />
        </div>
        <ul className="CoApplicantConsentFormDetails">
          {applicationConsentLanguage.map((detail, i) => (
            <React.Fragment key={`detail-${i}`}>{detail}</React.Fragment>
          ))}
        </ul>
      </div>
      <Button loading={loading} type="submit" className="CoApplicantConsentFormSubmit">
        Submit
      </Button>
    </form>
  );
};

export default CoApplicantConsentForm;
