import { omit } from 'lodash';
import {
  APPLICANT_FORM_FIELD,
  APPLICATION_FORM_FIELD,
  NON_APPLICANT_MODEL_FORM_FIELD_KEYS,
  NON_APPLICATION_MODEL_FORM_FIELD_KEYS,
} from './formFields';

export const APPLICANT_MODEL_FIELD = omit(
  { ...APPLICANT_FORM_FIELD, mailingAddress: 'mailing_address' },
  NON_APPLICANT_MODEL_FORM_FIELD_KEYS,
);
export const APPLICATION_MODEL_FIELD = omit(
  {
    ...APPLICATION_FORM_FIELD,
    hasSubmittedLiens: 'has_submitted_liens',
    hasSubmittedApplicants: 'has_submitted_applicants',
  },
  NON_APPLICATION_MODEL_FORM_FIELD_KEYS,
);
