import { RESOURCE_CATEGORIES, RESOURCE_TYPES } from '../constants/resources';
import PrioritizingFinancialGoals from './components/pay-off-debt-build-savings-prioritizing-financial-goals';
import WhyPropertyTaxesIncrease from './components/why-property-taxes-increase';
import ShouldISellMyHome from './components/should-i-sell-my-home';
import FinancingHomeRenovation from './components/renovations/financing-home-renovation';
import SaveForHomeRepairs from './components/renovations/save-for-home-repairs';
import PrioritizeHomeRenovations from './components/renovations/prioritize-home-renovations';
import howHomeValueEstimatorsWork from './components/how-home-value-estimators-work';
import roofMaintenanceTips from './components/roof-maintenance-tips-every-homeowner-should-know';
import topHomeRenovations from './components/top-5-home-renovations-with-highest-roi';
import waysToFinanceAHomeRenovation from './components/ways-to-finance-a-home-renovation';
import howLongDoYourHomesSystemsLast from './components/how-long-do-your-homes-systems-and-applications-last';
import Trends from './components/home-equity/trends';
import InflationReductionAct from './components/home-equity/inflation-reduction-act';
import HowToRefinance from './components/home-equity/how-to-refinance';
import EfficiencyUpgrades from './components/maintenance/efficiency-upgrades';
import HomeWarranty from './components/maintenance/home-warranty';

import payDebt from 'images/dashboard/pay-debt-or-save-hero-scaled.png';
import propertyTaxes from 'images/dashboard/property-taxes-hero-scaled.png';
import sellOrTap from 'images/dashboard/sell_or_tap_hero-scaled.png';
import houseAppreciation from 'images/dashboard/house-appreciation-hero-1-scaled.png';
import solarCredit from 'images/dashboard/solar-credit-hero-scaled.png';
import lake from 'images/dashboard/lakefront.png';
import greenHome from 'images/dashboard/green-home-hero-scaled.png';
import homeWarranty from 'images/dashboard/home-warranty-hero2-scaled.png';
import simpliSafe from 'images/dashboard/simpli-safe.png';
import ladder from 'images/dashboard/ladder.png';
import coins from 'images/dashboard/coins.png';
import walnutWood from 'images/dashboard/walnut_wood.png';
import homeValueHero from 'images/dashboard/home-value-hero.jpg';
import renovationsROIHero from 'images/dashboard/renovations-ROI-hero.jpg';
import appliancesLastHero from 'images/dashboard/appliances-last-hero.jpg';
import roofHero from 'images/dashboard/roof-hero.jpg';
import waysToFinanceHomeRenovationHero from 'images/dashboard/ways-finance-home-renovation-hero.jpg';
import { FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID } from '../constants';

// Main featured resource
const HOW_HOME_VALUE_ESTIMATORS_WORK = {
  title: 'How Home Value Estimators Work',
  content: howHomeValueEstimatorsWork,
  excerpt: 'How Home Value Estimators Work',
  coverImage: homeValueHero,
  date: '2024-02-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'how-home-value-estimators-work',
};

// Featured Resources
const ROOF_MAINTENANCE_TIPS = {
  title: '11 Roof Maintenance Tips Every Homeowner Should Know',
  content: roofMaintenanceTips,
  excerpt: '11 Roof Maintenance Tips Every Homeowner Should Know',
  coverImage: roofHero,
  date: '2024-02-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: '11-roof-maintenance-tips-every-homeowner-should-know',
};

const TOP_5_HOME_RENOVATIONS = {
  title: 'Top 5 Home Renovations with the Highest Return on Investment',
  content: topHomeRenovations,
  excerpt: 'Top 5 Home Renovations with the Highest Return on Investment',
  coverImage: renovationsROIHero,
  date: '2024-02-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MARKET_INSIGHTS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'top-5-home-renovations-with-highest-roi',
};

const FINANCE_HOME_RENOVATIONS = {
  title: '6 Ways to Finance a Home Renovation',
  content: waysToFinanceAHomeRenovation,
  excerpt: '6 Ways to Finance a Home Renovation',
  coverImage: waysToFinanceHomeRenovationHero,
  date: '2024-02-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: '6-ways-to-finance-a-home-renovation',
};

const HOW_LONG_DO_YOUR_HOMES_SYSTEMS_AND_APPLIANCES_LAST = {
  title: "How Long Do Your Home's Systems And Appliances Last?",
  content: howLongDoYourHomesSystemsLast,
  excerpt: "How Long Do Your Home's Systems And Appliances Last?",
  coverImage: appliancesLastHero,
  date: '2024-02-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'how-long-do-your-homes-systems-and-appliances-last',
};

// Financing My Goals
const PRIORITIZING_FINANCIAL_GOALS = {
  title: 'Pay Off Debt or Build Savings? Prioritizing Your Financial Goals',
  content: PrioritizingFinancialGoals,
  excerpt: 'Pay Off Debt or Build Savings? Prioritizing Your Financial Goals',
  coverImage: payDebt,
  date: '2022-08-17',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'pay-off-debt-build-savings-prioritizing-financial-goals',
};

const UNDERSTANDING_PROPERTY_TAXES = {
  title: 'Understanding Property Taxes and Why They Increase',
  content: WhyPropertyTaxesIncrease,
  excerpt: 'Understanding Property Taxes and Why They Increase',
  coverImage: propertyTaxes,
  date: '2021-08-10',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'why-property-taxes-increase',
};

const SHOULD_I_SELL_MY_HOME = {
  title: 'Should You Sell Your Home or Tap Into Your Home Equity?',
  content: ShouldISellMyHome,
  excerpt: 'Should You Sell Your Home or Tap Into Your Home Equity?',
  coverImage: sellOrTap,
  date: '2021-06-01',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'should-i-sell-my-home',
};

// Grow my home value

const TRENDS_HOME_APPRECIATION = {
  title: 'What Trends Are Causing House Prices to Appreciate?',
  content: Trends,
  excerpt: 'What Trends Are Causing House Prices to Appreciate?',
  coverImage: houseAppreciation,
  date: '2020-04-13',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'what-causes-home-value-to-appreciate',
};

const INFLATION_REDUCTION_ACT = {
  title: 'How the Inflation Reduction Act is Helping Homeowners Go Solar',
  content: InflationReductionAct,
  excerpt: 'How the Inflation Reduction Act is Helping Homeowners Go Solar',
  coverImage: solarCredit,
  date: '2023-04-11',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'inflation-reduction-act-helps-homeowners-go-solar',
};

const HOW_TO_REFINANCE = {
  title: 'How to Refinance: A Guide to Refinancing Your Mortgage',
  content: HowToRefinance,
  excerpt: 'How to Refinance: A Guide to Refinancing Your Mortgage',
  coverImage: lake,
  date: '2022-04-01',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'how-to-refinance-mortgage',
};

// Protect my investment

export const SIMPLI_SAFE = {
  id: FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID,
  theme: 'primary',
  title: 'Give your family the gift of a safer home.',
  text: 'Receive an exclusive 50% Hometap discount on SimpliSafe + 2 months of free monitoring.',
  linkText: 'Save now',
  href: 'https://simplisafe.com/hometap?utm_source=hometap&utm_medium=partnerdigital&utm_campaign=sr',
  image: simpliSafe,
  disclaimer: 'Hometap may receive compensation when you purchase a SimpliSafe kit.',
};

export const EFFICIENCY_UPGRADES = {
  title: '9 Home Efficiency Upgrades Homeowners Should Know About',
  content: EfficiencyUpgrades,
  excerpt: '9 Home Efficiency Upgrades Homeowners Should Know About',
  coverImage: greenHome,
  date: '2021-04-15',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'green-home-efficiency-upgrades',
};

export const HOME_WARRANTY = {
  title: 'What Is a Home Warranty and Do You Need One?',
  content: HomeWarranty,
  excerpt: 'What Is a Home Warranty and Do You Need One?',
  coverImage: homeWarranty,
  date: '2023-02-09',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'what-is-a-home-warranty-and-do-you-need-one',
};

// Renovations
const FINANCING_HOME_RENOVATION = {
  title: 'Financing a Home Renovation: What Renovation Loan Is Best?',
  content: FinancingHomeRenovation,
  excerpt: 'Financing a Home Renovation: What Renovation Loan Is Best?',
  coverImage: ladder,
  date: '2020-07-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'home-renovation-loans',
};

const SAVING_FOR_HOME_REPAIRS = {
  title: 'How to Save for Home Repairs Before They’re Needed',
  content: SaveForHomeRepairs,
  excerpt: 'How to Save for Home Repairs Before They’re Needed',
  coverImage: coins,
  date: '2019-03-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'how-much-to-save-for-home-maintenance-and-repairs',
};

const PRIORITIZE_HOME_RENOVATION = {
  title: 'How to Prioritize Home Renovations and Repairs',
  content: PrioritizeHomeRenovations,
  excerpt: 'How to Prioritize Home Renovations and Repairs',
  coverImage: walnutWood,
  date: '2023-05-03',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'prioritize-renovations-and-repairs',
};

export const FEATURED_RESOURCE = {
  ...HOW_HOME_VALUE_ESTIMATORS_WORK,
};

export const FEATURED_RESOURCES_LIST = [
  ROOF_MAINTENANCE_TIPS,
  TOP_5_HOME_RENOVATIONS,
  FINANCE_HOME_RENOVATIONS,
  HOW_LONG_DO_YOUR_HOMES_SYSTEMS_AND_APPLIANCES_LAST,
];

export const FINANCING_MY_GOALS_LIST = [
  PRIORITIZING_FINANCIAL_GOALS,
  UNDERSTANDING_PROPERTY_TAXES,
  SHOULD_I_SELL_MY_HOME,
];

export const GROW_MY_HOME_VALUE_AND_EQUITY_LIST = [TRENDS_HOME_APPRECIATION, INFLATION_REDUCTION_ACT, HOW_TO_REFINANCE];

export const RENOVATIONS = [FINANCING_HOME_RENOVATION, SAVING_FOR_HOME_REPAIRS, PRIORITIZE_HOME_RENOVATION];

export const ALL_POSTS = [
  FEATURED_RESOURCE,
  ...FEATURED_RESOURCES_LIST,
  ...FINANCING_MY_GOALS_LIST,
  ...GROW_MY_HOME_VALUE_AND_EQUITY_LIST,
  ...RENOVATIONS,
  HOME_WARRANTY,
  EFFICIENCY_UPGRADES,
];
