import { Icon, useWindowSize } from '@hometap/htco-components';
import './Insight.scss';

const Insight = ({ header = 'DID YOU KNOW...', text }) => {
  const { isScreenSize } = useWindowSize();
  const isSmallerIcon = isScreenSize('xs');
  return (
    <div className="Insights">
      <div>
        <div className="InsightsBulbIcon">
          <Icon name="icon-lightbulb" size={isSmallerIcon ? 'lg' : '2x'} />
        </div>
      </div>
      <div>
        <h5>{header}</h5>
        <p>{typeof text === 'string' ? text : text()}</p>
      </div>
    </div>
  );
};

export default Insight;
