import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import DashboardLayout from 'components/Layouts/DashboardLayout/DashboardLayout';
import SecondaryLayout from 'components/Layouts/SecondaryLayout/SecondaryLayout';
import { EquityScenarioContextProvider } from 'apps/dashboard/hooks/useEquityScenarioContext';
import { HomeValuationProvider } from 'apps/dashboard/hooks/useHomeValuation';
import { DashboardTabsProvider } from 'components/Layouts/DashboardLayout/hooks/useDashboardTabsContext';
import { HedInvestmentProvider } from 'apps/dashboard/hooks/useHedInvestmentContext';
import HomeEquityController from '../home-equity-controller/HomeEquityController';
import InvestmentController from '../investment-controller/InvestmentController';
import MaintenanceController from '../maintenance-controller/MaintenanceController';
import FeaturedOffersController from '../featured-offers-controller/FeaturedOffersController';
import ResourcesController from '../resources-controller/mainTab/ResourcesController';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import ResourcesPost from '../resources-controller/post/ResourcesPost';

const DashboardRouter = () => {
  return (
    <>
      <HedInvestmentProvider>
        <HomeValuationProvider>
          <EquityScenarioContextProvider>
            <DashboardTabsProvider>
              <Switch>
                <PrivateRoute exact path="/dashboard">
                  <Redirect to={getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS)} />
                </PrivateRoute>
                <PrivateRoute exact path={`${getNewDashboardUrl(HOMEOWNER_DASHBOARD.RESOURCES)}/:postTitle`}>
                  <SecondaryLayout prevUrl={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}`}>
                    <ResourcesPost />
                  </SecondaryLayout>
                </PrivateRoute>
                <PrivateRoute path="/dashboard/:page?">
                  <DashboardLayout>
                    <PrivateRoute path={getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS)}>
                      <InvestmentController />
                    </PrivateRoute>
                    <PrivateRoute path={getNewDashboardUrl(HOMEOWNER_DASHBOARD.HOME_EQUITY)}>
                      <HomeEquityController />
                    </PrivateRoute>
                    <PrivateRoute path={getNewDashboardUrl(HOMEOWNER_DASHBOARD.MAINTENANCE)}>
                      <MaintenanceController />
                    </PrivateRoute>
                    <PrivateRoute path={getNewDashboardUrl(HOMEOWNER_DASHBOARD.FEATURED_OFFERS)}>
                      <FeaturedOffersController />
                    </PrivateRoute>
                    <PrivateRoute exact path={getNewDashboardUrl(HOMEOWNER_DASHBOARD.RESOURCES)}>
                      <ResourcesController />
                    </PrivateRoute>
                  </DashboardLayout>
                </PrivateRoute>
              </Switch>
            </DashboardTabsProvider>
          </EquityScenarioContextProvider>
        </HomeValuationProvider>
      </HedInvestmentProvider>
    </>
  );
};

export default DashboardRouter;
