import ApplicationController from 'apps/application/ApplicationController';
import { ApplicationProvider } from 'apps/application/hooks/useApplicationContext';
import { ApplicationNavProvider } from 'apps/application/hooks/useApplicationNavContext';
import CommonLayout from 'components/Layouts/CommonLayout';
import { useRouteMatch, Switch } from 'react-router-dom';
import AppraisalSelectionController from 'apps/appraisal-selection/AppraisalSelectionController';
import ModalPageLayout from 'components/Layouts/ModalPageLayout/ModalPageLayout';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const TrackDetailsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={`${path}/appraisal-selection`}>
        <ModalPageLayout>
          <AppraisalSelectionController />
        </ModalPageLayout>
      </PrivateRoute>

      <PrivateRoute path={`${path}/application`}>
        <CommonLayout>
          <ApplicationProvider>
            <ApplicationNavProvider>
              <ApplicationController />
            </ApplicationNavProvider>
          </ApplicationProvider>
        </CommonLayout>
      </PrivateRoute>
    </Switch>
  );
};

export default TrackDetailsRouter;
