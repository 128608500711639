import React from 'react';
import NavNestedItems from 'components/nav/NavNestedItems/NavNestedItems';
import NavItemLink from 'components/nav/NavItemLink/NavItemLink';

import cx from 'classnames';
import PropTypes from 'prop-types';

const NavSectionGroup = ({ groupIndex, section, active, onToggle, open }) => {
  return (
    <>
      <div
        className={cx(
          'relative before:absolute before:-left-6 before:-right-6 before:top-0 before:h-[1px] before:bg-neutral-light-100 ',
          {
            'pt-6 before:content-[""]': groupIndex !== 0,
          },
        )}
      >
        <NavNestedItems
          title={section.section}
          icon={section.icon}
          active={active}
          open={open}
          onToggle={onToggle}
          navItems={section.items}
        />
      </div>
      {section.extraContent && section.extraContent()}
    </>
  );
};

const NavPrimaryMenu = ({ openSection, onToggleOpenSection, navLinks, navSectionGroups, className, dataTestId }) => {
  const getActiveSection = section => section.items.some(item => item.active);

  return (
    <div className={cx('relative w-full', className)} data-testid={dataTestId}>
      <div className="flex flex-col gap-3 p-4 pt-6">
        {navLinks.map(link => (
          <NavItemLink key={link.title} icon={link.icon} title={link.title} to={link.path} active={link.active} />
        ))}
        {navSectionGroups.map((sectionGroup, groupIndex) => {
          return (
            <div key={`nav-sectionGroup-${groupIndex}`} className="flex flex-col gap-3 pb-3">
              {sectionGroup.map(section => {
                return (
                  <NavSectionGroup
                    key={section.section}
                    groupIndex={groupIndex}
                    active={getActiveSection(section)}
                    open={openSection === section.section}
                    section={section}
                    onToggle={open => (open ? onToggleOpenSection(section.section) : onToggleOpenSection(null))}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

NavPrimaryMenu.propTypes = {
  openSection: PropTypes.string,
  onToggleOpenSection: PropTypes.func,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }),
  ),
  navSectionGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        section: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            active: PropTypes.bool,
          }),
        ).isRequired,
        extraContent: PropTypes.func,
      }),
    ),
  ),
  className: PropTypes.string,
};

export default NavPrimaryMenu;
