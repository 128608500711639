import cx from 'classnames';

import { Button, useWindowSize, AddressDropdown } from '@hometap/htco-components';
import ClientHeader from '../Header/Header';

import { HOMEOWNER_DASHBOARD, getDashboardUrl } from 'utils/links';

import homeImage from '../../../images/header-images/houseImage1.png';
import homeImage2 from '../../../images/header-images/houseImage2.png';
import homeImage3 from '../../../images/header-images/houseImage3.png';
import homeImage4 from '../../../images/header-images/houseImage4.png';
import { formatFullAddress } from '../../../utils/address';

import { HEADER_NAV_SECTION } from '../HeaderNav/constants';
import { Link, useHistory, useLocation, matchPath } from 'react-router-dom';

import useAccessHomeEquityCTA from 'apps/dashboard/utils/useAccessHomeEquityCTA';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { MEMBERSHIP_ROLES } from 'apps/re-inquiry/constants/ReInquiryConstants';
import { useCallback, useEffect, useState } from 'react';
import useTrack from 'hooks/useTrack';
import useStates from 'hooks/useStates';
import AddNewPropertyModal from 'components/modals/AddNewPropertyModal/AddNewPropertyModal';
import { useReferFriendCTA } from 'components/ReferFriendCTAButton';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useFlags } from 'launchdarkly-react-client-sdk';

import '../../Headers/Header/Header.scss';
import './AddressDropdownHeader.scss';
import { useCurrentHome, useUserHomes } from 'hooks/useCurrentHome';

const imageArray = [homeImage, homeImage2, homeImage3, homeImage4];

const MobilePropertyAddressButton = ({ address, onClick, image, selected }) => {
  return (
    <Button className={cx('MobilePropertyAddressButton', { isSelected: selected })} onClick={onClick}>
      <div className="MobilePropertyAddressButtonImage">
        <img src={image} style={{ position: 'relative', top: '0', left: '0', borderRadius: '6px' }} alt="" />
      </div>
      <div className="MobilePropertyAddressButtonAddressContainer">
        <div className="MobilePropertyAddressButtonStreetAddress">
          {address.street}
          {address?.unit && `,   ${address.unit}`}
        </div>
        <div className="MobilePropertyAddressButtonCityStateZip">
          {address.city}, {address.state} {address.zipCode}
        </div>
      </div>
    </Button>
  );
};

const SCREEN_BREAKPOINT_XL = 1400;

const AddressDropdownHeader = ({ className, ...headerProps }) => {
  const [isNewPropertyModalOpen, setIsNewPropertyModalOpen] = useState();

  const { tracks = [], pendingTracks } = useUserTracksContext();
  const { track } = useTrack();
  const { trackId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { operationalStatesList } = useStates();
  const { data: homes = [], isLoading: isHomesQueryLoading } = useUserHomes();
  const { home, setHomeById } = useCurrentHome();
  const { accessEquityHeaderCta } = useFlags();

  const dashboardUrl = getDashboardUrl();
  const isOnPropertyDashboard = Boolean(matchPath(location?.pathname, { path: dashboardUrl }));

  const getIsTrackPending = id => pendingTracks?.find(pendingTrack => pendingTrack?.id === id);
  const isTrackPending = getIsTrackPending(trackId) || getIsTrackPending(track?.id);

  const referFriendCTA = useReferFriendCTA();
  const { hasTappableEquity, openAccessEquityWindow } = useAccessHomeEquityCTA();

  useEffect(() => {
    if (!home || !track || isHomesQueryLoading) return;

    const doesTrackBelongToProperty = track?.home?.address.business_key === home?.address.business_key;
    // prevent infinite rerenders just in case we start allowing tracks to show up for unverified co-applicants
    if (!isTrackPending && !isOnPropertyDashboard && !doesTrackBelongToProperty) {
      setHomeById(track?.home?.id);
    }
  }, [home, isOnPropertyDashboard, setHomeById, track, isTrackPending, isHomesQueryLoading]);

  const isUserAPrimaryOnAnyGivenTrack = tracks.find(track => track?.membership?.role === MEMBERSHIP_ROLES.PRIMARY);

  const properties = homes?.map(home => {
    const { street, unit, city, state, zip_code: zipCode } = home.address;
    return { value: home.id, mainInfo: formatFullAddress(street, unit, city, state, zipCode), ...home };
  });

  const { isScreenSize, screenWidth } = useWindowSize();
  // We only want to display the address dropdown on large desktop screens
  const isDesktopSm = isScreenSize('md');
  const isMobile = isScreenSize('sm');

  const getSelectedProperty = () => {
    const findProperty = property =>
      properties.find(home => home?.address?.business_key === property?.address?.business_key);

    const foundCurrentProperty = findProperty(home);
    if (foundCurrentProperty) return foundCurrentProperty;

    return findProperty(homes[0]);
  };

  const shouldSeeAccountLinks = Boolean(!tracks?.length) || isUserAPrimaryOnAnyGivenTrack;

  const getOtherNavItems = useCallback(() => {
    const navItems = [];
    if (referFriendCTA.shouldBeShown) {
      navItems.push({
        component: (
          <Button size="normal" theme="link" className="OtherNavCTA" onClick={referFriendCTA.onClick}>
            Refer a friend
          </Button>
        ),
        visible: isDesktopSm,
      });
    }
    if (hasTappableEquity && accessEquityHeaderCta) {
      navItems.push({
        component: (
          <Button size="normal" theme="link" className="OtherNavCTA" onClick={openAccessEquityWindow}>
            Access equity
          </Button>
        ),
        visible: isMobile,
      });
    }
    return navItems;
  }, [accessEquityHeaderCta, referFriendCTA, hasTappableEquity, isMobile, isDesktopSm, openAccessEquityWindow]);

  const navItemsConfig = {
    [HEADER_NAV_SECTION.PROPERTIES.key]: {
      title: HEADER_NAV_SECTION.PROPERTIES.title,
      visible: isDesktopSm && !isTrackPending,
      items: [
        {
          component: (
            <ul className="AddressDropDownHeaderMobileProperties">
              {properties?.map((homeProperty, index) => (
                <li key={homeProperty?.value}>
                  <MobilePropertyAddressButton
                    address={homeProperty.address}
                    image={imageArray[index % imageArray.length]}
                    onClick={() => setHomeById(homeProperty.value)}
                    selected={home?.id === homeProperty.value}
                  />
                </li>
              ))}
              {properties?.length < 10 && (
                <Button
                  icon="plus-circle-2"
                  theme="link"
                  className="addPropertyButton"
                  onClick={() => setIsNewPropertyModalOpen(true)}
                >
                  Add home to dashboard
                </Button>
              )}
            </ul>
          ),
        },
      ],
    },
    [HEADER_NAV_SECTION.ACCOUNT.key]: {
      items: [
        {
          component: <Link to={`/${HOMEOWNER_DASHBOARD.SUPPORT}`}>Get Support</Link>,
          visible: shouldSeeAccountLinks,
        },
        {
          component: <Link to={`/${HOMEOWNER_DASHBOARD.PROFILE}`}>Account Settings</Link>,
          visible: shouldSeeAccountLinks,
        },
      ],
    },
    [HEADER_NAV_SECTION.OTHER.key]: {
      items: getOtherNavItems(),
    },
  };

  const supportsHEI = operationalStatesList?.find(state => state?.abbreviation === home?.address?.state);

  // Filter homes by the business_key of their addresses"
  const uniqueByBusinessKey = properties.reduce((uniques, home) => {
    const key = home?.address.business_key;
    if (!uniques.hasOwnProperty(key)) {
      uniques[key] = home;
    }
    return uniques;
  }, {});
  const filteredHomes = Object.values(uniqueByBusinessKey);
  const shouldRenderAddressDropdown = !isDesktopSm && properties.length > 0 && !isTrackPending;
  return (
    <>
      {isNewPropertyModalOpen && <AddNewPropertyModal setIsOpen={setIsNewPropertyModalOpen} />}

      <ClientHeader
        {...headerProps}
        className={cx('AddressDropdownHeader', className)}
        navItems={navItemsConfig}
        centerContent={
          shouldRenderAddressDropdown && (
            <AddressDropdown
              value={getSelectedProperty()}
              size={screenWidth < SCREEN_BREAKPOINT_XL ? 'small' : 'normal'}
              onChange={property => {
                setHomeById(property.value);
                const isTrackAndPropertyMatch = track?.home?.address.business_key === property?.value;
                if ((!isOnPropertyDashboard && !isTrackAndPropertyMatch) || !supportsHEI) {
                  return history.push(dashboardUrl);
                }
              }}
              options={filteredHomes}
              onAdd={() => setIsNewPropertyModalOpen(true)}
            />
          )
        }
      />
    </>
  );
};

export default AddressDropdownHeader;
