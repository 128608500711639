import React, { useState } from 'react';
import moment from 'moment';
import { DataTable, Paper, Toggle, useWindowSize, Button, useBoolState, Badge } from '@hometap/htco-components';

import DocumentsUploadController from 'apps/documents/DocumentsUploadController';
import { DOCUMENT_TAGS } from 'apps/documents/constants';
import NoDocuments from './components/NoDocuments/NoDocuments';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper/SectionLoadingWrapper';
import SecondaryHeader from 'components/Headers/SecondaryHeader/SecondaryHeader';
import Footer from 'components/Footer/Footer';
import { getDateTimeParts } from 'utils/date';
import { getInvestmentStatementUrl } from 'utils/links';
import { readReceiptEligibleDocumentKinds } from 'utils/document';
import useTrackDocuments from '../documents/hooks/useTrackDocuments';
import useTrack from 'hooks/useTrack';
import useInvestmentStatementList from 'apps/quarterly-statement/hooks/useInvestmentStatementList';
import { tagIDD } from 'apps/documents/hooks/useTrackDocuments';

import './ResourcesTable.scss';

const DOCUMENT_LIST_TYPE = {
  HOMEOWNER: { label: 'My uploads', value: 'homeowner' },
  SHARED: { label: 'Shared with me', value: 'shared' },
};

const ResourcesTableController = () => {
  const [toggleValue, setToggleValue] = useState(DOCUMENT_LIST_TYPE.HOMEOWNER.value);
  const { track } = useTrack();
  const { isScreenSize, screenWidth } = useWindowSize();

  const { statements, fetchInvestmentStatementListAsync } = useInvestmentStatementList({
    investmentId: track?.active_investment_id,
  });
  const {
    documents,
    isLoading: isDocumentsLoading,
    error: documentsError,
    executeFetchTrackDocuments,
  } = useTrackDocuments({ fetchImmediately: true });

  // We need a track in order to load investment statements and documents
  const isLoading = !track || fetchInvestmentStatementListAsync.loading || isDocumentsLoading;
  const error = fetchInvestmentStatementListAsync.error || documentsError;

  const filteredDocuments = documents.filter(document => {
    if (toggleValue === DOCUMENT_LIST_TYPE.HOMEOWNER.value) {
      return !document.isShared;
    }

    return document.isShared;
  });

  /**
   * Marks Investment Disclosure Documents as read when the file is clicked
   * @param {Document} document
   */
  const handleViewDocument = document => {
    if (readReceiptEligibleDocumentKinds(document)) {
      tagIDD({ documentId: document?.id, trackId: track?.id, tag: DOCUMENT_TAGS.READ_BY_HOMEOWNER });
    }
  };

  const orderedStatements = statements?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const showNewBadge = row => {
    const [mostRecentStatement] = orderedStatements;
    const isMostRecentStatement = mostRecentStatement.id === row.id;
    const hasBeenRead = !!row.last_viewed_at;

    // If statement is the most recent AND it has not been viewed, show the new badge
    return isMostRecentStatement && !hasBeenRead;
  };

  const statementColumnConfig = {
    name: {
      name: 'Statement',
      selector: row => (
        <a
          href={getInvestmentStatementUrl({
            trackId: track?.id,
            investmentId: track?.active_investment_id,
            statementId: row.id,
          })}
          className="ResourcesTableStatementLink"
        >
          {moment(row.created_at).format('MMMM YYYY')}{' '}
          {showNewBadge(row) && <Badge className="ResourcesTableBadge" theme="primaryGreen15" label="New" />}
        </a>
      ),
    },
    created: {
      name: 'Created',
      selector: row => {
        const { date } = getDateTimeParts(row.created_at);
        return date;
      },
    },
  };

  const statementColumns = [statementColumnConfig.name, statementColumnConfig.created];

  const documentColumnConfig = {
    name: {
      name: 'Name',
      selector: row => (
        <a
          href={row.file_url}
          onClick={() => handleViewDocument(row)}
          target="_blank"
          rel="noopener noreferrer"
          className="ResourcesTableFileLink"
        >
          {row.name}
        </a>
      ),
    },
    type: {
      name: 'Type',
      selector: row => (
        <div>
          {row.kind_display}
          {!!row.uploadedFor?.applicant?.full_name && <div>{row.uploadedFor.applicant.full_name}</div>}
        </div>
      ),
    },
    created: {
      name: 'Created',
      selector: row => {
        const { date, time } = getDateTimeParts(row.created_at);
        return `${date} at ${time}`;
      },
    },
    createdBy: {
      name: 'Created by',
      selector: row => row.createdByDisplayName,
    },
  };

  const documentColumns = [
    documentColumnConfig.name,
    documentColumnConfig.type,
    documentColumnConfig.created,
    documentColumnConfig.createdBy,
  ];

  const mobileDocumentColumns = isScreenSize('xs')
    ? [documentColumnConfig.name]
    : [documentColumnConfig.name, documentColumnConfig.type];

  const isModalOpen = useBoolState();

  const UPLOAD_MODAL_WIDTH = 1130;

  const shouldShowQuarterlyStatements =
    track?.active_investment_id && statements?.length > 0 && !fetchInvestmentStatementListAsync.error;

  return (
    <SectionLoadingWrapper loading={isLoading} error={error}>
      <div className="ResourcesTablePage">
        <div className="ResourcesTableContainer">
          <SecondaryHeader label={'Investment ID:'} value={track?.friendly_id} />
          {shouldShowQuarterlyStatements && (
            <Paper className="ResourcesTableWrapper" data-testid="resources_statements_table">
              <div className="ResourcesTableHeading">
                <h2 className="ResourcesTableHeadingTitle">Statements</h2>
                <p className="ResourcesTableHeadingSubtitle">Access all of your quarterly account statements below.</p>
              </div>
              <div>
                <DataTable className="ResourcesTable" columns={statementColumns} data={orderedStatements} />
              </div>
            </Paper>
          )}
          <Paper className="ResourcesTableWrapper" data-testid="resources_documents_table">
            <div
              className="ResourcesTableHeading ResourceDocumentHead
            er"
            >
              <div className="ResourcesTableHeadingUploadRow">
                <h2 className="ResourcesTableHeadingTitle">Documents</h2>
                <Button size="small" onClick={() => isModalOpen.setTrue()}>
                  Upload
                </Button>
              </div>
              <p className="ResourcesTableHeadingSubtitle">
                View the documents you’ve uploaded and documents that have been shared with you.
              </p>
              <Toggle
                className="ResourcesTableToggle"
                value={toggleValue}
                options={Object.values(DOCUMENT_LIST_TYPE)}
                onChange={setToggleValue}
              />
            </div>
            <div>
              <DataTable
                className="ResourcesTable"
                columns={documentColumns}
                data={filteredDocuments}
                noDataComponent={<NoDocuments />}
                mobileColumns={mobileDocumentColumns}
              />
            </div>
          </Paper>
        </div>

        <DocumentsUploadController
          includeKindSelect
          asModalProps={{
            open: isModalOpen.value,
            width: screenWidth >= UPLOAD_MODAL_WIDTH + 20 ? UPLOAD_MODAL_WIDTH : '95%',
            onClose: () => isModalOpen.setFalse(),
          }}
          onUpload={async () => {
            isModalOpen.setFalse();
            setToggleValue(DOCUMENT_LIST_TYPE.HOMEOWNER.value);
            return await executeFetchTrackDocuments();
          }}
        />
      </div>
      <Footer />
    </SectionLoadingWrapper>
  );
};

export default ResourcesTableController;
