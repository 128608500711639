import { Button } from '@hometap/htco-components';
import cx from 'classnames';
import env from 'utils/env';
import './DemoButton.scss';

// Reusable demo button component, which takes in some data and a click action

const DemoButton = ({ demoData, onClickAction, buttonText = 'Demo', className }) => {
  if (env.isProd()) {
    return null;
  }
  return (
    <div className={cx('DemoButtonWrapper', className)}>
      <Button
        type="button"
        onClick={() => {
          onClickAction(demoData());
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default DemoButton;
