import React, { useEffect, useState } from 'react';
import InvestmentSummaryCard from './InvestmentSummaryCard';
import ReInquiryCTA from './ReInquiryCTA';
import { Button, SlideDown, Loader, useAsync } from '@hometap/htco-components';
import cx from 'classnames';
import { refinancingHomeFAQ, requestSettlementFAQ, sellingHomeFAQ, STAGES } from 'apps/dashboard/constants';
import succulent from 'images/dashboard/succulent.png';
import './InvestmentController.scss';
import useUserTracksContext from 'hooks/useUserTracksContext';
import useNavigatorContext from 'components/Layouts/DashboardLayout/hooks/useDashboardTabsContext';
import Footer from 'components/Footer/Footer';
import FAQList from 'components/FAQList/FAQList';
import ReferFriendCTAButton, { useReferFriendCTA } from 'components/ReferFriendCTAButton';
import { useHistory } from 'react-router-dom';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import { useCurrentHome } from 'hooks/useCurrentHome';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { fetchEquityPlannerHomes } from 'apps/dashboard/data';
import { useEnableGlia, useGliaIdentification } from 'hooks/useGlia';

const InvestmentController = () => {
  const [inactiveOpen, setInactiveOpen] = useState(false);
  const { home, loading: homeLoading } = useCurrentHome();
  const { tracks, pendingTracks, loading: tracksLoading } = useUserTracksContext();
  const { hasHEDOnCurrentHome } = useNavigatorContext();
  const history = useHistory();
  const referFriendCTA = useReferFriendCTA();
  const equityPlannerHomesAsync = useAsync(fetchEquityPlannerHomes);

  useEnableGlia(true, false);
  useGliaIdentification();

  const currentHomeTracks = tracks?.filter(track => {
    return track?.home.address.business_key === home?.address.business_key;
  });
  const closedTracks = currentHomeTracks?.filter(track => track.current_stage === STAGES.CLOSED_LOST);
  const activeTracks = currentHomeTracks?.filter(track => track.current_stage !== STAGES.CLOSED_LOST);

  const currentHomeHasInvestedTracks = currentHomeTracks?.some(track => track.current_stage === STAGES.INVESTED);

  const inactiveApplicationsLabel = inactiveOpen ? 'Hide inactive applications' : 'View inactive applications';

  const hasClosedOrActiveTracks = closedTracks?.length > 0 || activeTracks?.length > 0;
  const reInquiryTitle = hasClosedOrActiveTracks ? (
    <span>
      Get more from homeownership, <span style={{ color: '#20A277' }}>get more from life</span>
    </span>
  ) : (
    'You don’t have any Investments started at this time.'
  );

  useEffect(() => {
    if (tracks && !tracksLoading && !currentHomeTracks.length) {
      if (hasHEDOnCurrentHome) {
        history.push(getNewDashboardUrl(HOMEOWNER_DASHBOARD.HOME_EQUITY));
      }
    }
  }, [tracks, tracksLoading, currentHomeTracks, hasHEDOnCurrentHome, history]);

  if (homeLoading || tracksLoading) {
    return (
      <div className="PageLoader">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return (
    <ScrollToTop>
      <div className="PageBodyCenteredContainer">
        <div className="InvestmentController">
          {hasClosedOrActiveTracks && <h1 className="InvestmentHeader">My Investments</h1>}
          {activeTracks?.length > 0 && (
            <div className="InvestmentControllerCards">
              {activeTracks.map(track => {
                return <InvestmentSummaryCard key={track.friendly_id} track={track} />;
              })}
            </div>
          )}
          {closedTracks?.length > 0 && (
            <Button className="InactiveApplications" theme="link" onClick={() => setInactiveOpen(!inactiveOpen)}>
              {inactiveApplicationsLabel}
            </Button>
          )}
          <SlideDown visible={inactiveOpen}>
            {closedTracks &&
              closedTracks.map(track => {
                return <InvestmentSummaryCard key={track.friendly_id} track={track} />;
              })}
          </SlideDown>
          <ReInquiryCTA
            title={reInquiryTitle}
            text="Use your equity to reach your financial goals."
            image={succulent}
            tracksLoading={tracksLoading}
            pendingTracks={pendingTracks}
            tracks={tracks}
            equityPlannerHomes={equityPlannerHomesAsync?.results}
          />
        </div>
      </div>
      <div className={cx('InvestmentDivider', { smallMargin: currentHomeHasInvestedTracks })} />
      {currentHomeHasInvestedTracks && (
        <div className="InvestedFAQ">
          <div className="InvestedFAQContainer PageBodyCenteredContainer">
            <FAQList
              heading="Investment questions"
              faqs={[requestSettlementFAQ, sellingHomeFAQ, refinancingHomeFAQ]}
              footer={
                <Button
                  className="InvestedFAQButton"
                  theme="secondary"
                  label="View all FAQs"
                  size="large"
                  onClick={() => history.replace(`/${HOMEOWNER_DASHBOARD.SUPPORT}`)}
                />
              }
            />
          </div>
        </div>
      )}
      <Footer theme="light" />
      {referFriendCTA.shouldBeShown && <ReferFriendCTAButton onClick={referFriendCTA.onClick} />}
    </ScrollToTop>
  );
};

export default InvestmentController;
