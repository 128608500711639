import { useEffect } from 'react';
import { useAsync } from '@hometap/htco-components';
import apiWithAuth from 'utils/api';

const fetchInvestmentStatements = async investmentId => {
  return await apiWithAuth.v1.get(`/investments/${investmentId}/statements`);
};

const useInvestmentStatementList = ({ investmentId }) => {
  const {
    execute: executeFetchInvestmentStatements,
    results,
    ...fetchInvestmentStatementListAsync
  } = useAsync(fetchInvestmentStatements, {
    executeThrow: true,
  });

  useEffect(() => {
    if (investmentId) {
      executeFetchInvestmentStatements(investmentId);
    }
  }, [investmentId, executeFetchInvestmentStatements]);

  return {
    statements: results || [],
    fetchInvestmentStatementListAsync: fetchInvestmentStatementListAsync || {},
    executeFetchInvestmentStatements,
  };
};

export default useInvestmentStatementList;
