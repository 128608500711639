import { Button, FormError } from '@hometap/htco-components';
import { getNewInquiryUrl } from 'utils/links';

const ReInquiryReviewError = ({ status }) => {
  let error;
  // if the inquiry create or conversion api has changed it will throw some sort of 400 error (most likely just a 400)
  // but to be safe we will catch them all and and prompt the homeowner to go through the inquiry v3 flow
  const isSome400Error = status < 500 && status >= 400;
  if (isSome400Error) {
    error = (
      <p>
        Looks like we’ve changed our inquiry, to continue please{' '}
        <Button theme="link" href={getNewInquiryUrl()}>
          edit your information.
        </Button>
      </p>
    );
  } else {
    error = 'Oops, something went wrong! Try again or contact us if having repeated trouble.';
  }

  return <FormError className="ReInquiryReviewError" standalone error={error} />;
};

export default ReInquiryReviewError;
