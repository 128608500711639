import { useEffect, useState } from 'react';
import { SelectionSet } from '@hometap/htco-components';

const registerBoolField = base => {
  // NOTE: this is a quick solution to allow for boolean values but should not be used with
  // forms where anything but "true" or "false" would be a potential value.
  return {
    ...base,
    onChange: (value, name, error) => {
      const parsedValue = JSON.parse(value);
      if (parsedValue === false) {
        base.unregisterRequiredField(name);
      }
      return base.onChange(parsedValue, name, error);
    },
  };
};

const BoolField = ({ baseRegisterField, trueLabel = 'Yes', falseLabel = 'No', ...rest }) => {
  const [base, setBase] = useState(registerBoolField(baseRegisterField));

  const { value, unregisterRequiredField, name } = base;

  useEffect(() => {
    setBase(registerBoolField(baseRegisterField));
    if (value === false) {
      unregisterRequiredField(name);
    }
  }, [value, baseRegisterField, name, unregisterRequiredField]);

  return (
    <SelectionSet
      {...base}
      options={[
        { label: trueLabel, value: true },
        { label: falseLabel, value: false },
      ]}
      {...rest}
    />
  );
};

export default BoolField;
