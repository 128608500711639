import React from 'react';
import { Paper, Button } from '@hometap/htco-components';
import balloons from 'images/balloons.png';
import { getPrimaryAppTrackDetailsUrl } from 'utils/links';

const OfferAccepted = ({ trackID }) => {
  return (
    <div className="OfferAccepted">
      <Paper className="OfferAcceptedContent" pad={5}>
        <h1>Welcome to the Hometap family!</h1>
        <p>We’re looking forward to helping you get more out of homeownership &mdash; and get more out of life.</p>
        <Button href={getPrimaryAppTrackDetailsUrl(trackID)}>Continue</Button>
        <img src={balloons} alt="" />
      </Paper>
    </div>
  );
};

export default OfferAccepted;
