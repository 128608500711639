export const PROGRESSIVE_UI_BASE_URL = '/inquiry/info';

const PROPERTY_URL = `${PROGRESSIVE_UI_BASE_URL}/property/`;
const PROPERTY_TYPE_URL = `${PROGRESSIVE_UI_BASE_URL}/property/type`;
const PROPERTY_USE_URL = `${PROGRESSIVE_UI_BASE_URL}/property/use`;
const MORTGAGE_BALANCE_URL = `${PROGRESSIVE_UI_BASE_URL}/mortgage`;
const USE_CASE_URL = `${PROGRESSIVE_UI_BASE_URL}/use-case`;
const RECEIVE_FUNDS_URL = `${PROGRESSIVE_UI_BASE_URL}/receive-funds`;
const HOMEOWNER_INFO_URL = `${PROGRESSIVE_UI_BASE_URL}/homeowner/info`;
const CONTACT_URL = `${PROGRESSIVE_UI_BASE_URL}/homeowner/contact/`;

const SUCCESSFUL_OUTCOME_URL = `${PROGRESSIVE_UI_BASE_URL}/submitted`;
const UNSUCCESSFUL_OUTCOME_URL = `${PROGRESSIVE_UI_BASE_URL}/results`;

const DEQUALIFIED_LOCATION_URL = `${UNSUCCESSFUL_OUTCOME_URL}/location/`;
const DEQUALIFIED_COOP_URL = `${UNSUCCESSFUL_OUTCOME_URL}/coop`;
const DEQUALIFIED_MOBILE_HOME_URL = `${UNSUCCESSFUL_OUTCOME_URL}/mobile`;
const DEQUALIFIED_RISK_SCORE_URL = `${UNSUCCESSFUL_OUTCOME_URL}/risk`;
const DEQUALIFIED_FICO_SCORE_URL = `${UNSUCCESSFUL_OUTCOME_URL}/fico`;

export const PROGRESSIVE_STEP_NAMES = {
  PROPERTY: 'property',
  PROPERTY_TYPE: 'propertyType',
  PROPERTY_USE: 'propertyUse',
  MORTGAGE_BALANCE: 'mortgageBalance',
  USE_CASE: 'useCase',
  RECEIVE_FUNDS: 'receiveFunds',
  INFO: 'info',
  CONTACT: 'contact',
  SUCCESS: 'success',
  DISQUALIFIED: 'disqualified',
  SUCCESSFUL_OUTCOME: 'successfulOutcome',
};

export const PAGE_TITLES = {
  [PROGRESSIVE_STEP_NAMES.PROPERTY]: 'Address | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.PROPERTY_TYPE]: 'Property Type | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.PROPERTY_USE]: 'Property Use | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.MORTGAGE_BALANCE]: 'Mortgage Balance | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.USE_CASE]: 'Use Case | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.RECEIVE_FUNDS]: 'Timeline | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.INFO]: 'Homeowner Info | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.CONTACT]: 'Contact Info | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.SUCCESS]: 'Estimate | Investment Application | Hometap Hub',
  [PROGRESSIVE_STEP_NAMES.DISQUALIFIED]: 'Estimate Unavailable | Investment Application | Hometap Hub',
};

// Starting DQ Pages: location, coop, mobileHome, risk, fico
export const DQ_PAGES = {
  LOCATION: 'location',
  LOCATION_SUBMITTED: 'locationSubmitted',
  COOP: 'coop',
  MOBILE_HOME: 'mobileHome',
  RISK: 'risk',
  RISK_SUBMITTED: 'riskSubmitted',
  RISK_AA: 'riskAdverseAction',
  FICO: 'fico',
  FICO_SUBMITTED: 'ficoSubmitted',
  FICO_AA: 'ficoAdverseAction',
};

export const PROGRESSIVE_UI_STEPS = {
  [PROGRESSIVE_STEP_NAMES.PROPERTY]: PROPERTY_URL,
  [PROGRESSIVE_STEP_NAMES.PROPERTY_TYPE]: PROPERTY_TYPE_URL,
  [PROGRESSIVE_STEP_NAMES.PROPERTY_USE]: PROPERTY_USE_URL,
  [PROGRESSIVE_STEP_NAMES.MORTGAGE_BALANCE]: MORTGAGE_BALANCE_URL,
  [PROGRESSIVE_STEP_NAMES.USE_CASE]: USE_CASE_URL,
  [PROGRESSIVE_STEP_NAMES.RECEIVE_FUNDS]: RECEIVE_FUNDS_URL,
  [PROGRESSIVE_STEP_NAMES.INFO]: HOMEOWNER_INFO_URL,
  [PROGRESSIVE_STEP_NAMES.CONTACT]: CONTACT_URL,
  [PROGRESSIVE_STEP_NAMES.SUCCESSFUL_OUTCOME]: SUCCESSFUL_OUTCOME_URL,
};

export const DQ_STEPS = {
  [DQ_PAGES.LOCATION]: DEQUALIFIED_LOCATION_URL,
  [DQ_PAGES.COOP]: DEQUALIFIED_COOP_URL,
  [DQ_PAGES.MOBILE_HOME]: DEQUALIFIED_MOBILE_HOME_URL,
  [DQ_PAGES.RISK]: DEQUALIFIED_RISK_SCORE_URL,
  [DQ_PAGES.FICO]: DEQUALIFIED_FICO_SCORE_URL,
};

export const PROGRESSIVE_FORM_FIELDS = {
  ZIP_CODE: 'zip_code',
  CITY: 'city',
  STREET: 'street',
  STATE: 'state',
  UNIT: 'unit',
  RESIDENCE_TYPE: 'residence_type',
  PROPERTY_TYPE: 'property_type',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  PRIMARY_USE_CASE: 'primary_use_case',
  PRIMARY_USE_CASE_CHOICE_DETAILS: 'primary_use_case_choice_details',
  WHEN_INTERESTED: 'when_interested',
  CONSENT_GIVEN: 'consent_given',
  SSN: 'ssn',
  BIRTH_DATE: 'birth_date',
  WHEN_RECEIVE_FUNDS: 'when_receive_funds',
  HOW_HEARD: 'how_heard',
  HOW_HEARD_DETAILS: 'how_heard_choice_details',
  MORTGAGE_BALANCE: 'mortgage_balance',
};

export const PROGRESSIVE_HOW_HEARD_VALUES = {
  ONLINE_SEARCH: 'online_search',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TIK_TOK: 'tik_tok',
  RADIO_PODCAST: 'radio_podcast',
  TV_ADVERTISEMENT: 'television_advertisement',
  NEWS_ARTICLE_OR_FINANCE_WEBSITE: 'news_article_or_finance_website',
  DIRECT_MAIL_OR_POSTCARD: 'direct_mail_or_postcard',
  FAMILY_OR_FRIEND: 'family_or_friend',
  OTHER: 'other',
};

export const PROGRESSIVE_TELL_US_MORE_FIELDS = [
  PROGRESSIVE_HOW_HEARD_VALUES.RADIO_PODCAST,
  PROGRESSIVE_HOW_HEARD_VALUES.TV_ADVERTISEMENT,
  PROGRESSIVE_HOW_HEARD_VALUES.NEWS_ARTICLE_OR_FINANCE_WEBSITE,
  PROGRESSIVE_HOW_HEARD_VALUES.OTHER,
];

export const PROGRESSIVE_TESTIMONIALS_CAROUSEL_CARDS = [
  {
    imageSrc: 'https://cdn.sanity.io/images/hmaiuprw/production/dd66737f2910c797e07facb3eb4c7d3a9285215a-288x288.jpg',
    imageAlt: 'Elizabeth B. Headshot',
    quote:
      'I was able to not only pay off my remaining debt faster, but I was also able to put more money into my savings.',
    author: 'Elizabeth B.',
  },
  {
    imageSrc: 'https://cdn.sanity.io/images/hmaiuprw/production/d844bacbe5035568310c1dec36bc86c6f68c4932-288x288.jpg',
    imageAlt: 'Brenda K. Headshot',
    quote:
      'My credit went from 640 to 779. I’m completely out of debt, it’s an amazing feeling. It’s something I’ve been working toward for a long time.',
    author: 'Brenda K.',
  },
  {
    imageSrc: 'https://cdn.sanity.io/images/hmaiuprw/production/4efd90f19c00b447be014a8603df7f4351b7eae0-288x288.jpg',
    imageAlt: 'Nana A. Headshot',
    quote:
      'I think Hometap is a great resource for homeowners that are looking to pay off some debt, build their credit up, build their business, or build a real estate portfolio like myself.',
    author: 'Nana A.',
  },
];

export const PRIMARY_USE_CASE_OPTIONS = [
  {
    value: 'use_case_debts',
    icon: 'compensation',
    description: 'Pay off loans, credit cards, or other debts so I can work toward financial freedom.',
  },
  {
    value: 'use_case_diversify',
    icon: 'hometap-share',
    description: 'Invest in other assets (stocks, bonds, mutual funds, etc.)',
  },
  {
    value: 'use_case_renovate',
    icon: 'renovate',
    description: 'Make home improvements, repairs, and/or renovations.',
  },
  {
    value: 'use_case_emergency',
    icon: 'healthcare',
    description: 'Pull together funds for emergencies or an unexpected life event.',
  },
  {
    value: 'use_case_business',
    icon: 'equity',
    description: 'Start or grow my small business.',
  },
  {
    value: 'use_case_bridge_loan',
    icon: 'custom',
    description: 'Put money toward my next home before selling my current one.',
  },
  {
    value: 'use_case_retirement',
    icon: 'retire',
    description: 'Live a more financially comfortable life in retirement.',
  },
  {
    value: 'use_case_divorce',
    icon: 'divorce',
    description: 'Secure funds to help navigate a divorce.',
  },
  {
    value: 'use_case_education',
    icon: 'learning',
    description: 'Access money for tuition or student loan payments.',
  },
  {
    value: 'use_case_fund_family',
    icon: 'fund-fam',
    description: 'Obtain money to support my growing family.',
  },
  {
    value: 'use_case_buy_home',
    icon: 'house-nature',
    description: 'Purchase a rental, investment, or vacation property.',
  },
  {
    value: 'use_case_other',
    icon: 'other',
    description: 'You tell us!',
  },
];

export const PREQUAL_OUTCOME = {
  PASS: 'pass',
  FAIL: 'fail',
  UNDETERMINED: 'undetermined',
};

export const DEQUALIFIED_PROPERTY_TYPES = {
  MOBILE: 'mh',
  COOP: 'cp',
};

export const CONTINUE_BUTTON_ID = 'inquiry-continue-button-id';

export const EMAIL_ERROR_MESSAGES = {
  IN_USE: {
    key: 'email_in_use',
    label: 'Looks like that email is already in use.',
    loginLabel: 'Log in to your account',
  },
  MUST_MATCH_SESSION: {
    key: 'email_must_match_session',
    label: 'Looks like you already have an account with us.',
    loginLabel: 'Log in to your account',
  },
};

export const scrollIntoViewByElementId = elemId => {
  document.getElementById(elemId).scrollIntoView({
    behavior: 'smooth',
  });
};
