import NavAccordion from '../NavAccordion/NavAccordion';
import logo from 'images/hometap-logomark.svg';
import cx from 'classnames';
import useNavItem from '../hooks/useNavItem/useNavItem';
import { Button, IconButton } from '@hometap/htco-components';
import { triggerClickOnKeyPress } from 'utils/a11y';
import { twMerge } from 'tailwind-merge';

const HomeTitle = ({ address, active, className, isHeader }) => {
  const streetClassName = cx({
    'font-bold': active,
    'leading-[26px]': isHeader,
    'font-normal': !active,
    'text-sm': !isHeader,
  });

  const addressMetadataClassName = cx('m-0', {
    'text-xs': !isHeader,
    'text-sm text-neutral-dark-75 leading-6': isHeader,
    'font-normal text-neutral-dark-75': !active,
  });

  const showAddressMetadata = address.city && address.state && address.zip_code;

  return (
    <div className={className} data-testid="home-picker-menuitem">
      <div className="flex flex-col">
        <div className={streetClassName}>{address.street}</div>
        {showAddressMetadata && (
          <p className={addressMetadataClassName}>
            {address.city}, {address.state}, {address.zip_code}
          </p>
        )}
      </div>
    </div>
  );
};

const HomePickerMenuItem = ({ home, onSelectHome, selectedHome }) => {
  const active = home.id === selectedHome.id;
  const { className } = useNavItem({ active });

  return (
    <div
      role="menuitem"
      className="mb-1 cursor-pointer rounded-xl outline-none focus:outline-1 focus:outline-offset-[-6px] focus:outline-blue-100"
      tabIndex="0"
      onClick={() => onSelectHome(home.id)}
      onKeyDown={triggerClickOnKeyPress}
    >
      <HomeTitle address={home.address} active={active} className={cx(twMerge(className, 'px-4 py-3 font-normal'))} />
    </div>
  );
};

export const HomePickerMenu = ({ homes = [], onSelectHome, selectedHome, onAddNewProperty }) => {
  return (
    <div className="pb-[18px]" data-testid="home-picker-menu">
      <p className="mb-4 mt-0 text-xs font-bold sm:mt-8">Select a home to view</p>
      {homes.map(home => (
        <div className="mb-4 last-of-type:mb-0" key={home.id}>
          <HomePickerMenuItem home={home} onSelectHome={onSelectHome} selectedHome={selectedHome} />
        </div>
      ))}

      <Button theme="link" className="!mt-2 text-sm font-bold !text-neutral-dark-75" onClick={onAddNewProperty}>
        + Add new home to dashboard
      </Button>
    </div>
  );
};

const HomePickerDropdownDesktop = ({ homes = [], onSelectHome, selectedHome, onAddNewProperty }) => {
  return (
    <NavAccordion dataTestId="home-picker-desktop">
      <NavAccordion.Toggle
        titleIndicator={
          <div>
            <img className="w-[48px]" src={logo} alt="hometap logo" />
          </div>
        }
        title={<HomeTitle isHeader address={selectedHome.address} active />}
      />

      <NavAccordion.Content>
        <HomePickerMenu
          homes={homes}
          onSelectHome={onSelectHome}
          selectedHome={selectedHome}
          onAddNewProperty={onAddNewProperty}
        />
      </NavAccordion.Content>
    </NavAccordion>
  );
};

const HomePickerDropdownMobile = ({ homes = [], onSelectHome, selectedHome, onAddNewProperty, onIconClick }) => {
  return (
    <NavAccordion dataTestId="home-picker-mobile">
      <div className="flex items-center">
        <div className="border-0 border-r border-solid border-neutral-light-100 p-6 outline-offset-[-1px]">
          <IconButton className="m-0 h-auto w-auto text-neutral-dark-75" icon="cancel" onClick={onIconClick} />
        </div>
        <div className="w-full p-4 pr-6">
          <NavAccordion.Toggle
            title={<HomeTitle isHeader address={{ street: selectedHome.address.street }} active className="p-1" />}
          />
        </div>
      </div>

      <NavAccordion.Content>
        <div className="relative translate-y-[-1px] border-0 border-t border-solid border-neutral-light-100 p-4">
          <HomePickerMenu
            homes={homes}
            onSelectHome={onSelectHome}
            selectedHome={selectedHome}
            onAddNewProperty={onAddNewProperty}
          />
        </div>
      </NavAccordion.Content>
    </NavAccordion>
  );
};

const dropdownVariants = { Desktop: HomePickerDropdownDesktop, Mobile: HomePickerDropdownMobile };

export default dropdownVariants;
