import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAsync, useForm } from '@hometap/htco-components';
import { pick } from 'lodash';

import useTrack from 'hooks/useTrack';
import { fetchInquiry } from 'apps/inquiry/data/inquiryRequests';

import {
  APPLICATION_CONFIG,
  APPLICATION_SECTION_URL_NAME,
  buildApplicationRoute,
  buildDataForApplicantSave,
  checkIsPrimaryApplicant,
  getApplicantFormKey,
  getOtherLienDescription,
} from '../utils';
import {
  APPLICANT_FORM_FIELD,
  APPLICANT_MAILING_ADDRESS_FIELDS,
  APPLICATION_FORM_FIELD,
} from '../constants/formFields';
import { APPLICANT_MODEL_FIELD, APPLICATION_MODEL_FIELD } from '../constants/applicationDataFields';
import useApplicants from './useApplicants';
import { useParams, useHistory } from 'react-router-dom';
import useApplication from './useApplication';

const getApplicationInitialFormData = application => {
  const hasSubmittedLiens = application[APPLICATION_MODEL_FIELD.hasSubmittedLiens]
    ? !!application[APPLICATION_MODEL_FIELD.lienTypes]?.length
    : undefined;

  const initialFormData = {
    [APPLICATION_FORM_FIELD.hasLiens]: hasSubmittedLiens,
    [APPLICATION_FORM_FIELD.isOwnedByTrust]: application[APPLICATION_MODEL_FIELD.isOwnedByTrust],
    [APPLICATION_FORM_FIELD.consentGiven]: application.has_consented,
    [APPLICATION_FORM_FIELD.hasHoa]: application[APPLICATION_FORM_FIELD.hasHoa],
    [APPLICATION_FORM_FIELD.hoaContactName]: application[APPLICATION_FORM_FIELD.hoaContactName],
    [APPLICATION_FORM_FIELD.hoaContactPhone]: application[APPLICATION_FORM_FIELD.hoaContactPhone],
    [APPLICATION_FORM_FIELD.hoaStreet]: application[APPLICATION_FORM_FIELD.hoaStreet],
    [APPLICATION_FORM_FIELD.hoaUnit]: application[APPLICATION_FORM_FIELD.hoaUnit],
    [APPLICATION_FORM_FIELD.hoaCity]: application[APPLICATION_FORM_FIELD.hoaCity],
    [APPLICATION_FORM_FIELD.hoaState]: application[APPLICATION_FORM_FIELD.hoaState],
    [APPLICATION_FORM_FIELD.hoaZipCode]: application[APPLICATION_FORM_FIELD.hoaZipCode],
  };

  if (initialFormData[APPLICATION_FORM_FIELD.hasLiens]) {
    initialFormData[APPLICATION_FORM_FIELD.lienTypes] = application[APPLICATION_MODEL_FIELD.lienTypes].map(
      lienType => lienType.kind,
    );
    initialFormData[APPLICATION_FORM_FIELD.otherLienDetails] = getOtherLienDescription(application);
  }

  return initialFormData;
};

const getInitialApplicantsFormData = (applicants = [], application) => {
  const initialFormData = applicants.reduce((acc, applicant) => {
    const hasSubmittedApplicants = application[APPLICATION_MODEL_FIELD.hasSubmittedApplicants] ? false : undefined;
    const primaryRelationShip = { [APPLICANT_FORM_FIELD.relationshipToPrimary]: 'self' };
    const hasMailingAddress = !!applicant?.mailing_address;
    const mailingAddressIsTrackAddress =
      hasMailingAddress && applicant.track_address.business_key === applicant.mailing_address.business_key;

    let mailingAddressData = {};
    if (!hasMailingAddress) {
      mailingAddressData[APPLICANT_FORM_FIELD.isTrackAddressMailingAddress] = undefined;
    } else if (mailingAddressIsTrackAddress) {
      mailingAddressData[APPLICANT_FORM_FIELD.isTrackAddressMailingAddress] = true;
    } else {
      mailingAddressData[APPLICANT_FORM_FIELD.isTrackAddressMailingAddress] = false;
      mailingAddressData = {
        ...mailingAddressData,
        street: applicant.mailing_address.street,
        city: applicant.mailing_address.city,
        state: applicant.mailing_address.state,
        zip_code: applicant.mailing_address.zip_code,
        unit: applicant.mailing_address.unit,
      };
    }

    const applicantFormKey = getApplicantFormKey(applicant?.id);
    const isPrimaryApplicant = checkIsPrimaryApplicant(applicant, applicants);
    // set helper properties on applicant object
    const additionalApplicantProperties = {
      isPrimaryApplicant,
      isCurrentFormValid: false,
      [APPLICANT_FORM_FIELD.hasOtherApplicants]: hasSubmittedApplicants,
      ...mailingAddressData,
      // initialize data for hidden form field on behalf of the primary applicant
      ...(isPrimaryApplicant && primaryRelationShip),
    };
    return { ...acc, [applicantFormKey]: { ...applicant, ...additionalApplicantProperties } };
  }, {});

  return initialFormData;
};

const ApplicationContext = createContext();

export const ApplicationProvider = ({ children }) => {
  // ---- Async Data for Application ----
  const { execute: executeFetchInquiry, ...inquiryAsync } = useAsync(fetchInquiry, { defaultLoading: true });

  const { track, loading: trackLoading, trackError } = useTrack();
  const { applicants: savedApplicants, executeFetchApplicants, ...applicantsState } = useApplicants();

  const activeApplicationId = track?.active_application_id;
  const { application, executeFetchApplication, ...applicationState } = useApplication(activeApplicationId);

  const isFetchingApplicationData = trackLoading || applicationState.fetchLoading || applicantsState.fetchLoading;
  const isInitialLoading = isFetchingApplicationData && !savedApplicants?.length && !application;

  const isLoading = applicantsState.isLoading || applicationState.isLoading;

  // We manage applicantsLoadingState explicitly because it is easier to handle the case where we
  // may or may not load the inquiry When any of the executeFetch calls happen the useCallback is
  // called again, so we need to know when we have begun and completed the loading sequence. Without
  // it, it becomes challenging to prevent duplicate network calls.
  const [applicantsLoadingState, setApplicantsLoadingState] = useState('NotLoaded');
  // ---- End Async Data for Application ----

  const [inMemoryApplicant, setInMemoryApplicant] = useState(null);
  const { trackId } = useParams();
  const history = useHistory();

  const applicantsConfig = APPLICATION_CONFIG.applicants;
  const getApplicationRoute = buildApplicationRoute(trackId);

  const applicants = useMemo(
    () => [...(savedApplicants || []), inMemoryApplicant].filter(applicant => applicant !== null),
    [inMemoryApplicant, savedApplicants],
  );

  const getApplicantFormData = applicantId => formData[getApplicantFormKey(applicantId)];
  const removeInMemoryApplicant = () => {
    setInMemoryApplicant(null);
    setErrors(getApplicantFormKey(), null);
  };

  const updateApplication = async data => await applicationState.updateApplicationAndRefetch(data);
  const refetchApplicants = () => executeFetchApplicants({ applicationId: activeApplicationId });

  const deleteApplicant = async applicantId => {
    applicantsState.setSelectedApplicantForDeletion(null);
    if (applicantsState.selectedApplicantForDeletion?.isNew) {
      await removeInMemoryApplicant();
      return history.replace(
        getApplicationRoute({
          applicantId: applicants[0]?.id,
          section: APPLICATION_SECTION_URL_NAME.applicants,
          pageKey: applicantsConfig[applicantsConfig.length - 1]?.pageKey,
        }),
      );
    }

    const updatedApplicants = await applicantsState.deleteApplicantAndRefetch({ applicationId: activeApplicationId });
    if (!updatedApplicants) return;

    // If the applicant being deleted does not have an id that matches the same applicant id in the url, do nothing
    if (applicantsState.selectedApplicantForDeletion.id !== applicantId) {
      return updatedApplicants;
    }

    return history.replace(
      getApplicationRoute({
        applicantId: updatedApplicants[updatedApplicants.length - 1]?.id,
        section: APPLICATION_SECTION_URL_NAME.applicants,
        pageKey: applicantsConfig[applicantsConfig.length - 1]?.pageKey,
      }),
    );
  };

  const saveApplicant = async (applicantId = '') => {
    const applicant = getApplicantFormData(applicantId);
    let data = buildDataForApplicantSave(applicant, track);

    const pickFields = [applicant?.dirtyFields];
    const hasDirtyMailingAddress = new Set([...applicant?.dirtyFields, ...APPLICANT_MAILING_ADDRESS_FIELDS]);
    if (hasDirtyMailingAddress.size) {
      pickFields.push(APPLICANT_MODEL_FIELD.mailingAddress);
    }
    const changedApplicantFields = pick(data, pickFields.flat());
    data = applicantId ? changedApplicantFields : data;

    const savedApplicant = await applicantsState.saveApplicantAndRefetch({
      data,
      applicantId,
      applicationId: activeApplicationId,
    });

    if (!savedApplicant) return;

    const hasUpdatedPersistedApplicantForm = !!applicant?.dirtyFields && applicantId;
    // we have created a new applicant and now it is persisted so we can remove the in memory
    // applicant from state
    if (!hasUpdatedPersistedApplicantForm) {
      removeInMemoryApplicant();
    }

    return savedApplicant;
  };

  const addNewApplicant = useCallback((initialData = {}) => {
    return setInMemoryApplicant({ isNew: true, ...initialData });
  }, []);

  const handleFetchInitialDataAfterTrackLoads = useCallback(
    async ({ applicationId, inquiryId }) => {
      if (applicantsLoadingState !== 'NotLoaded') {
        return;
      }
      const shouldFetchData = Boolean(!applicants?.length);

      if (!shouldFetchData) {
        return;
      }
      setApplicantsLoadingState('Loading');

      const [persistedApplicants] = await Promise.all([
        executeFetchApplicants({ applicationId }),
        executeFetchApplication({ applicationId }),
      ]);

      const shouldCreateInMemoryPrimaryApplicant = !persistedApplicants?.length && inMemoryApplicant === null;
      if (!shouldCreateInMemoryPrimaryApplicant) {
        setApplicantsLoadingState('Loaded');
        return;
      }

      const inquiry = await executeFetchInquiry(inquiryId);
      const primaryApplicantPrefillFields = pick(inquiry, Object.values(APPLICANT_FORM_FIELD));
      addNewApplicant(primaryApplicantPrefillFields);
      setApplicantsLoadingState('Loaded');
    },

    [
      executeFetchApplicants,
      executeFetchApplication,
      executeFetchInquiry,
      applicants,
      inMemoryApplicant,
      addNewApplicant,
      applicantsLoadingState,
      setApplicantsLoadingState,
    ],
  );

  const hasFetchError =
    (!isInitialLoading && !activeApplicationId) ||
    applicantsState.fetchError ||
    applicationState.fetchError ||
    inquiryAsync.error;

  useEffect(() => {
    // wait for track to be loaded before attempting to fetch application data
    if (!trackLoading && track && !trackError) {
      handleFetchInitialDataAfterTrackLoads({
        inquiryId: track.inquiry_id,
        applicationId: activeApplicationId,
      });
    }
  }, [track, trackError, trackLoading, handleFetchInitialDataAfterTrackLoads, activeApplicationId]);

  const { formData, updateFormData, registerField, handleFieldChange, isFormValid, setErrors, errors } = useForm();

  useEffect(() => {
    if (!application) return;

    const initialApplicationFormData = getApplicationInitialFormData(application);
    const initialApplicantsFormData = { ...getInitialApplicantsFormData(applicants, application), dirtyFields: [] };
    const initialFormData = { ...initialApplicantsFormData, ...initialApplicationFormData };

    updateFormData(initialFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicants, application]);

  const contextData = {
    track,
    isLoading,
    applicants,
    application,
    hasFetchError,
    saveApplicant,
    addNewApplicant,
    isInitialLoading,
    refetchApplicants,
    getApplicantFormData,
    deleteApplicant,
    removeInMemoryApplicant,
    isFetchingApplicationData,
    updateApplication,
    applicationFormErrors: errors,
    applicationFormData: formData,
    isCurrentFormValid: isFormValid,
    applicationId: activeApplicationId,
    handleApplicationFormFieldChange: handleFieldChange,
    selectedApplicantForDeletion: applicantsState.selectedApplicantForDeletion,
    setSelectedApplicantForDeletion: applicantsState.setSelectedApplicantForDeletion,
    consentApplication: applicationState.consentApplication,
    registerApplicationFormField: (name, valueProp = 'value') => {
      const baseRegisterField = registerField(name, valueProp);
      return {
        ...baseRegisterField,
        onChange: (value, name, error) => {
          if (formData[name] !== value) {
            // TODO: This is a hack for a quick way to add dirty fields to the form data. This
            // should be refactored to not directly modify the formData.
            formData.dirtyFields = Array.from(new Set([...formData.dirtyFields, name]));
          }

          if (value === false) {
            return updateFormData({ [name]: value });
          }

          handleFieldChange(value, name, error);
        },
      };
    },
    primaryApplicant: applicants.find(applicant => checkIsPrimaryApplicant(applicant)),
    refetchApplication: () => executeFetchApplication({ applicationId: activeApplicationId }),
  };

  return <ApplicationContext.Provider value={contextData}>{children}</ApplicationContext.Provider>;
};

/**
 * @typedef {object} ApplicationFormData
 * @property {object} applicant - object that stores a specific applicant's form data where the key
 * is `applicant:<applicantId>`. Note `applicantId` will be the coerced to the string 'new' for
 * non-persisted applicants
 */

/**
 * @typedef {object} ApplicationContext
 * @property {object} track - the track data associated with the application
 * @property {boolean} isLoading - is the application or applicants state loading?
 * @property {boolean} isInitialLoading - Are any of the track, application, or applicants states loading?
 * @property {boolean} isFetchingApplicationData - is the application data being fetched?
 * @property {boolean} hasFetchError - is there an error fetching any of the data required for the
 * context to behave properly?
 * @property {object} application - persisted application that come form the backend not to be
 * confused with the application form data stored via formData.
 * @property {object} applicationFormData - all stored data for all forms in the application
 * @property {object} applicationFormErrors - all stored errors for all forms in the application
 *
 * @property {Array} applicants - persisted applicants that come form the backend not to be confused
 * with applicants stored via formData from the `useApplicantForm` hook.
 * @property {boolean} isCurrentFormValid - checks that an application level form is valid
 * @property {ApplicationFormData} applicationFormData - all stored data for all forms in the
 * application
 * @property {function} registerApplicationFormField - sets application level form fields
 * @property {function} handleApplicationFormFieldChange - allows customizing form data structure.
 * This is to set applicant specific objects with their relevant data.
 * @property {object} primaryApplicant - the primary applicant of the application
 * @property {function} refetchApplication - refetches the application data and updates the context
 * @property {function} refetchApplicants - refetches the applicants data and updates the context
 * @property {function} updateApplication - updates the application data and refetches the
 * application to update the context
 * @property {function} addNewApplicant - adds a new applicant in memory to the context
 * @property {function} removeInMemoryApplicant - removes an in memory applicant from the context
 * @property {function} saveApplicant - saves an applicant to the backend and updates the context
 * @property {function} deleteApplicant - deletes an applicant from the backend and updates
 * the context
 * @property {function} getApplicantFormData - gets the form data for a specific applicant
 * @property {String} applicationId - the track's active_application_id
 * @property {object} selectedApplicantForDeletion - an applicant that is selected for deletion
 * @property {function} setSelectedApplicantForDeletion - sets an applicant as selected for deletion
 */

/**
 * Collects and stores application form data and also responsible for fetching application and
 * applicant data from the backend
 * @return {ApplicationContext}
 */
const useApplicationContext = () => useContext(ApplicationContext);

export default useApplicationContext;
