import { Redirect, useParams } from 'react-router-dom';
import useApplicationContext from '../hooks/useApplicationContext';
import {
  APPLICATION_CONFIG,
  APPLICATION_SECTION_URL_NAME,
  filterConditionalPages,
  getInMemoryApplicants,
} from '../utils';
import { NotFoundBlock } from '@hometap/htco-components';
import { buildApplicationRoute } from '../utils';
import ApplicantFormSectionWrapper from '../forms/ApplicantFormSectionWrapper';
import ApplicationFormSectionWrapper from '../forms/ApplicationFormSectionWrapper';
import ApplicantDeleteModal from './modals/ApplicantDeleteModal/ApplicantDeleteModal';
import { APPLICANT_FORM_FIELD } from '../constants/formFields';
import { RouterPrompt } from 'apps/progressive-inquiry/components/RouterPrompt';
import useApplicationNavContext from '../hooks/useApplicationNavContext';

const ApplicationPageFromConfig = () => {
  const {
    track,
    isLoading,
    applicants,
    application,
    primaryApplicant,
    isInitialLoading,
    refetchApplicants,
    applicationFormData,
    getApplicantFormData,
    deleteApplicant,
    removeInMemoryApplicant,
    selectedApplicantForDeletion,
    setSelectedApplicantForDeletion,
  } = useApplicationContext();
  const { currentPageIsDisabled, nextPage, nextAnticipatedPage } = useApplicationNavContext();
  const { page, applicantId, ...params } = useParams();

  const section = params.section || APPLICATION_SECTION_URL_NAME.applicants;

  const currentApplicantFormData = getApplicantFormData(applicantId);
  const applicantToDeleteFormData = getApplicantFormData(selectedApplicantForDeletion?.id);

  if (!APPLICATION_CONFIG[section]) return <NotFoundBlock />;

  const config = filterConditionalPages(APPLICATION_CONFIG[section], applicationFormData);
  const hasInMemoryApplicant = getInMemoryApplicants(applicants).length;
  const isApplicantSection = section === APPLICATION_SECTION_URL_NAME.applicants;
  const isPropertySection = section === APPLICATION_SECTION_URL_NAME.property;

  // Redirect route if section, page or applicantId are not found in the URL path
  const firstPageKey = config[0]?.pageKey;
  const trackRoute = buildApplicationRoute(track?.id);
  if (!params.section || !page || (!applicantId && !hasInMemoryApplicant && isApplicantSection)) {
    const trackRouteProps = { section, pageKey: page || firstPageKey };
    if (isApplicantSection) {
      trackRouteProps.applicantId = applicantId || primaryApplicant?.id;
    }
    return <Redirect push to={trackRoute(trackRouteProps)} />;
  }

  if (((!applicantId && hasInMemoryApplicant) || isPropertySection) && currentPageIsDisabled) {
    return <Redirect push to={trackRoute({ section, pageKey: firstPageKey })} />;
  }

  const applicantIdDoesNotExist = !isInitialLoading && applicantId && !applicants.find(({ id }) => id === applicantId);
  if (applicantIdDoesNotExist && !hasInMemoryApplicant) {
    return <NotFoundBlock />;
  }

  const pageConfig = config.find(({ pageKey }) => pageKey === page);

  if (!pageConfig && section in APPLICATION_SECTION_URL_NAME) {
    return <Redirect push to={trackRoute({ section, pageKey: firstPageKey })} />;
  }

  if (!pageConfig) return <NotFoundBlock />;

  const currentPageIndex = config.findIndex(({ pageKey }) => pageKey === page);
  const isLastPage = currentPageIndex === config.length - 1;
  // lastPage is default save page but we allow for the config to set an isSavePage override
  const sectionHasOverriddenSavePage = !!config.filter(({ isSavePage }) => !!isSavePage).length;
  const hasDirtyFields = !!currentApplicantFormData?.dirtyFields;
  const savePage =
    pageConfig.isSavePage && typeof pageConfig.isSavePage === 'function'
      ? pageConfig.isSavePage({ formData: currentApplicantFormData, application })
      : pageConfig.isSavePage;

  const isSavePage = (!sectionHasOverriddenSavePage && isLastPage) || savePage;

  let WrapperComponent = ApplicationFormSectionWrapper;
  if (section === APPLICATION_SECTION_URL_NAME.applicants) {
    WrapperComponent = ApplicantFormSectionWrapper;
  }
  const shouldNotNavigateAwayFromApplicantSection =
    !applicantId && !currentApplicantFormData?.isPrimaryApplicant && nextAnticipatedPage !== nextPage?.to;
  const hasEditedSavedApplicant = hasDirtyFields && applicantId;

  const shouldNotRefreshApplicantSection =
    // is creating a new applicant and has not saved or is viewing a saved applicant and has an
    // active applicant in memory
    ((!shouldNotNavigateAwayFromApplicantSection && !applicantId) || (hasInMemoryApplicant && applicantId)) &&
    isApplicantSection;

  const shouldNotRefreshPropertySection =
    (isPropertySection || isApplicantSection) && applicationFormData.dirtyFields?.length && !hasEditedSavedApplicant;

  const { pageTitle } = pageConfig || {};
  return (
    <div>
      {pageTitle && (
        <h1>
          {typeof pageTitle === 'function' ? pageTitle({ data: { track, currentApplicantFormData } }) : pageTitle}
        </h1>
      )}

      <WrapperComponent
        pageConfig={pageConfig}
        currentPageIndex={currentPageIndex}
        isSavePage={isSavePage}
        key={applicantId || pageConfig.pageKey}
      >
        {pageConfig.form()}
      </WrapperComponent>
      <ApplicantDeleteModal
        isOpen={!!selectedApplicantForDeletion}
        onClose={() => setSelectedApplicantForDeletion(null)}
        onConfirm={() => !isLoading && deleteApplicant(applicantId)}
        applicantFirstName={applicantToDeleteFormData && applicantToDeleteFormData[APPLICANT_FORM_FIELD.firstName]}
      />

      <RouterPrompt
        bodyText={
          shouldNotNavigateAwayFromApplicantSection &&
          !currentApplicantFormData?.isPrimaryApplicant &&
          'You have an applicant that hasn’t been saved. You will lose all changes you’ve made to the applicant if you navigate to this page.'
        }
        when={hasEditedSavedApplicant}
        onOK={async () => {
          if (hasInMemoryApplicant) {
            if (!currentApplicantFormData?.isPrimaryApplicant) {
              removeInMemoryApplicant();
            }
            return;
          }
          await refetchApplicants();
        }}
        onlyOnUnload={shouldNotRefreshApplicantSection || shouldNotRefreshPropertySection}
      />
    </div>
  );
};

export default ApplicationPageFromConfig;
