import React from 'react';
import TrackButton from '../../../../../../components/TrackButton/TrackButton';
import {
  DropdownSelector,
  DetailBox,
  FormError,
  SelectionSet,
  SlideDown,
  useErrorScroll,
  useForm,
} from '@hometap/htco-components';
import useStateInfo from '../../../../utils/useStates';
import './QuizBasicForm.scss';

const QuizBasicForm = ({ onSubmit }) => {
  const { handleSubmit, registerField, formData, formSubmitError } = useForm();
  useErrorScroll(formSubmitError);

  const handleFormatOnSubmit = data => {
    if (data.homeowner_status === 'no') {
      data.is_homeowner = false;
    }
    onSubmit(data);
  };

  const { dropdownStates } = useStateInfo();

  return (
    <div className="QuizBasicForm">
      <FormError standalone error={formSubmitError} />
      <h2>Let's start with the basics:</h2>
      <form onSubmit={handleSubmit(handleFormatOnSubmit)}>
        <SelectionSet
          label="Are you a homeowner?"
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            { label: 'I own multiple homes', value: 'multiple' },
          ]}
          required
          {...registerField('homeowner_status')}
        />
        <SlideDown visible={formData.homeowner_status === 'multiple'}>
          <DetailBox
            icon="icon-house"
            emphasis="Note:"
            text="Hometap currently invests in primary residences, vacation homes, and rental properties. We invest in single and multi-family homes, as well as condos."
          />
        </SlideDown>
        <DropdownSelector
          options={dropdownStates}
          label="What state is your property located in?"
          required
          {...registerField('state')}
        />
        <TrackButton eventProperties={{ destination: '/fit-quiz' }} type="submit">
          Continue
        </TrackButton>
      </form>
    </div>
  );
};

export default QuizBasicForm;
