import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Toggle, useWindowSize } from '@hometap/htco-components';
import { useHistory } from 'react-router-dom';
import { EQUITY_VIEW } from '../../../constants';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { buildHomeProfileUrl } from 'utils/links';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import EquityViewSettings from '../equity-view-settings/EquityViewSettings';
import './EquityViewSelector.scss';

const VIEW_OPTIONS = [
  { label: 'Today’s View', value: EQUITY_VIEW.TODAY },
  { label: 'Plan for the future', value: EQUITY_VIEW.FUTURE },
];

const EquityViewSelector = () => {
  const { isFutureView, setIsFutureView, futureScenario } = useEquityScenarioContext();
  const { setupProfileLater } = useDelayHomeEquityProfile();
  const history = useHistory();
  const location = useLocation();
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [scrollDir, setScrollDir] = useState(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [headerTotalHeight, setHeaderTotalHeight] = useState(0);

  const emptyFutureState = isFutureView && !futureScenario?.id;

  const TABS_CONTAINER_HEIGHT =
    document.querySelector('.DashboardLayoutAddressHeader .htco-TabsContainer')?.offsetHeight || 0;
  useLayoutEffect(() => {
    setHeaderTotalHeight(
      document.querySelector('.DashboardLayoutAddressHeader .htco-HeaderContainer')?.offsetHeight || 0,
    );
  }, [isHeaderHidden]);

  useEffect(() => {
    let pageStartsWithoutNav = isHeaderHidden;
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setScrollDir(prevScrollPos > currentScrollPos ? 'up' : 'down');
      setPrevScrollPos(currentScrollPos);
      if (headerTotalHeight < currentScrollPos) {
        pageStartsWithoutNav = false;
      }
      if (scrollDir === 'down' && headerTotalHeight < currentScrollPos) {
        setIsHeaderHidden(true);
      }
      if (scrollDir === 'up' && !pageStartsWithoutNav) {
        setIsHeaderHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, location, isHeaderHidden, scrollDir, headerTotalHeight]);

  const getPositionTop = () => {
    return isHeaderHidden ? TABS_CONTAINER_HEIGHT : headerTotalHeight + TABS_CONTAINER_HEIGHT;
  };

  const onToggle = value => {
    if (value === 'today') {
      if (isFutureView) {
        setIsFutureView(false);
      }
    } else if (value === 'future') {
      // TODO: Once the Toggle component has diabled functionality, disable Toggle instead of redirecting.
      if (setupProfileLater) {
        history.push(buildHomeProfileUrl());
        return;
      }
      if (!isFutureView) {
        setIsFutureView(true);
      }
    }
  };

  const { isScreenSize } = useWindowSize();
  const isTablet = isScreenSize('sm');

  return (
    <div className="EquityViewSelectorContainer" style={{ top: getPositionTop() }}>
      <div className="PageBodyCenteredContainer ">
        <div className="EquityViewSelector">
          <Toggle
            options={VIEW_OPTIONS}
            onChange={onToggle}
            value={isFutureView ? EQUITY_VIEW.FUTURE : EQUITY_VIEW.TODAY}
            className="EquityViewSelectorToggle"
            labelWidth="50%"
          />

          {!isTablet && !emptyFutureState && <EquityViewSettings isInline />}
        </div>
      </div>
    </div>
  );
};

export default EquityViewSelector;
