import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Button, Container, MuiTextInput, useBoolState } from '@hometap/htco-components';
import { usePlacesWidget } from 'react-google-autocomplete';
import { parseGoogleAutocompleteComponents, parseDataToAddressString } from 'utils/googleAutocompleteAddress';
import { OnboardingLayout, Panel, CallToAction } from '../template/OnboardingTemplate';
import OnboardingAddressModal from './OnboardingAddressModal';
import OnboardingPanelCard from './OnboardingPanelCard';
import homeRenovation from 'images/equity-planner/onboarding/onboarding-address.png';
import './OnboardingAddress.scss';

const OnboardingAddress = ({ form, onPrev, onNext }) => {
  const { registerField, updateFormData, errors, formData, isFormValid } = form;
  const [open, setOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State variable for dropdown open/closed flag
  const isDropdownOpenRef = useRef(false); // Ref to track dropdown open/closed state

  const handleOpen = () => {
    updateFormData({
      street: '',
      unit: '',
      city: '',
      state: '',
      zip_code: '',
    });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const formattedAddress = parseDataToAddressString(formData);
  const isValidGoogleAddress = useBoolState(formattedAddress === formData.address);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_PLACES_API_KEY,
    inputAutocompleteValue: 'off',
    onPlaceSelected: place => {
      const { address_components } = place;
      if (address_components && address_components.length) {
        const addressObj = parseGoogleAutocompleteComponents(place.address_components);
        // Set An flag variable to determine if address is valid True by default
        const addressValid = !Object.values(addressObj).includes('');
        const address = parseDataToAddressString(addressObj);
        updateFormData({ ...addressObj, address });

        if (addressValid) {
          setTimeout(() => {
            isValidGoogleAddress.setTrue();
          });
        }
      }
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'types'],
    },
  });

  const addressField = registerField('address');
  const onAddressChange = (v, ...props) => {
    isValidGoogleAddress.setFalse();
    addressField.onChange(v, ...props);
  };
  const isDisabled = !!errors?.address || !isValidGoogleAddress.value || !isFormValid;

  const inputClasses = property => cx({ OnboardingInputWithValue: formData[property] });

  const handleInputClick = () => {
    setIsDropdownOpen(true);
    isDropdownOpenRef.current = true;
  };

  const handleOutsideClick = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsDropdownOpen(false);
      isDropdownOpenRef.current = false;
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleKeyDown = event => {
    // We may need to expand this for other navigation keys
    if (event.key === 'Escape' || event.key === 'Tab') {
      setIsDropdownOpen(false);
      isDropdownOpenRef.current = false;
    }
  };

  return (
    <div className="OnboardingAddress" data-testid="address-page">
      <OnboardingLayout>
        <Panel>
          <h1>Tell us about your home</h1>
          <div className="OnboardingAddressPanelBodyText" data-testid="subtitle">
            Enter your address for a more personalized and accurate forecast.
          </div>
          <Container className="OnboardingAddressRow" row>
            <Container className="OnboardingAddressPropertyAddress">
              <MuiTextInput
                tabIndex="0"
                className={inputClasses('address')}
                ref={ref}
                name="address"
                label="Property address"
                data-testid="property-address"
                required
                {...addressField}
                onChange={onAddressChange}
                onClick={handleInputClick}
              />
            </Container>
            <Container className="OnboardingAddressUnit">
              <MuiTextInput
                name="unit"
                label="Unit (optional)"
                data-testid="unit"
                maxLength={50}
                {...registerField('unit')}
              />
            </Container>
          </Container>
          <div className="OnboardingAddressInputs">
            {isDropdownOpen ||
              (addressField.value && !isValidGoogleAddress.value && (
                <div>
                  <p className="OnboardingManualAddressText">Address not found, please try again.</p>
                  <Button
                    className="OnboardingManualAddressButton"
                    theme="link"
                    onClick={handleOpen}
                    data-testid="manual-address-button"
                  >
                    I’ll enter my address manually
                  </Button>
                  <OnboardingAddressModal form={form} open={open} onNext={onNext} handleClose={handleClose} />
                </div>
              ))}
          </div>
          <CallToAction>
            <div className="OnboardingLinkButton">
              <Button theme="link" onClick={onPrev} data-testid="back-button">
                Back
              </Button>
            </div>
            <Button onClick={onNext} disabled={isDisabled} data-testid="next-button">
              Next
            </Button>
          </CallToAction>
        </Panel>
        <Panel containsVisual>
          <OnboardingPanelCard
            headerText="See your equity grow over time and learn how you can increase it at a faster rate."
            imgSrc={homeRenovation}
            imgAlt="my equity over time"
            dataTestId="panel-image"
          />
        </Panel>
      </OnboardingLayout>
    </div>
  );
};

export default OnboardingAddress;
