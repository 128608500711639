import { useCallback } from 'react';
import { useWindowSize } from '@hometap/htco-components';
import PropTypes from 'prop-types';
import FinanceCalculator from './FinanceCalculator';
import FinanceOptionOverview from './FinanceOptionOverview';
import { FINANCE_OPTIONS_OVERVIEW } from './data/financeData';
import './FinanceOptionDetails.scss';

const FinanceOptionDetails = ({
  calculatorData,
  financeOptions,
  selectedFinanceOption,
  setCalculatorData,
  setSelectedFinanceOption,
  onGetPreQualified,
  onAddToEquityChart,
  isRequestLoading,
  loanDetails,
  setLoanDetails,
  financialGoal,
}) => {
  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize('md');
  const selectedOptionOverview = FINANCE_OPTIONS_OVERVIEW[selectedFinanceOption.value] || {};

  const handleCalculatorDataChange = useCallback(
    (key, newValue) => {
      setCalculatorData(prevData => ({
        ...prevData,
        [key]: newValue,
      }));
    },
    [setCalculatorData],
  );

  return (
    <div className="FinanceOptionDetailsWrapper">
      <div className="FinanceOptionDetailsSideBar">
        <FinanceCalculator
          selectedFinanceOption={selectedFinanceOption}
          financeOptions={financeOptions}
          calculatorData={calculatorData}
          onSelectFinance={setSelectedFinanceOption}
          onAddToEquityChart={onAddToEquityChart}
          isRequestLoading={isRequestLoading}
          onGetPreQualified={onGetPreQualified}
          onCalculatorDataChange={handleCalculatorDataChange}
          loanDetails={loanDetails}
          setLoanDetails={setLoanDetails}
        />
        {!isMobile && (
          <div className="FinanceOptionDetailsCoverImage">
            <img src={selectedOptionOverview.coverImg} alt="Finance" />
          </div>
        )}
      </div>
      <FinanceOptionOverview optionOverview={selectedOptionOverview} financialGoal={financialGoal} />
      {isMobile && (
        <div className="FinanceOptionDetailsCoverImage">
          <img src={selectedOptionOverview.coverImg} alt="Finance" />
        </div>
      )}
    </div>
  );
};

const financeOptionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  calculatorCardFooterCopy: PropTypes.string.isRequired,
});

FinanceOptionDetails.propTypes = {
  calculatorData: PropTypes.object.isRequired,
  financeOptions: PropTypes.arrayOf(financeOptionType).isRequired,
  selectedFinanceOption: financeOptionType.isRequired,
  setCalculatorData: PropTypes.func.isRequired,
  setSelectedFinanceOption: PropTypes.func.isRequired,
  onGetPreQualified: PropTypes.func,
  onAddToEquityChart: PropTypes.func,
  loanDetails: PropTypes.object,
  setLoanDetails: PropTypes.func,
};

export default FinanceOptionDetails;
