import React, { useState } from 'react';
import { Button, Grid, Modal, MuiSelect, MuiTextInput, useForm } from '@hometap/htco-components';
import cx from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import useGooglePlaceInput from '../../../hooks/useGooglePlaceInput';
import useStates from '../../../hooks/useStates';
import { useAddPropertyMutation, useCurrentHome } from 'hooks/useCurrentHome';

import './AddNewPropertyModal.scss';

export const ADD_NEW_HOME_ADDRESS_FIELDS = {
  ZIP_CODE: 'zip_code',
  CITY: 'city',
  STREET: 'street',
  STATE: 'state',
  UNIT: 'unit',
};

const AddPropertyError = ({ errorMessage }) => (
  <ErrorMessage className="AddNewPropertyErrorMessage">{errorMessage}</ErrorMessage>
);

const AddNewPropertyModal = ({ setIsOpen }) => {
  const [isManualAddressForm, setIsManualAddressForm] = useState(false);
  const { statesForDropdown } = useStates();

  const { setHomeById } = useCurrentHome();
  const { isLoading, mutateAsync, error } = useAddPropertyMutation();

  const addPropertyErrorMap = {
    'Address already exists for this user': 'Home already added to Home Equity Dashboard',
    'You have reached your maximum number of homes': 'You have reached your maximum number of homes.',
  };

  const errorMessage = addPropertyErrorMap[error?.response?.data?.address];

  const { registerField, isFormValid, handleSubmit, updateFormData, formData } = useForm();

  const onAddress = (address, addressObj) => {
    updateFormData({ address, ...addressObj });
  };

  const valid = () => {
    const { zip_code } = formData;
    const zipCodeValid = zip_code && zip_code.length === 5;
    const optionalFields = ['unit', 'street_number'];
    return (
      Object.keys(formData).every(key => optionalFields.includes(key) || formData[key]) && isFormValid && zipCodeValid
    );
  };
  const { ref, isValidGoogleAddress } = useGooglePlaceInput({ onAddress });

  const isValidPropertyAddress = formData.address && isValidGoogleAddress;

  const handleModalClose = () => {
    const resetFields = Object.values(ADD_NEW_HOME_ADDRESS_FIELDS).reduce((acc, field) => {
      acc[field] = '';
      return acc;
    }, {});
    updateFormData(resetFields);
    setIsOpen(false);
  };

  const handleAddProperty = async () => {
    const data = await mutateAsync({ address: formData });
    setHomeById(data.id);
    // keep the modal open so errors can be addressed
    if (error) return;
    handleModalClose();
  };

  return (
    <Modal
      className="AddNewPropertyModal"
      width={718}
      // Hack to allow the correct ref, for the google places api, to be present when this component is mounted. Parents
      // are responsible for conditionally rendering the Modal
      open={true}
      onClose={() => {
        handleModalClose();
        setIsManualAddressForm(false);
      }}
    >
      <div className={cx('AddAddressModalContent', { isManualAddressForm })}>
        {isManualAddressForm ? (
          <>
            <h1>Add home to dashboard</h1>
            <form onSubmit={handleSubmit(handleAddProperty)} className="AddManualAddressModalForm">
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid xs={12} sm={6}>
                  <MuiTextInput
                    name="street"
                    label="Street address"
                    maxLength={50}
                    width="100%"
                    required
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.STREET)}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <MuiTextInput
                    name="unit"
                    label="Unit (optional)"
                    maxLength={50}
                    width="100%"
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.UNIT)}
                  />
                </Grid>
                <Grid xs={12} sm={12}>
                  <MuiTextInput
                    name="city"
                    label="City"
                    maxLength={50}
                    width="100%"
                    required
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.CITY)}
                  />
                </Grid>
                <Grid xs={12} sm={6} className="AddManualAddressModalState">
                  <MuiSelect
                    name="state"
                    label="State"
                    options={statesForDropdown}
                    width="100%"
                    required
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.STATE)}
                  />
                </Grid>
                <Grid xs={12} sm={6} className="AddManualAddressModalZip">
                  <MuiTextInput
                    name="zip_code"
                    label="ZIP code"
                    maxLength={5}
                    required
                    mask="00000"
                    width="100%"
                    validator={val => val.length !== 5 && 'Zip code must be 5 digits'}
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.ZIP_CODE)}
                  />
                </Grid>
              </Grid>

              <Button
                className="AddManualAddressModalSubmit"
                disabled={!valid() || isLoading}
                width="100%"
                type="submit"
                loading={isLoading}
              >
                Add home
              </Button>
            </form>
          </>
        ) : (
          <>
            <h1>Add home to dashboard</h1>
            <div className="AddNewPropertyModalFields">
              <MuiTextInput
                label="Address"
                width="100%"
                name="address"
                ref={ref}
                value={formData.address}
                onChange={address => updateFormData({ address })}
              />
              <MuiTextInput
                label="Unit (optional)"
                maxLength={50}
                name="unit"
                onChange={value => updateFormData({ unit: value }, ADD_NEW_HOME_ADDRESS_FIELDS.UNIT)}
                {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.UNIT)}
              />
            </div>
            <div className="AddNewPropertyButtonContainer">
              <Button
                onClick={handleAddProperty}
                width="100%"
                disabled={!isValidPropertyAddress || isLoading}
                loading={isLoading}
              >
                Add home
              </Button>
              <Button
                data-testid="manually-enter-new-property"
                theme="link"
                onClick={() => setIsManualAddressForm(true)}
              >
                Enter address manually
              </Button>
            </div>
          </>
        )}
        {errorMessage && <AddPropertyError errorMessage={errorMessage} />}
      </div>
    </Modal>
  );
};

export default AddNewPropertyModal;
