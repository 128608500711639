import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, Loader } from '@hometap/htco-components';

const propTypes = {
  index: PropTypes.number.isRequired, // The order of the loader in the sequence
  label: PropTypes.string.isRequired, // The title displayed at the top of the modal
  isNext: PropTypes.bool, // Whether to start counting up to the minimum time
  minLoadTime: PropTypes.number, // The minimum amount of time the loader should spin
  onStatusChange: PropTypes.func, // Callback for when the loader has stopped spinning
  isDone: PropTypes.bool, // Indicates when another async dependency has completed
  onDone: PropTypes.func, // Callback for when another async dependency has completed
};

const defaultProps = {
  minLoadTime: 0,
  isDone: true,
};

const getStatusIcon = status => {
  if (!status) {
    return <span />;
  }

  if (status === 'on') {
    return <Loader size="small" />;
  }

  return <Icon name="icon-check-btl" />;
};

const FlashLoader = ({ desc, status }) => {
  return (
    <Fragment key={desc}>
      <span className={cx('icon', status)}>{getStatusIcon(status)}</span>
      <span key={`${desc}_`} className={cx('desc', status)}>
        {desc}
      </span>
    </Fragment>
  );
};

const DelayedLoader = ({ index, label, isNext, minLoadTime, onStatusChange, isDone, onDone }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isNext) {
      setTimeout(() => {
        if (isDone) {
          setIsLoading(false);
          if (onStatusChange) onStatusChange(index);
          if (onDone) onDone();
        }
      }, minLoadTime);
    }
  }, [minLoadTime, setIsLoading, isNext, onStatusChange, isDone, onDone, index]);

  return <FlashLoader desc={label} status={isLoading ? 'on' : 'off'} />;
};

DelayedLoader.propTypes = propTypes;
DelayedLoader.defaultProps = defaultProps;

export default DelayedLoader;
