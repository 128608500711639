import { MuiSelect, MuiTextInput } from '@hometap/htco-components';

import useApplicantForm from '../hooks/useApplicantForm';
import { APPLICANT_FORM_FIELD } from '../constants/formFields';

const employmentStatusOptions = [
  { label: 'Employed', value: 'employed' },
  { label: 'Unemployed', value: 'unemployed' },
  { label: 'Retired', value: 'retired' },
];

const EmploymentInfoForm = () => {
  const { registerApplicantField } = useApplicantForm();

  return (
    <div className="ApplicationInfoFormFields">
      <MuiSelect
        label="Employment Status"
        options={employmentStatusOptions}
        width="100%"
        required
        {...registerApplicantField(APPLICANT_FORM_FIELD.employmentStatus)}
      />
      <MuiTextInput
        type="number"
        label="Total Annual Income"
        startAdornment="$"
        mask={[
          {
            mask: Number,
            signed: false,
            thousandsSeparator: ',',
            max: 999999999,
          },
        ]}
        required
        unmask="typed"
        width="100%"
        {...registerApplicantField(APPLICANT_FORM_FIELD.totalAnnualIncome)}
      />
    </div>
  );
};

export default EmploymentInfoForm;
