import React from 'react';
import { Accordion } from '@hometap/htco-components';

const HometapProcess = () => {
  return (
    <div>
      <p>Getting a Hometap Investment is done in a few simple steps:</p>

      <ol className="numbered-list">
        <li>
          <strong>Complete an Investment Inquiry</strong>

          <p className="InquiryOutcomeFAQProcessListItemContent">
            Create an account and submit an Investment Inquiry through the Hometap website
          </p>
        </li>
        <li>
          <strong>Speak with your Investment Manager</strong>
          <p className="InquiryOutcomeFAQProcessListItemContent">
            If we think an Investment might be possible, we’ll send you an Investment Estimate, which includes estimated
            terms specific to your property. Your Hometap Investment Manager will set up a call with you to walk through
            the numbers and answer any questions you may have about the Application process.
          </p>
        </li>
        <li>
          <strong>Submit your Application</strong>
          <p className="InquiryOutcomeFAQProcessListItemContent">
            Once you’ve uploaded or scanned your documents to your computer, grab a cup of coffee and get started – this
            Application shouldn’t take more than 20 minutes.
          </p>
        </li>
        <li>
          <strong>Signing</strong>
          <p className="InquiryOutcomeFAQProcessListItemContent">
            Before we can prepare the final Investment Details, we’ll schedule a physical appraisal of your property
            using a third-party appraiser. Once the appraisal is complete and the Investment Details are finalized, we
            can sign the documents and begin the transfer of funds!
          </p>
        </li>
      </ol>
    </div>
  );
};

const WhoCanUseHometap = () => {
  return (
    <div>
      <p>
        Because there are a lot of factors that go into determining if Hometap can make an Investment in a property,
        there isn’t a black-and-white list of criteria. Each property is evaluated independently. That’s why we suggest
        you start an Investment Estimate so that our Investment Managers have the information they need to speak with
        you about your specific scenario. Here are some of the qualities that tend to make for a good fit:
      </p>
      <ul className="small-bulleted-list">
        <li>
          <span>Your single-family home or condo is located in a state in which we’re currently operational</span>
        </li>
        <li>
          <span>
            We have a minimum FICO requirement of 500, though most of the homeowners we work with tend to have a score
            of 600+
          </span>
        </li>
        <li>
          <span>You have a minimum of 25% equity in your home</span>
        </li>
        <li>
          <span>
            The investment amount you’re looking for is 25% or less of your total home value, or under $600,000 (this is
            the maximum amount that we can invest, and it will depend on home value and equity percentage, as well)
          </span>
        </li>
      </ul>
    </div>
  );
};

const InquiryOutcomeFAQ = ({ isAdditionalTrack }) => {
  return (
    <div className="InquiryOutcomeFAQContainer">
      <h5 className="InquiryOutcomeFAQHeading">SOME COMMON QUESTIONS</h5>
      {isAdditionalTrack ? (
        <>
          <Accordion header="Will I work with the same Investment Manager?">
            While we do our best to pair you with the same Investment Manager you worked with the first time around,
            there are some cases when it’s not possible. However, every one of our Investment Managers is committed to
            providing our homeowners with the best experience possible, and we’re confident that you’ll be happy with
            any of our helpful team members!
          </Accordion>
          <Accordion header="Will I need another appraisal? Will I have to pay the same closing costs?">
            Since{' '}
            <a
              href="https://www.hometap.com/homeowner-resources/homeowners-guide-home-value/"
              rel="noreferrer"
              target="_blank"
            >
              appraisals
            </a>{' '}
            expire after 90 days, you’ll likely need another appraisal if you’re taking another Investment after that
            point. You can also expect similar closing costs as you were responsible for with the first Investment. The
            closing cost fee will only apply to the second Investment amount. As with the first Investment, this will be
            deducted from the funding amount, so you won’t have any out-of-pocket expenses.
          </Accordion>
          <Accordion header="Are there benefits to an Investment Increase, beyond the additional funding?">
            This all depends on the amount of time between Investments. While your time frame may be shortened the
            second (or third) time around, most of the documentation we require expires after 90 days, so you’ll likely
            need to provide more recent versions.
          </Accordion>
        </>
      ) : (
        <>
          <Accordion header="What's the difference between Hometap and a loan?">
            Unlike a lender, we receive no monthly payments or guaranteed return on the money we’ve invested. For some,
            taking an equity investment can be an intelligent way to fund the opportunities and needs that come up in
            life while eliminating the “debt stress” of increased monthly payments.
          </Accordion>
          <Accordion header="Who can use a Hometap HEI?">
            <WhoCanUseHometap />
          </Accordion>
          <Accordion header="How long do I have to settle a Hometap Investment?">
            The effective period of a Hometap Investment is 10 years. You can either buy out our Investment with
            savings, take out a home equity (or other) loan, or sell your house during the effective period. We call
            this settling the Investment.
          </Accordion>
          <Accordion header="What is the process for getting a Hometap Investment?">
            <HometapProcess />
          </Accordion>
        </>
      )}
    </div>
  );
};
export default InquiryOutcomeFAQ;
