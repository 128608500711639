import React, { useEffect } from 'react';
import cx from 'classnames';
import { Button, MuiTextInput, useAsync, SlideDown } from '@hometap/htco-components';
import { OnboardingLayout, CallToAction, Panel } from '../template/OnboardingTemplate';
import OnboardingPanelCard from './OnboardingPanelCard';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import useLogAsyncError from 'hooks/useLogAsyncError';
import annualAppreciation from 'images/equity-planner/onboarding/annual-appreciation.png';
import { emailValidator, phoneValidator } from 'utils/validators';
import { formatFullAddress } from 'utils/address';
import { getLoginUrlWithEquityDashboardNextStep } from 'utils/links';
import { browserIdentify, browserTrack } from 'utils/segment';
import { buildAccountCreateRequestData, buildSegmentData, createAccount } from '../../../data/createAccount';
import './OnboardingHomeownerInfo.scss';

const OnboardingHomeownerInfo = ({ form, onPrev, onNext }) => {
  const { formData, isFormValid, registerField, tracking_data } = form;
  const { first_name, last_name, email, street, unit, city, state, zip_code } = formData;
  const { error, loading, results, execute: createNewAccount } = useAsync(createAccount);

  const emailError = error?.response?.data?.email;
  const shouldLog = emailError && emailError[0] === 'email_in_use';
  useLogAsyncError('Account creation failed', !shouldLog);

  // PAT-376: We don't want to use useEffect for results/error handling due to potential bugs
  // This refactor ticket will remove useAsync and useEffect for this use case.
  useEffect(() => {
    if (results && !error) {
      // send account created segment event
      const segmentData = buildSegmentData({ ...formData, tracking_data });
      browserIdentify(formData.email, {
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
      });
      browserTrack.equityDashboardAccountCreatedBrowser(segmentData);
      onNext();
    }
  }, [results, onNext, error, formData, tracking_data]);

  const onAccountCreate = async data => {
    if (data) {
      // create account
      const userData = buildAccountCreateRequestData({ ...formData, tracking_data });
      await createNewAccount(userData);
    }
  };

  const enableNext = first_name && last_name && email;

  const inputClasses = property => cx({ OnboardingInputWithValue: formData[property] });
  return (
    <div className="OnboardingHomeownerInfo" data-testid="signup-page">
      <OnboardingLayout>
        <Panel>
          <h1>Now, tell us a bit about yourself</h1>
          <div className="OnboardingHomeownerInfoPanelBodyText" data-testid="subtitle">
            We just need a little more information to set up your account.
          </div>
          <div className="OnboardingHomeownerInfoFirstLastName">
            <MuiTextInput
              tabIndex="0"
              className={inputClasses('first_name')}
              name="first_name"
              label="First name"
              data-testid="first-name"
              maxLength={30}
              width="100%"
              required
              {...registerField('first_name')}
            />
            <MuiTextInput
              className={inputClasses('last_name')}
              name="last_name"
              label="Last name"
              data-testid="last-name"
              maxLength={30}
              width="100%"
              required
              {...registerField('last_name')}
            />
          </div>
          <MuiTextInput
            className={inputClasses('email')}
            name="email"
            label="Email address"
            data-testid="email"
            type="text"
            inputMode="email"
            width="100%"
            validator={emailValidator}
            maxLength={254}
            required
            {...registerField('email')}
          />
          <MuiTextInput
            className={inputClasses('phone_number')}
            name="phone_number"
            label="Mobile number"
            data-testid="phone-number"
            type="tel"
            inputMode="tel"
            width="100%"
            mask="000-000-0000"
            unmask={false}
            maxLength={14}
            validator={phoneValidator}
            {...registerField('phone_number')}
          />
          <div className="OnboardingWhyWeNeedContent">
            Don't miss out on important updates, exclusive offers, and real-time reminders for managing your home. By
            submitting your phone number, you agree to receive automated texts from Hometap. Data rates may apply.
          </div>
          <div className="OnboardingSignupError">
            <SlideDown visible={!!error}>
              <ErrorMessage>
                {error?.response?.data?.email?.[0] === 'email_in_use' ? (
                  <span>
                    Looks like that email is already in use.{' '}
                    <a href={getLoginUrlWithEquityDashboardNextStep()}>Log in here.</a>
                  </span>
                ) : (
                  <span>We are unable to reach the server, please try again.</span>
                )}
              </ErrorMessage>
            </SlideDown>
          </div>
          <CallToAction>
            <div className="OnboardingLinkButton">
              <Button theme="link" onClick={onPrev} data-testid="back-button">
                Back
              </Button>
            </div>
            <Button
              id="OnboardingHomeownerInfoNext"
              onClick={onAccountCreate}
              disabled={!enableNext || !isFormValid || loading}
              data-testid="next-button"
              loading={loading}
            >
              Next
            </Button>
          </CallToAction>
        </Panel>
        <Panel containsVisual>
          <OnboardingPanelCard
            headerText="Create and compare scenarios that visualize potential growth in your equity over time."
            footerText={formatFullAddress(street, unit, city, state, zip_code)}
            imgSrc={annualAppreciation}
            imgAlt="annual appreciation scenarios"
            data-testid="panel-image"
          />
        </Panel>
      </OnboardingLayout>
    </div>
  );
};

export default OnboardingHomeownerInfo;
