import React from 'react';
import { Paper, Button } from '@hometap/htco-components';
import { currency } from 'utils/numbers';
import TrackButton from '../../../components/TrackButton/TrackButton';
import '../SettlementCalculator.scss';

const SettlementResult = ({ onBackButton, settlementAmount }) => {
  return (
    <div>
      <Paper theme="medium" className="SettlementResultWrapper">
        <div className="SettlementResultInnerWrapper">
          <h2 className="SettlementResultHeader">
            You can expect your settlement amount to be about{' '}
            <span className="SettlementResultNumber">{currency(Math.round(settlementAmount / 10) * 10)}.</span>
          </h2>

          <div className="SettlementResultBodyText">
            Ready to settle now? To officially start the process, fill out the document below and email it to{' '}
            <Button theme="link" href="mailto:homeowners@hometap.com">
              homeowners@hometap.com.
            </Button>
          </div>

          <ul className="SettlementResultList">
            <li className="SettlementResultListItem">
              <TrackButton
                theme="link"
                // This is a link to a file in the S3 bucket public-portals-assets-production-portal
                // served by CloudFront
                href="https://d1hy1o05tcwu10.cloudfront.net/client/pdf/hometap-binding-offer-notice.pdf"
                target="_blank"
              >
                Binding Offer Notice
              </TrackButton>
            </li>
          </ul>
        </div>
      </Paper>

      <Button icon="angle-left" theme="link" className="SettlementResultBackButton" onClick={onBackButton}>
        Recalculate my settlement amount
      </Button>
    </div>
  );
};

export default SettlementResult;
