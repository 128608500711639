import React from 'react';
import cx from 'classnames';
import { Icon, Button, SlideDown } from '@hometap/htco-components';
import { NavLink, matchPath, useLocation, useHistory } from 'react-router-dom';
import './ApplicationNavSection.scss';

const ApplicationNavSection = ({ sectionLabel, navItems, disabled: sectionDisabled, icon }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const isSectionOpen =
    !sectionDisabled &&
    !!navItems.find(({ to }) => {
      const match = matchPath(pathname, to);
      return !!match && match.isExact;
    });

  return (
    <ul className="ApplicationNavSection">
      <div className="ApplicationNavSectionHeaderContainer">
        <Button
          theme="link"
          disabled={sectionDisabled}
          className="ApplicationNavSectionHeader"
          onClick={() => {
            history.push(navItems[0].to);
          }}
        >
          {sectionLabel}
        </Button>
        {icon && !sectionDisabled && (
          <span onClick={icon.action} onKeyDown={icon.action} role="button" tabIndex="0">
            <Icon className="ApplicationNavSectionIcon" name={icon.name} color={icon?.color} size="lg" />
          </span>
        )}
      </div>
      <SlideDown visible={isSectionOpen} maxHeight={350}>
        <div className="ApplicationNavSectionItems">
          {navItems.map(item => {
            return (
              item && (
                <li className="ApplicationNavSectionItem" key={item.key}>
                  <NavLink
                    exact
                    activeClassName="isActive"
                    className={cx('ApplicationNavSectionLink', { isDisabled: item.disabled })}
                    to={item.to}
                  >
                    {item.navLabel}
                  </NavLink>
                </li>
              )
            );
          })}
        </div>
      </SlideDown>
    </ul>
  );
};

export default ApplicationNavSection;
