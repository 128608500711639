import cx from 'classnames';
import { Badge } from '@hometap/htco-components';
import './PercentageBadge.scss';

const PercentageBadge = ({ percentage = '90', theme = 'primaryGreen15' }) => (
  <div className="PercentageBadgeWrapper">
    <Badge label={`${percentage}% COMPLETE`} theme={theme} className={cx('ResourcesBadge', 'ResourcesCardBadge')} />
  </div>
);

export default PercentageBadge;
