import cx from 'classnames';
import { Header, IconButton, SlideSide, useBoolState, useWindowSize } from '@hometap/htco-components';
import useCurrentUser from 'hooks/useCurrentUser';
import env from 'utils/env';
import { getDashboardUrl } from 'utils/links';
import HeaderNavController from '../HeaderNav/HeaderNavController';

import './Header.scss';

const ClientHeader = ({ navItems = [], centerContent, className, ...headerProps }) => {
  const { user } = useCurrentUser();

  const { isScreenSize } = useWindowSize();
  const isDesktopSm = isScreenSize('md');
  const isMobileNavVisible = useBoolState();

  const MobileToggle = () => {
    return (
      <IconButton icon="menu" onClick={isMobileNavVisible.toggle} className="Hamburger" data-testid="mobile-menu" />
    );
  };

  return (
    <>
      <Header
        logoLink={user ? getDashboardUrl() : undefined}
        environment={env.getEnv()}
        className={cx('Header', 'HeaderContainer', className)}
        {...headerProps}
      >
        {centerContent && <div className="HeaderCenterContainer">{centerContent}</div>}
        <div className="HeaderRightSideContainer">
          <HeaderNavController navItems={navItems} />
          {isDesktopSm && <MobileToggle />}
        </div>
      </Header>

      <SlideSide
        onBackdropClick={isMobileNavVisible.setFalse}
        maxWidth={375}
        visible={isMobileNavVisible.value}
        isOverlay={true}
        displayBackdrop={true}
        direction="right"
      >
        <div className="HeaderMobileNavContent">
          <IconButton icon="close" onClick={isMobileNavVisible.setFalse} className="MobileMenuCancel" />
          <HeaderNavController navItems={navItems} isDesktopSm={isDesktopSm} />
        </div>
      </SlideSide>
    </>
  );
};

export default ClientHeader;
