import React, { useContext } from 'react';
import { editHomeProfileUrl, getNewDashboardUrl, getNewHEDUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import useUserTracksContext from '../../../../hooks/useUserTracksContext';
import { useHistory, matchPath } from 'react-router-dom';
import { useCurrentHome } from 'hooks/useCurrentHome';

const defaultTabOptions = {
  maxWidth: '100%',
  className: 'HeaderTabContainer',
  justifyTabs: 'flex-start',
  showBorderLine: false,
  scrollable: true,
  mobileBreakpoint: 'mobileLg',
};

const hasHED = ({ home, userCanAccessHome }) => {
  if (!home) {
    return false;
  }
  return userCanAccessHome;
};

const hasMatch = path => {
  return (
    matchPath(window.location.pathname, {
      path: `${path}`,
      exact: false,
    }) !== null
  );
};

const DASHBOARDS_TABS = [
  {
    label: 'Investments',
    path: HOMEOWNER_DASHBOARD.INVESTMENTS,
    userCanView: ({ tracks, home }) => {
      const tracksOnHome = tracks?.filter(track => track?.home?.id === home?.id);

      // a co-applicant can view the investment tab if they were removed from all tracks
      return !home || !!tracksOnHome?.length;
    },
  },
  {
    label: 'Home Equity',
    path: HOMEOWNER_DASHBOARD.HOME_EQUITY,
    userCanView: hasHED,
  },
  {
    label: 'Maintenance',
    path: HOMEOWNER_DASHBOARD.MAINTENANCE,
    userCanView: hasHED,
  },
  {
    label: 'Featured Offers',
    path: HOMEOWNER_DASHBOARD.FEATURED_OFFERS,
    userCanView: () => true,
  },
  {
    label: 'Resources',
    path: HOMEOWNER_DASHBOARD.RESOURCES,
    userCanView: () => true,
  },
];

const Context = React.createContext({});

export const DashboardTabsProvider = ({ children }) => {
  const { tracks = [], loading: fetchTrackLoading } = useUserTracksContext();
  const { home, userCanAccessHome } = useCurrentHome();

  const history = useHistory();

  const allTabs = [
    {
      path: HOMEOWNER_DASHBOARD.ROOT,
      tabs: DASHBOARDS_TABS,
      shouldDisplayTabs: !fetchTrackLoading,
      displayTabsExceptions: [
        editHomeProfileUrl(),
        getNewHEDUrl('/finance'),
        getNewHEDUrl('/explore-financing'),
        getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.RENOVATIONS}`),
        getNewDashboardUrl(`${HOMEOWNER_DASHBOARD.RESOURCES}/:postTitle`),
      ],
    },
  ];

  const currentTabData = allTabs.find(data => hasMatch(data.path));
  const tabs = currentTabData
    ? currentTabData.tabs
        .filter(
          tabConfig => currentTabData.shouldDisplayTabs && tabConfig.userCanView({ tracks, home, userCanAccessHome }),
        )
        .map(tabConfig => ({
          label: tabConfig.label,
          value: tabConfig.path,
          component: tabConfig.component,
        }))
    : [];

  const shouldShowTabs =
    !!currentTabData && !currentTabData.displayTabsExceptions.find(exception => hasMatch(exception));
  let currentTab = tabs.find(({ value }) => hasMatch(getNewDashboardUrl(value)));
  const hasHEDOnCurrentHome = hasHED({ home, userCanAccessHome });
  const getDefaultTab = (tracks, tabs) => {
    if (!tracks) return undefined;

    if (tracks.length) {
      return tabs.find(({ value }) => value === HOMEOWNER_DASHBOARD.INVESTMENTS);
    }
    return tabs.find(({ value }) => value === HOMEOWNER_DASHBOARD.HOME_EQUITY);
  };

  if (currentTab === undefined) {
    currentTab = getDefaultTab(tracks, tabs);
  }

  const tabPropsList = currentTab
    ? {
        ...defaultTabOptions,
        tabs,
        curTab: currentTab?.value,
        onChange: value => {
          history.push(getNewDashboardUrl(value));
        },
      }
    : null;

  return <Context.Provider value={{ tabPropsList, shouldShowTabs, hasHEDOnCurrentHome }}>{children}</Context.Provider>;
};

const useDashboardTabsContext = () => useContext(Context);
export default useDashboardTabsContext;
