import React from 'react';
import { Container, Helptip } from '@hometap/htco-components';
import { formatDate } from 'utils/date';
import { currency } from 'utils/numbers';
import '../SettlementCalculator.scss';

const Cell = ({ label, value, helpText }) => (
  <Container grid="6" className="InvestmentInfoGridContainer">
    <div className="InvestmentInfoLabelWrapper">
      <div className="InvestmentInfoLabel">{label}:</div>
      <div className="InvestmentInfoValue">{value}</div>
      {helpText && <Helptip text={helpText} />}
    </div>
  </Container>
);

const InvestmentInfo = ({
  hometapShare,
  effectiveDate,
  expirationDate,
  beginningHomeValue,
  investmentAmount,
  settlementDate,
  appreciatedHomeValue,
}) => {
  const effectiveDateFormatted = formatDate(effectiveDate, 'MMMM DD, YYYY');
  const expirationDateFormatted = formatDate(expirationDate, 'MMMM DD, YYYY');

  return (
    <div className="InvestmentInfo">
      <Container row className="InvestmentInfoGridContainerRow">
        <Cell
          label="Effective date"
          value={effectiveDateFormatted}
          helpText="The start of your Investment period, which is the fourth business day after you signed the Investment Documents."
        />
        <Cell label="Investment amount" value={currency(investmentAmount)} />
      </Container>

      <Container row className="InvestmentInfoGridContainerRow">
        <Cell
          label="Expiration date"
          value={expirationDateFormatted}
          helpText="The 10th anniversary of the effective date."
        />
        <Cell label="Beginning home value" value={currency(beginningHomeValue)} />
      </Container>

      {hometapShare && (
        <Container row className="InvestmentInfoGridContainerRow">
          <Cell label="Settlement date" value={formatDate(settlementDate, 'MMMM DD, YYYY')} />
          <Cell label="Anticipated home value" value={currency(appreciatedHomeValue)} />
        </Container>
      )}
    </div>
  );
};

export default InvestmentInfo;
