import { useAsync } from '@hometap/htco-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSearchParams from 'hooks/useSearchParams';
import { apiWithAuth } from 'utils/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useLocalState from 'apps/htdc/utils/useLocalState';
import { COLOR_ORDER } from '../constants/smartFactConstants';
import useSessionStorage from 'hooks/useSessionStorage';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { useCurrentHome } from 'hooks/useCurrentHome';

const isLessThan24Hours = dateStr1 => {
  if (!dateStr1) {
    return false;
  }
  const date1 = new Date(dateStr1);
  const date2 = new Date();

  // Calculate the difference in milliseconds
  const diff = date2.getTime() - date1.getTime();

  // Check if the difference is more than 24 hours
  return diff < 24 * 60 * 60 * 1000;
};

const fetchSmartFacts = async (requestedSmartFacts = [], excludedSmartFacts = [], smartContexts = []) => {
  return await apiWithAuth.v1.post(`/smart-facts/`, {
    contexts: [...smartContexts],
    amount: 3,
    requested_fact_ids: [...requestedSmartFacts],
    excluded_fact_ids: [...excludedSmartFacts],
  });
};

const useSmartFacts = () => {
  const [smartCardsDate, setSmartCardsDate] = useLocalState(null, 'SmartCardDate');
  const [unhelpfulSmartFacts, setUnhelpfulSmartFacts] = useLocalState([], 'UnhelpfulSmartFacts');
  // Track the previous list of smart facts so we can compare it to what is in the local state
  const [prevUnhelpfulSmartFacts, setPrevUnhelpfulSmartFacts] = useState(unhelpfulSmartFacts);
  const { setupProfileLater } = useDelayHomeEquityProfile();
  const { home } = useCurrentHome();
  const { initialScenario } = useEquityScenarioContext();

  const { showSmartCards } = useFlags();
  const {
    error: smartFactsError,
    loading: smartFactsLoading,
    execute,
  } = useAsync(fetchSmartFacts, { immediate: false });

  const recentlyViewedSmartFacts = isLessThan24Hours(smartCardsDate);
  const [sessionSmartFacts, setSessionSmartFacts] = useSessionStorage(`SessionSmartFacts-${home?.id}`);

  useEffect(() => {
    if (!home?.id) return;
    const sessionStorageSmartFacts = sessionStorage.getItem(`SessionSmartFacts`);
    if (!sessionStorageSmartFacts || sessionStorageSmartFacts === 'undefined') return;
    setSessionSmartFacts(JSON.parse(sessionStorageSmartFacts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home?.id]);

  const query = useSearchParams();
  const requestedFactIds = query.get('hometap_insight');

  const unhelpfulSmartFactsChanged = unhelpfulSmartFacts.length !== prevUnhelpfulSmartFacts.length;
  const shouldRefetchSmartFacts = !recentlyViewedSmartFacts || unhelpfulSmartFactsChanged;

  const fetchAndStoreSmartFacts = useCallback(
    async ({ homeId, scenarioId, unhelpfulSmartFactsChanged }) => {
      if (!homeId || !scenarioId) return;

      const requestedSmartFacts = requestedFactIds?.split(',').map(smartFact => smartFact.trim());

      // If a user has not recently viewed smart facts OR the unhelpful smart facts list has
      // changed, fetch the new smart facts with exclusions
      if (showSmartCards && shouldRefetchSmartFacts) {
        const updatedSmartFacts = await execute(requestedSmartFacts, unhelpfulSmartFacts, [
          { kind: 'system' },
          { kind: 'user' },
          { kind: 'home', id: homeId },
          { kind: 'equity_scenario', id: scenarioId },
        ]);

        setSessionSmartFacts(updatedSmartFacts);
        // After updating the smart facts list, update the previous unhelpful smart facts reference
        // with the latest list from our local storage
        if (unhelpfulSmartFactsChanged) {
          setPrevUnhelpfulSmartFacts(unhelpfulSmartFacts);
        }
      }
    },
    [
      execute,
      requestedFactIds,
      setSessionSmartFacts,
      setPrevUnhelpfulSmartFacts,
      shouldRefetchSmartFacts,
      showSmartCards,
      unhelpfulSmartFacts,
    ],
  );

  // Fetch smart facts
  useEffect(() => {
    // Don't grab smart facts before the build home profile is completed
    if (!home?.hed_debts_confirmed || setupProfileLater) return;
    // We want to know if there are new unhelpful smart facts in the local state
    if (sessionSmartFacts && !unhelpfulSmartFactsChanged) return;

    fetchAndStoreSmartFacts({ unhelpfulSmartFactsChanged, homeId: home?.id, scenarioId: initialScenario?.id });
  }, [
    sessionSmartFacts,
    showSmartCards,
    recentlyViewedSmartFacts,
    unhelpfulSmartFacts,
    home?.hed_debts_confirmed,
    home?.id,
    initialScenario?.id,
    prevUnhelpfulSmartFacts.length,
    fetchAndStoreSmartFacts,
    unhelpfulSmartFactsChanged,
    setupProfileLater,
  ]);

  useEffect(() => {
    // Clear smartFacts response stored in session storage if user has recently viewed them
    if (recentlyViewedSmartFacts) {
      setSessionSmartFacts(undefined);
      return;
    }
  }, [recentlyViewedSmartFacts, setSessionSmartFacts]);

  const smartFacts = useMemo(
    () =>
      (sessionSmartFacts || []).map((smartFact, i) => {
        const hasCta = !!(smartFact?.cta?.text && smartFact?.cta?.url);

        return {
          id: smartFact?.id,
          theme: COLOR_ORDER[i % COLOR_ORDER.length],
          header: smartFact?.fact,
          hasCta: hasCta,
          ctaText: smartFact?.cta?.text,
          onCtaClick: () => window.open(smartFact?.cta?.url, '_blank'), // maybe incorporate react router history
          onHelpful: () => {},
          onUnhelpful: (event, newUnhelpfulFact) => {
            if (!unhelpfulSmartFacts.includes(newUnhelpfulFact)) {
              setUnhelpfulSmartFacts([...unhelpfulSmartFacts, newUnhelpfulFact]);
            }
          },
          ...smartFact,
        };
      }),
    [sessionSmartFacts, setUnhelpfulSmartFacts, unhelpfulSmartFacts],
  );

  return {
    smartFacts,
    smartFactsError,
    smartFactsLoading,
    smartCardsDate,
    setSmartCardsDate,
    recentlyViewedSmartFacts,
    updateSmartFacts: fetchAndStoreSmartFacts,
  };
};

export default useSmartFacts;
