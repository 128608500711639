import { Modal } from '@hometap/htco-components';
import { APPLICANT_FORM_FIELD } from 'apps/application/constants/formFields';

const ApplicantInProgressWarningModal = ({ isOpen, onClose, applicant }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      {ariaProps => {
        const warningHeaderBase = applicant[APPLICANT_FORM_FIELD.firstName]
          ? `${applicant.first_name}'s`
          : 'You still have an applicant whose';

        return (
          <>
            <b id={ariaProps.label}>{warningHeaderBase} application is still in progress.</b>
            <div id={ariaProps.description}>
              Please make sure to finish filling out the application for{' '}
              {applicant?.[APPLICANT_FORM_FIELD.firstName] || 'the in progress applicant.'}
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default ApplicantInProgressWarningModal;
