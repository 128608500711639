import { getPortalsUrl } from './links';
// Cypress makes it difficult to test javascript redirects
// https://docs.cypress.io/guides/guides/web-security.html#JavaScript-Redirects
// So this lets us stub and test window functions
window.api = {
  redirect: url => {
    window.location.replace(url);
  },
};

window.htap = {
  redirectToTrackInnerIndex: track => {
    window.location.assign(getPortalsUrl(`/track/${track.id}`));
    return null;
  },
  redirectToTrackStage: (trackId, stagePath = '') => {
    window.location.assign(getPortalsUrl(`/track/${trackId}/${stagePath}`));
    return null;
  },
  redirectToReInquiryReview: key => {
    window.location.assign(`${window.location.origin}/re-inquiry/${key}`);
    return null;
  },

  openNewTab: url => {
    window.open(url, '_blank');
    return null;
  },
};
