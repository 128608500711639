import { useHistory } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import { LOCAL_STORAGE_LATER_KEY } from 'apps/dashboard/constants';
import { useCurrentHome } from 'hooks/useCurrentHome';

const useDelayHomeEquityProfile = () => {
  const history = useHistory();
  const { home } = useCurrentHome();
  // the following methods are for handling the delay of build profile setup by using local storage variables.
  // this can be improved upon by storing on the backend.
  const handleSetupProfileLater = () => {
    const setupProfileLaterIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_KEY) || '[]');
    if (home?.id && !setupProfileLaterIds.includes(home.id)) {
      setupProfileLaterIds.push(home.id);
    }
    localStorage.setItem(LOCAL_STORAGE_LATER_KEY, JSON.stringify(setupProfileLaterIds));
    history.push(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`);
  };

  const removeDelayLocalStorageKey = homeId => {
    const setupProfileLaterIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_KEY) || '[]');
    const updatedIds = setupProfileLaterIds.filter(id => id !== homeId);
    localStorage.setItem(LOCAL_STORAGE_LATER_KEY, JSON.stringify(updatedIds));
  };
  const setupProfileLaterLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_KEY) || '[]');
  const setupProfileLaterHomeMatch = setupProfileLaterLocalStorage?.find(id => id === home?.id);
  const setupProfileLater = !home?.hed_debts_confirmed && setupProfileLaterHomeMatch;

  return {
    setupProfileLater,
    handleSetupProfileLater,
    removeDelayLocalStorageKey,
  };
};

export default useDelayHomeEquityProfile;
