export const ValueChartHomeIcon = () => {
  return (
    <path
      d="M24.1666 14.1474L19.1667 10.0101C18.7083 9.62311 18.115 9.40918 17.5 9.40918C16.8851 9.40918 16.2917 9.62311 15.8334 10.0101L10.8334 14.1474C10.5687 14.3708 10.3575 14.6449 10.2138 14.9514C10.0701 15.2579 9.99726 15.5898 10.0001 15.925V22.7995C10.0001 23.4253 10.2635 24.0255 10.7323 24.468C11.2011 24.9106 11.837 25.1592 12.5001 25.1592H22.5C23.163 25.1592 23.7989 24.9106 24.2677 24.468C24.7366 24.0255 25 23.4253 25 22.7995V15.9171C25.0016 15.5833 24.9282 15.2529 24.7845 14.9478C24.6409 14.6427 24.4303 14.3699 24.1666 14.1474ZM19.1667 23.5861H15.8334V19.6533C15.8334 19.4447 15.9212 19.2446 16.0774 19.0971C16.2337 18.9496 16.4457 18.8667 16.6667 18.8667H18.3334C18.5544 18.8667 18.7663 18.9496 18.9226 19.0971C19.0789 19.2446 19.1667 19.4447 19.1667 19.6533V23.5861ZM23.3333 22.7995C23.3333 23.0081 23.2455 23.2082 23.0892 23.3557C22.933 23.5032 22.721 23.5861 22.5 23.5861H20.8333V19.6533C20.8333 19.0274 20.5699 18.4272 20.1011 17.9847C19.6323 17.5422 18.9964 17.2936 18.3334 17.2936H16.6667C16.0037 17.2936 15.3678 17.5422 14.8989 17.9847C14.4301 18.4272 14.1667 19.0274 14.1667 19.6533V23.5861H12.5001C12.279 23.5861 12.0671 23.5032 11.9108 23.3557C11.7545 23.2082 11.6667 23.0081 11.6667 22.7995V15.9171C11.6669 15.8054 11.6922 15.6951 11.7411 15.5933C11.7899 15.4916 11.8612 15.4009 11.9501 15.3272L16.95 11.1978C17.1021 11.0717 17.2976 11.0021 17.5 11.0021C17.7024 11.0021 17.8979 11.0717 18.05 11.1978L23.05 15.3272C23.1389 15.4009 23.2101 15.4916 23.259 15.5933C23.3078 15.6951 23.3332 15.8054 23.3333 15.9171V22.7995Z"
      fill="white"
    />
  );
};

export const ValueChartInvestmentIcon = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9928 22.1877V21.0043C18.667 20.9254 19.2085 20.6923 19.6173 20.305C20.0262 19.9176 20.2306 19.4371 20.2306 18.8633C20.2306 18.4401 20.1266 18.0976 19.9186 17.8358C19.7106 17.574 19.4506 17.375 19.1386 17.2387C18.8266 17.1024 18.4339 16.9769 17.9605 16.8622V13.9681C18.6706 14.047 19.2874 14.3375 19.811 14.8395L20.1445 14.1617C19.8791 13.8963 19.5582 13.6812 19.1816 13.5162C18.805 13.3512 18.4088 13.2508 17.9928 13.215V12.0315H17.2396V13.2042C16.7806 13.2472 16.3754 13.3674 16.0239 13.5646C15.6725 13.7619 15.3981 14.0183 15.2009 14.3339C15.0036 14.6495 14.905 15.0045 14.905 15.399C14.905 15.8508 15.0144 16.2148 15.2331 16.491C15.4519 16.7671 15.7227 16.9769 16.0454 17.1204C16.3682 17.2638 16.777 17.4001 17.2719 17.5292V20.2619C16.8631 20.2404 16.4847 20.1579 16.1369 20.0145C15.789 19.871 15.4429 19.6523 15.0987 19.3582L14.7651 20.036C15.0807 20.3301 15.4483 20.5596 15.8679 20.7246C16.2875 20.8895 16.7447 20.9863 17.2396 21.015V22.1877H17.9928ZM17.2719 16.69C16.7985 16.5609 16.4363 16.3995 16.1853 16.2059C15.9343 16.0122 15.8087 15.7325 15.8087 15.3667C15.8087 14.9722 15.9378 14.6512 16.196 14.4038C16.4543 14.1563 16.8129 14.0111 17.2719 13.9681V16.69ZM17.9605 17.7013V20.2404C18.398 20.183 18.7369 20.0378 18.9772 19.8047C19.2175 19.5716 19.3376 19.265 19.3376 18.8848C19.3376 18.5692 19.2193 18.3236 18.9826 18.1478C18.7459 17.9721 18.4052 17.8233 17.9605 17.7013Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6558 17.5002C27.6558 11.8911 23.1087 7.34399 17.4995 7.34399C11.8904 7.34399 7.34326 11.8911 7.34326 17.5002C7.34326 23.1094 11.8904 27.6565 17.4995 27.6565C23.1087 27.6565 27.6558 23.1094 27.6558 17.5002ZM8.12451 17.5002C8.12451 12.3226 12.3218 8.12524 17.4995 8.12524C22.6772 8.12524 26.8745 12.3226 26.8745 17.5002C26.8745 22.6779 22.6772 26.8752 17.4995 26.8752C12.3218 26.8752 8.12451 22.6779 8.12451 17.5002Z"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9928 22.1877V21.0043C18.667 20.9254 19.2085 20.6923 19.6173 20.305C20.0262 19.9176 20.2306 19.4371 20.2306 18.8633C20.2306 18.4401 20.1266 18.0976 19.9186 17.8358C19.7106 17.574 19.4506 17.375 19.1386 17.2387C18.8266 17.1024 18.4339 16.9769 17.9605 16.8622V13.9681C18.6706 14.047 19.2874 14.3375 19.811 14.8395L20.1445 14.1617C19.8791 13.8963 19.5582 13.6812 19.1816 13.5162C18.805 13.3512 18.4088 13.2508 17.9928 13.215V12.0315H17.2396V13.2042C16.7806 13.2472 16.3754 13.3674 16.0239 13.5646C15.6725 13.7619 15.3981 14.0183 15.2009 14.3339C15.0036 14.6495 14.905 15.0045 14.905 15.399C14.905 15.8508 15.0144 16.2148 15.2331 16.491C15.4519 16.7671 15.7227 16.9769 16.0454 17.1204C16.3682 17.2638 16.777 17.4001 17.2719 17.5292V20.2619C16.8631 20.2404 16.4847 20.1579 16.1369 20.0145C15.789 19.871 15.4429 19.6523 15.0987 19.3582L14.7651 20.036C15.0807 20.3301 15.4483 20.5596 15.8679 20.7246C16.2875 20.8895 16.7447 20.9863 17.2396 21.015V22.1877H17.9928ZM17.2719 16.69C16.7985 16.5609 16.4363 16.3995 16.1853 16.2059C15.9343 16.0122 15.8087 15.7325 15.8087 15.3667C15.8087 14.9722 15.9378 14.6512 16.196 14.4038C16.4543 14.1563 16.8129 14.0111 17.2719 13.9681V16.69ZM17.9605 17.7013V20.2404C18.398 20.183 18.7369 20.0378 18.9772 19.8047C19.2175 19.5716 19.3376 19.265 19.3376 18.8848C19.3376 18.5692 19.2193 18.3236 18.9826 18.1478C18.7459 17.9721 18.4052 17.8233 17.9605 17.7013Z"
        stroke="white"
        strokeWidth="0.7"
      />
    </>
  );
};

export const ValueChartRenovationIcon = () => {
  return (
    <g transform="translate(8, 9)">
      <path
        d="M14.2775 2.63176L11 5.90926L9.8075 4.71676C9.52646 4.43739 9.14628 4.28057 8.75 4.28057C8.35372 4.28057 7.97354 4.43739 7.6925 4.71676L6.5 5.90926L13.25 12.6593L14.4425 11.4668C14.7219 11.1857 14.8787 10.8055 14.8787 10.4093C14.8787 10.013 14.7219 9.63281 14.4425 9.35176L13.25 8.15926L16.5275 4.88176C16.8259 4.58339 16.9935 4.17872 16.9935 3.75676C16.9935 3.33481 16.8259 2.93013 16.5275 2.63176C16.2291 2.33339 15.8245 2.16577 15.4025 2.16577C14.9805 2.16577 14.5759 2.33339 14.2775 2.63176Z"
        fill="#366CED"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 6.65918C5.75 8.90918 4.25 9.28418 2 9.65918L8 17.1592C9.5 16.4092 12.5 13.4092 12.5 11.9092"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.375 13.7842L3.875 11.9092" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  );
};
