import { groupBy } from 'lodash';

import TextHighlighter from 'components/TextHighlighter';
import HouseCanaryHELOCForm from './forms/HouseCanaryHELOCForm';
import HouseCanaryHELoanForm from './forms/HouseCanaryHELoanForm';
import HouseCanaryJudgementOrOtherForm from './forms/HouseCanaryJudgementOrOtherForm';
import HouseCanaryMortgageForm from './forms/HouseCanaryMortgageForm';

import { HOUSE_CANARY_LIEN_TYPE, EQUITY_SCENARIO_LIEN_TYPE } from '../../constants';

const SHARED_SUBTITLE = 'Please review and make necessary updates to the below details before you continue.';

const houseCanaryLienForms = {
  [HOUSE_CANARY_LIEN_TYPE.MORTGAGE]: {
    form: HouseCanaryMortgageForm,
    title: <TextHighlighter text="Review your mortgage details" highlight="mortgage" />,
    relativeScenarioLienType: EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE,
    skipButtonText: "I don't have a mortgage",
    subtitle:
      "We've added the below information based on available data about your home. Please make any necessary updates to ensure a complete and accurate picture of your home equity.",
  },
  [HOUSE_CANARY_LIEN_TYPE.HOME_EQUITY_LOAN]: {
    form: HouseCanaryHELoanForm,
    title: <TextHighlighter text="We found a home equity loan on your property" highlight="home equity loan" />,
    relativeScenarioLienType: EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN,
    skipButtonText: "I don't have a home equity loan",
    subtitle: SHARED_SUBTITLE,
  },
  [HOUSE_CANARY_LIEN_TYPE.HELOC]: {
    form: HouseCanaryHELOCForm,
    title: (
      <TextHighlighter
        text="We found a home equity line of credit on your property"
        highlight="home equity line of credit"
      />
    ),
    relativeScenarioLienType: EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT,
    skipButtonText: "I don't have a HELOC",
    subtitle: SHARED_SUBTITLE,
  },
  [HOUSE_CANARY_LIEN_TYPE.JUDGEMENT_TAX_OTHER]: {
    form: HouseCanaryJudgementOrOtherForm,
    title: <TextHighlighter text="We found another type of debt on your property" highlight="another type of debt" />,
    relativeScenarioLienType: EQUITY_SCENARIO_LIEN_TYPE.OTHER,
    skipButtonText: "I don't have this debt",
    subtitle:
      'This could be a home judgment, tax, or other lien. Please review the below details and make any necessary updates before you continue.',
  },
};

/**
 * Returns the starting point for the house canary lien forms. If the homeowner has not skipped any
 * liens we start at the 0th index. If they've skipped liens in between the last lien we return the
 * last found. Finally, if the homeowner skipped the second to last lien we return the the index of
 * the last element in the array.
 *
 * @example
 * hcLiensTypes = ['mortgage', 'home_equity_loan', 'heloc', 'judgement_tax_other']
 * scenarioLiensTypes = []
 * getHCFormsStartingPoint(-1, hcLiensTypes) // 0
 *
 * hcLiensTypes = ['mortgage', 'home_equity_loan', 'heloc', 'judgement_tax_other']
 * scenarioLiensTypes = ['heloc']
 * getHCFormsStartingPoint(4, hcLiensTypes) // 3
 *
 * hcLiensTypes = ['mortgage', 'home_equity_loan', 'heloc', 'judgement_tax_other']
 * scenarioLiensTypes = ['home_equity_loan']
 * getHCFormsStartingPoint(1, hcLiensTypes) // 1
 */
const getHCFormsStartingPoint = (lastRelevantLienTypeIndex, liens) => {
  if (lastRelevantLienTypeIndex === -1) return 0;
  if (lastRelevantLienTypeIndex >= liens.length) return liens.length - 1;
  return lastRelevantLienTypeIndex;
};

export const getHouseCanaryLienForms = (hcLiens = [], scenarioLiens) => {
  const groupedHouseCanaryLiens = groupBy(hcLiens, 'lien_type');
  const groupedScenarioLiens = groupBy(scenarioLiens, 'lien_type');

  // if we have didn't create an equity scenario lien after a particular house canary lien type, we assume
  // we skipped the hc lien.
  const lastRelevantLienTypeIndex = Object.values(houseCanaryLienForms).findLastIndex(({ relativeScenarioLienType }) =>
    Boolean(groupedScenarioLiens[relativeScenarioLienType]),
  );

  const filteredHCLienForms = Object.keys(houseCanaryLienForms).filter(key => {
    const form = houseCanaryLienForms[key];
    return Boolean(!groupedScenarioLiens[form.relativeScenarioLienType]) && key in groupedHouseCanaryLiens;
  });
  const startingPoint = getHCFormsStartingPoint(lastRelevantLienTypeIndex, filteredHCLienForms);

  const orderedLiens = filteredHCLienForms
    .map(key => ({
      ...houseCanaryLienForms[key],
      liens: groupedHouseCanaryLiens[key] || [],
    }))
    .slice(startingPoint, filteredHCLienForms.length);

  return orderedLiens;
};
