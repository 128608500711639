import '@hometap/htco-components/index.css';
import './styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

export const IDLE_WARNING_MODAL_CONTAINER = 'IdleWarningModalContainer';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <div id={IDLE_WARNING_MODAL_CONTAINER} />
  </React.StrictMode>,
  document.getElementById('root'),
);
