import { useEffect } from 'react';
import { useAsync } from '@hometap/htco-components';

import { fetchScenarios, fetchScenarioCalculation } from './scenarioRequests';
import { fetchShareFraction } from './rundownRequests';
import { hardcodedAppreciation } from '../utils/sliderUtils';

const SLIDER_NETWORK_CALL_DEBOUNCE_RATE = 250; // milliseconds

const useScenarioCalculator = ({
  type,
  rundownId,
  startingHomeValue,
  investmentAmount,
  investmentLength,
  investmentTermUnit,
}) => {
  const fetchScenariosAsync = useAsync(fetchScenarios);
  const {
    execute: executeFetchScenarioCalculation,
    loading: isFetchingScenarioCalculation,
    results: fetchScenarioCalculationResults,
    error: fetchScenarioCalculationError,
  } = useAsync(fetchScenarioCalculation);

  const {
    execute: executeFetchShareFraction,
    loading: isFetchingShareFraction,
    results: fetchShareFractionResults,
    error: fetchShareFractionError,
  } = useAsync(fetchShareFraction);

  const getAllScenarios = async () => {
    await fetchScenariosAsync.execute(type, rundownId, { investmentAmount, investmentLength, investmentTermUnit });
  };

  useEffect(() => {
    if (rundownId !== null && investmentAmount !== null) {
      const timeout = setTimeout(() => {
        // noinspection JSIgnoredPromiseFromCall
        getAllScenarios();
      }, SLIDER_NETWORK_CALL_DEBOUNCE_RATE);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [rundownId, investmentAmount, investmentLength]);

  useEffect(() => {
    if (startingHomeValue !== null && investmentAmount !== null) {
      const timeout = setTimeout(() => {
        const requestBody = {
          investment_amount: investmentAmount,
          effective_period: {
            unit: investmentTermUnit,
            length: investmentLength,
          },
          appreciation_percent: hardcodedAppreciation,
        };
        executeFetchScenarioCalculation(type, rundownId, requestBody, { investment_amount: investmentAmount });
      }, SLIDER_NETWORK_CALL_DEBOUNCE_RATE);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [startingHomeValue, investmentAmount, investmentLength]);

  useEffect(() => {
    if (rundownId !== null && startingHomeValue !== null && investmentAmount !== null) {
      const timeout = setTimeout(() => {
        executeFetchShareFraction(rundownId, type, investmentAmount, startingHomeValue);
      }, SLIDER_NETWORK_CALL_DEBOUNCE_RATE);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [rundownId, type, startingHomeValue, investmentAmount]);

  return {
    scenarios: fetchScenariosAsync.results,
    scenarioCalculation: fetchScenarioCalculationResults || {},
    isFetchingScenarioCalculation,
    fetchScenarioCalculationError,

    homeShareRatios: fetchShareFractionResults,
    isFetchingHomeShareRatios: isFetchingShareFraction,
    isErrorFetchingHomeShareRatios: fetchShareFractionError,
  };
};

export default useScenarioCalculator;
