import React from 'react';

import './DebtDisclaimer.scss';

const DebtDisclaimer = () => {
  return (
    <div className="DebtCalcDisclaimer">
      The estimate above is based solely on information you provided, is to be used for illustrative purposes only, and
      is not intended as financial advice. Consult a qualified financial advisor about your exact circumstances.
    </div>
  );
};

export default DebtDisclaimer;
