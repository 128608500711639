export const STAGES = {
  APP_READY: 'ApplicationReady',
  APP_IN_PROGRESS: 'ApplicationInProgress',
  APP_IN_REVIEW: 'ApplicationInReview',
  APP_APPROVED: 'ApplicationApproved',
  APP_REJECTED: 'ApplicationRejected',
  OFFER_PREPARED: 'InitialOfferPrepared',
  OFFER_READY: 'OfferReady',
  OFFER_DECLINED: 'OfferDeclined',
  CONDITIONS_IN_REVIEW: 'ConditionsInReview',
  CLOSING_IN_PROGRESS: 'ClosingInProgress',
  INVESTED: 'Invested',
  SETTLED: 'Settled',
  CLOSED_LOST: 'ClosedLost',
  INQUIRY_DQ: 'InquiryDisqualified',
  INQUIRY_REVIEW: 'InquiryInReview',
};

export const PRIMARY_STAGE_CTA_MAPPING = {
  [STAGES.APP_READY]: 'Start your Application',
  [STAGES.APP_IN_PROGRESS]: 'Finish your Application',
  [STAGES.APP_IN_REVIEW]: 'Check Application status',
  [STAGES.OFFER_READY]: 'View Offer',
  [STAGES.INVESTED]: 'Settle your Investment',
};

export const INVESTMENTS_LANDING_PAGE_STAGES = [
  STAGES.APP_READY,
  STAGES.APP_IN_PROGRESS,
  STAGES.APP_IN_REVIEW,
  STAGES.APP_APPROVED,
  STAGES.OFFER_PREPARED,
  STAGES.OFFER_READY,
  STAGES.CONDITIONS_IN_REVIEW,
  STAGES.CLOSING_IN_PROGRESS,
];

export const HOME_EQUITY_LANDING_PAGE_STAGES = [
  STAGES.INVESTED,
  STAGES.SETTLED,
  STAGES.CLOSED_LOST,
  STAGES.OFFER_DECLINED,
];

export const MEMBERSHIP_STATUS_MAPPING = {
  exceeded: 'Exceeded Verification Attempts',
  pending: 'Pending Verification',
};

const APP_IN_REVIEW_LABEL = 'Application in Review';
const AWAITING_FUNDS_LABEL = 'Awaiting Funds';

export const STAGE_DISPLAYS = {
  [STAGES.APP_READY]: 'Ready to Apply',
  [STAGES.APP_IN_PROGRESS]: 'Application in Progress',
  [STAGES.APP_IN_REVIEW]: APP_IN_REVIEW_LABEL,
  [STAGES.APP_APPROVED]: APP_IN_REVIEW_LABEL,
  [STAGES.OFFER_PREPARED]: APP_IN_REVIEW_LABEL,
  [STAGES.OFFER_READY]: 'Ready to Sign',
  [STAGES.OFFER_DECLINED]: 'Investment Offer Declined',
  [STAGES.CONDITIONS_IN_REVIEW]: AWAITING_FUNDS_LABEL,
  [STAGES.CLOSING_IN_PROGRESS]: AWAITING_FUNDS_LABEL,
  [STAGES.INVESTED]: 'Invested',
  [STAGES.SETTLED]: 'Settled',
  [STAGES.CLOSED_LOST]: 'Closed',
};

export const COOKIES = {
  DISABLE_PROPERTY_DATA: 'ht_disable_property_data',
  DISABLE_PROPERTY_MODAL: 'ht_disable_property_modal',
};
