import { uniq } from 'lodash';
import { useHistory, useLocation, matchPath, useRouteMatch } from 'react-router-dom';

import {
  getApplicantFormKey,
  APPLICATION_CONFIG,
  APPLICATION_SECTION_URL_NAME,
  filterConditionalPages,
  buildApplicationRoute,
  hasInMemoryApplicant,
  getFullApplicationPath,
} from '../utils';
import useApplicationContext from './useApplicationContext';

import { APPLICATION_MODEL_FIELD } from '../constants/applicationDataFields';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const ApplicationNavContext = createContext();

export const ApplicationNavProvider = ({ children }) => {
  const { applicants, applicationFormData, setSelectedApplicantForDeletion, track, application } =
    useApplicationContext();
  const history = useHistory();

  const loc = useLocation();
  const { path } = useRouteMatch();
  const match = matchPath(loc.pathname, { path: getFullApplicationPath(path) });

  const { section, page, applicantId } = match?.params;

  const [activePages, setActivePages] = useState();
  const [nextAnticipatedPage, setNextAnticipatedPage] = useState(null);
  const [shouldDisableOtherSections, setShouldDisableOtherSections] = useState(false);

  const hasApplicationChanged = Object.keys(applicationFormData?.dirtyFields || {})?.length;

  useEffect(() => {
    if (!applicantId && hasInMemoryApplicant(applicants)) {
      setShouldDisableOtherSections(true);
    } else if (section === APPLICATION_SECTION_URL_NAME.property && hasApplicationChanged) {
      setShouldDisableOtherSections(true);
    } else {
      setShouldDisableOtherSections(false);
    }
  }, [applicantId, applicants, hasApplicationChanged, section]);

  const applicantConfig = filterConditionalPages(
    APPLICATION_CONFIG[APPLICATION_SECTION_URL_NAME.applicants],
    applicationFormData,
  );
  const propertyConfig = filterConditionalPages(
    APPLICATION_CONFIG[APPLICATION_SECTION_URL_NAME.property],
    applicationFormData,
  );
  const consentConfig = filterConditionalPages(
    APPLICATION_CONFIG[APPLICATION_SECTION_URL_NAME.consent],
    applicationFormData,
  );

  const applicantSectionLinks = applicants.map((applicant, i) => {
    const applicantFormData = applicationFormData[getApplicantFormKey(applicant.id)];
    const applicantFirstName = applicantFormData?.first_name || applicant.first_name || 'Applicant';

    const items = applicantConfig.map(({ pageKey, navLabel }, i) => {
      const url = buildApplicationRoute(track?.id)({
        section: APPLICATION_SECTION_URL_NAME.applicants,
        pageKey,
        applicantId: applicant.id || '',
      });
      return { navLabel, page: pageKey, key: `${pageKey}-${i}`, to: url };
    });

    const nextAnticipatedUrlIndex = items.findIndex(item => item.to === nextAnticipatedPage);

    return {
      sectionKind: APPLICATION_SECTION_URL_NAME.applicants,
      sectionLabel: `About ${applicantFirstName}`,
      id: applicant?.id,
      key: applicant?.id || `applicantSection-${i}`,
      disabled: Boolean(shouldDisableOtherSections && applicant?.id),
      icon: !applicantFormData?.isPrimaryApplicant && {
        action: () => setSelectedApplicantForDeletion(applicant),
        name: 'bin',
        color: '#b41a1a',
      },
      navItems: items.map((item, i) => {
        // Disable nav items only for a new applicant.
        // Don't disable the first page in a section.
        // Disable pages that have not yet been viewed
        // If the current form has errors then prevent from going ahead to previously viewed pages.
        const canViewAhead = activePages?.includes(item.to) && applicantFormData?.isCurrentFormValid;
        const isEnabled = !!applicant?.id || i === 0 || i < nextAnticipatedUrlIndex || canViewAhead;
        return { ...item, disabled: !isEnabled };
      }),
    };
  });

  const address = track?.home?.address?.street;
  const propertySectionLinks = useMemo(
    () => ({
      sectionKind: APPLICATION_SECTION_URL_NAME.property,
      sectionLabel: `About ${address}`,
      disabled:
        !application?.[APPLICATION_MODEL_FIELD.hasSubmittedApplicants] ||
        (shouldDisableOtherSections && section !== APPLICATION_SECTION_URL_NAME.property),
      key: address,
      navItems: propertyConfig.map(({ pageKey, navLabel }, i) => {
        const url = buildApplicationRoute(track?.id)({
          section: APPLICATION_SECTION_URL_NAME.property,
          pageKey,
        });

        const isEnabled =
          i === 0 || activePages?.includes(url) || application?.[APPLICATION_MODEL_FIELD.hasSubmittedLiens];

        return {
          key: pageKey,
          page: pageKey,
          navLabel,
          to: url,
          disabled: !isEnabled,
        };
      }),
    }),
    [address, application, shouldDisableOtherSections, propertyConfig, track?.id, activePages, section],
  );

  const consentSectionLinks = useMemo(
    () => ({
      sectionKind: APPLICATION_SECTION_URL_NAME.consent,
      sectionLabel: 'Accept Terms and Submit',
      disabled: !application?.[APPLICATION_MODEL_FIELD.hasSubmittedLiens] || shouldDisableOtherSections,
      key: APPLICATION_SECTION_URL_NAME.consent,
      navItems: consentConfig.map(({ pageKey, navLabel }, i) => {
        return {
          key: pageKey,
          page: pageKey,
          navLabel: navLabel,
          to: buildApplicationRoute(track?.id)({
            section: APPLICATION_SECTION_URL_NAME.consent,
            pageKey: pageKey,
          }),
          disabled: false,
        };
      }),
    }),
    [application, track?.id, shouldDisableOtherSections, consentConfig],
  );

  const allSectionLinks = useMemo(
    () => [...applicantSectionLinks, propertySectionLinks, consentSectionLinks],
    [propertySectionLinks, applicantSectionLinks, consentSectionLinks],
  );

  const currentSectionIdx = allSectionLinks.findIndex(({ sectionKind, id }) => {
    return sectionKind === section && (id === applicantId || section !== APPLICATION_SECTION_URL_NAME.applicants);
  });
  const currentSection = allSectionLinks[currentSectionIdx];
  const pageIndex = currentSection?.navItems?.findIndex(link => link.page === page);
  const nextPage = currentSection?.navItems[pageIndex + 1];

  useEffect(() => {
    allSectionLinks.forEach(link => {
      link.navItems.forEach((item, i) => {
        if (item.to === loc.pathname) {
          setNextAnticipatedPage(link.navItems[i + 1]?.to);
        }
      });
    });
  }, [loc.pathname, setNextAnticipatedPage, allSectionLinks, nextAnticipatedPage, nextPage]);

  const lastSection = allSectionLinks[allSectionLinks.length - 1];
  const lastPageOfApplicationLink = lastSection.navItems[lastSection?.navItems.length - 1].to;
  const isLastPageOfApplication = useRouteMatch(lastPageOfApplicationLink);

  const contextData = {
    allSectionLinks,
    nextPage,
    isLastPageInSection: !nextPage,
    isLastPageOfApplication,
    currentPageIsDisabled: currentSection?.disabled || currentSection?.navItems[pageIndex]?.disabled,
    navigateToNewApplicant: ({ applicantId, pageKey } = {}) => {
      const nextPageRoute = buildApplicationRoute(track?.id)({
        section: APPLICATION_SECTION_URL_NAME.applicants,
        pageKey: pageKey || applicantConfig[0].pageKey,
        applicantId,
      });
      setActivePages([]);
      setNextAnticipatedPage(nextPageRoute);
      history.push(nextPageRoute);
    },
    navigateToFirstApplicant: pageKey => {
      const nextPageRoute = buildApplicationRoute(track?.id)({
        section: APPLICATION_SECTION_URL_NAME.applicants,
        pageKey: pageKey || applicantConfig[0].pageKey,
        applicantId: applicants[0].id,
      });

      history.replace(nextPageRoute);
    },
    navigateToNextPage: () => {
      let nextPageRoute = nextPage?.to;
      let newActivePages = [].concat(activePages || []);

      if (nextPage) {
        newActivePages = uniq([...newActivePages, nextPageRoute]);
      } else {
        nextPageRoute = allSectionLinks[currentSectionIdx + 1]?.navItems[0]?.to;
        newActivePages = [];
      }

      setActivePages(newActivePages);
      if (nextPageRoute) {
        setNextAnticipatedPage(nextPageRoute);
        history.push(nextPageRoute);
      } else {
        window.htap.redirectToTrackInnerIndex({ id: track.id });
      }
    },
  };

  return <ApplicationNavContext.Provider value={contextData}>{children}</ApplicationNavContext.Provider>;
};

const useApplicationNavContextContext = () => useContext(ApplicationNavContext);
export default useApplicationNavContextContext;
