import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, useAsync } from '@hometap/htco-components';
import Slide from '@mui/material/Slide';
import { buildNewHEDUrl } from 'utils/links';
import EquityRenovationDetails from './EquityRenovationDetails';
import EquityRenovationSelector from './EquityRenovationSelector';
import EquityRenovationGoals from './EquityRenovationGoals';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RenovationFinancingSection from './RenovationFinancingSection';
import Footer from 'components/Footer/Footer';
import './EquityRenovations.scss';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { saveRenovationToScenario } from './data/requests';
import { createRenovationFromTemplate, parseRenovationKind } from './data/renovationUtils';
import useAccessHomeEquityCTA from 'apps/dashboard/utils/useAccessHomeEquityCTA';
import useRenovationTemplates from './hooks/useRenovationTemplates';
import useComputedState from 'hooks/useComputedState';
import { useCurrentHome } from 'hooks/useCurrentHome';

const VIEWS = {
  SELECTOR: 'selector',
  GOAL: 'goal',
  DETAILS: 'details',
};

const EquityRenovationController = () => {
  const { project, renovationId } = useParams();
  const { showTappableEquityCard } = useFlags();
  const history = useHistory();
  const [goalQuestionShown, setGoalQuestionShown] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState('');
  const [otherGoalText, setOtherGoalText] = useState('');
  const [cardContainerHeight, setCardContainerHeight] = useState(0);
  const [showOtherGoalQuestion, setShowOtherGoalQuestion] = useState(false);
  const cardContainerRef = useRef(null);
  const selectorRef = useRef(null);
  const goalRef = useRef(null);
  const { createFutureScenario, setIsFutureView, changeScenarioRenovations, futureScenario, initialScenario } =
    useEquityScenarioContext();
  const { home } = useCurrentHome();

  let currentView = VIEWS.SELECTOR;
  const isSelectorView = !project;

  if (!goalQuestionShown && !isSelectorView && !renovationId) {
    currentView = VIEWS.GOAL;
  } else if (project) {
    currentView = VIEWS.DETAILS;
  }

  useEffect(() => {
    const calculateChildHeight = () => {
      if (currentView === VIEWS.SELECTOR && selectorRef.current) {
        setCardContainerHeight(selectorRef.current.getBoundingClientRect().height);
      } else if (currentView === VIEWS.GOAL && goalRef.current) {
        setCardContainerHeight(goalRef.current.getBoundingClientRect().height);
      }
    };

    setTimeout(() => {
      calculateChildHeight();
    }, 0);

    window.addEventListener('resize', calculateChildHeight);

    return () => {
      window.removeEventListener('resize', calculateChildHeight);
    };
  }, [currentView, cardContainerHeight, showOtherGoalQuestion]);

  const handleNavigate = useCallback(renoType => history.push(buildNewHEDUrl({ renoType })), [history]);

  const handleGoalSelection = (goal, otherText) => {
    setIsFutureView(true);
    setGoalQuestionShown(true);
    if (goal) {
      setSelectedGoal(goal);
    }
    if (otherText) {
      setOtherGoalText(otherText);
    }
  };

  const { hasTappableEquity } = useAccessHomeEquityCTA();

  const {
    loading: isRenovationTemplatesLoading,
    error: renovationTemplatesError,
    getRenovationProjectOptions,
    getRenovationTemplatesByProject,
    matchTemplateForRenovation,
  } = useRenovationTemplates(home?.address?.state);
  const projectTemplates = useMemo(
    () => getRenovationTemplatesByProject(project),
    [getRenovationTemplatesByProject, project],
  );
  const [currentRenovation, setCurrentRenovation, resetCurrentRenovation] = useComputedState(() => {
    if (futureScenario && renovationId) {
      return futureScenario?.renovations?.find(({ id }) => id === renovationId);
    }

    let foundByProject;
    if (futureScenario && project) {
      foundByProject = futureScenario?.renovations?.find(
        ({ renovation_kind }) => parseRenovationKind(renovation_kind).project.toLowerCase() === project.toLowerCase(),
      );
    }

    return foundByProject || (projectTemplates && createRenovationFromTemplate(projectTemplates[0]));
  }, [futureScenario, renovationId, project, projectTemplates]);

  const renovationProjectOptions = useMemo(
    () =>
      getRenovationProjectOptions(futureScenario?.renovations || initialScenario?.renovations || [], !!renovationId),
    [futureScenario?.renovations, getRenovationProjectOptions, renovationId, initialScenario?.renovations],
  );

  const selectedTemplate = useMemo(
    () => matchTemplateForRenovation(currentRenovation),
    [currentRenovation, matchTemplateForRenovation],
  );
  const setSelectedTemplate = useCallback(
    template => setCurrentRenovation({ ...(currentRenovation || {}), ...createRenovationFromTemplate(template) }),
    [currentRenovation, setCurrentRenovation],
  );
  const handleRenoTypeChange = useCallback(
    renoType => {
      resetCurrentRenovation();
      handleNavigate(renoType);
    },
    [handleNavigate, resetCurrentRenovation],
  );

  const saveRenovation = useCallback(async () => {
    const renoBody = { ...currentRenovation };

    if (selectedGoal) {
      renoBody.renovation_goal = selectedGoal;
    }
    if (otherGoalText) {
      renoBody.renovation_goal_other = otherGoalText;
    }

    if (!futureScenario?.id) {
      await createFutureScenario(renoBody);
    } else {
      const data = await saveRenovationToScenario(home.id, futureScenario?.id, renoBody);
      if (data) {
        const renosWithoutCurrent = futureScenario?.renovations?.filter(({ id }) => id !== data.id);
        changeScenarioRenovations([...renosWithoutCurrent, data]);
      }
    }
  }, [
    currentRenovation,
    selectedGoal,
    otherGoalText,
    createFutureScenario,
    home.id,
    futureScenario,
    changeScenarioRenovations,
  ]);

  const { execute: handleSaveRenovation, loading, error } = useAsync(saveRenovation, { executeThrow: true });

  return (
    <>
      <div className="PageBodyCenteredContainer EquityRenovation">
        <div>
          <p className="EquityRenovationBack">
            <Link to={buildNewHEDUrl()} data-testid="back-to-dashboard-button">
              <strong>Back to Dashboard</strong>
            </Link>
          </p>
        </div>
      </div>

      {currentView === VIEWS.DETAILS ? (
        <div className="PageBodyCenteredContainer EquityRenovation">
          <Grid container spacing={2}>
            <div className="EquityRenovationWrapper">
              <EquityRenovationDetails
                project={project}
                currentRenovation={currentRenovation}
                renovationProjectOptions={renovationProjectOptions}
                projectTemplates={projectTemplates}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                renovationSaveError={error}
                renovationSaveLoading={loading}
                onChangeRenovation={handleRenoTypeChange}
                onSaveRenovation={handleSaveRenovation}
                selectedGoal={selectedGoal}
                otherGoalText={otherGoalText}
              />
            </div>
          </Grid>
        </div>
      ) : (
        <div
          ref={cardContainerRef}
          style={{ minHeight: cardContainerHeight }}
          className="EquityRenovationCardContainer"
        >
          <Slide
            direction="right"
            in={currentView === VIEWS.SELECTOR}
            timeout={400}
            mountOnEnter
            unmountOnExit
            appear={false}
            easing={{
              enter: 'linear',
              exit: 'linear',
            }}
            container={cardContainerRef.current}
          >
            <div className="EquityRenovationSlideCard">
              <div className="EquityRenovationWrapper" ref={selectorRef}>
                <EquityRenovationSelector
                  isLoading={isRenovationTemplatesLoading}
                  error={renovationTemplatesError}
                  renovationProjectOptions={renovationProjectOptions}
                  onSelect={handleNavigate}
                />
              </div>
            </div>
          </Slide>

          <Slide
            direction="left"
            in={currentView === VIEWS.GOAL}
            timeout={400}
            mountOnEnter
            unmountOnExit
            easing={{
              enter: 'linear',
              exit: 'linear',
            }}
            container={cardContainerRef.current}
          >
            <div className="EquityRenovationSlideCard">
              <div className="EquityRenovationWrapper" ref={goalRef}>
                <EquityRenovationGoals
                  onSelect={handleGoalSelection}
                  showOtherGoalQuestion={showOtherGoalQuestion}
                  setShowOtherGoalQuestion={setShowOtherGoalQuestion}
                />
              </div>
            </div>
          </Slide>
        </div>
      )}

      {currentView !== VIEWS.DETAILS && <Footer />}

      {currentView === VIEWS.DETAILS && (
        <>
          <div className="RenovationCalculatorDisclaimer PageBodyCenteredContainer">
            Data source: Provided by Kukun © {new Date().getFullYear()}, a leading real estate data company. Kukun uses
            data about your home's location and value to create a highly accurate cost estimate of the renovation types
            and ROI based on material type.
          </div>

          <div className="EquityRenovationSectionWrapper DashboardContainerDark">
            <RenovationFinancingSection
              isEligibleForHEI={hasTappableEquity && showTappableEquityCard}
              onSaveRenovation={handleSaveRenovation}
            />
            <Footer theme="dark" />
          </div>
        </>
      )}
    </>
  );
};

export default EquityRenovationController;
