import React from 'react';
import { Container } from '@hometap/htco-components';
import { getNewInquiryUrl } from 'utils/links';
import TrackButton from 'components/TrackButton/TrackButton';

import './DebtCTA.scss';

const DebtCTA = () => {
  return (
    <Container row justify="center">
      <Container className="DebtCalculatorCTA">
        <h2> Looking for a way to pay off debt faster?</h2>
        <p className="DebtCalculatorCTAParagraph">
          <strong>With zero interest and no monthly payments,</strong> Hometap allows you to access your home
          equity—without taking on more debt.
        </p>
        <div className="DebtCalculatorCTAButtons">
          <TrackButton label="See if I prequalify" href={getNewInquiryUrl()} />
        </div>
      </Container>
    </Container>
  );
};

export default DebtCTA;
