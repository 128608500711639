import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, SelectionSet } from '@hometap/htco-components';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { SCENARIO_PERIOD_OPTIONS } from 'apps/dashboard/constants';
import { getDateDifference } from 'utils/date';
import { currency, percent } from 'utils/numbers';
import {
  CONFIRM_DEBTS,
  LIEN_TYPE_SELECTIONS,
  MAX_LIENS,
  PROFILE_INSTANCES,
} from 'apps/dashboard/constants/buildYourProfile';
import buildProfileLandingImg from 'images/dashboard/build-profile-house-2.jpg';
import { HOMEOWNER_DASHBOARD, getNewDashboardUrl } from 'utils/links';
import './ConfirmDebts.scss';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';

const ConfirmDebts = ({
  instance,
  setCurStage,
  onNext,
  showCTA,
  title,
  description: alternateDescription,
  onSingleLienEdit,
  form,
}) => {
  const { formData, updateFormData } = form;
  const [confirming, setConfirming] = useState(false);
  const { home } = useCurrentHome();
  const { currentScenario, deleteLien, getEquityScenarioLiensAsync } = useEquityScenarioContext();
  const { investment } = useHedInvestmentContext();
  const { removeDelayLocalStorageKey } = useDelayHomeEquityProfile();
  const history = useHistory();

  const homeMutation = useCurrentHomeMutation();

  const getEquityScenarioLiens = getEquityScenarioLiensAsync?.execute;
  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;

  useEffect(() => {
    const getLiens = async () => {
      await getEquityScenarioLiens(home?.id, currentScenario?.id);
    };
    if (home?.id && currentScenario?.id) {
      getLiens();
    }
  }, [home?.id, currentScenario?.id, getEquityScenarioLiens]);

  const updateDebtsConfirmed = async () => {
    removeDelayLocalStorageKey(home?.id);
    await homeMutation.mutateAsync({ hed_debts_confirmed: true });
  };

  const handleConfirm = async () => {
    if (home.hed_debts_confirmed) {
      history.push(getNewDashboardUrl(HOMEOWNER_DASHBOARD.HOME_EQUITY));
    } else {
      setConfirming(true);
      await updateDebtsConfirmed();
    }
    setConfirming(false);
  };

  const originationDate = investment?.effective_date;
  const investmentAmount = investment?.investment_amount;
  const maturityDate = investment?.maturity_date;
  const investmentTerm = getDateDifference(originationDate, maturityDate, 'years');

  const { description, cta } = CONFIRM_DEBTS.find(item => item.instance === instance);

  const shouldDisableEdit = () => {
    if (instance === PROFILE_INSTANCES.SETUP_PROFILE) {
      return equityScenarioLiens?.length === MAX_LIENS;
    }
    return false;
  };

  const renderSelectOptions = () => {
    const options = [
      {
        icon: 'icon-house-value',
        label: 'Add debts manually',
        value: 'add-manually',
        description: 'Select each lien type on your property, provide the details, and we’ll take it from there.',
        disabled: shouldDisableEdit(),
      },
    ];
    return options;
  };

  const onSingleLienDelete = async lien => {
    updateFormData({ ...formData, liens: formData.liens.filter(item => item !== lien.lien_type) });
    await deleteLien(currentScenario?.id, lien.id);
    await getEquityScenarioLiensAsync?.execute(home?.id, currentScenario?.id);
  };

  return (
    <>
      <Grid
        className="DashboardBuildProfileConfirmDebts"
        container
        rowSpacing={{ xs: 2 }}
        columnSpacing={{ xs: 2 }}
        data-testid="debt-confirm"
      >
        <Grid sm={12} md={6}>
          <div className="DashboardBuildProfileLeftPanel">
            <h3>{title}</h3>
            <span className="DebtConfirmSubtitle">{alternateDescription || description}</span>
            <ul className="DebtConfirmationLienSummary">
              {investment && (
                <li>
                  <div className="DebtConfirmData">
                    <b>Home Equity Investment: </b>
                    {investmentTerm} years, {currency(investmentAmount)}
                  </div>
                </li>
              )}
              {equityScenarioLiens?.length > 0 &&
                equityScenarioLiens?.map(lien => {
                  const lienDict = LIEN_TYPE_SELECTIONS.find(l => l.value === lien.lien_type);
                  const term = SCENARIO_PERIOD_OPTIONS.find(l => l.value === lien.lien_kind);
                  return (
                    <li key={`${lien.lien_type}`}>
                      <div className="DebtConfirmData">
                        <b>{lienDict?.label}: </b>
                        <div>
                          {term && `${term?.label}, `}
                          {percent(lien.rate, '0.[000]')}, {currency(lien.estimated_lien_balance)},{' '}
                          {currency(lien.monthly_payment)}{' '}
                        </div>
                      </div>
                      <div className="DebtConfirmEditDelete">
                        <Button
                          className="DebtConfirmEdit"
                          theme="link"
                          data-testid="debt-confirm-edit"
                          onClick={() => onSingleLienEdit(lien)}
                        >
                          Edit
                        </Button>
                        <div className="DebtConfirmEditDeleteDivider" />
                        <Button theme="link" data-testid="debt-confirm-delete" onClick={() => onSingleLienDelete(lien)}>
                          Delete
                        </Button>
                      </div>
                    </li>
                  );
                })}
            </ul>
            {showCTA && (
              <Button loading={confirming} onClick={() => handleConfirm()} data-testid="debt-confirm-dashboard">
                {cta}
              </Button>
            )}
            <div className="DebtConfirmationDivider">
              <div className="DebtDivider" />
              <div className="DebtDividerLabel">Have another debt to add?</div>
              <div className="DebtDivider" />
            </div>
            <SelectionSet
              align="left"
              labelWidth="100%"
              name="confirmDebtCTA"
              onChange={() => {
                updateFormData({
                  ...formData,
                  liens: equityScenarioLiens?.map(lien => lien.lien_type),
                });
                setCurStage(2);
              }}
              options={renderSelectOptions()}
            />
          </div>
        </Grid>
        <Grid
          className="DashboardBuildProfileStepImage"
          sm={12}
          md={6}
          display="flex"
          justifyContent="right"
          alignItems="center"
        >
          <img className="DashboardBuildProfileImage" src={buildProfileLandingImg} alt="home-equity" />
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmDebts;
