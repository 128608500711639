import { useCallback, useEffect } from 'react';
import { Container, useAsync, useWindowSize } from '@hometap/htco-components';

import { fetchTrackRundowns } from '../../../track-details/data/trackDetailsRequests';
import useTrack from 'hooks/useTrack';
import { fetchOffer } from 'apps/offers/data/offerRequests';

import CoApplicantTodos from './components/CoApplicantTodos/CoApplicantTodos';
import TrackDetailsSidebar from '../../../track-details/components/TrackDetailsSidebar/TrackDetailsSidebar';
import TrackDetailsResourceList from '../../../track-details/components/TrackDetailsSidebar/components/TrackDetailsResourceList/TrackDetailsResourceList';
import {
  getDefaultTrackDetailsSidebarBottomComponent,
  getDefaultTrackDetailsSidebarTopComponent,
  getResourceSections,
} from '../../../track-details/utils/sidebar';

import './CoApplicantTrackDetails.scss';

const CoApplicantTrackDetails = ({ track }) => {
  const { isRolloutFlagEnabled, loading: isTrackLoading } = useTrack();
  const { results: rundowns = [], execute: executeFetchTrackRundowns } = useAsync(fetchTrackRundowns);
  const { results: offer, execute: executeFetchTrackOffer } = useAsync(fetchOffer);

  const { isScreenSize } = useWindowSize();

  const loadAsyncData = useCallback(async () => {
    const asyncs = [executeFetchTrackRundowns(track?.id)];

    if (track?.active_offer_rundown_id) {
      asyncs.push(executeFetchTrackOffer(track?.active_offer_rundown_id));
    }

    return await Promise.all(asyncs);
  }, [executeFetchTrackOffer, executeFetchTrackRundowns, track?.active_offer_rundown_id, track?.id]);

  useEffect(() => {
    if (!isTrackLoading && track && !!track.active_application_id) {
      loadAsyncData();
    }
  }, [isTrackLoading, track, loadAsyncData]);

  return (
    <Container className="TrackDetailsController">
      <div className="CoApplicantTrackDetailsSidebarContainer">
        <TrackDetailsSidebar
          topContent={getDefaultTrackDetailsSidebarTopComponent({ track, isScreenSize })}
          middleContent={
            <TrackDetailsResourceList
              resources={getResourceSections({
                rundowns,
                offer,
                documents: [],
                track,
                showDocumentsSection: false,
                isRolloutFlagEnabled,
                isScreenSize,
              })}
              track={track}
              isRolloutFlagEnabled={isRolloutFlagEnabled}
            />
          }
          bottomContent={getDefaultTrackDetailsSidebarBottomComponent({ track, isScreenSize })}
        />
      </div>
      <CoApplicantTodos track={track} rundowns={rundowns} offer={offer} />
    </Container>
  );
};

export default CoApplicantTrackDetails;
