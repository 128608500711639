import { Badge, Grid } from '@hometap/htco-components';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import { CATEGORY_COLOR_MAP } from 'apps/dashboard/constants/resources';
import ResourcesFooter from './ResourcesFooter';
import './FeaturedResources.scss';

const FeatureResourcesListItem = ({ featuredResource, addTopMargin = false }) => {
  const { category, excerpt, urlSlug } = featuredResource;
  return (
    <div className={cx('FeaturedResourcesListItem', addTopMargin && 'addTopMargin')}>
      <Badge label={category} theme={CATEGORY_COLOR_MAP[category]} className="ResourcesBadge" />
      <Link
        to={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}/${urlSlug}`}
        className="FeaturedResourcesLink"
      >
        <h4 className="FeatureResourcesListItemTitle">{excerpt}</h4>
      </Link>
      <ResourcesFooter featuredResource={featuredResource} />
    </div>
  );
};

const FeaturedResources = ({ featuredResource, resourcesList }) => {
  const { category, coverImage, excerpt, subtitle, title, urlSlug } = featuredResource;
  return (
    <Grid container rowSpacing={{ xs: 2, sm: 2 }} columnSpacing={{ xs: 2, sm: 2, md: 10, lg: 14 }}>
      <Grid sm={12} md={7}>
        <div className="FeaturedResourcesMainContainer">
          <Link
            to={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}/${urlSlug}`}
            className="FeaturedResourcesLink"
          >
            <div className="FeaturedResourcesMain">
              <img className="FeaturedResourcesFeaturedImage" src={coverImage} alt={title} />

              <Badge
                label={category}
                theme={CATEGORY_COLOR_MAP[category]}
                className={cx('ResourcesBadge', 'FeaturedResourcesBadge')}
              />

              <h2 className="FeaturedResourcesTitle">{excerpt}</h2>
              <div className="FeaturedResourcesSubtitle">{subtitle}</div>
              <ResourcesFooter featuredResource={featuredResource} />
            </div>
          </Link>
        </div>
      </Grid>
      <Grid sm={12} md={5}>
        {resourcesList.map((featuredResource, i) => {
          return (
            <FeatureResourcesListItem
              addTopMargin={i !== 0}
              featuredResource={featuredResource}
              key={`featured-resources-${title}-${i}`}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FeaturedResources;
