import cx from 'classnames';
import { Link } from 'react-router-dom-v5-compat';
import { Button, Icon } from '@hometap/htco-components';

import { mapResource } from 'apps/track-details/utils/sidebar';
import TrackDetailsSidebarIconWrapper from '../TrackDetailsSidebarIconWrapper/TrackDetailsSidebarIconWrapper';
import './TrackDetailsResourceList.scss';

const ResourceItem = ({ icon, dateCopy, description, isLocked, className, linkProps = {} }) => {
  const isInternalLink = !!linkProps.to;

  const link = (
    <Button theme="link" {...linkProps} className={cx('TrackDetailsResourceListItemDescription', linkProps.className)}>
      {description}
      {
        // TODO: This allows the `Link` component to sit on top of our styled `Button` We need to
        // update the `Button` component to remove the need for this hack
      }
      {isInternalLink && <Link className="TrackDetailsResourceListLinkMask" to={linkProps.to} />}
    </Button>
  );

  return (
    <li className={cx('TrackDetailsResourceListItem', { isLocked })}>
      {(icon || isLocked) && (
        <div>
          <TrackDetailsSidebarIconWrapper isDisabled={isLocked}>
            <Icon name={isLocked ? 'lock' : icon} />
          </TrackDetailsSidebarIconWrapper>
        </div>
      )}

      <div className={cx('TrackDetailsResourceListItemMeta', className, { isCentered: isLocked })}>
        {isLocked ? <p className="TrackDetailsResourceListItemDescription isLocked">Not yet available.</p> : link}
        {dateCopy && <small className="TrackDetailsResourceListItemDateCopy">{dateCopy}</small>}
      </div>
    </li>
  );
};

const ResourceSectionList = ({ sectionResources = [], track, sectionKey, isRolloutFlagEnabled }) => {
  return sectionResources.length
    ? sectionResources.map((sectionResource, i) => {
        const resourceItem = mapResource(sectionResource, track);
        const { icon, dateCopy, description, id, className, isVisible, linkProps } = resourceItem;
        const isSectionResourceVisible = isVisible ? isVisible({ isRolloutFlagEnabled }) : true;

        if (!isSectionResourceVisible) return null;

        return (
          <ResourceItem
            icon={icon}
            key={`${sectionResource.resourceType}-${i}`}
            dateCopy={dateCopy}
            description={description}
            id={id}
            className={className}
            linkProps={linkProps}
          />
        );
      })
    : [<ResourceItem key={`${sectionKey}-locked`} isLocked />];
};

const TrackDetailsResourceList = ({ track, resources = [], isRolloutFlagEnabled }) => {
  return (
    <>
      <div className="TrackDetailsResourceList">
        {resources.map(({ sectionHeading, sectionResources, extraContent }) => {
          return (
            <ul className="TrackDetailsResourceGroup" key={`resource-section-${sectionHeading}`}>
              {sectionHeading && <small className="TrackDetailsResourceListHeading">{sectionHeading}</small>}
              <ul className={cx('TrackDetailsResourceListItems', { isEmpty: !sectionResources?.length })}>
                <ResourceSectionList
                  track={track}
                  sectionResources={sectionResources}
                  sectionKey={sectionHeading}
                  isRolloutFlagEnabled={isRolloutFlagEnabled}
                />
                {extraContent?.isVisible && extraContent.content}
              </ul>
            </ul>
          );
        })}
      </div>
    </>
  );
};

export default TrackDetailsResourceList;
