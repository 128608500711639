import libertyHomeguardBody from './images/liberty-homeguard-body.png';
import libertyHomeguardLogo from './images/liberty-homeguard-logo.png';
import simplisafeBody from './images/simplisafe-body.png';
import simplisafeLogo from './images/simpliSafe-logo.png';
import policyGeniusBody from './images/policy-genius-body.png';
import policyGeniusLogo from './images/policy-genius-logo.png';
import honeyquoteBody from './images/honeyquote-body.png';
import honeyquoteLogo from './images/honeyquote-logo.svg';

const FEATURED_OFFERS = [
  {
    id: 'policyGenius',
    image: policyGeniusBody,
    logo: policyGeniusLogo,
    title: 'It Pays to Shop Around for the Best Insurance Policy.',
    ctaText: 'Find the best plan',
    ctaLink: 'https://policygenius.go2cloud.org/aff_c?offer_id=733&aff_id=2223',
    disclaimer:
      '*Savings determined by calculating the average difference between the lowest and second lowest home insurance policy estimates provided to shoppers with two or more estimates between 6/01/20 and 5/18/21. Potential savings are based on a composite of multiple different contracts and insurers. Not all policies in this calculation are available in all states, and availability may be based on eligibility. Savings may vary by policy amount and location.',
    body: [
      'Compare personalized quotes by top-rated insurers, such as Travelers, MetLife, and Nationwide, side-by-side',
      'Find a policy with the best coverage and rate for you',
      'Save money: customers saved an average of $350* per year on home insurance',
      'Get multiple quotes through one simple, straightforward process',
      'Talk to real humans: a team of licensed agents answers your questions, handles paperwork, and helps you make decisions confidently',
    ],
    shouldShowOffer: ({ flags, home }) => {
      return home?.address.state !== 'FL' && flags?.showPolicyGenius;
    },
  },
  {
    id: 'honeyquote',
    image: honeyquoteBody,
    logo: honeyquoteLogo,
    title: 'Find the Right Florida Homeowners Insurance Policy, Faster.',
    ctaText: 'Shop Florida home insurance plans',
    ctaLink: 'https://www.honeyquote.com/partners/hometap?ref=hometap&campaign=6',
    disclaimer:
      'While Hometap is a referral partner, it is not an insurance agent or an insurance company. Any insurance products are provided solely by HoneyQuote. Hometap doesn’t receive compensation from HoneyQuote.',
    body: [
      <span>
        <b>Quick quotes:</b> Compare multiple quotes by top-rated insurers, instantly and side-by-side
      </span>,
      <span>
        <b>Local expertise:</b> Talk to licensed Florida agents, 24/7, via phone or live chat
      </span>,
      <span>
        <b>Best rates:</b> HoneyQuote’s 365 Rate Buzzer™ continuously shops for new quotes to ensure you always have
        the best rate, even after you’ve purchased a policy
      </span>,
      <span>
        <b>Personalized products:</b> Plans and service customized for Florida’s unique insurance landscape
      </span>,
    ],
    shouldShowOffer: ({ flags, home }) => {
      if (!home) return false;
      return home.address.state === 'FL' && flags?.showHoneyquote;
    },
  },
  {
    id: 'libertyHomeguard',
    image: libertyHomeguardBody,
    logo: libertyHomeguardLogo,
    title: 'Worry Less With A Home Warranty.',
    ctaText: 'See warranty plans',
    ctaLink: 'https://www.libertyhomeguard.com/hometap/',
    disclaimer: 'Hometap may receive compensation when partner products are purchased.',
    body: [
      'Nationwide coverage with a network of reliable, skilled technicians in all 50 states',
      'A dedicated support team that’s available 24/7, 365 days a year',
      'A home visit from a licensed technician, typically within just 24–48 hours of filing a claim',
      'High ratings by the Better Business Bureau, Google, Trustpilot, and more',
      'Access to exclusive, discounted pricing on select appliances and systems for your home renovation projects',
    ],
  },
  {
    id: 'simplisafe',
    image: simplisafeBody,
    logo: simplisafeLogo,
    title: 'Protect Your Home with 2023’s Best Home Security System.*',
    ctaText: 'Protect your home',
    ctaLink: 'https://simplisafe.com/hometap?utm_source=hometap&utm_medium=partnerdigital&utm_campaign=sr',
    disclaimer:
      '*vs. non-verified SimpliSafe® alarms. Requires Fast Protect™ Monitoring plan with Video Verification. Hometap may receive compensation when you purchase a SimpliSafe kit. Daily price based on monthly monitoring costs averaged over the year.  Best home security system rating based on U.S. News & World Report’s annual ranking of home security systems.',
    body: [
      '24/7 whole home protection against break-ins, fires, floods, and more',
      'Faster police response with verified video evidence*',
      'No contracts or hidden fees',
      'Professional monitoring for under $1/day, plus 2 months free',
      'Hometap homeowners save up to $240',
    ],
  },
];

export default FEATURED_OFFERS;
