import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Container, MuiTextInput, SlideDown, useAsync, useBoolState } from '@hometap/htco-components';
import { createInquiry } from '../../inquiry/data/inquiryRequests';
import useGooglePlaceInput from 'hooks/useGooglePlaceInput';
import houseImg from 'images/inquiry/house2.png';
import {
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import InquiryAddressModal from './InquiryAddressModal';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage.js';
import { CallToAction, ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import { DQ_PAGES } from '../constants/progressiveInquiryConstants';
import { PREQUAL_OUTCOME } from '../constants/progressiveInquiryConstants';
import { PREQUAL_TYPES } from '../../inquiry/constants/APIConstants';
import { parsePrequalificationsToMap } from '../../inquiry/utils/parsePrequalificationOutcomes';
import './InquiryProperty.scss';

const InquiryProperty = ({
  form,
  trackingData,
  step,
  onNext,
  onDequalify,
  manuallyEnteredValidAddress,
  autoFillValidAddress,
  onValidAddressChange,
}) => {
  const { registerField, handleSubmit, updateFormData, formData } = form;
  const onAddress = (address, addressObj) => {
    updateFormData({ address, ...addressObj });
  };
  const { ref, isValidGoogleAddress } = useGooglePlaceInput({ onAddress });
  const manualOn = useBoolState(false);
  const [focus, setFocus] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const { results, loading, execute: postInquiry } = useAsync(createInquiry);

  const onSubmit = useCallback(() => {
    const { city, state, street, zip_code, unit } = formData;
    const data = {
      address: { street, city, state, zip_code, unit },
    };

    const requiredFields = [FIELDS.STREET, FIELDS.CITY, FIELDS.STATE, FIELDS.ZIP_CODE];
    const emptyFields = Object.keys(data.address).find(key => {
      return requiredFields.includes(key) && data.address[key]?.length === 0;
    });

    if (emptyFields) {
      setInvalidAddress(true);
    } else {
      const createApi = handleSubmit(() => {
        postInquiry({ ...data, ...trackingData }, [PREQUAL_TYPES.ZIP, PREQUAL_TYPES.STATE]);
      });
      createApi();
    }
  }, [handleSubmit, postInquiry, trackingData, formData]);

  useEffect(() => {
    if (manuallyEnteredValidAddress && formData.address && !results) {
      onSubmit();
    }
    if (results) {
      const { prequalifications, id } = results;
      const prequalMap = parsePrequalificationsToMap(prequalifications);
      const { StatePrequalification, ZipPrequalification } = prequalMap?.prequalifications;
      const checkFailedPrequal = outcome_code => outcome_code === PREQUAL_OUTCOME.FAIL;

      if (
        checkFailedPrequal(StatePrequalification.outcome_code) ||
        checkFailedPrequal(ZipPrequalification.outcome_code)
      ) {
        onDequalify(DQ_PAGES.LOCATION);
      } else {
        onNext(step + 1, id);
      }
    }
  }, [results, onNext, step, onDequalify, onSubmit, manuallyEnteredValidAddress, formData.address]);

  const isAddressValid = isValidGoogleAddress || autoFillValidAddress;
  const canContinueToTheNextPage = formData.address && isAddressValid && !invalidAddress;
  canContinueToTheNextPage && scrollIntoViewByElementId(CONTINUE_BUTTON_ID);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === 'Escape' || event.key === 'Tab') {
      setFocus(false);
    }
  };

  const PropertyUnitLabel = () => (
    <>
      Unit <span className={'PropertyUnitLabel'}>(optional)</span>
    </>
  );

  return (
    <div className="Property" data-testid="inquiry_property_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.PROPERTY]} />
      <ProgressiveUITemplate>
        <ContentPanel>
          <h1>Is your property eligible for a Hometap Investment?</h1>
          <p className="PropertyBodyText InquiryBodyText">
            You can prequalify for a Hometap investment in{' '}
            <span className="InquiryEmphasisBold">less than two minutes.</span> First, we need your address.
          </p>
          <Container className="InquiryPropertyAddressRow" row>
            <Container className="InquiryInputPropertyAddress">
              <MuiTextInput
                required
                label="Property Address"
                ref={ref}
                name="address"
                value={formData.address}
                width="100%"
                onChange={address => {
                  setInvalidAddress(false);
                  onValidAddressChange(false);
                  updateFormData({ address });
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
            </Container>
            <Container className="InquiryInputPropertyUnit">
              <MuiTextInput maxLength={50} width="100%" label={<PropertyUnitLabel />} {...registerField(FIELDS.UNIT)} />
            </Container>
          </Container>
          <div className="InquiryError">
            <SlideDown visible={invalidAddress}>
              <ErrorMessage>
                <span>Please enter a valid address.</span>
              </ErrorMessage>
            </SlideDown>
          </div>
          {!focus && formData.address && !isAddressValid && (
            <div className="PropertyManualAddress">
              <p className="PropertyNotFound">Address not found, please try again.</p>
              <button type="button" onClick={manualOn.setTrue}>
                I’ll enter my address manually
              </button>
              <InquiryAddressModal
                open={manualOn.value}
                handleClose={manualOn.setFalse}
                form={form}
                onNext={onSubmit}
              />
            </div>
          )}
          <CallToAction>
            <Button id={CONTINUE_BUTTON_ID} disabled={!canContinueToTheNextPage} onClick={onSubmit} loading={loading}>
              Continue
            </Button>
          </CallToAction>
        </ContentPanel>
        <ImageContentPanel imageSource={houseImg} imgAlt="car parked in driveway" includeTrustPilot={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryProperty;
