export const PREQUAL_OUTCOME_CODES = {
  PASS: 'pass',
  FAIL: 'fail',
  UNDETERMINED: 'undetermined',
};

export const PREQUAL_TYPES = {
  STATE: 'StatePrequalification',
  ZIP: 'ZipPrequalification',
  PROPERTY_TYPE: 'PropertyTypePrequalification',
  RISK: 'RiskPrequalification',
  INVESTMENT_AMOUNT: 'InvestmentAmountPrequalification',
  HOME_VALUATION: 'HomeValuationPrequalification',
};

export const RISK_BAND_TYPES = {
  PASS: 'band_pass',
  FAIL: 'band_fail',
  UNKNOWN: 'band_unknown',
  NO_DATA_NO_SSN: 'no_data_no_ssn',
  NO_DATA_WITH_SSN: 'no_data_with_ssn',
  API_ERROR: 'api_error',
  GRANDFATHERED: 'grandfathered',
};

export const FICO_SCORE_TYPES = {
  PASS: 'fico_pass',
  FAIL: 'fico_fail',
  UNKNOWN: 'fico_unknown',
  NO_DATA_NO_SSN: 'no_data_no_ssn',
  NO_DATA_WITH_SSN: 'no_data_with_ssn',
  API_ERROR: 'api_error',
  GRANDFATHERED: 'grandfathered',
};

export const UNSUCCESSFUL_OUTCOME_PARAMS = {
  OTHER_STATE: 'rros', // other state
  EXPANSION_STATE: 'rres', // expansion state
  ZIP_CODE: 'ruzc', // undesirable zip code
  PROPERTY: 'rupt', // property type
  RISK: 'rurb', // risk band
  RISK_BAND_AA: 'adverse-action-risk',
  FICO_SCORE_AA: 'adverse-action-fico',
};

export const FAILED_PREQUAL_REDIRECT_MAP = {
  [PREQUAL_TYPES.STATE]: {
    'Non Operational': UNSUCCESSFUL_OUTCOME_PARAMS.OTHER_STATE, // other state
    Expansion: UNSUCCESSFUL_OUTCOME_PARAMS.EXPANSION_STATE, // expansion state
  },
  [PREQUAL_TYPES.ZIP]: UNSUCCESSFUL_OUTCOME_PARAMS.ZIP_CODE, // undesirable zip code
  [PREQUAL_TYPES.PROPERTY_TYPE]: UNSUCCESSFUL_OUTCOME_PARAMS.PROPERTY, // property type
  [PREQUAL_TYPES.RISK]: UNSUCCESSFUL_OUTCOME_PARAMS.RISK, // risk band
  RISK_BAND_AA: UNSUCCESSFUL_OUTCOME_PARAMS.RISK_BAND_AA, // risk adverse action
  FICO_SCORE_AA: UNSUCCESSFUL_OUTCOME_PARAMS.FICO_SCORE_AA, // fico adverse action
};
