import React, { useEffect } from 'react';
import cx from 'classnames';
import { FeaturedContentCarousel } from '@hometap/htco-components';
import { SeasonChecklist } from 'apps/dashboard/components/maintenance-controller/homeMaintenanceTasks';
import { FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID, FEATURED_CONTENT_CAROUSEL_SLIDES } from 'apps/dashboard/constants';
import { useHomeMaintenanceTasks } from 'apps/dashboard/hooks';
import Footer from 'components/Footer/Footer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import './MaintenanceController.scss';

const MaintenanceController = () => {
  const { onLoadTasks, onSelectTask, curSeasonChecklist, outOfSeasonChecklists } = useHomeMaintenanceTasks();

  useEffect(() => {
    onLoadTasks();
  }, [onLoadTasks]);

  const libertyAd = FEATURED_CONTENT_CAROUSEL_SLIDES.find(ad => {
    return ad.id === FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID;
  });

  return (
    <ScrollToTop>
      <div className="PageBodyCenteredContainer">
        <div className={cx('MaintenanceController')}>
          <div className="MaintenanceHeader">
            <h1>Home maintenance</h1>
            <span>Keep your home in shape with seasonal checklists and DIY maintenance guides</span>
          </div>
          <div className="MaintenanceCurrentSeason">
            <div className="MaintenanceCurrentSeasonContent">
              <div className="CurrentTasks">
                <div className="SeasonChecklistWrapper">
                  <SeasonChecklist checklist={curSeasonChecklist} onSelectTask={onSelectTask} />
                </div>
              </div>
              <FeaturedContentCarousel cards={[libertyAd]} />
            </div>
          </div>
          <div className="SeasonTasks UpcomingTasks">
            <div className="title">Plan ahead for upcoming seasons</div>
            <div className="SeasonList">
              {outOfSeasonChecklists.map(checklist => {
                return (
                  <div className="SeasonChecklistWrapper" key={checklist.season}>
                    <SeasonChecklist checklist={checklist} onSelectTask={onSelectTask} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer theme="light" />
    </ScrollToTop>
  );
};

export default MaintenanceController;
