import { Button } from '@hometap/htco-components';
import React from 'react';
import { Link } from 'react-router-dom';

export const requestSettlementFAQ = {
  question: 'How do I request a Settlement Statement?',
  answer: (
    <p>
      Please reach out to our Investment Support team at{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>{' '}
      or call <span>1-617-604-6985</span> and provide the following information: A good-through date for your Settlement
      Statement, and the method of settlement (home sale, refinance, or cash). If the method is home sale, please also
      provide a copy of the executed Purchase and Sale Agreement.
    </p>
  ),
};

export const sellingHomeFAQ = {
  question: 'I’m selling my home. What do I need to do?',
  answer: (
    <p>
      As outlined in your Hometap Option Purchase Agreement, the sale of your home will prompt the need to settle your
      Hometap Investment at the time of sale.
      <br />
      <br />
      Please follow the below steps to ensure a smooth settlement process:
      <ol>
        <li>
          Upon acceptance of a binding offer on your home, please email a copy of the executed version of the Purchase
          and Sale Agreement and the attached "Binding Offer Notice" form to us.
        </li>
        <li>
          From the information in the agreement, we’ll generate a Settlement Statement for your Hometap Investment that
          will include settlement amounts good for 30 days, wiring instructions, and more information around the lien
          release process.
        </li>
        <li>Upon receipt of funds from you, we’ll release all associated liens.</li>
      </ol>
      <br />
      Please reach out to our Investment Support team at{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>{' '}
      or call <span>1-617-604-6985</span> for more details.
    </p>
  ),
};

export const refinancingHomeFAQ = {
  question: 'I’m refinancing my home. What do I need to do?',
  answer: (
    <p>
      If you plan on settling your Hometap Investment as part of your refinance, please reach out to our Investment
      Support team for a Settlement Statement. If you plan to keep your Investment active, please review the information
      below.
      <br />
      <br />
      For broad context, Hometap may be open to rate-and-term refinances. At this point, we usually check to make sure
      there is no material change in principal or new, onerous terms added to the mortgage. The homeowner must also meet
      our Owner’s Ending Equity threshold. Please contact our Investment Support team at{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>{' '}
      or call <span>1-617-604-6985</span> for more details.
      <br />
      <br />
      In terms of timeline, once we have the necessary documents to corroborate the loan details in hand, we’ll provide
      approval within 48 hours and follow up by mail with a notarized Subordination Agreement. These documents usually
      include some of the below items:
      <ol>
        <li>Signed borrower's authorization</li>
        <li>1008 (underwriting transmittal)</li>
        <li>1003 (mortgage application)</li>
        <li>Signed loan estimate</li>
        <li>Closing disclosure</li>
        <li>Mortgage note</li>
        <li>Title report, including vesting legal description</li>
        <li>Copy of the appraisal</li>
        <li>Lender approval</li>
      </ol>
      <br />
      *Please note that the ending equity requirements change by fund
    </p>
  ),
};

export const renovationAdjustmentFAQ = {
  question: 'I’m making renovations to my home. Am I eligible for an adjustment or credit?',
  answer: (
    <p>
      You may be eligible for a credit, also known as a renovation adjustment, if you meet certain criteria. Please
      email{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>{' '}
      for a Request for Renovation Adjustment form, timeline for project completion as well as any questions you may
      have. This form outlines the process and lists the required documentation necessary for review. Please complete it
      and return along with before and after photos, a detailed summary of the renovation, and any contracts or receipts
      related to the renovation.
      <br />
      <br />
      Key Guidelines/Notes:
      <ul>
        <li>Renovations must have occurred after your effective date.</li>
        <li>Please fill out the Summary Section in the Renovation Adjustment form.</li>
        <li>Please read the whole form, as it has important information.</li>
      </ul>
    </p>
  ),
};

export const loanDuringInvestmentFAQ = {
  question: 'I’m looking to take out a loan or HELOC during my Hometap Investment. What steps should I take?',
  answer: (
    <p>
      Any additional debt on your property requires approval from Hometap. Hometap will provide approval pursuant to the
      terms in your signed Option Purchase Agreement.
      <br />
      <br />
      You may have to provide us with loan documentation by 30 days prior to the proposed closing. Once we have the
      necessary documents to corroborate the loan details in hand, and we approve the loan, we’ll provide approval
      within 48 hours. These documents typically include some of the following items:
      <br />
      <br />
      <b>Loan:</b>
      <ul>
        <li>Application</li>
        <li>Terms of your loan agreement</li>
        <li>Mortgage note</li>
        <li>Copy of the appraisal</li>
        <li>Lender approval/Preliminary approval</li>
        <br />
      </ul>
      <b>HELOC:</b>
      <ul>
        <li>HELOC/LOC application</li>
        <li>Mortgage note</li>
        <li>Copy of the appraisal</li>
        <li>Lender approval/Preliminary approval</li>
      </ul>
      <br />
      For other types of financing, such as a mortgage refinance, please contact our Investment Support team for further
      guidance. We can be reached at{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>{' '}
      or <span>1-617-604-6985</span>.
    </p>
  ),
};

export const emailsFromHometapFAQ = {
  question: 'I’m receiving a lot of postal mail that mentions Hometap. What is this?',
  answer: (
    <p>
      It’s not uncommon for "ancillary" providers unrelated to Hometap to comb through the registry of deeds to identify
      newly recorded real estate transactions on properties in order to target individuals for their mortgage or life
      insurance products.
      <br />
      <br />
      These mailings are NOT related to Hometap, nor are they associated with or sponsored by Hometap at all.
      <br />
      <br />
      If you have further questions, please feel free to email a copy of the mail you are receiving to the Investment
      Support team at{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>{' '}
      or by phone at <span>1-617-604-6985</span> so we can take a look.
    </p>
  ),
};

export const propertyHaveBeenDamagedFAQ = {
  question: 'My property has been damaged. What should I do now?',
  answer: (
    <p>
      Once you’ve filed an insurance claim and received a check, please reach out to us to have your claim endorsed.
      We’ve streamlined the process so that you can start your repairs as soon as possible.
      <br />
      <br />
      First, email the following information to us at{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>
      :
      <ul>
        <li>The type of damage to the property</li>
        <li>The amount of the claim check</li>
        <li>The adjuster’s report</li>
        <li>The best address for us to send a FedEx label</li>
      </ul>
      <br />
      Once Hometap receives these items, we’ll be ready to endorse your insurance claim check(s). We’ll provide you with
      an overnight FedEx label so the check(s) can be shipped directly to our team for endorsement. Please allow 3-5
      business days for this endorsement process to be completed. Upon endorsement, all checks will be shipped back to
      you via FedEx overnight to ensure a timely receipt.
      <br />
      <br />
      Feel free to reach out to us at{' '}
      <Button theme="link" href="mailto:homeowners@hometap.com">
        homeowners@hometap.com
      </Button>{' '}
      or <span>1-617-604-6985</span> if you have any additional questions!
    </p>
  ),
};

export const aboutMyInvestmentFAQs = [
  {
    question: 'Do I have to tell Hometap if I want to renovate or make changes to my home?',
    answer: (
      <p>
        No, we’re not involved in any renovation decisions and we don’t share in any of the home value attributable to
        renovations, as determined by an appraiser.
        <br />
        <br />
        So, if you’re renovating to make your life better, excellent! If you’re renovating in the hopes of increasing
        the value of your home, just do your homework. Some renovations add more value than others; some don’t pay for
        themselves at all.
      </p>
    ),
  },
  renovationAdjustmentFAQ,
  loanDuringInvestmentFAQ,
  {
    question: 'What happens if the value of my home goes down?',
    answer:
      'In exchange for our upfront cash Investment, Hometap receives a share of the sale or market price of the home. If the value doesn’t go up and we make less, we make less. That’s the risk we take, and that’s for Hometap to worry about, not you.',
  },
  propertyHaveBeenDamagedFAQ,
  emailsFromHometapFAQ,
];

export const settlingMyInvestmentFAQs = [
  {
    question: 'Can I estimate my future settlement amount?',
    answer: (
      <p>
        If you’re curious about your estimated settlement amount, we recommend checking out our Settlement Calculator!
        It allows you to explore estimated settlement amounts based on the home value and settlement date you select. To
        access the calculator, log into your account, scroll until you see the button that reads, “Settle My
        Investment,” and add in any date during the lifetime of your Investment, as well as your estimated home value,
        for a projected settlement amount. Once you’ve determined your preferred settlement method, you can contact the
        Investment Support team via email at{' '}
        <Button theme="link" href="mailto:homeowners@hometap.com">
          homeowners@hometap.com
        </Button>{' '}
        or by phone at <span>1-617-604-6985.</span>
      </p>
    ),
  },
  requestSettlementFAQ,
  {
    question: 'What if I want to sell my house before the effective period is up?',
    answer:
      'No problem – it’s totally up to you; just loop us in. Hometap will receive the Hometap share from the proceeds of the sale.',
  },
  sellingHomeFAQ,
  refinancingHomeFAQ,
  {
    question:
      'What happens if I don’t sell the home by the end of the effective period and want to keep it or pass it on to my kids? What do I owe Hometap?',
    answer:
      'If you don’t want to sell your house during the effective period, you can settle the Investment with savings or by taking out a home equity (or other) loan. Any settlement is based on the current market value of the home.',
  },
];

export const aboutHomeEquityFAQs = [
  {
    question: 'How do you determine my home value?',
    answer:
      'Hometap’s Home Value Estimate combines multiple industry-proven AVMs (automated valuation models) to give you an accurate and up-to-date estimated home value.',
  },
  {
    question: 'How do you determine my home equity?',
    answer:
      'Your home equity is calculated by subtracting the amount you owe on all loans secured by your house from its appraised value.',
  },
  {
    question: 'How did you determine my mortgage balance payoff over time?',
    answer:
      'Your mortgage balance payoff over time is calculated by using the interest rate you provided, your initial mortgage balance upon account creation, and your monthly mortgage payment. Each year, as you continue to pay more and more of your principal and interest, this balance decreases.',
  },
  {
    question: 'Why is my home value different from what I see on other sites?',
    answer:
      'Like Hometap, many other real estate sites leverage automated valuation models (AVMs) to estimate the value of your home. While many of these models use similar data sources, the manner in which each calculates your home value will almost always differ due to the nuances of the models themselves.  Keep in mind that these are estimates, and the true value of your home can only be determined through a real estate transaction, like selling or leveraging the latent equity in your home. ',
  },
];

export const aboutMyDashboardFAQs = [
  {
    question: 'Where does the 2-7% appreciation percentage come from? Can I add custom percentages?',
    answer:
      'As of today, you can’t edit these values. Each comes from historical data of home price appreciation across the country from the Federal Housing Finance Agency.',
  },
  {
    question: 'What if my home depreciates? Do you have a scenario for depreciation?',
    answer:
      'Over a 10-year period, historical data suggests that homes appreciate over time, despite potential for annual depreciation.',
  },
  {
    question: 'Can I add home maintenance tasks?',
    answer:
      'Not at the moment. The current items are based upon our curated set of tasks to maintain and increase the equity in your home. Over time, these will evolve. ',
  },
  {
    question: 'Can I add renovations beyond the ones listed?',
    answer:
      'Today, we list the most common home renovations, but that list will keep growing over time. Check back regularly for more home renovation and repair options! ',
  },
  {
    question: 'Can I edit the cost or expected value of the renovation?',
    answer:
      'Today, you can’t edit the cost of a renovation. Of course, there are many variations with respect to how much a specific renovation would cost and the expected value it will add to your home. That being said, we’ve worked hard to find the best possible information source and have partnered with Kukun to provide you with this data. Over time, we hope to evolve this functionality to give more granular insights into cost vs. value for all different types of renovations.',
  },
  {
    question: 'What source do you use for your renovation data?',
    answer:
      "Our partner, Kukun, uses data about your home's location and value to create highly accurate cost estimates for different renovation types and return on investment based on material type.",
  },
  {
    question: 'What if I encounter an issue with the data or functionality in my dashboard?',
    answer: (
      <>
        You can reach out to Hometap at <a href="mailto:productsupport@hometap.com">productsupport@hometap.com</a> with
        any questions or concerns.
      </>
    ),
  },
];

export const accountManagementFAQs = [
  {
    question: 'What is Sign in with Google? What data do you collect from Google?',
    answer:
      'Sign in with Google allows you to use your existing Google/Gmail account to log into Hometap without setting a password.  We only collect your email address to verify that you’re you. If you have two-factor authentication enabled for your Google account, you will initially have to validate your account with a one time passcode, but can set Hometap to be remembered for your next login.',
  },
  {
    question: 'What is Sign in with a Magic Link?',
    answer:
      'Instead of using a password, we will send you a one time passcode in the form of a URL to the email associated with your account. Clicking this link will trigger that email, and once you click it, you’ll be logged into your account. Please note, the link will expire within 30 minutes of receiving the email.',
  },
  {
    question: 'Can I add multiple properties to my account?',
    answer: 'You can track and manage your home equity for as many properties as you own, up to a maximum of 10.',
  },
  {
    question: 'How do I reset my password?',
    answer: (
      <>
        Navigate to <a href="https://go.hometap.com/profile">go.hometap.com/profile</a> and you’ll see a reset link if
        you have already set up a password. Please note, if you’ve signed in via Google or via magic link upon account
        creation, you will not have a password specifically for your Hometap account. You can always sign in via magic
        link, minimizing the need for a password.
      </>
    ),
  },
];

export const investmentStatementFAQs = [
  requestSettlementFAQ,
  {
    question: 'Where can I find the executed contract for my Investment?',
    answer: ({ documentsLink }) => (
      <>
        On your Investment’s{' '}
        <Link className="font-bold" to={documentsLink}>
          Statements & Documents
        </Link>{' '}
        page, go to the <b>Shared with me</b> tab in the <b>Documents</b> section.
      </>
    ),
  },
  // How does Hometap’s share change based on settlement date?
  sellingHomeFAQ,
  refinancingHomeFAQ,
  renovationAdjustmentFAQ,
];
