import { Button } from '@hometap/htco-components';

const RISK_DQ_COPY = {
  header: 'We can’t make an investment, yet — but we can still help you harness your home equity.',
  subtitle:
    'We consider a combination of factors when making investments, including information from a credit reporting agency.',
  link: 'Learn about the Fair Credit Reporting Act',
};

const RiskFail = ({ handleClick }) => {
  return (
    <div>
      <h1 className="DQHeader" data-testid="dq-header">
        {RISK_DQ_COPY.header}
      </h1>
      <p className="DQSubtitle" data-testid="dq-subtitle">
        {RISK_DQ_COPY.subtitle}
      </p>
      <Button theme="link" className="DQLink" onClick={handleClick}>
        {RISK_DQ_COPY.link}
      </Button>
    </div>
  );
};

export default RiskFail;
