import React, { useState } from 'react';
import { Grid, useWindowSize } from '@hometap/htco-components';
import { PropertyLocationFail, RiskFail, RiskAA } from './outcomeTextComponents';
import { DQ_PAGES } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import HedDqCta from './HedDqCta';
import './InquiryDQ.scss';

const InquiryDQ = ({ step }) => {
  const [stepDQ, setStepDQ] = useState(step);
  const { isScreenSize } = useWindowSize();

  const renderHeader = () => {
    switch (stepDQ) {
      case DQ_PAGES.LOCATION:
      case DQ_PAGES.COOP:
      case DQ_PAGES.MOBILE_HOME:
        return <PropertyLocationFail reason={stepDQ} />;
      case DQ_PAGES.RISK:
      case DQ_PAGES.FICO:
        return (
          <RiskFail
            handleClick={() => setStepDQ(DQ_PAGES.RISK_AA)}
            additionalText="Check out our homeowner resources to learn how to increase the value of your home."
          />
        );
      case DQ_PAGES.RISK_AA:
      case DQ_PAGES.FICO_AA:
        return <RiskAA onBack={() => setStepDQ(DQ_PAGES.RISK)} />;
      default:
        return null;
    }
  };

  const hideCtaSection = stepDQ === DQ_PAGES.RISK_AA && isScreenSize('sm');

  return (
    <Grid className="InquiryDQ" container>
      <Grid sm={12} md={6} className="InquiryDQContentPanel">
        {renderHeader()}
      </Grid>
      {!hideCtaSection && (
        <Grid sm={12} md={6} className="InquiryDQHedCta">
          <HedDqCta />
        </Grid>
      )}
    </Grid>
  );
};

export default InquiryDQ;
