import Configurator from 'components/Configurator/Configurator';
import Footer from 'components/Footer/Footer';
import AddressDropdownHeader from 'components/Headers/AddressDropdownHeader/AddressDropdownHeader';

const AddressHeaderLayout = ({ children }) => {
  return (
    <>
      <AddressDropdownHeader />
      <main>
        {children}
        <Configurator />
        <Footer />
      </main>
    </>
  );
};

export default AddressHeaderLayout;
