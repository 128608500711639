import groq from 'groq';

export const homeownerResourceQuery = groq`
  {
    featuredImage { 
        altText, 
        image { 
          crop, 
          hotspot, 
          asset->{ url, "height": metadata.dimensions.height, "width": metadata.dimensions.width }
        }
      },
      coverImage { 
        altText, 
        image { 
          crop, 
          hotspot, 
          asset->{ url }
        }
      },
    category,
    title,
    slug,
    excerpt,
    "type": _type,
    length,
    date,
    author->{
      name,
      picture{ asset->{ url } }
    },
    content[]{
      _type,
      _type == 'bodyImage' => {
          alt, 
          caption,
          "url": image.asset->url,
          "height": image.asset->metadata.dimensions.height,
          "width": image.asset->metadata.dimensions.width,
          href
      },
      _type != 'bodyImage' => @
    },
    tags[defined(_ref)] -> {
      "slug": slug.current, 
      title
    },
    relatedResources[defined(_ref)] -> {
      featuredImage { 
        altText, 
        image { 
          crop, 
          hotspot, 
          asset->{ url }
        }
      },
      coverImage { 
        altText, 
        image { 
          crop, 
          hotspot, 
          asset->{ url }
        }
      },
      category,
      title,
      "type": _type,
      length,
      "slug": slug.current
    },
    youTubeUrl,
    downloadButtonLabel,
    "downloadUrl": fileUrl,
    gated,
    formType,
    disclaimer->{
      title,
      body
    },
    showDisclaimer,
    formTitle,
    resourceName,
    metadata {
      title,
      description,
      "openGraphImage": {
        "url": openGraph.image.asset->url,
        "height": openGraph.image.asset->metadata.dimensions.height,
        "width": openGraph.image.asset->metadata.dimensions.width,
        "alt": openGraph.imageAlt
      }
    }
  }
`;

export const SLUG_NAMES = { RENOVATIONS_BEFORE_RETIRING: 'renovations-before-retiring' };

// To add an article to be queried from Sanity, add the slug to this array
export const slugs = [SLUG_NAMES.RENOVATIONS_BEFORE_RETIRING];
