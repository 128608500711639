import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@hometap/htco-components';

import RundownCostEstimate from '../RundownCostEstimate/RundownCostEstimate';
import RundownScenarioCard from '../RundownScenarioCard/RundownScenarioCard';

import './RundownScenariosAndCosts.scss';

const RundownScenariosAndCosts = ({ rundown, investmentAmount, scenarios = [] }) => {
  const renderCostEstimate = () => {
    return !rundown.costs ? null : (
      <>
        <div className="RundownCostsContainer">
          <RundownCostEstimate costs={rundown.costs} investmentAmount={investmentAmount} />
        </div>
        <p className="RundownCostsFinePrint">
          This estimate is nonbinding and subject to final approval by Hometap according to its current underwriting
          criteria and may be rescinded for any reason, including but not limited to additional information that is
          submitted or obtained as part of Hometap’s review process.
        </p>
      </>
    );
  };

  return (
    <Paper theme="plain" className="RundownScenariosAndCostsContainer">
      <div className="RundownScenariosAndCostsWrapper">
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          {scenarios.map(scenario => {
            return (
              <Grid key={scenario?.id} className="RundownScenarioCardContainer" xs={12} sm={12} md={4} lg={4}>
                <RundownScenarioCard
                  appreciationIndicator={scenario.appreciation_indicator}
                  appreciationPercent={scenario.appreciation_percent}
                  description={scenario.description}
                  hometapShare={scenario.hometap_share}
                  homeownerShare={scenario.homeowner_share}
                  investmentAmount={scenario.investment_amount}
                  effectivePeriod={scenario.effective_period}
                  isShareCapped={scenario.is_share_capped}
                  mortgageBalance={scenario.final_mortgage_balance}
                  projectedHomeValue={scenario.appreciated_home_value}
                />
              </Grid>
            );
          })}
        </Grid>

        {renderCostEstimate()}
      </div>
    </Paper>
  );
};

RundownScenariosAndCosts.propTypes = {
  rundown: PropTypes.shape({
    current_mortgage_balance: PropTypes.number,
    original_mortgage_balance: PropTypes.number,
    mortgage_term: PropTypes.number,
    mortgage_rate: PropTypes.number,
    costs: PropTypes.array,
    show_and_use_mortgage_info: PropTypes.bool,
  }).isRequired,
  scenarios: PropTypes.array,
  investmentAmount: PropTypes.number,
};

export default RundownScenariosAndCosts;
