import { useAsync } from '@hometap/htco-components';
import { fetchRenovationTemplates } from '../data/requests';
import { useCallback, useMemo } from 'react';
import { groupBy } from 'lodash';
import {
  LEGACY_RENOVATION_PROJECT_NAMES,
  parseRenovationKind,
  RENOVATION_PROJECT_ICONS,
} from '../data/renovationUtils';

const useRenovationTemplates = state => {
  const {
    results: renovationTemplates = [],
    loading,
    error,
  } = useAsync(fetchRenovationTemplates, {
    immediate: !!state,
    params: [state],
  });

  const renovationsByProject = useMemo(() => groupBy(renovationTemplates, 'name'), [renovationTemplates]);

  const renovationByProjectLookup = useMemo(
    () =>
      Object.entries(renovationsByProject).reduce((acc, [key, value]) => {
        acc[key.toLowerCase()] = value;
        return acc;
      }, {}),
    [renovationsByProject],
  );

  const renovationByCodeLookup = useMemo(
    () =>
      renovationTemplates.reduce((acc, tpl) => {
        acc[tpl.code.toLowerCase()] = tpl;
        return acc;
      }, {}),
    [renovationTemplates],
  );

  const renovationProjectNames = useMemo(() => Object.keys(renovationsByProject), [renovationsByProject]);

  const getRenovationProjectOptions = useCallback(
    (existingRenovations = [], addLegacyProjects = false) => {
      const existingProjects = new Set(
        existingRenovations.map(({ renovation_kind }) => parseRenovationKind(renovation_kind).project.toLowerCase()),
      );
      const options = renovationProjectNames.map(project => {
        const projectKey = project.toLowerCase();
        const disabled = existingProjects.delete(projectKey);
        return {
          label: project,
          value: projectKey,
          icon: RENOVATION_PROJECT_ICONS[projectKey],
          disabled,
        };
      });

      if (addLegacyProjects) {
        existingProjects.forEach(projectKey => {
          if (LEGACY_RENOVATION_PROJECT_NAMES[projectKey]) {
            options.push({
              label: LEGACY_RENOVATION_PROJECT_NAMES[projectKey],
              value: projectKey,
              icon: RENOVATION_PROJECT_ICONS[projectKey],
              disabled: true,
            });
          }
        });
      }

      return options;
    },
    [renovationProjectNames],
  );

  const matchTemplateForRenovation = useCallback(
    renovation => (renovation && renovationByCodeLookup[renovation.renovation_kind.toLowerCase()]) || null,
    [renovationByCodeLookup],
  );

  const getRenovationTemplatesByProject = useCallback(
    project => (project && renovationByProjectLookup[project.toLowerCase()]) || null,
    [renovationByProjectLookup],
  );

  return {
    loading,
    error,
    getRenovationProjectOptions,
    getRenovationTemplatesByProject,
    matchTemplateForRenovation,
  };
};

export default useRenovationTemplates;
