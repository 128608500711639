import { apiNoAuth, apiWithAuth } from 'utils/api';

// These are still authenticated requests but we don't want to redirect if we encounter a 403
export const fetchTrackApplicant = async trackId =>
  await apiNoAuth.get(`tracks/${trackId}/current-applicant`, { withCredentials: true });

export const verifySSN = async (trackId, data) =>
  await apiNoAuth.post(`tracks/${trackId}/current-applicant/actions/verify`, data, {
    withCredentials: true,
  });

export const giveCoApplicantConsent = async (applicantId, data) =>
  await apiWithAuth.v1.post(`applicants/${applicantId}/actions/consent`, data);
