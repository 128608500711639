import React, { useEffect, useState } from 'react';
import './DashboardGoalsPage.scss';
import { Button, MuiTextInput, SelectionSet, Loader } from '@hometap/htco-components';
import Footer from 'components/Footer/Footer';
import { useHistory } from 'react-router-dom';
import Insight from 'components/insight/Insight';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import useConfigurationOptions from 'hooks/useConfigurationOptions';
import { scrollTop } from 'utils/domScripts';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';

const DashboardGoalsPage = () => {
  const history = useHistory();
  const { home } = useCurrentHome();
  const currentHomeMutation = useCurrentHomeMutation();
  const [selectedItem, setSelectedItem] = useState();
  const [extraDetail, setExtraDetail] = useState('');
  const [showMoreDetailBlock, setShowMoreDetailBlock] = useState(false);
  const { dropdownConfigurationOptions } = useConfigurationOptions({ names: ['financial_goal'] });

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (home?.hed_financial_goal) {
      setSelectedItem(home.hed_financial_goal);
      if (home.hed_financial_goal_other) {
        setExtraDetail(home.hed_financial_goal_other);
      }
    }
  }, [home]);

  const handleContinueClick = async () => {
    if (selectedItem === null) {
      return;
    }
    const data = {
      hed_financial_goal: selectedItem,
      hed_financial_goal_other: selectedItem === 'other' && showMoreDetailBlock ? extraDetail : '',
    };
    if (selectedItem !== 'other' || (selectedItem === 'other' && showMoreDetailBlock)) {
      await currentHomeMutation.mutateAsync(data);
      history.push('/dashboard/home-equity/finance');
      return;
    }
    setShowMoreDetailBlock(true);
  };

  const noSelectionOptions = !Object.keys(dropdownConfigurationOptions).length;
  if (currentHomeMutation.isPending || noSelectionOptions) {
    return (
      <div className="PageLoader">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return (
    <>
      <div className="DashboardGoalsContainer">
        <div className="BackButtonContainer">
          <Button className="BackButton" theme="link" onClick={() => history.push(HOMEOWNER_DASHBOARD.ROOT)}>
            Back to Dashboard
          </Button>
        </div>
        <div className="DashboardGoalsInnerContainer">
          <h3 className="pageTitle">What is the primary financial goal you want to achieve?</h3>
          {showMoreDetailBlock ? (
            <MuiTextInput
              onChange={setExtraDetail}
              name="hed_financial_goal_other"
              type="text"
              label="Other (You tell us!)"
              width="auto"
              maxLength={255}
              value={extraDetail}
              helperText="Let us know which goal you want to acheive"
              className="OtherDetailBlock"
            />
          ) : (
            <SelectionSet
              align="left"
              labelWidth="50%"
              name="hed_financial_goal"
              onChange={value => {
                setSelectedItem(value);
              }}
              value={selectedItem}
              options={dropdownConfigurationOptions?.financial_goal}
              theme="normal"
            />
          )}
          <Button
            className="GoalsContinueButton"
            disabled={!selectedItem || (showMoreDetailBlock && !extraDetail)}
            type="button"
            loading={currentHomeMutation.isPending}
            onClick={handleContinueClick}
            data-testid="goals-continue-button"
          >
            Continue
          </Button>
          <div className="GoalInsights">
            <Insight text="Understanding your needs as a homeowner can help you choose the right product to meet your financial goals. Each financial product is different, and offers pros and cons for each personal use case." />
          </div>
        </div>
      </div>
      <Footer theme="light" />
    </>
  );
};

export default DashboardGoalsPage;
