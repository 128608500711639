import React, { useEffect, useRef } from 'react';
import JSXParser from 'react-jsx-parser';
import { Button, Icon } from '@hometap/htco-components';
import PullQuote from './PullQuote';

const CMSContent = ({ ref, content }) => {
  // Make sure all external links in posts open in new window
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.querySelectorAll('a').forEach(link => {
        const pathIsRelative = link.href.match(/^\/.*[^com]$/);
        const pathIsHometap = link.href.match('hometap.com');
        if (!pathIsHometap && !pathIsRelative && !link.target) {
          link.target = '__blank';
          link.rel = 'noopener noreferrer';
        }
      });

      const paragraphs = contentRef.current.getElementsByTagName('p');
      if (paragraphs.length) {
        const [firstParagraph] = paragraphs;
        firstParagraph.setAttribute('class', 'ResourcePostIntro');
      }
    }
  });

  return (
    <div className="ContentBody" ref={contentRef}>
      <JSXParser components={{ Button, Icon, PullQuote }} jsx={prepareComponents(content)} />
    </div>
  );
};

const prepareComponents = content => {
  // Grab all characters between a < and a >...
  return content?.replace(/<[^>]+>/g, match => {
    // ...and replace curly quotes with code quotes.
    return match.replace(/[“”]/g, '"').replace(/[‘’]/g, "'");
  });
};

export default CMSContent;
