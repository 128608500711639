import { useBoolState } from '@hometap/htco-components';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

const useNavItem = ({ active, open }) => {
  const isPressed = useBoolState();

  // htco- is a hack to remove the overrides in base.scss to avoid overriding font-color and hover
  // states with important tags. In some cases the styles in base.scss are use !important too so it
  // would be even more hacky to override them.
  const className = twMerge(
    cx(
      'htco- flex w-full items-center rounded-xl px-2 py-4 font-bold hover:bg-blue-5 border border-solid border-[transparent] transition-all duration-100',
      {
        'text-blue-100': active || isPressed.value,
        'pb-8': open,
        'text-neutral-dark-75': !active && !isPressed.value,
        'border-blue-15 bg-blue-5': open || active,
      },
    ),
  );

  return {
    onMouseDown: isPressed.setTrue,
    onMouseUp: isPressed.setFalse,
    onTouchStart: isPressed.setTrue,
    onTouchEnd: isPressed.setFalse,
    className,
  };
};

export default useNavItem;
