import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAsync } from '@hometap/htco-components';
import { apiWithAuth } from 'utils/api';

export const ROLLOUT_FLAGS = {
  self_serve_appraisal_type: 'self_serve_appraisal_type',
};

const fetchTrack = async id => {
  return await apiWithAuth.v1.get(`/tracks/${id}`);
};

export const patchTrack = async (id, data) => {
  return await apiWithAuth.v1.patch(`/tracks/${id}/`, data);
};

const TrackContext = createContext({});

export const TrackProvider = ({ children, trackId }) => {
  // allows trackId to be an optional prop that can be set by a consumer.
  const [currentTrackId, setCurrentTrackId] = useState(trackId);
  const { loading, error, results, execute: fetchExecute } = useAsync(fetchTrack, { defaultLoading: true });
  const patchAsync = useAsync(patchTrack, { executeThrow: true });

  useEffect(() => {
    if (currentTrackId) {
      fetchExecute(currentTrackId);
    }
  }, [currentTrackId, fetchExecute]);

  const fetch = async () => await fetchExecute(currentTrackId);
  const patch = async data => {
    await patchAsync.execute(currentTrackId, data);
    return await fetch();
  };

  const isRolloutFlagEnabled = flag => results?.enabled_rollout_flags?.includes(flag);

  const contextData = {
    patch: {
      ...patchAsync,
      execute: patch,
    },
    track: results,
    fetch,
    loading,
    error,
    setTrackId: useCallback(
      id => {
        if (trackId) {
          console.error("Can't set a trackId when defined as props");
          return;
        }

        setCurrentTrackId(id);
      },
      [trackId],
    ),
    isRolloutFlagEnabled,
  };

  return <TrackContext.Provider value={contextData}>{children}</TrackContext.Provider>;
};

const useTrack = () => useContext(TrackContext);

export default useTrack;
