import React from 'react';
import { Icon } from '@hometap/htco-components';

import { getPrimaryAppTrackDetailsUrl } from 'utils/links';

import './ApplicationHeader.scss';

const ApplicationHeader = ({ trackId, onToggleNavVisible }) => (
  <div className="ApplicationHeaderContainer">
    <button className="ApplicationHeaderMobileNav" onClick={onToggleNavVisible}>
      <Icon name="icon-menu" className="icon-2x" />
    </button>
    <a className="ApplicationHeaderCloseButton" href={getPrimaryAppTrackDetailsUrl(trackId)}>
      <Icon name="icon-cancel" className="icon-2x" />
    </a>
  </div>
);

export default ApplicationHeader;
