// ------------------------------
// Trustpilot
// ------------------------------

export const initTrustpilot = () => {
  const [widget] = document.getElementsByClassName('trustpilot-widget');
  loadTrustPilotElement(widget);
  // Since iframe events don't bubble up this is
  // how we can integrate segment tracking with trustpilot
  // window.addEventListener('blur', trackIframeClick);
};

export const loadTrustPilotElement = node => {
  if (window.Trustpilot && node) {
    window.Trustpilot.loadFromElement(node, true);
  }
};
