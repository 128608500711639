import { useEffect, useState } from 'react';
import { browserWindow } from './window';

const useLocalState = (initialValue, key) => {
  const keyWithPrefix = `htco${key}`;
  const [value, setValue] = useState(() => {
    if (typeof browserWindow.localStorage === 'object') {
      const localStorageValue = localStorage.getItem(keyWithPrefix);
      if (localStorageValue && localStorageValue !== 'undefined') {
        try {
          return JSON.parse(localStorageValue);
        } catch (e) {
          console.error('Failed to parse ', localStorageValue);
          return initialValue;
        }
      }
    }
    return initialValue;
  });

  useEffect(() => {
    localStorage.setItem(keyWithPrefix, JSON.stringify(value));
  }, [value, key, keyWithPrefix]);

  return [value, setValue];
};

export default useLocalState;
