import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, NotFoundBlock, useAsync, useBoolState } from '@hometap/htco-components';
import { browserTrack } from 'utils/segment';
import { fetchOffer, viewOffer } from './data/offerRequests';
import OfferBannerController from './components/OfferBannerController';
import OfferAccepted from './components/OfferAccepted';
import RundownLayout from 'apps/rundowns/components/RundownLayout';
import { getVersion } from 'utils/env';
import useLogAsyncError from 'hooks/useLogAsyncError';
import { getDashboardUrl } from 'utils/links';
import useTrack from 'hooks/useTrack';

const OfferController = () => {
  const { id } = useParams();
  const offerAsync = useAsync(fetchOffer, { immediate: true, params: [id] });
  const offer = offerAsync.results;
  const acceptedOffer = useBoolState(false);

  const { setTrackId, ...trackAsync } = useTrack();

  useEffect(() => {
    if (offer) {
      setTrackId(offer.track_id);

      if (offer && trackAsync.track) {
        viewOffer(offer.id);
        browserTrack.homeownerInvestmentRundownViewed({
          id: offer.id,
          document_type: 'offer',
          description: offer.description,
          track_role: trackAsync.track?.membership.role,
          version_number: getVersion(),
        });
      }
    }
  }, [offer, setTrackId, trackAsync.track]);

  useLogAsyncError('Failed to load Offer', offerAsync.error);
  if (offerAsync.error) {
    return <NotFoundBlock buttonLink={getDashboardUrl()} />;
  }

  if (offerAsync.loading || trackAsync.loading) {
    return (
      <div style={{ textAlign: 'center', margin: '50pt' }}>
        <Loader size="large"></Loader>
      </div>
    );
  }

  return (
    <div className="OfferController">
      {acceptedOffer.value === true ? (
        <OfferAccepted trackID={offer?.track_id} />
      ) : (
        <>
          {offer && <OfferBannerController offer={offer} trackAsync={trackAsync} onAccept={acceptedOffer.setTrue} />}
          <RundownLayout dataAsync={offerAsync} type="offers" />
        </>
      )}
      ;
    </div>
  );
};

export default OfferController;
