import React from 'react';
import TrackButton from '../../../../../../components/TrackButton/TrackButton';
import {
  CheckboxSet,
  DropdownSelector,
  FormError,
  LabelWithHelp,
  Toggle,
  useErrorScroll,
  useForm,
} from '@hometap/htco-components';

import {
  ALTERNATIVE_OPTIONS,
  INVESTMENT_BUCKET_OPTIONS,
  MONTHY_EXPENSE_OPTIONS,
  RAINY_DAY_FUND_OPTIONS,
  TERM_OPTIONS,
} from '../../../utils/fitQuiz/fitQuizDataUtils';

const QuizFinanceForm = ({ onSubmit }) => {
  const { handleSubmit, handleFieldChange, registerField, formData, formSubmitError } = useForm();
  useErrorScroll(formSubmitError);

  return (
    <div className="quiz-finance-form">
      <FormError standalone error={formSubmitError} />
      <h1>Thanks! Next, tell us a bit about your financial situation:</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DropdownSelector
          label={
            <LabelWithHelp
              label="What is your ideal Hometap Investment amount?"
              helpText={
                <span>
                  <b>FYI</b> - We can invest up to $600,000 in your home. Most homeowners ask for between $50,000 and
                  $100,000.
                </span>
              }
            />
          }
          aria-label="What is your ideal Hometap Investment amount?"
          required
          options={INVESTMENT_BUCKET_OPTIONS.map(bucket => {
            return { label: bucket, value: bucket };
          })}
          {...registerField('ideal_investment_amount')}
        />

        <DropdownSelector
          label="Is this your forever home?"
          required
          options={TERM_OPTIONS}
          {...registerField('term')}
        />

        <CheckboxSet
          label="Are you considering any other option alongside Hometap? Choose all that apply:"
          required
          options={ALTERNATIVE_OPTIONS}
          {...registerField('alternatives_considered')}
          onChange={(value, name) => {
            if (value.includes('none')) {
              if (formData.alternatives_considered && formData.alternatives_considered.includes('none')) {
                value = value.filter(val => val !== 'none');
              } else {
                value = ['none'];
              }
            }
            handleFieldChange(value, name);
          }}
        />

        <DropdownSelector
          label={
            <LabelWithHelp
              label={`How would you describe your "rainy day fund"`}
              helpTitle="Why do we ask this?"
              helpText="Thinking about what you’ve tucked away vs. what you might need in an emergency can be useful in helping you evaluate Hometap."
            />
          }
          aria-label="How would you describe your rainy day fund"
          required
          options={RAINY_DAY_FUND_OPTIONS}
          {...registerField('rainy_day_fund')}
        />

        <Toggle
          label={
            <LabelWithHelp
              label="Do you ever worry about covering monthly expenses?"
              helpTitle="Be Honest!"
              helpText="This is just to help you assess fit, not for us to qualify you!"
            />
          }
          options={MONTHY_EXPENSE_OPTIONS}
          {...registerField('monthly_expenses_concern')}
        />

        <TrackButton eventProperties={{ destination: '/fit-quiz' }} type="submit">
          Continue
        </TrackButton>
      </form>
    </div>
  );
};

export default QuizFinanceForm;
