import React from 'react';
import cx from 'classnames';
import { Helptip } from '@hometap/htco-components';
import PropTypes from 'prop-types';
import './DataList.scss';

const DataList = ({ data = [], className }) => {
  return (
    <table className={cx(className, 'DataList')}>
      <tbody>
        {data.map(row => {
          const { label, value, rowClass, helpTip } = row;
          return (
            <tr key={label} className={rowClass}>
              <td>
                <span className="DataListLabel">{label}</span>
                {helpTip && <Helptip {...helpTip} />}
              </td>
              <td className="DataListValue">{value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataList;

DataList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      rowClass: PropTypes.string,
      helpTip: PropTypes.shape({ text: PropTypes.string }),
    }),
  ),
};
