/**
 * Demo data to populate fields in the equity planner onboarding pages
 *
 */
export const demoOnboardingData = () => {
  return {
    first_name: 'John',
    last_name: 'Doe',
    email: `test+${Math.random().toString(36).substring(5)}@hometap.com`,
    phone_number: '555-555-5555',
    address: '790 Boylston St, Boston, MA 02199, USA',
    street: '790 Boylston St',
    city: 'Boston',
    state: 'MA',
    zip_code: '02199',
    unit: '20i',
    onboarding_goal: 'curious',
    hasMortgage: 'yes',
    lien_kind: '30_year_fixed',
    rate: 5.125,
    last_known_current_balance: 165540.85,
    monthly_payment: 1445.52,
  };
};
