import React, { useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useStateInfo from '../../utils/useStates';
import { Loader, ProgressIndicator, useWin } from '@hometap/htco-components';
import { scrollTop } from 'utils/domScripts';
import { fromQueryString, toQueryString } from '../../utils/urlUtilities';

import TrustPilot from '../../../inquiry/components/TrustPilot';
import QuizBasicForm from '../components/fitQuizForms/QuizBasicForm/QuizBasicForm';
import QuizFinanceForm from '../components/fitQuizForms/QuizFinanceForm/QuizFinanceForm';
import QuizPersonalForm from '../components/fitQuizForms/QuizPersonalForm/QuizPersonalForm';
import QuizUseCaseForm from '../components/fitQuizForms/QuizUseCaseForm/QuizUseCaseForm';
import { convertIsHomeownerParam, getFieldsForUrl } from '../utils/fitQuiz/fitQuizDataUtils';
import { getFitQuizCookieData, trackQuizData } from '../utils/fitQuiz/fitQuizTrackRequest';
import useTrackingData from '../../../dashboard/hooks/useTrackingData';

import '../FitQuiz.scss';

const PAGES_NAMES = ['Basics', 'Uses', 'Financial Info', 'Final Details', 'Your Results!'];
const PAGE_KEYS = ['basic', 'uses', 'finance', 'info', 'results'];
const getStep = page => {
  // Add 1 b/c index starts at 0, and another 1 for the old intro page
  return PAGE_KEYS.indexOf(page) + 2;
};

const formatInstapageParams = ({ state, is_homeowner } = {}) => {
  const instapageHomeOwnerStatus = is_homeowner && convertIsHomeownerParam(is_homeowner);
  return {
    is_homeowner: instapageHomeOwnerStatus !== 'no',
    homeowner_status: instapageHomeOwnerStatus,
    state: state,
  };
};

const FitQuizController = () => {
  const { isStateOperational, stateMap, abbreviationMap } = useStateInfo();
  const [page, setPage] = useState('basic');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const pageIndex = PAGE_KEYS.indexOf(page);
  const history = useHistory();
  const [, setCookie] = useCookies();
  const flags = useFlags();
  const trackingData = useTrackingData();

  const win = useWin(() => {
    const urlParams = win && fromQueryString(win.location.search);
    const instapageParams = {};

    if (urlParams.hasOwnProperty('state') && urlParams.hasOwnProperty('is_homeowner')) {
      instapageParams.is_homeowner = urlParams.is_homeowner;
      instapageParams.state = abbreviationMap[urlParams.state];
    }

    if (instapageParams && Object.keys(instapageParams).length > 0) {
      setLoading(true);
      // This timeout ensures that Page Viewed fires before Fit Quiz specific events
      setTimeout(() => {
        handleNavTo(PAGE_KEYS[1])(formatInstapageParams(instapageParams), 2);
      }, 200);
    }
  });

  const handleNavTo = nextPage => {
    return (subFormData = {}, stepOverride) => {
      const newFormData = { ...formData, ...subFormData };
      newFormData.stateStatus = stateMap[newFormData.state].status;
      const step = stepOverride || getStep(page);
      const { name, value, options } = getFitQuizCookieData(newFormData);
      setCookie(name, value, options);

      if (newFormData.hasOwnProperty('is_homeowner') && !newFormData.is_homeowner) {
        trackQuizData(step, { ...newFormData, utmData: trackingData, flags }, 'unqualified');
        handleGoToResult({ result: 'not_homeowner' });
      } else if (newFormData.hasOwnProperty('state') && newFormData.state && !isStateOperational(newFormData.state)) {
        trackQuizData(step, { ...newFormData, utmData: trackingData, flags }, 'unqualified');
        handleGoToResult({ result: 'state_not_operational', state: newFormData.state });
      } else if (nextPage === 'result') {
        trackQuizData(step, { ...newFormData, utmData: trackingData, flags }, 'mql');
        handleGoToResult({ result: 'done', ...newFormData });
      } else {
        trackQuizData(step, { ...newFormData, utmData: trackingData, flags }, 'mql');
        setLoading(false);
        setPage(nextPage);
        setFormData(newFormData);
        scrollTop();
      }
    };
  };

  const handleGoToResult = paramData => {
    history.push(`/fit-quiz-results?${toQueryString(getFieldsForUrl(paramData))}`);
  };

  const renderForm = () => {
    switch (page) {
      case 'basic':
        return <QuizBasicForm onSubmit={handleNavTo(PAGE_KEYS[1])} />;
      case 'uses':
        return <QuizUseCaseForm onSubmit={handleNavTo(PAGE_KEYS[2])} />;
      case 'finance':
        return <QuizFinanceForm onSubmit={handleNavTo(PAGE_KEYS[3])} />;
      case 'info':
        return <QuizPersonalForm onSubmit={handleNavTo('result')} useCase={formData.use_case} />;
      default:
        return <QuizBasicForm onSubmit={handleNavTo(PAGE_KEYS[1])} />;
    }
  };

  return (
    <div className={cx('FitQuizController', page)}>
      {page === 'basic' && !loading && (
        <div className="FitQuizIntro">
          <div className="FitQuizIntroContent">
            <h1>Is Hometap right for me?</h1>
            <p>
              Hometap provides homeowners like you with a smart new loan alternative for tapping into your home equity
              without taking on debt. Is Hometap a good option for you?{' '}
              <strong>Take our 2 minute assessment to find out.</strong>
            </p>
          </div>
        </div>
      )}
      <div className={cx('FitQuizWrapper', { isLoading: loading })}>
        {loading ? (
          <Loader className="FitQuizLoader" size="large" />
        ) : (
          <>
            <ProgressIndicator steps={PAGES_NAMES} current={pageIndex > -1 && pageIndex} />
            <div className="FitQuizForms">{renderForm()}</div>
          </>
        )}
      </div>
      <TrustPilot />
    </div>
  );
};

export default FitQuizController;
