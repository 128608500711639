import React, { useMemo } from 'react';
import { BarChart, Icon } from '@hometap/htco-components';
import { barChartDebtCalculations } from '../../utils/debtCalculations';
import { NUMBER_FORMATS } from 'apps/htdc/utils/formatters';

const DebtBarChart = ({ debts, height = 350 }) => {
  const SelectableIcon = ({ name, selectedColor }) => <Icon name={name} color={selectedColor} />;

  const barChartData = useMemo(() => barChartDebtCalculations(debts), [debts]);
  return (
    <BarChart
      height={height}
      isStacked
      tooltipFormatter={(value, name) => [
        NUMBER_FORMATS.currency(value, 0),
        name.charAt(0).toUpperCase() + name.slice(1),
      ]}
      xTickFormatter={xTick => NUMBER_FORMATS.currency(xTick, 0)}
      chartData={barChartData}
      series={[
        { label: 'Balance', key: 'balance', color: ({ balanceColor }) => balanceColor },
        { label: 'Interest', key: 'interest', color: ({ interestColor }) => interestColor },
      ]}
      xAxisMobileTicks={({ balanceColor, icon }, isSelected) => (
        <SelectableIcon name={icon} selectedColor={isSelected ? balanceColor : '#0F172A'} />
      )}
      breaksOn="md"
    />
  );
};

export default DebtBarChart;
