import React from 'react';
import { Header, IconButton } from '@hometap/htco-components';
import { getPrimaryAppTrackDetailsUrl } from 'utils/links';
import useTrack from 'hooks/useTrack';

const ModalPageHeaderLite = ({ onClose }) => {
  const { track } = useTrack();

  const handleClose = () => {
    if (onClose) {
      onClose(track?.id);
    } else if (track) {
      window.location = getPrimaryAppTrackDetailsUrl(track.id);
    } else {
      window.history.back();
    }
  };

  return (
    <div>
      <Header className="border-none px-0">
        <div className="flex flex-grow justify-end">
          <IconButton
            data-testid="modal-page-close-button"
            className="-mr-1 text-base text-neutral-dark-100"
            icon="icon-close"
            size="2x"
            onClick={handleClose}
          />
        </div>
      </Header>
    </div>
  );
};

export default ModalPageHeaderLite;
