import { useEffect, useMemo } from 'react';
import { useAsync } from '@hometap/htco-components';
import useTrack from 'hooks/useTrack';
import apiWithAuth from 'utils/api';
import sentry from 'utils/sentry';
import useApplicants from 'apps/application/hooks/useApplicants';

const fetchStatement = (investmentId, statementId) =>
  apiWithAuth.v1.get(`/investments/${investmentId}/statements/${statementId}/`);

const markStatementAsViewed = (investmentId, statementId) =>
  apiWithAuth.v1.post(`/investments/${investmentId}/statements/${statementId}/action/view/`);

const useInvestmentStatementPage = (investmentId, statementId) => {
  const { track, loading: trackLoading, error: trackError } = useTrack();

  const statementAsync = useAsync(fetchStatement, { defaultLoading: true });
  const { execute: fetch } = statementAsync;

  const {
    applicants,
    fetchLoading: applicantsLoading,
    executeFetchApplicants: fetchApplicants,
    fetchError: applicantsError,
  } = useApplicants();

  useEffect(() => {
    fetch(investmentId, statementId);
  }, [fetch, investmentId, statementId]);

  useEffect(() => {
    if (track) {
      fetchApplicants({ applicationId: track.active_application_id });
    }
  }, [track, fetchApplicants]);

  const isPrimaryUser = useMemo(() => track?.membership.role === 'primary', [track]);

  const statement = useMemo(() => {
    const statementData = statementAsync.results;
    if (!statementData) return null;

    const homeValue = parseFloat(statementData.home_valuation.value);

    const htShare = parseFloat(statementData.hometap_share);
    // statement.homeowner_share does not include liens balance.
    // We need share including all liens balance, so we just subtract Hometap's share from the home value.
    const hoShare = homeValue - htShare;

    return {
      ...statementData,
      hometapShare: {
        dollars: htShare,
        percent: (htShare / homeValue) * 100,
        crrPercent: parseFloat(statementData.crr_percent) * 100,
        isShareCapped: statementData.is_share_capped,
      },
      homeownerShare: {
        dollars: hoShare,
        percent: (hoShare / homeValue) * 100,
      },
      homeValue,
    };
  }, [statementAsync.results]);

  return {
    loading: trackLoading || statementAsync.loading || applicantsLoading,
    fetch: statementAsync.execute,
    statement: statement,
    track,
    applicants,
    isPrimaryUser,
    error: trackError || statementAsync.error || applicantsError,
  };
};

export const useInvestmentStatementMarkAsViewed = statement => {
  const { execute } = useAsync(markStatementAsViewed);
  useEffect(() => {
    if (statement) {
      execute(statement.investment.id, statement.id).catch(error => {
        sentry.logErrorWrapper(`Failed to mark statement "${statement.id}" as viewed`, error);
      });
    }
  }, [statement, execute]);
};

export default useInvestmentStatementPage;
