import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ContactInfo from './contact-info/ContactInfo';
import FAQList from 'components/FAQList/FAQList';
import {
  aboutMyDashboardFAQs,
  aboutHomeEquityFAQs,
  aboutMyInvestmentFAQs,
  settlingMyInvestmentFAQs,
  accountManagementFAQs,
  INVESTED_HOMEOWNER_SUPPORT_NUMBER,
  HED_USER_SUPPORT_NUMBER,
  PRODUCT_SUPPORT_EMAIL,
  INVESTMENT_SUPPORT_EMAIL,
} from 'apps/dashboard/constants';
import useUserTracksContext from 'hooks/useUserTracksContext';
import Footer from 'components/Footer/Footer';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper/SectionLoadingWrapper';
import investmentCat from '../../../../images/dashboard/investment-support-cat.png';
import generalDog from '../../../../images/dashboard/general-contact-dog.png';
import investmentDog from '../../../../images/dashboard/investment-support-dog.png';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import './DashboardSupport.scss';

const DashboardSupport = () => {
  const { tracks, loading } = useUserTracksContext();
  const flags = useFlags();

  // On initial page load, loading is false before tracks are grabbed.
  // We don't want to redirect users before the call is fired.
  const userRole = tracks?.[0]?.membership.role || 'hed' || null;
  const userHasTracks = tracks?.length > 0;

  const preInvestmentStageList = [
    'ApplicationReady',
    'ApplicationInProgress',
    'ApplicationInReview',
    'ApplicationApproved',
    'InitialOfferPrepared',
    'OfferReady',
    'ConditionsInReview',
    'ClosingInProgress',
  ];
  const postInvestmentStageList = ['Invested'];

  const investmentManagers = {};
  tracks?.forEach(track => {
    if (track.investment_manager && preInvestmentStageList.includes(track.current_stage)) {
      const imID = track.investment_manager.id;
      const ids = investmentManagers[imID]?.friendly_ids || [];
      ids.push(track.friendly_id);

      investmentManagers[imID] = {
        friendly_ids: ids,
        investment_manager: track.investment_manager,
      };
    }
  });
  const uniqInvestmentManagers = Object.values(investmentManagers);
  const showInvestmentManager = uniqInvestmentManagers.length > 0;

  const postInvestmentTrack = tracks?.find(track => {
    return postInvestmentStageList.includes(track.current_stage);
  });

  const investmentSupportContactInfo = {
    scheduling_url: 'https://calendly.com/investment-support',
    headshot_url: investmentCat,
    phone_number: INVESTED_HOMEOWNER_SUPPORT_NUMBER,
    email: INVESTMENT_SUPPORT_EMAIL,
  };

  const generalContactInfo = {
    headshot_url: investmentDog,
    phone_number: INVESTED_HOMEOWNER_SUPPORT_NUMBER,
  };

  const hedUserContactInfo = {
    headshot_url: generalDog,
    email: PRODUCT_SUPPORT_EMAIL,
  };

  if (flags.showPhoneNumber) {
    hedUserContactInfo.phone_number = HED_USER_SUPPORT_NUMBER;
  }

  const DashboardSupportContactTitle = (
    <h2>{!showInvestmentManager && !postInvestmentTrack ? 'Need Support? We can help!' : 'My Hometap Team'}</h2>
  );

  return (
    <ScrollToTop>
      <SectionLoadingWrapper loading={loading || !userRole}>
        <div className="DashboardSupport">
          <div className="DashboardSupportContact">
            {userHasTracks && (
              <div className="DashboardSupportContactContainer PageBodyCenteredContainer">
                <p className="mb-0 mt-8 text-sm font-bold text-neutral-dark-75">Get help</p>
                {DashboardSupportContactTitle}
                {uniqInvestmentManagers.map(im => (
                  <ContactInfo
                    friendly_id={im.friendly_ids.join(', ')}
                    contact_information={im.investment_manager}
                    card_title="Investment Manager"
                    card_description="Hometap Investment Managers work with you from start to finish to get your investment funded."
                  />
                ))}
                {postInvestmentTrack && (
                  <ContactInfo
                    friendly_id={postInvestmentTrack.friendly_id}
                    card_title="Investment Support"
                    contact_information={investmentSupportContactInfo}
                    card_description="After your investment is funded, Hometap’s Investment Support team can help you settle when you are ready, or answer any questions along the way."
                  />
                )}
                {!showInvestmentManager && !postInvestmentTrack && (
                  <ContactInfo
                    card_title="Contact Us"
                    contact_information={generalContactInfo}
                    card_description="Call us toll-free Monday - Friday: 9am - 5pm EST"
                  />
                )}
              </div>
            )}
            {!userHasTracks && userRole === 'hed' && (
              <div className="DashboardSupportContactContainer PageBodyCenteredContainer">
                <p className="mb-0 mt-8 text-sm font-bold text-neutral-dark-75">Get help</p>
                {DashboardSupportContactTitle}
                <ContactInfo
                  card_title="Contact Us"
                  contact_information={hedUserContactInfo}
                  card_description="Have questions about the Home Equity Dashboard? Reach out to us at the email below."
                />
              </div>
            )}
          </div>

          <div className="DashboardSupportFAQ">
            <div className="DashboardSupportFAQContainer PageBodyCenteredContainer">
              <h2>Frequently Asked Questions</h2>
              {userHasTracks && <FAQList heading="About my Investment" faqs={aboutMyInvestmentFAQs} />}
              {userHasTracks && <FAQList heading="Settling my Investment" faqs={settlingMyInvestmentFAQs} />}
              <FAQList heading="About Home Equity" faqs={aboutHomeEquityFAQs} />
              {userRole !== 'co-applicant' && <FAQList heading="About my Dashboard" faqs={aboutMyDashboardFAQs} />}
              <FAQList heading="Account management" faqs={accountManagementFAQs} />
            </div>
            <Footer />
          </div>
        </div>
      </SectionLoadingWrapper>
    </ScrollToTop>
  );
};

export default DashboardSupport;
