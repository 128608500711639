import { Switch, Route, Redirect } from 'react-router-dom';
import { PlannerOnboard } from '../equity-planner-onboarding';
import { EQUITY_PLANNER_URL, ONBOARD_URL, ONBOARD_URL_WITH_PARAMS, SHARE_URL } from 'apps/dashboard/constants';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import useCurrentUser from 'hooks/useCurrentUser';
import { getLoginUrlWithEquityDashboardNextStep } from 'utils/links';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import { Helmet } from 'react-helmet-async';
import './EquityPlanner.scss';

const EquityPlannerRouter = () => {
  const { user } = useCurrentUser();
  const seoTitle = 'Hometap | Manage your home equity';
  const seoDescription =
    'Access all of the digital tools and resources you need to better manage, monitor, and forecast your home equity in order to maximize the value of your largest financial asset.';
  const ADDRESS_URL = `${ONBOARD_URL}/address`;
  const WELCOME_URL = `${ONBOARD_URL}/welcome`;

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription}></meta>
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
      </Helmet>
      <Switch>
        {/* logged in users should not be able to run through onboarding */}
        {!user && (
          <Route path={ONBOARD_URL} exact>
            <PlannerOnboard />
          </Route>
        )}
        {!user && (
          <Route path={WELCOME_URL} exact>
            <PlannerOnboard />
          </Route>
        )}
        {!user && (
          <Route path={ADDRESS_URL} exact>
            <PlannerOnboard stepOverride={1} />
          </Route>
        )}
        {!user && (
          <Route path={ONBOARD_URL}>
            <Redirect push to={ADDRESS_URL} />
          </Route>
        )}
        {!user && (
          <Route path={EQUITY_PLANNER_URL} exact>
            <Redirect push to={ONBOARD_URL} />
          </Route>
        )}
        {!user && (
          <Route path={SHARE_URL} exact>
            <Redirect push to={ONBOARD_URL_WITH_PARAMS} />
          </Route>
        )}
        <PrivateRoute redirect={getLoginUrlWithEquityDashboardNextStep()}>
          <Redirect to={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`} />
        </PrivateRoute>
      </Switch>
    </>
  );
};

export default EquityPlannerRouter;
