import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@hometap/htco-components';
import './EquityScenarioCard.scss';

const EquityScenarioCard = ({ title, description, icon, iconTheme, disabled = false, onClick, ...props }) => {
  return (
    <button className="EquityScenarioCard" disabled={disabled} onClick={onClick} {...props}>
      <div className={cx('EquityScenarioCardIconContainer', { isDark: iconTheme === 'dark' })}>
        <Icon size="2x" name={icon} color="white" />
      </div>
      <div className="EquityScenarioCardContent">
        <div className="EquityScenarioCardTitle">{title}</div>
        <div className="EquityScenarioCardDescription">{description}</div>
      </div>
    </button>
  );
};

EquityScenarioCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconTheme: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
export default EquityScenarioCard;
