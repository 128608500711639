import { NotFoundBlock } from '@hometap/htco-components';
import React from 'react';

const ExceededVerificationAttempts = ({ error }) => {
  return (
    <NotFoundBlock
      error=""
      className="CoApplicantExceededVerification"
      title="Whoops! Those details don’t match the ones we have on file for you."
    >
      <p>
        Please give us a call at {error?.response?.data?.reviewer_phone || '(855) 223-3144'} so we can straighten this
        out.
      </p>
    </NotFoundBlock>
  );
};

export default ExceededVerificationAttempts;
