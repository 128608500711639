import { MuiTextInput } from '@hometap/htco-components';
import { SHARED_INPUT_PROPS, TEXT_INPUTS } from './sharedFields';
import { positiveValueValidator } from 'utils/validators';

const HouseCanaryJudgementOrOtherForm = ({ registerField }) => {
  return TEXT_INPUTS.map(input => (
    <MuiTextInput
      key={input.label}
      {...SHARED_INPUT_PROPS}
      label={input.label}
      aria-label={input.label}
      mask={input.mask}
      startAdornment={input.startAdornment}
      endAdornment={input.endAdornment}
      type={input.type}
      inputMode={input.inputMode}
      placeholder={input.placeholder}
      required={input.required || true}
      helperText={input.helperText}
      validator={positiveValueValidator}
      {...registerField(input.name)}
    />
  ));
};

export default HouseCanaryJudgementOrOtherForm;
