import React from 'react';
import PropTypes from 'prop-types';
import Insight from 'components/insight/Insight';
import { FINANCIAL_GOAL_CALCULATOR_LABEL } from 'apps/dashboard/constants/financialGoals';
import { FINANCE_QUALIFICATION_CARD_TYPE } from './data/financeData';
import './FinanceOptionOverview.scss';

const FinanceQualificationCard = ({ title, value, valueToDisplay, type }) => {
  return (
    <div className="FinanceQualificationCard">
      <div className="FinanceQualificationCardLabel">{title}</div>
      <h1 className="FinanceQualificationCardValue">{valueToDisplay ? valueToDisplay : value}</h1>
      {type === FINANCE_QUALIFICATION_CARD_TYPE.PERCENT && (
        <div className="FinanceCardProgressBarContainer">
          <div className="FinanceCardProgressBarFill" style={{ width: value }}></div>
        </div>
      )}
    </div>
  );
};

const FinanceOptionOverview = ({ optionOverview, financialGoal }) => {
  const { title, description, primarySection, otherSections, tapToInsights } = optionOverview;
  const financialGoalLabel = FINANCIAL_GOAL_CALCULATOR_LABEL[financialGoal] || FINANCIAL_GOAL_CALCULATOR_LABEL.other;

  return (
    <div className="FinanceOptionOverview">
      <div className="FinanceOptionOverviewHeader">
        <div className="FinanceOptionOverviewLabel">
          Fund your{' '}
          <span className="FinanceOptionOverviewLabelHighlight" data-testid="finance-calculator-goal-title">
            {financialGoalLabel}
          </span>{' '}
          with a
        </div>
        <h1 className="FinanceOptionTitle">{title}</h1>
        <p className="FinanceOptionDescription">{description}</p>
      </div>

      {primarySection && (
        <div className="FinanceOptionDataContainer">
          <h3 className="FinanceOptionDataTitle">What do I need to know?</h3>
          {primarySection.paragraphs && (
            <div className="FinanceOptionDataDescription">
              {primarySection.paragraphs.map((paragraph, i) => (
                <p key={`primary_section_paragraph_${i}`}>{paragraph}</p>
              ))}
            </div>
          )}

          {primarySection.bulletPoints && (
            <ul className="FinanceOptionDataList">
              {primarySection.bulletPoints.map((item, i) => (
                <li className="DataListItem" key={`primary_section_list_${i}`}>
                  {item.highlightedCopy && <span className="highlightedText">{item.highlightedCopy}</span>}
                  {item.copy}
                </li>
              ))}
            </ul>
          )}

          {primarySection.whoQualifies && (
            <div className="FinanceQualificationCardContainer">
              <>
                <div className="FinanceQualificationCardTitle">Who qualifies?</div>
                <div
                  className={`FinanceQualificationCardWrapper ${
                    primarySection.whoQualifies.length >= 3 ? 'responsiveCardWrapper' : ''
                  }`}
                >
                  {primarySection.whoQualifies.map((qualification, i) => (
                    <FinanceQualificationCard
                      key={`qualification_card_${i}`}
                      title={qualification.title}
                      value={qualification.value}
                      valueToDisplay={qualification.valueToDisplay}
                      type={qualification.type}
                    />
                  ))}
                </div>
              </>
            </div>
          )}
        </div>
      )}

      {otherSections?.map((section, i) => (
        <div className="FinanceOptionDataContainer" key={`section_${i}`}>
          <h3 className="FinanceOptionDataTitle">{section.title}</h3>
          <div className="FinanceOptionDataDescription">
            {section.paragraphs &&
              section.paragraphs.map((paragraph, i) => (
                <p key={`section_paragraph_${i}`}>
                  {paragraph.highlightedCopy && <span className="highlightedText">{paragraph.highlightedCopy}</span>}
                  {paragraph.copy}
                </p>
              ))}
          </div>
          {section?.description && <div className="FinanceOptionDataDescription">{section.description}</div>}
          {section.bulletPoints && (
            <ul className="FinanceOptionDataList">
              {section.bulletPoints.map((item, i) => (
                <li className="DataListItem" key={`section_list_${i}`}>
                  {item.highlightedCopy && <span className="highlightedText">{item.highlightedCopy}</span>}
                  {item.copy}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}

      {tapToInsights && <Insight text={tapToInsights} />}
    </div>
  );
};

const FinanceQualificationCardPropTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueToDisplay: PropTypes.string,
  type: PropTypes.oneOf(Object.values(FINANCE_QUALIFICATION_CARD_TYPE)),
};

const propTypesShape = PropTypes.shape({
  highlightedCopy: PropTypes.string,
  copy: PropTypes.string.isRequired,
});

FinanceOptionOverview.propTypes = {
  optionOverview: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    primarySection: PropTypes.shape({
      bulletPoints: PropTypes.arrayOf(propTypesShape),
      paragraphs: PropTypes.arrayOf(PropTypes.string),
      whoQualifies: PropTypes.arrayOf(PropTypes.shape(FinanceQualificationCardPropTypes)),
    }),
    otherSections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        paragraphs: PropTypes.arrayOf(propTypesShape),
        bulletPoints: PropTypes.arrayOf(propTypesShape),
      }),
    ),
    tapToInsights: PropTypes.string,
  }).isRequired,
};

export default FinanceOptionOverview;
