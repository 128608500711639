import React from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { postAVM, fetchHomeValuation, updateHome } from 'apps/dashboard/data/homeEquityApi';

import useUserTracksContext from 'hooks/useUserTracksContext';
import { useCurrentHome } from 'hooks/useCurrentHome';

const useHomeValuationQuery = home => {
  const { tracks = [], loading: tracksLoading } = useUserTracksContext();
  const queryKey = homeValuationQueryKey(home);
  return useQuery({
    queryFn: () => getHomeValuation(home, tracks),
    queryKey,
    enabled: !!home && !tracksLoading,
  });
};

export const useHomeValuationMutation = home => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async value => await updateHome(home.id, { homeowner_estimated_home_value: value }),
    onSuccess: newHome => {
      queryClient.setQueryData(['user.homes'], homes => {
        return homes.map(oldHome =>
          oldHome.id !== home.id
            ? oldHome
            : {
                ...oldHome,
                ...newHome,
              },
        );
      });
    },
  });
};

const getRelevantHomeValFromTrack = tracks => {
  const relevantHomeValueTracks = tracks
    .filter(track => track?.relevant_home_valuation?.invalidated === false)
    .sort((a, b) => new Date(b.inquiry_created_date) - new Date(a.inquiry_created_date));
  return relevantHomeValueTracks?.[0]?.relevant_home_valuation?.value;
};

const processHomeValuationData = (estimatedHomeVal, homeValuationData, tracks) => {
  const relevantHomeValue = getRelevantHomeValFromTrack(tracks);
  if (estimatedHomeVal) {
    return {
      value: parseInt(estimatedHomeVal),
      avm: homeValuationData || null,
    };
  }
  if (relevantHomeValue) {
    return { value: relevantHomeValue };
  }
  return homeValuationData || {};
};

const getHomeValuation = async (home, tracks) => {
  if (home.is_eligible_for_avm_request) {
    await postAVM(home.id);
  }
  const homeValuation = await fetchHomeValuation(home.id);
  return processHomeValuationData(home.homeowner_estimated_home_value, homeValuation, tracks);
};

const homeValuationQueryKey = home => [
  'home.valuation',
  { homeId: home?.id, estimated: home?.homeowner_estimated_home_value },
];

const Context = React.createContext({});

export const useHomeValuation = () => React.useContext(Context);

export const HomeValuationProvider = ({ children }) => {
  const { home } = useCurrentHome();
  const { data: homeValuation, isLoading } = useHomeValuationQuery(home);
  return <Context.Provider value={{ homeValuation, isLoading }}>{children}</Context.Provider>;
};
