import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@hometap/htco-components';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import ResourcesCard from './ResourcesCard';
import PromotionalOfferCard from './promotional-offer-card/PromotionalOfferCard';
import {
  FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID,
  FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID,
} from 'apps/dashboard/constants';
import './ResourcesSection.scss';

const ResourcesSection = ({
  title = '',
  subTitle = '',
  resources = [],
  showButton = false,
  onPostClick = () => {},
}) => {
  return (
    <div className="DashboardContainerDark">
      <div className={cx('PageBodyCenteredContainer', 'ResourceArticleLists')}>
        {title && <h2 className="ResourcesSectionTitle">{title}</h2>}
        {subTitle && <div className="ResourcesSectionSubTitle">{subTitle}</div>}
        <div className="ResourcesSectionGridWrapper">
          <Grid container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 2 }}>
            {resources.map((resource, i) => (
              <Grid xs={12} sm={6} md={6} lg={4} key={`${resource?.title}-${i}`}>
                {resource?.id === FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID ||
                resource?.id === FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID ? (
                  <PromotionalOfferCard {...resource} />
                ) : (
                  <ResourcesCard isLoading={false} onClick={onPostClick} isExternal={false} {...resource} />
                )}
              </Grid>
            ))}
          </Grid>
          {showButton && (
            <div className="ResourcesSectionButtonWrapper">
              <Link to={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}`} className="ResourcesCardLink">
                <Button size="small" theme="alternative">
                  View all resources
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResourcesSection;
