import { apiWithAuth } from 'utils/api';

export const fetchTracks = async () => {
  return await apiWithAuth.v1.get(`/tracks/`);
};

export const fetchPendingTracks = async () => {
  return await apiWithAuth.v1.get('/tracks/pending/');
};

export const fetchEquityPlannerHomes = async () => {
  const homes = await apiWithAuth.v1.get('/homes/');
  return homes.filter(home => {
    if (!home?.tracks?.length) {
      return true;
    }
    return false;
  });
};
