import { apiWithAuth } from 'utils/api';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';

// IMPORTANT: Be sure to always call `viewRundown` when a homeowner views a Rundown. This is
// necessary for a legal audit trail.

export const fetchRundown = async id => {
  return await apiWithAuth.v1.get(`/rundowns/${id}`);
};

export const createAndFetchShareFractions = async (home_value, investment_amount) => {
  return await apiWithAuth.v1.post('/pricing/share-fractions', { home_value, investment_amount });
};

export const fetchShareFraction = async (id, type, amount_gross, home_value) => {
  const params = type === 'rundowns' ? { amount_gross, home_value } : {};
  const results = await apiWithAuth.v1.get(`/${type}/${id}/share-fractions`, { params });
  return results.sort((a, b) => a.range_years_upper - b.range_years_upper);
};

export const sendInteractionEvent = (type, rundown = {}, misc = {}) => {
  browserTrack.homeownerInvestmentRundownInteractionOccurred({
    id: rundown.id,
    interaction_type: type,
    description: rundown.description,
    document_type: rundown.is_offer ? 'offer' : 'rundown',
    version_number: getVersion(),
    ...misc,
  });
};

export const viewRundown = async id => {
  await apiWithAuth.v1.post(`/rundowns/${id}/actions/view/`);
};
