export const FINANCIAL_GOAL_CALCULATOR_LABEL = {
  debt_payoff: 'debt payoff',
  portfolio_diversification: 'investment portfolio',
  home_improvement: 'home improvement',
  life_events_or_emergency_funding: 'life event',
  small_business_funding: 'small business',
  bridge_to_my_next_home: 'next home purchase',
  retirement: 'retirement',
  divorce_transition: 'divorce transition',
  education: 'education',
  family_expansion: 'family expansion',
  investment_property_or_vacation_home: 'next home purchase',
  other: 'goals',
};
