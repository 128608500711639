import React from 'react';
import { Helptip } from '@hometap/htco-components';
import { currency } from 'utils/numbers';

const InquirySuccessfulCTAOutcome = ({ upToAmount, name, isAdditionalTrack, isPartnerOutcome, investmentAmount }) => {
  const renderPartnerSelectedAmount =
    isPartnerOutcome && investmentAmount && investmentAmount > 0 && investmentAmount < upToAmount;

  let outcomeHeaderContent;

  if (upToAmount && name) {
    outcomeHeaderContent = (
      <>
        {name}, you could get up to{' '}
        <span className="InquiryOutcomeUpToAmountWrapper">
          <span className="InquiryOutcomeUpToAmount">{currency(upToAmount)}</span>
          <Helptip
            title="How did we calculate this number?"
            text="This amount is based on our current estimated value of your home. It may change once we complete a more detailed valuation analysis of your property and determine how much equity is available in your home."
            className="InquiryOutcomeHelpTip"
          />
        </span>
        <br /> from Hometap!
      </>
    );
  } else if (isPartnerOutcome) {
    outcomeHeaderContent = `You’ve been pre-qualified for a Hometap Investment up to ${currency(upToAmount)}!`;
  } else {
    outcomeHeaderContent = 'You are prequalified for a Hometap Investment!';
  }
  return (
    <>
      <div className="InquiryOutcomeHeaderContainer">
        <h1>{outcomeHeaderContent}</h1>
      </div>

      <div className="InquiryOutcomeSubTextContainer">
        {renderPartnerSelectedAmount && (
          <h3 className="InquiryOutcomePartnerSubheader">
            Selected Investment amount:
            <span className="InquiryOutcomeInvestmentAmount"> {currency(investmentAmount)}</span>
          </h3>
        )}
        {isAdditionalTrack ? (
          <>
            Your Hometap Investment Manager will personalize your Investment Scenario Planner and let you know as soon
            as it's ready to view.
          </>
        ) : (
          <>
            <strong>We’ve sent a magic link to your inbox</strong> so you can verify your account. Once you've confirmed
            you’re you, we can get to work. In the meantime, your Hometap Investment Manager is busy personalizing your
            Investment Scenario Planner.
          </>
        )}
      </div>
    </>
  );
};

export default InquirySuccessfulCTAOutcome;
