import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Loader } from '@hometap/htco-components';
import Footer from 'components/Footer/Footer';
import FEATURED_OFFERS from './featuredOffers';
import './FeaturedOffersController.scss';
import { useCurrentHome } from 'hooks/useCurrentHome';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

const FeaturedOffer = ({ offer, direction, showDivider }) => {
  const { id, image, title, body, bodyAlt, logo, logoAlt, ctaText, ctaLink, disclaimer } = offer;
  const className = cx('FeaturedOffer', { FeaturedOfferReverse: direction });

  return (
    <div className={className}>
      <div className="FeaturedOfferContainer">
        <div className="FeaturedOfferBodyImageContainer">
          <img className="FeaturedOfferBodyImage" src={image} alt={bodyAlt} />
        </div>
        <div className="FeaturedOfferContentContainer">
          <div className="FeaturedOfferContent">
            <img
              className={cx('FeaturedOfferLogo', { FeaturedOfferSimpliSafe: id === 'simplisafe' })}
              src={logo}
              alt={logoAlt}
            />
            <h4 className="FeaturedOfferTitle">{title}</h4>
            <div className="FeaturedOfferBody">
              <ul>
                {body?.map(item => {
                  return <li key={item}>{item}</li>;
                })}
              </ul>
            </div>
            <Button className="FeaturedOfferCTA" size="small" href={ctaLink} target="_blank">
              {ctaText}
            </Button>
            <div className="FeaturedOfferDisclaimer">{disclaimer}</div>
          </div>
        </div>
      </div>
      {showDivider && <div className="FeaturedOfferDivider" />}
    </div>
  );
};

const FeaturedOffersController = () => {
  const flags = useFlags();

  const { home, loading } = useCurrentHome();

  if (loading || !home) {
    return (
      <div className="FeaturedOfferLoader">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  const configContext = { flags, home };

  const renderedOffers = FEATURED_OFFERS.filter(offer => {
    return offer.shouldShowOffer ? offer.shouldShowOffer(configContext) : true;
  });

  return (
    <ScrollToTop>
      <div className="FeaturedOffersController">
        <div className="FeaturedOffersSubtitle">Offers for you from Hometap’s</div>
        <h1>
          <span className="FeaturedOffersTitleGreen">Best-in-class </span>partners
        </h1>
        <div className="FeaturedOffersDescription">
          <span>As a homeowner, you already have plenty to think about.</span> That’s why we’ve done the work to find
          and select the best partners possible to help you get the most out of homeownership — industry leaders who put
          their customers first.
        </div>
        <div>
          {renderedOffers.map((offer, i) => {
            const configuredCtaLink =
              typeof offer.ctaLink === 'function' ? offer.ctaLink(configContext) : offer.ctaLink;
            const componentOverride = offer.componentOverride && offer.componentOverride(configContext);

            if (componentOverride) {
              return componentOverride;
            }

            return (
              <FeaturedOffer
                key={offer.id}
                offer={{ ...offer, ctaLink: configuredCtaLink }}
                direction={i % 2 !== 0}
                showDivider={i < renderedOffers.length - 1}
              />
            );
          })}
        </div>
        <Footer theme="light" />
      </div>
    </ScrollToTop>
  );
};

export default FeaturedOffersController;
