export const capitalizeFirstLetter = word => word?.charAt(0).toUpperCase() + word?.slice(1);

export const formatPhoneNumberSeparator = (phone, proper = true, join = '.') => {
  if (proper) {
    const digits = phone?.split('-');
    return `(${digits[0]}) ${digits[1]}-${digits[2]}`;
  }
  return phone?.split('-').join(join);
};

export const formatFullName = ({ first_name, last_name, middle_name }) => {
  const formattedMiddleName = !middle_name || middle_name.toLowerCase() === 'n/a' ? '' : middle_name;

  const fullName = `${first_name} ${formattedMiddleName} ${last_name}`;
  return fullName
    .split(' ')
    .filter(n => n)
    .map(name => capitalizeFirstLetter(name))
    .join(' ');
};
