import { Icon } from '@hometap/htco-components';
import { motion } from 'framer-motion';

import './SuccessCheckMark.scss';

const SuccessCheckMark = ({ successText, iconName = 'check-mdc' }) => {
  return (
    <motion.div
      className="SuccessCheckMark"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut' }}
    >
      <Icon name={iconName} />

      {successText && <span className="SuccessCheckMarkText">&nbsp;{successText}</span>}
    </motion.div>
  );
};

export default SuccessCheckMark;
