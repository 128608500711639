import React from 'react';
import PropTypes from 'prop-types';

const LegalLink = ({ section, text, className }) => (
  <a
    className={className}
    theme="link"
    href={`https://www.hometap.com/legal/?section=${section}#${section}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);

LegalLink.propTypes = {
  section: PropTypes.oneOf([
    'terms-of-use',
    'privacy-policy',
    'sharing-of-information-disclosure',
    'consent-to-electronic-communications-and-disclosures',
    'credit-authorization',
    'accessibility-disclosure',
  ]),
  text: PropTypes.node,
  className: PropTypes.node,
};

export default LegalLink;
