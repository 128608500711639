import React from 'react';

const InquiryUndeterminedCTAOutcome = ({ isAdditionalTrack }) => {
  return (
    <>
      <div className="InquiryOutcomeHeaderContainer">
        <h1>We’re getting closer...</h1>
      </div>
      <div className="InquiryOutcomeSubTextContainer">
        We weren’t able to gather all of the information we need to pre-qualify you for an Investment and provide you
        with an Estimate.
      </div>
      <div className="InquiryOutcomeSubTextContainer">
        Sit tight, and your Investment Manager will reach out to you shortly to request the necessary details to help
        you take the next step toward pre-qualifying for a Hometap Investment and finding out how much equity you can
        access.{' '}
        {isAdditionalTrack &&
          'You can also get started on your Application - it takes less than 5 minutes to complete!'}
      </div>
      {!isAdditionalTrack && (
        <div className="InquiryOutcomeSubTextContainer">
          In the meantime, we’ve sent a magic link to your inbox so you can verify your account, so be on the lookout.
          You can also get started on your Application - it takes less than 5 minutes to complete!
        </div>
      )}
    </>
  );
};

export default InquiryUndeterminedCTAOutcome;
