import cx from 'classnames';
import { createRef, useLayoutEffect, useRef, useState } from 'react';
import { useWindowSize } from '@hometap/htco-components';

import PricingMatrixHeading from '../PricingMatrixHeading/PricingMatrixHeading';
import PricingMatrixValue from '../PricingMatrixValue/PricingMatrixValue';

import './PricingMatrixTable.scss';

const PricingMatrixTable = ({ rows, data }) => {
  const headerRowRefs = useRef([]);
  const [headerRowHeight, setHeaderRowHeight] = useState(0);
  const { screenWidth } = useWindowSize();

  headerRowRefs.current = data.map((_, i) => headerRowRefs.current[i] ?? createRef(0));
  const headerRowRefHeights = headerRowRefs.current.map(ref => ref.current?.offsetHeight);

  useLayoutEffect(() => {
    setHeaderRowHeight(Math.max.apply(null, headerRowRefHeights));
  }, [screenWidth, headerRowRefHeights]);

  const tableRows = rows.map((row, index) => {
    return (
      <tr key={index} className="PricingMatrixRow">
        <th className="PricingMatrixTableKey">{row.label}</th>

        {data.map(item => {
          const valueData = item[row.key];
          return (
            <td
              // Used for shading a preferred column item
              className={cx('PricingMatrixTableValueContainer', { isPreferred: item.isPreferred })}
              key={valueData.value}
            >
              <PricingMatrixValue valueData={valueData} />
              {item.isPreferred && index === rows.length - 1 && <div className="PricingMatrixTableRoundedBottom" />}
            </td>
          );
        })}
      </tr>
    );
  });

  // Plus 1 for the key column
  const columnWidth = 100 / (data.length + 1);

  return (
    <table>
      <thead>
        <tr>
          <th className="PricingMatrixTableHeadingContainer" style={{ width: `${columnWidth}%` }} />
          {data.map(({ heading, isPreferred, badge }, i) => {
            const { title, iconName, subtitle, buttonProps } = heading;
            const shouldSetHight = !isNaN(headerRowHeight) && screenWidth < 1620;

            return (
              <th
                key={heading.title}
                style={{
                  width: `${columnWidth}%`,
                  ...(shouldSetHight && { height: headerRowHeight }),
                }}
                className={cx('PricingMatrixTableHeadingContainer', { isPreferred: isPreferred })}
              >
                {isPreferred && <div className="PricingMatrixTableRoundedTop" />}
                <div ref={headerRowRefs.current[i]} className="PricingMatrixTableHeadingWrapper">
                  <PricingMatrixHeading
                    title={title}
                    iconName={iconName}
                    subtitle={subtitle}
                    buttonProps={buttonProps}
                    badge={badge}
                  />
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="PricingMatrixTableRows">{tableRows}</tbody>
    </table>
  );
};

export default PricingMatrixTable;
