import React from 'react';
import Layout from '../components/Layout';
import PageSEO from '../components/PageSEO';
import DebtCalculatorController from './controllers/DebtCalculatorController';

const DebtCalculator = () => {
  return (
    <PageSEO
      description="Our Cost of Debt Calculator (or debt payoff calculator) is designed to help you conceptualize how much of your debt is interest."
      title="Debt Calculator"
      canonicalUrl="https://go.hometap.com/debt-calculator"
      opengraphTitle="Hometap | Debt Calculator"
      opengraphDescription="Our Cost of Debt Calculator (or debt payoff calculator) is designed to help you conceptualize how much of your debt is interest."
    >
      <Layout>
        <DebtCalculatorController />
      </Layout>
    </PageSEO>
  );
};

export default DebtCalculator;
