export const COLORS = {
  TEAL: 'teal',
  LIGHT_BLUE: 'lightBlue',
  PURPLE: 'purple',
};

export const COLOR_ORDER = [COLORS.TEAL, COLORS.LIGHT_BLUE, COLORS.PURPLE];

export const themeMap = {
  [COLORS.TEAL]: 'isTeal',
  [COLORS.LIGHT_BLUE]: 'isLightBlue',
  [COLORS.PURPLE]: 'isPurple',
};

export const themeHighlightTextMap = {
  [COLORS.TEAL]: 'SmartCardGreenText',
  [COLORS.LIGHT_BLUE]: 'SmartCardAccessibleGreenText',
  [COLORS.PURPLE]: 'SmartCardPurpleText',
};
