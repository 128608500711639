import React from 'react';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { useRouteMatch, Switch } from 'react-router-dom';
import ReInquiryOutcome from './components/ReInquiryOutcome';
import ReInquiryReview from './components/ReInquiryReview';

import './ReInquiry.scss';

const ReInquiryRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={match.path}>
        <ReInquiryReview />
      </PrivateRoute>
      <PrivateRoute exact path={`${match.path}/outcome`}>
        <ReInquiryOutcome />
      </PrivateRoute>
    </Switch>
  );
};

export default ReInquiryRouter;
