import React, { useState } from 'react';
import {
  Button,
  Container,
  ModalConfirm,
  MuiTextInput,
  ProfileInfoCard,
  PropertyCard,
  useWindowSize,
} from '@hometap/htco-components';
import { getPortalsUrl } from 'utils/links';
import { phoneValidator } from 'utils/validators';
import useCurrentUser from 'hooks/useCurrentUser';
import { useUserHomes } from 'hooks/useCurrentHome';
import Footer from 'components/Footer/Footer';
import AddNewPropertyModal from 'components/modals/AddNewPropertyModal/AddNewPropertyModal';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import './DashboardProfile.scss';

const DashboardProfile = () => {
  const [isNewPropertyModalOpen, setNewPropertyModalOpen] = useState(false);
  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const [phoneModalButtonText, setPhoneModalButtonText] = useState('Save phone number');

  const { user } = useCurrentUser();
  const { data: homes } = useUserHomes();
  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize('xs');

  const handleConfirm = () => {
    setPhoneModalButtonText('Processing...');
    // TODO update phone number when API is available
    setPhoneModalOpen(false);
    setPhoneModalButtonText('Save phone number');
  };

  return (
    <ScrollToTop>
      <div>
        {isNewPropertyModalOpen && <AddNewPropertyModal setIsOpen={setNewPropertyModalOpen} />}

        <Container className="PageBodyCenteredContainer">
          <p className="mb-2 mt-6 text-sm font-bold text-neutral-dark-75">Account settings</p>

          <div className="DashboardProfileInfo">
            <h2 className="DashboardProfileSubHeader">My profile</h2>
            <ProfileInfoCard
              profileInfo={{
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                // TODO add phone number when API is ready
              }}
              onChangePassword={() => window.open(getPortalsUrl('auth/password-reset/?next=/dashboard/', '_blank'))}
              onEditPhoneNumber={() => setPhoneModalOpen(true)}
            />
          </div>

          <div className="DashboardProfilePropertyTitle">
            <h2 className="DashboardProfileSubHeader">Manage properties</h2>
            {!isMobile && homes?.length < 10 && (
              <Button
                theme="primary"
                className="DashboardProfileAddPropertyButton"
                onClick={() => setNewPropertyModalOpen(true)}
              >
                Add Property
              </Button>
            )}
          </div>

          <div className="DashboardProfilePropertyList">
            {homes?.map((home, index) => (
              <PropertyCard
                key={home.id}
                property={{
                  street: home.address.street,
                  city: home.address.city,
                  state: home.address.state,
                  zipCode: home.address.zip_code,
                }}
                propertyImg={`property-${(index % 4) + 1}`}
                shouldHideDeleteButton={true}
              />
            ))}
          </div>

          {isMobile && homes?.length < 10 && (
            <Button
              theme="primary"
              className="DashboardProfileAddPropertyButton"
              onClick={() => setNewPropertyModalOpen(true)}
            >
              Add Property
            </Button>
          )}
        </Container>

        <ModalConfirm
          open={phoneModalOpen}
          header="Edit your phone number"
          confirmText={phoneModalButtonText}
          onConfirm={handleConfirm}
          onClose={() => setPhoneModalOpen(false)}
          className="DashboardProfileModal"
        >
          <MuiTextInput
            label="Enter phone number"
            name="phone_number"
            type="tel"
            inputMode="tel"
            mask="000-000-0000"
            unmask={false}
            maxLength={14}
            validator={phoneValidator}
            width="100%"
          />
        </ModalConfirm>
        <Footer />
      </div>
    </ScrollToTop>
  );
};

export default DashboardProfile;
