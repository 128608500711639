import React from 'react';

import { Button, Helptip, useAsync } from '@hometap/htco-components';

import { currency } from 'utils/numbers';
import { getPrimaryAppTrackDetailsUrl } from 'utils/links';
import { fetchInquiry } from 'apps/re-inquiry/data/reInquiryRequests';

/* This is a stripped down version of InquirySuccessfulCTAOutcome */
const ReInquiryOutcomeSuccess = ({ name, upToAmount, inquiryId }) => {
  const inquiryAsync = useAsync(fetchInquiry, { immediate: true, defaultLoading: true, params: [inquiryId] });
  return (
    <div className="ReInquiryOutcomeWrapper">
      <div className="ReInquiryOutcomeContainer">
        <div className="ReInquiryOutcomeHeaderContainer">
          {upToAmount && name ? (
            <h1>
              {name ? `${name}, you` : 'You'} could get up to{' '}
              <span className="ReInquiryOutcomeUpToAmountWrapper">
                <span className="ReInquiryOutcomeUpToAmount">{currency(upToAmount)}</span>
                <Helptip
                  title="How did we calculate this number?"
                  text="This amount is based on our current estimated value of your home. It may change once we complete a more detailed valuation analysis of your property and determine how much equity is available in your home."
                  className="ReInquiryOutcomeHelpTip"
                />
              </span>
              <br /> from Hometap!
            </h1>
          ) : (
            <h1>You are prequalified for a Hometap Investment!</h1>
          )}
        </div>
        <div className="ReInquiryOutcomeSubTextContainer">
          <p>
            Congratulations, you’re pre-qualified for a Hometap Investment! Sit tight and your Investment Manager will
            reach out to you about next steps.
          </p>
        </div>
        <Button label="Start Application" href={getPrimaryAppTrackDetailsUrl(inquiryAsync.results?.track_id)} />
      </div>
    </div>
  );
};

export default ReInquiryOutcomeSuccess;
