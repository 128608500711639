import { Container, useWindowSize, Button } from '@hometap/htco-components';
import { useHistory } from 'react-router-dom';
import { editHomeProfileUrl } from 'utils/links';
import VisxChart from './VisxChart';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import EquityViewSettings from '../equity-view-settings/EquityViewSettings';

const ValueChartPanel = ({ futureInvestment, showRenovations = true, showHEI = true }) => {
  const history = useHistory();
  const { currentScenario, chartForecast, isFutureView, hideChartPanel } = useEquityScenarioContext();
  const { investment } = useHedInvestmentContext();
  const { setupProfileLater } = useDelayHomeEquityProfile();

  const annualAppreciationSeries = currentScenario?.annualAppreciation;
  const hasRenos = isFutureView && showRenovations;

  const breakPointsV2 = {
    xs: 600,
    sm: 767,
    tablet: 900,
    md: 1024,
    lg: 1280,
    xl: 1728,
  };

  const { screenWidth, isScreenSize } = useWindowSize({
    breakpoints: breakPointsV2,
  });

  const valueAdjustPanelWidth = hideChartPanel ? 0 : 371;

  const getPagePadding = () => {
    if (isScreenSize('lg')) {
      return 72;
    } else if (isScreenSize('md')) {
      return 40;
    } else if (isScreenSize('sm')) {
      return 16;
    }
    return 108;
  };

  const pagePadding = getPagePadding();

  let width;
  let showViewSettings = false;
  if (isScreenSize('xs') || isScreenSize('sm') || isScreenSize('tablet')) {
    showViewSettings = true;
    width = screenWidth;
  } else {
    width = screenWidth - valueAdjustPanelWidth - pagePadding;
  }

  const height = 360;

  const handleDetailsCTAClick = () => {
    history.push(editHomeProfileUrl());
  };

  return (
    <div className="ValueChartPanel" data-testid="value-chart-panel">
      <div className="ChartInfoLabel" data-testid="chart-info">
        <div>{isFutureView ? 'Future Equity Forecast' : 'Today’s Equity Forecast'}</div>
        <div className="ChartActionLinks">
          {!isFutureView && hideChartPanel && !setupProfileLater ? (
            <Button className="ChartInfoEditPropertyBtn" theme="link" onClick={handleDetailsCTAClick}>
              Edit property details
            </Button>
          ) : null}
          {showViewSettings && <EquityViewSettings modal />}
        </div>
      </div>

      <VisxChart
        width={width}
        height={height}
        homeAppreciationSeries={annualAppreciationSeries}
        investment={futureInvestment && showHEI ? futureInvestment : investment}
        forecast={chartForecast}
        isScreenSmall={isScreenSize('md')}
        isScreenXs={isScreenSize('xs')}
        data-testid="value-chart"
        hasRenos={hasRenos}
        isFutureView={isFutureView}
        showHEI={showHEI}
      />
      <Container row justify="space-between" className="ChartFooter">
        <ul>
          <li>
            <span className="line home-value" data-testid="legend-home-value"></span> <span>HOME VALUE</span>
          </li>
          {(investment || (futureInvestment && isFutureView)) && showHEI && (
            <li>
              <span className="line hei" data-testid="legend-hei"></span> HEI
            </li>
          )}
          <li>
            <span className="line mortgage" data-testid="legend-debts"></span> TOTAL DEBTS
          </li>
          {(investment || (futureInvestment && isFutureView)) && showHEI && (
            <li>
              <span className="square hometap-share" data-testid="legend-hometap-share"></span> HOMETAP’S SHARE
            </li>
          )}
          <li>
            <span className="square equity" data-testid="legend-equity"></span> MY EQUITY
          </li>
        </ul>
      </Container>
    </div>
  );
};

export default ValueChartPanel;
