import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import {
  Button,
  Container,
  Paper,
  IconButton,
  Icon,
  FormError,
  Modal,
  useForm,
  MuiSelect,
} from '@hometap/htco-components';
import DocumentsFileList from './components/DocumentsFileList/DocumentsFileList';
import FileUploader from 'components/FileUploader/FileUploader';
import useConfigurationOptions from 'hooks/useConfigurationOptions';
import { bulkFileUpload, handleRelatedDocumentTasks } from './data/documentRequests';
import { some } from 'lodash';
import { formatFileObject, DOCUMENT_STATUS } from './utils';
import { MAX_UPLOAD_SIZE_BYTES, MAX_BULK_UPLOAD_FILE_COUNT, KIND_OTHER_VALUE, KIND_OTHER_DISPLAY } from './constants';
import useTask from '../../hooks/useTask';
import useSearchParams from '../../hooks/useSearchParams';
import { showNotification } from 'utils/toasts';

import './DocumentsUpload.scss';

const hasErrors = files => {
  return some(files, file => file.status === DOCUMENT_STATUS.REJECTED);
};

const DocumentKindSelectStep = ({ step, text }) => {
  return (
    <p className="DocumentUploadKindSelectStep">
      <strong>Step {step}:</strong> {text}
    </p>
  );
};

const INITIAL_KIND_SELECT_FORM_DATA = { kind: null };

const DocumentsUploadController = ({ asModalProps, onUpload, includeKindSelect }) => {
  // TODO: A lot of this can be moved to the useDocumentsHook

  const params = useParams();
  const { trackId } = params;
  const queryParams = useSearchParams();
  const kind = queryParams.get('kind') || params.kind;
  const title = queryParams.get('title');
  const description = queryParams.get('description');
  const notes = queryParams.get('notes');
  const query = useSearchParams();
  const personId = query.get('person_id');
  const fullName = query.get('full_name');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const uploaderRef = useRef();

  const { taskId, completeDocUploadTask } = useTask();

  const { handleSubmit, registerField, formData, isFormValid, updateFormData } = useForm(INITIAL_KIND_SELECT_FORM_DATA);

  const { getConfigurationValueDisplay, dropdownConfigurationOptions } = useConfigurationOptions({
    names: ['homeowner_document_type', 'document_kinds_with_person'],
  });

  const kindOtherOrNull = kind === KIND_OTHER_VALUE ? KIND_OTHER_DISPLAY : null;
  const kindLabel = getConfigurationValueDisplay('homeowner_document_type', kind) || kindOtherOrNull;
  const kindOptions = [...(dropdownConfigurationOptions?.homeowner_document_type || [])]
    .filter(option => !dropdownConfigurationOptions?.document_kinds_with_person?.includes(option.value))
    .sort((a, b) => a.value.localeCompare(b.value))
    .concat({ label: KIND_OTHER_DISPLAY, value: KIND_OTHER_VALUE });

  const handlePrepare = ({ acceptedFiles, rejectedFiles }) => {
    // Format each file group as one list of files for display, create id for easy reference
    // [{id, file: File, status: "rejected", errors: [{message: ""}]}]
    const newFiles = acceptedFiles.map(file => formatFileObject(file, DOCUMENT_STATUS.ACCEPTED));
    rejectedFiles.forEach(file => {
      newFiles.push(formatFileObject(file, DOCUMENT_STATUS.REJECTED));
    });
    const allFiles = files.concat(newFiles);
    setFiles(allFiles);
  };

  const handleClose = () => {
    window.htap.redirectToTrackInnerIndex({ id: trackId });
  };

  const handleRemoveFile = removeFile => {
    const newFiles = files.filter(file => file.id !== removeFile.id);
    setFiles(newFiles);
  };

  const handleTriggerFilePicker = () => {
    uploaderRef.current();
  };

  const handleUploadFiles = async () => {
    setLoading(true);

    // Bulk upload only accepted files
    const acceptedFiles = files.filter(file => file.status === DOCUMENT_STATUS.ACCEPTED);
    const docKindToUpload = kindLabel ? kind : formData?.kind;

    const response = await bulkFileUpload(acceptedFiles, trackId, docKindToUpload, personId);
    // Extend the file state to update the new status or errors that occurred
    const updatedFiles = files.map(file => {
      return response ? { ...file, ...response, id: response?.id } : file;
    });

    setFiles(updatedFiles);
    setLoading(false);

    if (hasErrors(updatedFiles)) return;

    if (taskId) {
      setDone(true);
      /* If this upload was associated with a Task, complete the task. We do not catch any errors
       * from the response here, but instead assume that the request succeeded. If not, an error
       * will be logged in Sentry. */
      const updatedDocIds = updatedFiles.map(file => file.id);
      await completeDocUploadTask(taskId, updatedDocIds);
    } else if (response.id) {
      const { todo_completed, todo_name } = await handleRelatedDocumentTasks(response.id);
      if (todo_completed) {
        showNotification({
          type: 'success',
          title: 'To-do marked complete',
          description: `"${todo_name}" has been marked complete.`,
        });
      }
    }

    if (onUpload) {
      await onUpload();
      return showNotification({
        type: 'success',
        title: 'Document uploaded successfully.',
        description: 'Thank you for submitting.',
      });
    }

    setTimeout(() => {
      handleClose();
    }, [2500]);
  };

  const content = (
    <form onSubmit={handleSubmit(handleUploadFiles)}>
      <div className="DocumentsUploadHeaderWrapper">
        <h1>{title || 'Upload document.'}</h1>
        {!asModalProps && (
          <IconButton className="DocumentsUploadCancel" size="2x" icon="cancel" onClick={handleClose} />
        )}
      </div>
      {includeKindSelect && (
        <>
          <DocumentKindSelectStep step={1} text="Select your document type." />
          <MuiSelect
            label="Document type"
            value={formData.kind}
            options={kindOptions}
            required
            width="100%"
            className="DocumentsUploadKindSelect"
            {...registerField('kind')}
          />
        </>
      )}

      {description && <div className="DocumentsUploadTypeSubTitle">{description}</div>}

      {notes && notes !== 'null' && notes !== 'undefined' && (
        <>
          <div className="DocumentsUploadNotesTitle">Note</div>
          <div className="DocumentsUploadNotes">{notes}</div>
        </>
      )}

      {fullName && (
        <>
          <div className="DocumentsFileField">Applicant</div>
          <div className="DocumentsTypeDisplay">{fullName}</div>
        </>
      )}

      {includeKindSelect && <DocumentKindSelectStep step={2} text="Choose from your files." />}

      <FileUploader
        options={{ disabled: loading, maxSize: MAX_UPLOAD_SIZE_BYTES }}
        uploadTrigger={uploaderRef}
        onUpload={handlePrepare}
      />

      {files?.length > MAX_BULK_UPLOAD_FILE_COUNT && (
        <FormError
          standalone
          error={`You’ve reached the file limit of ${MAX_BULK_UPLOAD_FILE_COUNT} files, please remove some to upload.`}
        />
      )}

      <DocumentsFileList
        files={files}
        loading={loading}
        onRemove={handleRemoveFile}
        onReplace={handleTriggerFilePicker}
      />

      <div className="DocumentsUploadButtonWrapper">
        {done ? (
          <span className="DocumentsUploadDoneCheck">
            <Icon name="check-circle" /> Done
          </span>
        ) : (
          <Button
            disabled={
              files?.length > MAX_BULK_UPLOAD_FILE_COUNT || hasErrors(files) || files?.length < 1 || !isFormValid
            }
            loading={loading}
            type="submit"
          >
            {loading ? 'Uploading...' : 'Upload'}
          </Button>
        )}
      </div>
    </form>
  );

  return asModalProps ? (
    <Modal
      open={asModalProps.open}
      onClose={() => {
        setFiles([]);
        setDone(false);
        updateFormData(INITIAL_KIND_SELECT_FORM_DATA);
        asModalProps.onClose();
      }}
      width={asModalProps.width}
    >
      {content}
    </Modal>
  ) : (
    <Container space={4}>
      <Paper theme="elevated" pad={4}>
        {content}
      </Paper>
    </Container>
  );
};

export default DocumentsUploadController;
