import { useForm } from '@hometap/htco-components';
import { useEffect } from 'react';

const categorizeLiens = liens => {
  return liens.reduce((data, lien) => {
    const { lien_kind, lien_kind_other, estimated_lien_balance, lien_type, monthly_payment, rate } = lien;

    if (!data[`${lien_type}_rate`]) {
      data[`${lien_type}_rate`] = parseFloat(rate);
    }

    if (!data[`${lien_type}_last_known_current_balance`]) {
      data[`${lien_type}_last_known_current_balance`] = estimated_lien_balance;
    }

    if (!data[`${lien_type}_monthly_payment`]) {
      data[`${lien_type}_monthly_payment`] = monthly_payment;
    }

    if (lien_type === 'mortgage_loan' && !data[`${lien_type}_lien_kind`]) {
      data[`${lien_type}_lien_kind`] = lien_kind;
    }

    if (lien_type === 'mortgage_loan' && !data[`${lien_type}_lien_kind_other`]) {
      data[`${lien_type}_lien_kind_other`] = lien_kind_other;
    }

    return data;
  }, {});
};

const useDebtForm = (liens, initialFromData, initialEditConfig) => {
  const form = useForm(initialFromData);
  const { updateFormData } = form;

  useEffect(() => {
    // The logic below pre-fills the form fields with existing lien data on the property from the backend
    if (!liens?.length) return;

    const formData = categorizeLiens(liens);

    let formLiens = liens.map(lien => lien.lien_type);

    if (initialEditConfig?.lienType) {
      formLiens = [initialEditConfig.lienType];
    }

    if (!form.formData.hasOrderedLiens) {
      updateFormData({ ...formData, liens: formLiens });
    } else {
      updateFormData({ ...formData });
    }

    return () => null;
  }, [liens, updateFormData, initialEditConfig?.lienType, form?.formData?.hasOrderedLiens]);

  return form;
};

export default useDebtForm;
