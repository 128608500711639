import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync } from '@hometap/htco-components';
import { useHomeEquityQueryParamData } from 'hooks/useQueryParamData';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import AddFinancingOption from './AddFinancingOption';
import Debt from '../Debt';
import { PROFILE_INSTANCES, FINANCE_GOALS_FIELDS, FINANCE_OPTION_STAGES } from '../../../constants/buildYourProfile';
import useTrackingData from '../../../hooks/useTrackingData';
import { getEquityScenarioLiens } from '../../../data';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import useDebtForm from 'apps/dashboard/hooks/useDebtForm';
import ProgressiveFormSlides from '../../ProgressiveFormSlides';
import { useCurrentHome } from 'hooks/useCurrentHome';

const getFormsToRender = ({ curStage, form, updateStages, onBack, onNext, initialEditConfig }) => [
  {
    slideProps: { direction: 'right', in: curStage === FINANCE_OPTION_STAGES.SELECT_FINANCE_OPTION, appear: false },
    component: <AddFinancingOption form={form} onNext={step => updateStages(step)} />,
  },
  {
    slideProps: { direction: 'left', in: curStage === FINANCE_OPTION_STAGES.FINANCE_OPTION_INFO },
    component: (
      <Debt
        onNext={onNext}
        onBack={onBack()}
        form={form}
        instance={PROFILE_INSTANCES.EDIT_PROFILE}
        singleLien={initialEditConfig?.lienType}
        onConfirm={() => {}}
      />
    ),
  },
];

const FinanceGoalsController = ({ initialEditConfig = {} }) => {
  const history = useHistory();
  const trackingData = useTrackingData();

  const { step: initialStage, ...initialFormData } = useHomeEquityQueryParamData(FINANCE_GOALS_FIELDS);

  const [showPrompt, setShowPrompt] = useState(false);
  const [curStage, setCurStage] = useState(parseInt(initialStage) || initialEditConfig?.stage || 1);
  const [prevStage, setPrevStage] = useState(FINANCE_OPTION_STAGES.SELECT_FINANCE_OPTION);

  const { home } = useCurrentHome();
  const { currentScenario } = useEquityScenarioContext();
  const { results: equityScenarioLiens } = useAsync(getEquityScenarioLiens, {
    immediate: true,
    params: [home?.id, currentScenario?.id],
  });

  const form = { ...useDebtForm(equityScenarioLiens, initialFormData, initialEditConfig), tracking_data: trackingData };

  const updateStages = step => {
    setShowPrompt(step === 2);
    setPrevStage(curStage);
    setCurStage(step);
  };

  const onNext = () => {
    updateStages(prevStage);
    history.push(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`);
  };

  const onBack = () => {
    if (initialEditConfig?.lienType) {
      return null;
    }
    return () => updateStages(prevStage);
  };

  const formsToRender = getFormsToRender({ curStage, form, updateStages, onBack, onNext, initialEditConfig });

  return <ProgressiveFormSlides slides={formsToRender} shouldShowPrompt={showPrompt} canGoBackToDashboard />;
};

export default FinanceGoalsController;
