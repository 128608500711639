import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Container } from '@hometap/htco-components';
import { getLoginUrl } from 'utils/links';
import './OnboardingTemplate.scss';

const DEFAULT_CONTENT_PANEL_COLOR = '#FFF';
const DEFAULT_GRID_SIZE = 6;

const layoutPropTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const panelPropTypes = {
  containsVisual: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  gridSize: PropTypes.number,
  header: PropTypes.node,
};

const OnboardingLayout = ({ backgroundImage, children }) => {
  // This effect will scope a few style overrides to this component in
  // order to achieve a full page layout
  useEffect(() => {
    const FULL_PAGE_CLASSNAME = 'OnboardingFullPageHtml';
    const HEADER_CLASSNAME = 'OnboardingHeader';
    const LOGIN_CLASSNAME = '.HeaderAuthLink';

    const htmlElement = document.querySelector('html');
    const headerElement = document.querySelector('header');
    const loginElement = document.querySelector(LOGIN_CLASSNAME);

    htmlElement.classList.add(FULL_PAGE_CLASSNAME);
    headerElement.classList.add(HEADER_CLASSNAME);
    if (loginElement) {
      const newLink = getLoginUrl().concat('/?next=/dashboard/home-equity');
      loginElement.setAttribute('href', newLink);
    }
  }, []);

  const styles = {};
  if (backgroundImage) {
    styles.backgroundImage = `url(${backgroundImage})`;
  }

  return (
    <div style={styles} className="OnboardingPageLayout">
      <Container className="OnboardingPageContainer" row>
        {children}
      </Container>
    </div>
  );
};

const Panel = ({ containsVisual, children, gridSize = DEFAULT_GRID_SIZE, reducePadding, className }) => {
  const containerClasses = cx(
    {
      OnboardingPagePanel: true,
      FlexVisualParent: containsVisual,
    },
    { OnboardingPageContainerReducePadding: !!reducePadding },
    className,
  );
  const childClasses = cx({ FlexVisualChild: containsVisual });

  return (
    <Container className={'OnboardingPagePanelContainer'} grid={gridSize}>
      <div
        style={!containsVisual ? { backgroundColor: DEFAULT_CONTENT_PANEL_COLOR } : null}
        className={containerClasses}
      >
        <div className={childClasses}>{children}</div>
      </div>
    </Container>
  );
};

const CallToAction = ({ children }) => {
  return <div className="OnboardingPageCTA">{children}</div>;
};

OnboardingLayout.propTypes = layoutPropTypes;
Panel.propTypes = panelPropTypes;

export { OnboardingLayout, CallToAction, Panel };
