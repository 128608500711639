import { isTrackExpiredOrClosed } from 'utils/tracksUtils';
import TrackExpiredClosed from 'components/TrackExpiredClosed/TrackExpiredClosed';
import useTrack from 'hooks/useTrack';
import React from 'react';

export const ActiveTrackCheck = ({ children, ...rest }) => {
  const { track } = useTrack();
  const fromRenderProps = rest?.render && rest.render(rest);
  if (!!fromRenderProps) {
    return fromRenderProps;
  }

  if (track && isTrackExpiredOrClosed(track)) {
    return <TrackExpiredClosed />;
  }

  return <>{children}</>;
};
