import { Icon, Tooltip } from '@hometap/htco-components';

import useApplicantForm from '../hooks/useApplicantForm';
import BoolField from '../components/BoolField/BoolField';

import { APPLICANT_FORM_FIELD } from '../constants/formFields';

const BackgroundInfoForm = () => {
  const { registerApplicantField } = useApplicantForm();

  const questions = [
    {
      name: APPLICANT_FORM_FIELD.hasHadBankruptcyFiling,
      label: 'Had a bankruptcy filing?',
      tooltip:
        'Bankruptcy is a legal proceeding that’s initiated when you’re unable to pay back your outstanding debts or obligations.',
    },
    {
      name: APPLICANT_FORM_FIELD.hasBeenConvictedOfFelony,
      label: 'Been convicted of a felony?',
      tooltip:
        'A felony is regarded as a more serious crime than a misdemeanor and is typically punishable by at least one year in prison.',
    },
    {
      name: APPLICANT_FORM_FIELD.hasReceivedPreForeclosureNotice,
      label: 'Received a pre-foreclosure notice for a property you owned?',
      tooltip:
        'A pre-foreclosure notice is a notice of default or lis pendens that a lender issues when the homeowner fails to make mortgage payments.',
    },
    {
      name: APPLICANT_FORM_FIELD.hasCompletedPreForeclosureSale,
      label: 'Completed a pre-foreclosure sale or short sale on a property?',
      tooltip:
        'During a pre-foreclosure sale, your property is sold to a third party and your lender agrees to accept less than the outstanding mortgage balance due at the time of sale.',
    },
    {
      name: APPLICANT_FORM_FIELD.hasTransferredTitleDueToForeclosure,
      label: 'Transferred a title to any property in lieu of foreclosure?',
      tooltip:
        'With a transfer in lieu of foreclosure, you transfer ownership of your property to your lender in order to prevent a foreclosure.',
    },
  ];

  return (
    <div className="ApplicationInfoFormFields">
      <div>
        {/* This block doesn't want the gap provided by ApplicationInfoFormFields so we wrapped it in a div*/}
        {questions.map(({ name, label, tooltip }) => {
          const labelAndTooltip = (
            <div>
              {label}{' '}
              <Tooltip content={tooltip}>
                <Icon name="info" />
              </Tooltip>
            </div>
          );
          return (
            <BoolField
              className="BackgroundInfoSelectionSet"
              required
              key={name}
              label={labelAndTooltip}
              baseRegisterField={registerApplicantField(name)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BackgroundInfoForm;
