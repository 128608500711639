import { createContext, useContext, useEffect, useState } from 'react';

const NavAccordionContext = createContext();

const useNavAccordion = () => {
  const context = useContext(NavAccordionContext);
  if (!context) throw new Error('useNavAccordion must be used within a NavAccordionProvider');
  return context;
};

export const NavAccordionProvider = ({ children, onToggle, canExpand = true, open }) => {
  const [isExpanded, setIsExpanded] = useState(open || false);

  useEffect(() => {
    if (open !== null) {
      setIsExpanded(open);
    }
  }, [open]);

  const handleClick = () => {
    canExpand && setIsExpanded(!isExpanded);
    onToggle && onToggle(canExpand ? !isExpanded : undefined);
  };

  return (
    <NavAccordionContext.Provider value={{ open: isExpanded && canExpand, onToggle: handleClick, canExpand }}>
      {children}
    </NavAccordionContext.Provider>
  );
};

export default useNavAccordion;
