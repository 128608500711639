import { useEffect } from 'react';
import { Button, Grid, InputMask, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import { SCENARIO_PERIOD_OPTIONS } from 'apps/dashboard/constants';
import { buildSelfReportedLienRequestData } from 'apps/dashboard/data';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import debtInfo from 'images/dashboard/debt-info.jpg';
import './ConfirmMortgageGaps.scss';
import { useCurrentHome } from 'hooks/useCurrentHome';

const ConfirmMortgageGaps = ({ form, onNext, onBack, onConfirm }) => {
  const { registerField, isFormValid } = form;

  const { home } = useCurrentHome();
  const { currentScenario, getEquityScenarioLiensAsync, updateLien, addLien } = useEquityScenarioContext();

  const getEquityScenarioLiens = getEquityScenarioLiensAsync?.execute;
  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;

  useEffect(() => {
    const getLiens = async () => {
      await getEquityScenarioLiens(home?.id, currentScenario?.id);
    };
    if (home?.id && currentScenario?.id) {
      getLiens();
    }
  }, [home?.id, currentScenario?.id, getEquityScenarioLiens]);

  const onConfirmMortgageGapsNext = async () => {
    const mortgageLien = equityScenarioLiens?.find(lien => lien?.lien_type === 'mortgage_loan');
    const requestData = buildSelfReportedLienRequestData({ lienType: 'mortgage_loan', ...form?.formData });

    if (mortgageLien) {
      await updateLien(currentScenario, mortgageLien?.id, {
        ...requestData,
      });
    } else {
      await addLien(currentScenario, { ...requestData });
    }
    await getEquityScenarioLiensAsync?.execute(home?.id, currentScenario?.id);
    onConfirm('mortgage_loan');
    onNext();
  };

  return (
    <Grid className="ConfirmMortgageGaps" container columnSpacing={{ sm: 2 }} data-testid="confirm-mortgage-gaps">
      <Grid className="ConfirmMortgageGapsLeftPanelWrapper" sm={12} md={6}>
        <div className="ConfirmMortgageGapsLeftPanel">
          <h3>Please confirm your mortgage information.</h3>
          <div className="DashboardBuildProfileSubtitle">
            These details will help keep your home equity and profile information up to date.
          </div>
          <MuiSelect
            name="mortgage_loan_lien_kind_other"
            label="Term"
            aria-label="Term"
            data-testid="mortgage_loan_term"
            width="100%"
            options={SCENARIO_PERIOD_OPTIONS}
            required
            {...registerField('mortgage_loan_lien_kind_other')}
          />
          <MuiTextInput
            name="mortgage_loan_rate"
            label="Rate"
            aria-label="Rate"
            type="number"
            inputMode="decimal"
            data-testid="mortgage_loan-rate"
            width="100%"
            mask={InputMask.PERCENT_MASK}
            endAdornment="%"
            unmask="typed"
            placeholder=" "
            required
            {...registerField('mortgage_loan_rate')}
          />
          <MuiTextInput
            name="mortgage_loan_last_known_current_balance"
            label="Mortgage balance"
            aria-label="Mortgage balance"
            type="number"
            inputMode="decimal"
            data-testid="mortgage_loan-last-known-current-balance"
            mask={InputMask.MONEY_USD_MASK}
            startAdornment="$"
            unmask="typed"
            required
            width="100%"
            placeholder=" "
            {...registerField('mortgage_loan_last_known_current_balance')}
          />
          <MuiTextInput
            name="mortgage_loan_monthly_payment"
            label="Monthly payment"
            aria-label="Monthly payment"
            type="number"
            inputMode="decimal"
            data-testid="mortgage_loan-monthly-payment"
            helperText="Only include principal and interest"
            mask={InputMask.MONEY_USD_MASK}
            startAdornment="$"
            unmask="typed"
            required
            width="100%"
            placeholder=" "
            {...registerField('mortgage_loan_monthly_payment')}
          />
        </div>
        <Grid className="ConfirmMortgageGapsCTA" container alignItems="center">
          <Grid sm={6} md={6}>
            <Button className="ConfirmMortgageGapsBack" theme="link" onClick={onBack} data-testid="back-button">
              Back
            </Button>
          </Grid>
          <Grid sm={6} md={6}>
            <div className="ConfirmMortgageGapsNext">
              <Button
                size="large"
                disabled={!isFormValid}
                onClick={onConfirmMortgageGapsNext}
                data-testid="next-button"
              >
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className="DashboardBuildProfileStepImage"
        sm={12}
        md={6}
        display="flex"
        justifyContent="right"
        alignItems="center"
      >
        <img className="DashboardBuildProfileImage" src={debtInfo} alt="home-equity" />
      </Grid>
    </Grid>
  );
};

export default ConfirmMortgageGaps;
