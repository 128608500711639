import React, { useContext, useState, useCallback } from 'react';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
const Context = React.createContext({});

const useNotificationBannerContext = () => useContext(Context);
export default useNotificationBannerContext;

/**
 * This provider is used to show a notification banner at the top of the page.
 *
 * @function showNotification - function to show the notification banner
 * @param {string | React.ReactNode} title
 * @param {string | React.ReactNode} supportingText
 * @param {string} icon
 * @param {string | React.ReactNode} rightSideContent
 * @param {function} onDismiss
 */
export const NotificationBannerProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const showNotificationBanner = useCallback(notification => {
    setNotification(notification);
  }, []);

  const handleDismiss = () => {
    notification.onDismiss();
    setNotification(null);
  };

  return (
    <Context.Provider value={{ showNotificationBanner }}>
      {notification && <NotificationBanner {...notification} onDismiss={handleDismiss} />}
      {children}
    </Context.Provider>
  );
};
