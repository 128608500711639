import { MuiSelect, MuiTextInput } from '@hometap/htco-components';
import { SCENARIO_PERIOD_OPTIONS } from 'apps/dashboard/constants';
import { SHARED_INPUT_PROPS, TEXT_INPUTS } from './sharedFields';
import { positiveValueValidator } from 'utils/validators';

const HouseCanaryMortgageForm = ({ registerField }) => {
  const isMonthlyPaymentField = name => name === 'monthly_payment';

  const getLabel = input => {
    return isMonthlyPaymentField(input.name) ? 'Monthly principal & interest payment' : input.label;
  };

  const getHelperText = input => {
    return isMonthlyPaymentField(input.name)
      ? 'This amount should exclude taxes and insurance, and will be lower than your total monthly payment.'
      : input.helperText;
  };

  return (
    <>
      <MuiSelect
        label="Term"
        aria-label="Term"
        options={SCENARIO_PERIOD_OPTIONS}
        {...registerField('lien_kind')}
        {...SHARED_INPUT_PROPS}
      />

      {TEXT_INPUTS.map(input => (
        <MuiTextInput
          key={input.label}
          {...SHARED_INPUT_PROPS}
          label={getLabel(input)}
          aria-label={getLabel(input)}
          mask={input.mask}
          startAdornment={input.startAdornment}
          endAdornment={input.endAdornment}
          type={input.type}
          inputMode={input.inputMode}
          placeholder={input.placeholder}
          required={input.required || true}
          helperText={getHelperText(input)}
          validator={positiveValueValidator}
          {...registerField(input.name)}
        />
      ))}
    </>
  );
};

export default HouseCanaryMortgageForm;
