import { useEffect, useState, useRef } from 'react';
import { MuiSelect, MuiTextInput } from '@hometap/htco-components';

import { validateSSN } from 'utils/validators';
import useApplicantForm from '../hooks/useApplicantForm';
import { APPLICANT_FORM_FIELD, APPLICANT_DEMO_DATA } from '../constants/formFields';
import DemoButton from 'components/DemoButton/DemoButton';
import useApplicationContext from '../hooks/useApplicationContext';
import { useParams } from 'react-router';

// TODO: These options should be fetched from the backend using the core/configurations endpoint
const maritalStatusOptions = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Separated', value: 'separated' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
];
const relationshipToPrimaryOptions = [
  { label: 'Spouse', value: 'spouse' },
  { label: 'Significant Other', value: 'significant_other' },
  { label: 'Parent ', value: 'parent' },
  { label: 'Child ', value: 'child' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Grandchild', value: 'grandchild' },
  { label: 'Parent in Law', value: 'parent_in_law' },
  { label: 'Child in Law', value: 'child_in_law' },
  { label: 'Roommate', value: 'roommate' },
  { label: 'Other', value: 'other' },
];

const PersonalInfoForm = () => {
  const { registerApplicantField, applicantFormData, handleUpdateApplicantForm } = useApplicantForm();
  const { primaryApplicant } = useApplicationContext();
  const { applicantId } = useParams();

  const ssnFieldData = registerApplicantField(APPLICANT_FORM_FIELD.ssn);
  const [ssnValue, setSsnValue] = useState('');
  const [isEditingSSN, setIsEditingSSN] = useState(false);

  const ssnInputRef = useRef(null);

  useEffect(() => {
    if (!isEditingSSN && ssnFieldData.value) {
      setSsnValue(ssnFieldData.value);
    }

    if (applicantFormData?.isNew) {
      setIsEditingSSN(true);
    }
  }, [isEditingSSN, ssnFieldData.value, applicantFormData?.isNew]);

  useEffect(() => {
    if (!applicantFormData?.isNew && isEditingSSN) {
      ssnInputRef.current.element.focus();
    }
  }, [applicantFormData?.isNew, isEditingSSN]);

  return (
    <div className="ApplicationInfoFormFields">
      <div className="ApplicationTextInputContainer">
        <MuiTextInput
          label="Legal First Name"
          width="100%"
          {...registerApplicantField(APPLICANT_FORM_FIELD.firstName)}
          required
          maxLength={30}
          disabled={applicantFormData?.isPrimaryApplicant}
        />
      </div>
      <div className="ApplicationTextInputContainer">
        <MuiTextInput
          label="Legal Middle Name (optional)"
          width="100%"
          maxLength={30}
          {...registerApplicantField(APPLICANT_FORM_FIELD.middleName)}
        />
      </div>
      <div className="ApplicationTextInputContainer">
        <MuiTextInput
          label="Legal Last Name"
          width="100%"
          {...registerApplicantField(APPLICANT_FORM_FIELD.lastName)}
          required
          maxLength={30}
          disabled={applicantFormData?.isPrimaryApplicant}
        />
      </div>

      <div className="ApplicationTextInputContainer">
        {/* MuiSSNInput doesn't work with ...registerField we have decided to
         just use MuiTextInput and have it be unobfuscated */}
        <MuiTextInput
          className="sm_cobrowsing_masked_field"
          label="Social Security Number"
          width="100%"
          isSSN
          {...ssnFieldData}
          disabled={!isEditingSSN}
          mask={isEditingSSN ? '000-00-0000' : '***-**-0000'}
          onShowHide={() => {
            setSsnValue('');
            setIsEditingSSN(true);
          }}
          onChange={value => {
            ssnFieldData.onChange(value);
            setSsnValue(value);
          }}
          required
          value={ssnValue}
          ref={ssnInputRef}
          iconName="icon-edit"
          showIcon={!isEditingSSN}
          validator={validateSSN}
        />
      </div>
      <MuiSelect
        required
        label="Marital Status"
        options={maritalStatusOptions}
        width="100%"
        {...registerApplicantField(APPLICANT_FORM_FIELD.maritalStatus)}
      />
      {!applicantFormData?.isPrimaryApplicant && (
        <MuiSelect
          required={!applicantFormData?.isPrimaryApplicant}
          label={`Relationship to ${primaryApplicant?.first_name || 'primary applicant'}`}
          options={relationshipToPrimaryOptions}
          width="100%"
          {...registerApplicantField(APPLICANT_FORM_FIELD.relationshipToPrimary)}
        />
      )}
      {!applicantId && (
        <DemoButton
          demoData={() => APPLICANT_DEMO_DATA({ isPrimaryApplicant: applicantFormData?.isPrimaryApplicant })}
          onClickAction={handleUpdateApplicantForm}
        />
      )}
    </div>
  );
};

export default PersonalInfoForm;
