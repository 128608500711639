import { Button, Container, Paper } from '@hometap/htco-components';
import React from 'react';
import { getNewInquiryUrl } from 'utils/links';

const TrackExpiredClosed = () => {
  return (
    <Container row space={5} justify="center">
      <Container grid="8">
        <Paper pad={5}>
          <h1>Glad you’re back!</h1>
          <p>
            It's been a little while and this investment opportunity is no longer available. Would you like to start a
            new investment?
          </p>
          <Button href={getNewInquiryUrl()}>Start a new Investment</Button>
          <Button theme="secondary" href="/dashboard">
            Back to My Dashboard
          </Button>
        </Paper>
      </Container>
    </Container>
  );
};

export default TrackExpiredClosed;
