import useTrack from 'hooks/useTrack';
import { formatOnlyStreetAddress } from 'utils/address';
import SecondaryLayout from './SecondaryLayout';

const TrackSecondaryLayout = ({ children, prevUrl, prevTrackUrl }) => {
  const { track, loading } = useTrack();

  const title = formatOnlyStreetAddress(track?.home?.address || {});

  return (
    <SecondaryLayout prevUrl={prevTrackUrl ? `/track/${track?.id}${prevTrackUrl}` : prevUrl} title={!loading && title}>
      {children}
    </SecondaryLayout>
  );
};

export default TrackSecondaryLayout;
