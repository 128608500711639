import React from 'react';
import { useQueryParams } from '../../utils/urlUtilities';

import ResultNotOwner from '../components/fitQuizResults/ResultNotOwner/ResultNotOwner';
import ResultUnsupportedState from '../components/fitQuizResults/ResultUnsupportedState/ResultUnsupportedState';
import ResultYesMaybe from '../components/fitQuizResults/ResultYesMaybe/ResultYesMaybe';
import { getConfidenceLevel } from '../utils/fitQuiz/fitQuizDataUtils';

import '../FitQuiz.scss';

const shouldRedirect = params => {
  return !params.result || (params.result === 'done' && Object.keys(params).length <= 1);
};

const ResultController = () => {
  const params = useQueryParams();

  let output = null;
  if (params?.result === 'not_homeowner') {
    output = <ResultNotOwner />;
  } else if (params?.result === 'state_not_operational') {
    output = <ResultUnsupportedState state={params?.state} />;
  } else if (shouldRedirect(params)) {
    window.history.pushState(null, '', '/fit-quiz');
  } else {
    output = <ResultYesMaybe confidence={getConfidenceLevel(params)} formData={params} />;
  }

  return (
    <div key={params.result && 'ready'} className="ResultController">
      {output}
    </div>
  );
};

export default ResultController;
