const waysToFinanceAHomeRenovation = `<p>Making home renovations has two obvious perks. First, you can make upgrades that fit your lifestyle and allow you to enjoy your home even more. Second, the upgrades you make now may help increase the value of your home, so that when it comes time to sell, you make a profit.</p>
<p>However, the way you fund your home renovation can have serious implications for your short- and long-term finances. For repairs that will cost more than you can afford to pay in a month, you may want to look at financing options beyond your credit card. That way, you can avoid paying the high interest rates that come with a credit card balance.</p>
<p>If you don&rsquo;t have the savings stashed away to fund your renovations, you do have other options. You can build your cash flow for home renovations, use your home equity, or use a home renovation loan.</p>
<h2>Construction Loans</h2>
<p>Construction loans &mdash; also known as home renovation loans or home remodel loans &mdash;are a common way to finance home improvement projects, as they often have lower interest rates than credit cards. With a home loan for construction, renovation costs are rolled into your mortgage, so you&rsquo;re essentially making one payment each month that includes both the cost of your home and the repairs or upgrades you plan to make.</p>
<p>This can be beneficial if you&rsquo;re purchasing a fixer-upper, but if you&rsquo;re already in your home, the cons may outweigh the pros as your new mortgage payment may have a higher interest rate. And, while renovation loans may be more appealing for homeowners without home equity, homeowners <em>with</em> home equity have additional options for accessing funds.</p>
<p>There are three main types of renovation loans that allow homeowners to make updates now rather than waiting to have enough cash saved.</p>
<h2>FHA 203(k) Loans</h2>
<p>Insured by the Federal Housing Administration, the <a href="https://www.hud.gov/program_offices/housing/sfh/203k">203(k) loan</a> comes in two forms: standard and limited.</p>
<p>The standard 203(k) loan covers major structural repairs and other renovations that cost at least $5,000. There is no cap on repair costs, however, you have to hire a U.S. Department of Housing and Urban Development (HUD) consultant to oversee renovations and projects. The HUD lists the projects covered by a <a href="https://www.hud.gov/program_offices/housing/sfh/203k/203k--df">standard loan</a>.</p>
<p>The limited 203(k) loan is capped at $35,000 for renovations, not including structural repairs.</p>
<p>Pros to 203(k) loans:</p>
<ul>
<li>Can use a standard loan to tear down and rebuild on the foundation.</li>
<li>Potentially easier to secure funding; since they&rsquo;re insured by the government, lenders may have less strict credit qualification requirements.</li>
<li>If your home isn&rsquo;t inhabitable as you make renovations, you may be able to include up to six months of mortgage payments in your loan. You can continue to make monthly payments, but live elsewhere.</li>
</ul>
<p>Cons to 203(k) loans:</p>
<ul>
<li>No do-it-yourself work with strict requirements on what renovations are covered.</li>
<li>Must use an <a href="https://www.hud.gov/program_offices/housing/sfh/lender/lenderlist">FHA-approved lender</a>.</li>
<li>Cannot use this loan with the intention of flipping the house or on homes that are under a year old.</li>
<li>With the standard loan, funds that aren&rsquo;t going to the seller (if buying the house) or to pay off an existing mortgage (if refinancing) are placed in an escrow account and released as rehabilitation is completed.</li>
<li>Improvements must be completed 60 days from the loan&rsquo;s closing date for limited loans and within six months of closing for standard loans. You will need a detailed estimate of the cost of work to be done <em>and</em> timeline from your contractor before your loan is approved.&nbsp;</li>
</ul>
<h2>HomeStyle Loans</h2>
<p>Offered by Fannie Mae, <a href="https://singlefamily.fanniemae.com/learning-center/originating-and-underwriting/faqs-homestyle-renovation">HomeStyle loans</a> are one of the more flexible renovation loans in that there are fewer restrictions on what you can use the funding for. Homeowners can get 75% of &ldquo;the sum of the purchase price of the property, plus renovation costs,&rdquo; or 75% of the &ldquo;as completed appraised value of the property&rdquo; &mdash; whichever is less.</p>
<p>That means if you&rsquo;re refinancing your home, an appraiser will estimate the value of your home post-renovations. If they determine the value of your property with renovations completed will be $300,000, then the financing amount can&rsquo;t exceed $225,000.</p>
<p>Pros to HomeStyle Loans:</p>
<ul>
<li>Can use funds for almost any renovation project, whether cosmetic or structural, including &ldquo;luxury&rdquo; updates like a swimming pool or landscaping.</li>
<li>Can use the loan on an investment property.</li>
<li>Can complete final work on a brand-new home, as long as the new home is 90% complete.</li>
<li>Like a 203(k) loan, you may be able to include up to six months of mortgage payments in your loan if you need to live elsewhere while renovations are completed.</li>
</ul>
<p>Cons to HomeStyle Loans:</p>
<ul>
<li>Like the 203(k) loan, your money sits in an escrow account and contractors get paid once they complete certain renovation tasks.</li>
<li>Must use a certified contractor who needs to include a detailed cost estimate of the work you want done &mdash; potentially <em>before</em> the loan is approved.</li>
</ul>
<h2>CHOICERenovation Loans</h2>
<p>Guaranteed by Freddie Mac, the amount of money you can receive for renovations using a CHOICERenovation loan is similar to the HomeStyle loan in that you can make renovations that cost up to 75% of the as-completed appraised value.</p>
<p>Pros to CHOICERenovation Loans:</p>
<ul>
<li>Can use the loan to repair your home if it&rsquo;s been hit by a natural disaster or to fortify it against a future disaster.</li>
<li>If buying a home and taking out a loan like <a href="https://sf.freddiemac.com/working-with-us/origination-underwriting/mortgage-products/home-possible">Freddie Mac&rsquo;s Home Possible loan</a>, you may be able to make DIY renovations in <a href="https://www.nerdwallet.com/blog/mortgages/build-down-payment-with-sweat-equity-diy-mortgage/">exchange for sweat equity</a> (but you&rsquo;ll need to go through an appraisal before and after improvements).</li>
</ul>
<p>Cons to CHOICERenovation Loans:</p>
<ul>
<li>All renovations must be completed within a year.</li>
</ul>
<h2>Personal Loans&nbsp;</h2>
<p>If none of the specialized loans above make sense for you due to use restrictions or approval criteria, yet another option for financing fixes is using a personal loan as a home improvement loan or home repair loan. They typically don&rsquo;t require any collateral and provide funding quickly, but come with high interest rates and additional monthly payments, so it&rsquo;s important to do your homework.</p>
<p>When you get a home renovation loan, you generally <em>must</em> use the funds toward making home improvements, often with strict requirements about what qualifies. The paperwork involved will be similar to when you first took out your mortgage.</p>
<p>That can slow down the process and, if your mortgage is locked in at a low interest rate or you only have a handful of years left to go on your mortgage, you may not want to mess with it (of course, <a href="https://www.hometap.com/blog/when-is-a-good-time-to-refinance-your-home">if you can</a> <a href="https://www.hometap.com/blog/when-is-a-good-time-to-refinance-your-home"><em>lower</em></a> <a href="https://www.hometap.com/blog/when-is-a-good-time-to-refinance-your-home">interest rates</a> and/or the time left on your loan, you&rsquo;ll want to do the math to decide if these options make the most sense).</p>
<p>Current homeowners should first look at how much equity they have built in their homes and the ways they can access that money. That way, you also won&rsquo;t be saddled with closing costs and other fees associated with refinancing.</p>
<h2>Home Equity Investments&nbsp;</h2>
<p>A <a href="https://www.hometap.com/how-it-works">home equity investment</a> allows you to get a portion of the value of your home in cash now in exchange for a share of the future value of your home. Since it&rsquo;s an investment, there are no monthly payments or interest. You settle the investment within 10 years with the profits of a home sale, with savings, a refinance, or a loan.</p>

`;

export default waysToFinanceAHomeRenovation;
