import React from 'react';
import '../SettlementCalculator.scss';

const SettlementDisclaimer = () => {
  return (
    <div className="SettlementDisclaimer">
      Please be advised: the settlement amount shown above is an estimate only and is subject to change. This amount is
      not to be relied upon as a final repurchase or settlement amount. To notify Hometap that you wish to settle your
      Investment or to obtain an accurate, formal settlement amount, please contact Hometap at homeowners@hometap.com.
    </div>
  );
};

export default SettlementDisclaimer;
