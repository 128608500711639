import cx from 'classnames';
import { Icon } from '@hometap/htco-components';
import './ErrorMessage.scss';

const ErrorMessage = ({ fullWidth, className, children }) => {
  return (
    <div className={cx('ErrorMessage', className, { ErrorMessageFullWith: fullWidth })}>
      <Icon name="error" />
      {children}
    </div>
  );
};

export default ErrorMessage;
