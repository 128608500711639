import { currency } from 'utils/numbers';

import succulent2x from 'images/succulent-2x.png';
import TrackDetailsMaxInvestmentAmountSubtitle from './TrackDetailsMaxInvestmentAmountSubtitle';

import './TrackDetailsMaxInvestmentAmount.scss';

const TrackDetailsMaxInvestmentAmount = ({ maxInvestmentAmount, heading, hideImage, homeValue }) => {
  const formattedInvestmentAmount = currency(maxInvestmentAmount);

  return (
    <div className="TrackDetailsMaxInvestmentAmount">
      {!hideImage && (
        <div>
          <img className="TrackDetailsMaxInvestmentAmountImage" src={succulent2x} aria-hidden alt="" />
        </div>
      )}
      <div className="TrackDetailsMaxInvestmentAmountContainer">
        <div className="TrackDetailsMaxInvestmentAmountWrapper">
          <div className="TrackDetailsMaxInvestmentAmountCopy">
            <p className="TrackDetailsMaxInvestmentAmountHeading">{heading}</p>
            <h1>
              {maxInvestmentAmount ? (
                <>
                  <sup className="TrackDetailsMaxInvestmentAmountCurrencySymbol">
                    {formattedInvestmentAmount.substring(0, 1)}
                  </sup>
                  {formattedInvestmentAmount.substring(1)}
                </>
              ) : (
                <span className="TrackDetailsMaxInvestmentAmountEmpty">---</span>
              )}
            </h1>
          </div>
        </div>
        <TrackDetailsMaxInvestmentAmountSubtitle maxInvestmentAmount={maxInvestmentAmount} homeValue={homeValue} />
      </div>
    </div>
  );
};

export default TrackDetailsMaxInvestmentAmount;
