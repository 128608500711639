import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { Route } from 'react-router-dom';
import { getLoginUrl } from 'utils/links';
import useSearchParams from '../../hooks/useSearchParams';

const PrivateRoute = ({ redirect, children, ...rest }) => {
  const query = useSearchParams();
  const { error } = useCurrentUser();

  if (!redirect) {
    redirect = getLoginUrl();
  }
  if (error?.response?.status === 403) {
    if (!query.has('next')) {
      query.append('next', window.location.pathname);
    }
    const queryString = query.toString() ? `?${query.toString()}` : '';
    const redirectWithQuery = `${redirect}${queryString}`;
    window.api.redirect(redirectWithQuery);
    return null;
  }

  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
