import React from 'react';
import PropTypes from 'prop-types';

const ExportLink = ({ fileName, data = [], children, ...props }) => {
  const csvHeader = 'data:text/csv;charset=utf-8,';
  const dataString = data.map(rows => rows.map(JSON.stringify).join(',')).join('\r\n');
  const downloadLink = encodeURI(csvHeader + dataString);

  return (
    <a href={downloadLink} download={`${fileName}.csv`} {...props}>
      {children || 'Export to CSV'}
    </a>
  );
};

ExportLink.propTypes = {
  fileName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.array),
};

export default ExportLink;
