import { Dropdown } from '@hometap/htco-components';
import { Link } from 'react-router-dom-v5-compat';

import { getLogoutUrl, HOMEOWNER_DASHBOARD } from 'utils/links';

const AccountDropdown = () => {
  const options = [
    <Link to={`/${HOMEOWNER_DASHBOARD.SUPPORT}`}>Get Support</Link>,
    <Link to={`/${HOMEOWNER_DASHBOARD.PROFILE}`}>Account Settings</Link>,
    <a href={getLogoutUrl()}>Log out</a>,
  ];

  const configuredOptions = options.map((item, index) => ({
    optionLabel: item,
    selectedLabel: '',
    value: index,
  }));

  return (
    <div
      className="inline-block hover:text-blue-100 active:text-blue-100 [&_.htco-DropdownSelectedInfoLabel]:mr-0 [&_.htco-DropdownSelectedInfoLabel]:font-bold [&_.htco-DropdownSelectedInfoLabel]:text-neutral-dark-100 [&_.htco-isActive_.htco-DropdownSelectedInfoLabel]:text-blue-100 [&_a]:inline-block [&_a]:w-[220px] [&_a]:px-3 [&_a]:py-4 [&_a]:!text-neutral-dark-100 [&_a]:no-underline [&_li]:p-0"
      data-testid="header-nav-account-dropdown"
    >
      <Dropdown label="Account" options={configuredOptions} onChange={() => null} />
    </div>
  );
};

export default AccountDropdown;
