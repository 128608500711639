import { getNewDashboardUrl, getSettlementCalculatorUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import calculator from 'images/quarterly-statements/calculator.png';
import maintenance from 'images/quarterly-statements/maintenance.png';

const PLAN_AHEAD = {
  image: calculator,
  title: 'Plan ahead',
  subTitle:
    'Map out different potential scenarios using our Settlement Calculator to help you find the settlement timeline that works best for you.',
  linkUrl: investmentId => (investmentId ? getSettlementCalculatorUrl(investmentId) : '#'),
  linkTitle: 'View Settlement Calculator',
};

const MAINTENANCE = {
  image: maintenance,
  title: 'Protect your home',
  subTitle: "Stay on top of seasonal maintenance tasks to maintain — and grow — your home's value.",
  linkUrl: getNewDashboardUrl(HOMEOWNER_DASHBOARD.MAINTENANCE),
  linkTitle: 'View maintenance tasks',
};

export const QUARTERLY_STATEMENTS_RESOURCES = [PLAN_AHEAD, MAINTENANCE];
