import { formatInlineAddress } from 'utils/address';
import { STAGES } from '../apps/dashboard/constants/trackConstants';

export const isTrackExpiredOrClosed = track => {
  return track.has_expired || track.current_stage === STAGES.CLOSED_LOST;
};

export const groupTracksByAddress = tracks => {
  return tracks.reduce((map, track) => {
    const formattedKey = formatInlineAddress(track.home.address);
    const data = map[formattedKey] || { tracks: [], inactiveTracks: [] };

    const organizedTracks = isTrackExpiredOrClosed(track)
      ? {
          tracks: data.tracks,
          inactiveTracks: [...data.inactiveTracks, track],
        }
      : {
          tracks: [...data.tracks, track],
          inactiveTracks: data.inactiveTracks,
        };

    map[formattedKey] = organizedTracks;
    return map;
  }, {});
};
