import { ModalConfirm, useAsync } from '@hometap/htco-components';
import React, { useState } from 'react';
import { patchHome } from 'apps/dashboard/data';

const NewValueAvailableModal = ({ home, valuation }) => {
  const { execute: patchHomeExecute } = useAsync(patchHome, { immediate: false });

  const checkAvmStatus = () => {
    if (valuation?.avm?.as_of_date && home?.homeowner_estimated_home_value_as_of) {
      if (valuation?.avm?.as_of_date > home?.homeowner_estimated_home_value_as_of) {
        return true;
      }
    }
    return false;
  };

  const [showNewValueModal, setShowNewValueModal] = useState(checkAvmStatus);
  const handleCancel = async () => {
    const today = new Date();
    setShowNewValueModal(false);
    return await patchHomeExecute(home?.id, {
      homeowner_estimated_home_value: home?.homeowner_estimated_home_value,
      homeowner_estimated_home_value_as_of: today.toISOString(),
    });
  };
  const handleClose = () => {
    setShowNewValueModal(false);
  };

  const handleConfirm = async () => {
    setShowNewValueModal(false);
    await patchHomeExecute(home?.id, { homeowner_estimated_home_value: null });
    window.location.reload();
  };

  return (
    <ModalConfirm
      className="PlannerDashboardConfirmNewValueModal"
      data-testid="hed-confirm-new-value-modal"
      width={580}
      open={showNewValueModal}
      header="New home value available!"
      confirmText="Use new value"
      cancelText="Keep existing value"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onClose={handleClose}
    >
      <div className="PlannerDashboardShowValueModalBody">
        <p>Your home value has recently changed. The new value is:</p>
        <h2>
          {valuation?.avm?.value !== undefined
            ? `$${new Intl.NumberFormat('en-US').format(valuation.avm.value.toFixed(0))}`
            : 'N/A'}
        </h2>
      </div>
    </ModalConfirm>
  );
};

export default NewValueAvailableModal;
