import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { intersection } from 'lodash';
import useTrackingData from 'apps/dashboard/hooks/useTrackingData';
import { useHomeEquityQueryParamData } from 'hooks/useQueryParamData';
import { PROFILE_INSTANCES, PROFILE_STAGES } from 'apps/dashboard/constants/buildYourProfile';
import BuildProfileLandingPage from './BuildProfileLandingPage';
import SelectDebts from './SelectDebts';
import ConfirmMortgageGaps from './ConfirmMortgageGaps';
import ConfirmDebts from './ConfirmDebts';
import {
  BUILD_PROFILE_FIELDS,
  BUILD_PROFILE_SELECTIONS_FIRST_TIME,
  SELECTION_OPTIONS,
} from 'apps/dashboard/constants/buildYourProfile';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { buildHomeProfileUrl, editHomeProfileUrl } from 'utils/links';
import useDebtForm from 'apps/dashboard/hooks/useDebtForm';
import ProgressiveFormSlides from '../ProgressiveFormSlides';
import DebtSlider from './DebtSlider';

const BuildProfileController = ({ instance, initialEditConfig }) => {
  const LIEN_ORDER = ['mortgage_loan', 'home_equity_loan', 'home_equity_line_of_credit', 'judgement_tax_or_other'];

  const { step: initialStage, ...initialFormData } = useHomeEquityQueryParamData(BUILD_PROFILE_FIELDS);

  const trackingData = useTrackingData();
  const history = useHistory();

  const { currentScenario, getEquityScenarioLiensAsync } = useEquityScenarioContext();

  const [curStage, setCurStage] = useState(parseInt(initialStage) || initialEditConfig?.step || 1);
  const [prevStage, setPrevStage] = useState(PROFILE_STAGES.BUILD_HOME_PROFILE);
  const [showPrompt, setShowPrompt] = useState(false);

  // Boolean for tracking whether or not the confirmation page should display after editing liens
  const [noConfirm, setNoConfirm] = useState(initialEditConfig?.noConfirm || false);

  const { results: equityScenarioLiens } = getEquityScenarioLiensAsync;

  const filteredBuildProfileSelectionsFirstTime = BUILD_PROFILE_SELECTIONS_FIRST_TIME.filter(
    item => item.value !== SELECTION_OPTIONS.LINK,
  ).map(item => {
    if (item.value === SELECTION_OPTIONS.USE_EXISTING) {
      return {
        ...item,
        label: 'Add debts',
      };
    }
    return item;
  });

  const form = {
    ...useDebtForm(equityScenarioLiens, initialFormData, initialEditConfig),
    tracking_data: trackingData,
  };

  const updateStages = step => {
    setShowPrompt(1 < step && step < 5);
    setPrevStage(curStage);
    setCurStage(step);
  };

  const onDebtSelect = () => {
    const orderedLiens = intersection(LIEN_ORDER, form?.formData?.liens);

    // Set the liens in the order we want the info pages to appear
    form.updateFormData({ liens: orderedLiens, hasOrderedLiens: true });
    setNoConfirm(false);
    updateStages(PROFILE_STAGES.DEBT_INFO_STEP);
  };

  const getDirection = (curStep, stepBefore) => {
    // When editing a single lien, always transition left
    if (initialEditConfig?.lienType && noConfirm) {
      return 'left';
    }
    const movingTo = prevStage === stepBefore && curStage === curStep;
    const movingBackFrom = prevStage === curStep && curStage === stepBefore;

    if (movingTo || movingBackFrom) {
      return 'left';
    }
    return 'right';
  };

  const onDebtNext = lienType => {
    const selectedLiens = form?.formData?.liens;
    if (selectedLiens?.indexOf(lienType) === selectedLiens?.length - 1 && !noConfirm) {
      return updateStages(PROFILE_STAGES.DEBT_CONFIRMATION_STEP);
    }
  };

  const onSingleLienEdit = lien => {
    // Navigate to the edit page for the lien type
    const url = instance === PROFILE_INSTANCES.EDIT_PROFILE ? editHomeProfileUrl() : buildHomeProfileUrl();
    history.push(url, { lienType: lien.lien_type });
    form.updateFormData({ liens: [lien.lien_type] });
    setCurStage(PROFILE_STAGES.DEBT_INFO_STEP);
  };

  const pagesToRender = [
    {
      slideProps: {
        direction: 'right',
        in: curStage === PROFILE_STAGES.BUILD_HOME_PROFILE || curStage === PROFILE_STAGES.EDIT_YOUR_PROFILE,
        appear: false,
        timeout: 400,
      },
      component:
        instance === PROFILE_INSTANCES.SETUP_PROFILE ? (
          <BuildProfileLandingPage
            setCurStage={setCurStage}
            onNext={step => updateStages(step)}
            selections={filteredBuildProfileSelectionsFirstTime}
            form={form}
          />
        ) : (
          <ConfirmDebts
            instance={instance}
            setCurStage={setCurStage}
            onNext={step => updateStages(step)}
            form={form}
            liens={currentScenario?.liens}
            onSingleLienEdit={onSingleLienEdit}
            title="Edit your home profile"
          />
        ),
    },
    {
      slideProps: {
        direction: getDirection(PROFILE_STAGES.DEBT_SELECTION_STEP, PROFILE_STAGES.BUILD_HOME_PROFILE),
        in: curStage === PROFILE_STAGES.DEBT_SELECTION_STEP,
      },
      component: (
        <SelectDebts
          onNext={onDebtSelect}
          onBack={() => updateStages(PROFILE_STAGES.BUILD_HOME_PROFILE)}
          form={form}
          instance={instance}
        />
      ),
    },

    {
      slideProps: {
        direction: getDirection(PROFILE_STAGES.DEBT_INFO_STEP, PROFILE_STAGES.DEBT_SELECTION_STEP),
        in: curStage === PROFILE_STAGES.DEBT_INFO_STEP,
        timeout: 400,
      },
      component: (
        <DebtSlider
          initialEditConfig={initialEditConfig}
          instance={instance}
          noConfirm={noConfirm}
          updateStages={updateStages}
          form={form}
          onDebtNext={onDebtNext}
        />
      ),
    },
    {
      slideProps: {
        direction: getDirection(PROFILE_STAGES.CONFIRM_MORTGAGE_INFO_STEP, PROFILE_STAGES.BUILD_HOME_PROFILE),
        in: curStage === PROFILE_STAGES.CONFIRM_MORTGAGE_INFO_STEP,
      },
      component: (
        <ConfirmMortgageGaps
          onNext={() => updateStages(PROFILE_STAGES.DEBT_CONFIRMATION_STEP)}
          onBack={() => updateStages(PROFILE_STAGES.BUILD_HOME_PROFILE)}
          onConfirm={() => localStorage.setItem(`is_confirmed_mortgage_loan`, 'true')}
          form={form}
        />
      ),
    },
    {
      slideProps: { direction: 'left', in: curStage === PROFILE_STAGES.DEBT_CONFIRMATION_STEP, timeout: 400 },
      component: (
        <ConfirmDebts
          setCurStage={setCurStage}
          onNext={step => updateStages(step)}
          form={form}
          liens={currentScenario?.liens}
          instance={instance}
          onSingleLienEdit={onSingleLienEdit}
          title="Thanks! Let’s review the information you’ve provided."
          description="If everything looks good, click the button below to explore your equity."
          showCTA
        />
      ),
    },
  ];

  return (
    <ProgressiveFormSlides
      slides={pagesToRender}
      shouldShowPrompt={showPrompt}
      canGoBackToDashboard={instance !== PROFILE_INSTANCES.SETUP_PROFILE}
    />
  );
};

export default BuildProfileController;
