import React from 'react';
import { Button } from '@hometap/htco-components';
import { browserTrack } from 'utils/segment';
import PropTypes from 'prop-types';

const TrackButton = ({ eventProperties, ...props }) => {
  const handleTrack = e => {
    browserTrack.cTAClicked({
      destination: props.href,
      page_url: window.location.href,
      style: props.theme || 'primary',
      text: e.target.innerText,
      ...eventProperties,
    });
    props.onClick && props.onClick(e);
  };
  return <Button {...props} onClick={handleTrack} />;
};

TrackButton.propTypes = {
  eventProperties: PropTypes.object,
  props: PropTypes.object,
};

export default TrackButton;
