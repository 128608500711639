import { apiWithAuth, apiNoAuth } from 'utils/api';
import { INVESTMENT_TERM_UNIT } from './rundownUtils';

export const getCalculateScenarioEndpoint = (type, id) => `/${type}/${id}/actions/calculate-scenario/`;

export const fetchScenarios = async (
  type,
  id,
  { investmentAmount, investmentLength, investmentTermUnit = INVESTMENT_TERM_UNIT.YEARS },
) => {
  return await apiWithAuth.v1.get(`/${type}/${id}/scenarios`, {
    params: {
      investment_amount: investmentAmount,
      effective_period_length: investmentLength,
      effective_period_unit: investmentTermUnit,
    },
  });
};

export const fetchScenarioCalculation = async (type, rundownId, body, params) => {
  return await apiNoAuth.post(getCalculateScenarioEndpoint(type, rundownId), body, { params });
};

export const fetchDefaultFutureHomeValue = async (amount, starting_home_value, term) => {
  const request = await apiNoAuth.post('/pricing/scenarios', {
    scenarios: [
      {
        idx: 0,
        investment_amount: amount,
        starting_home_value,
        appreciation_percent: {
          value: 0.03,
          annualized: false,
        },
        effective_period: {
          unit: 'years',
          length: parseInt(term),
        },
      },
    ],
  });

  if (request?.scenarios && request.scenarios[0]) {
    return request?.scenarios[0].appreciated_home_value;
  }
  return starting_home_value + 10000;
};
