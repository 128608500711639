export const CHECKLISTS_DEFAULT_DATA = [
  {
    season: 'Spring',
    tasks: [
      {
        label: 'Check Your Deck',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-check-your-deck/',
        name: 'checkDeck',
      },
      {
        label: 'Add New Mulch to Your Landscaping',
        complete: false,
        link: 'http://hometap.com/blog/hometap-tip-add-new-mulch-to-your-landscaping/',
        name: 'mulch',
      },
      {
        label: 'Patch Your Asphalt Driveway',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-patch-your-asphalt-driveway/',
        name: 'asphaltDriveway',
      },
      {
        label: 'Clear Dust From Your Dryer Vent',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-clear-dust-from-your-dryer-vent/',
        name: 'dryerVent',
      },
      {
        label: 'Replace the Batteries in Your Smoke and Carbon Monoxide Detectors',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-replace-the-batteries-in-your-smoke-and-carbon-monoxide-detectors/',
        name: 'smokeDetectors',
      },
    ],
    order: 1,
  },
  {
    season: 'Summer',
    tasks: [
      {
        label: 'Outdoor Pruning',
        complete: false,
        link: 'https://www.hometap.com/blog/outdoor-pruning-your-weekly-tip-from-hometap/',
        name: 'outdoorPruning',
      },
      {
        label: 'Bug-proof Your House and Garden',
        complete: false,
        link: 'https://www.hometap.com/blog/keeping-bugs-out-of-your-house-and-garden/',
        name: 'bugProof',
      },
      {
        label: 'Prep Your Home Before Vacation',
        complete: false,
        link: 'https://www.hometap.com/blog/going-on-vacation-dont-forget-to-prep-your-home/',
        name: 'vacation',
      },
      {
        label: 'Dust Your Ceiling Fan Blades',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-dust-your-ceiling-fan-blades/',
        name: 'ceilingFanBlades',
      },
      {
        label: 'Check Your Foundation Vents and Crawl Spaces',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-check-your-foundation-vents-and-crawl-spaces/',
        name: 'foundationVents',
      },
    ],
    order: 2,
  },
  {
    season: 'Fall',
    tasks: [
      {
        label: 'Clean Your Gutters and Downspouts',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-clean-your-gutters-and-downspouts/',
        name: 'cleanGutters',
      },
      {
        label: 'Schedule Your Furnace Inspection',
        complete: false,
        link: 'https://www.hometap.com/blog/how-to-ensure-your-furnace-is-ready-for-winter/',
        name: 'furnaceInspection',
      },
      {
        label: 'Flush and Close Outdoor Faucets',
        complete: false,
        link: 'https://www.hometap.com/blog/how-to-flush-outdoor-faucets/',
        name: 'flushFaucets',
      },
      {
        label: 'Prep Your Garden and Landscaping for Winter',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-prep-your-garden-for-winter/',
        name: 'gardenAndLandscaping',
      },
      {
        label: 'Stow Away Your Outdoor Furniture',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-stow-away-your-outdoor-furniture/',
        name: 'stowAwayFurniture',
      },
    ],
    order: 3,
  },
  {
    season: 'Winter',
    tasks: [
      {
        label: 'Replace Your Kitchen Water Filters',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-replace-your-kitchen-water-filters/',
        name: 'waterFilters',
      },
      {
        label: 'Avoid These Costly Maintenance Mistakes',
        complete: false,
        link: 'https://www.hometap.com/blog/home-maintenance-mistakes-according-to-inspectors/',
        name: 'maintenanceMistakes',
      },
      {
        label: 'Clean Your Refrigerator Coils',
        complete: false,
        link: 'http://hometap.com/blog/hometap-tip-clean-your-refrigerator-coils/',
        name: 'refrigeratorCoils',
      },
      {
        label: 'Drain and Flush Your Hot Water Heater',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-drain-and-flush-your-hot-water-heater/',
        name: 'waterHeater',
      },
      {
        label: 'Test Your Sump Pump',
        complete: false,
        link: 'https://www.hometap.com/blog/hometap-tip-test-your-sump-pump/',
        name: 'sumpPump',
      },
    ],
    order: 4,
  },
];
