import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@hometap/htco-components';
import { getFlattenAcceptedFileTypes, bytesToSize } from '../../apps/documents/utils';
import { MAX_BULK_UPLOAD_FILE_COUNT, MAX_UPLOAD_SIZE_BYTES } from '../../apps/documents/constants';
import './FileUploader.scss';

// Overwrite error messages to be more user friendly and aligned with our backend errors.
const ERROR_CODE_MESSAGE_MAP = {
  'file-invalid-type': "File extension is not allowed. Allowed extensions are: 'pdf, jpg, png'",
  'file-too-large': `File size exceeds limit of ${bytesToSize(MAX_UPLOAD_SIZE_BYTES)}.`,
};

const FileUploader = ({ options, uploadTrigger, onUpload }) => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } = useDropzone({
    accept: getFlattenAcceptedFileTypes().toString(),
    ...options,
  });

  useEffect(() => {
    if (uploadTrigger) {
      uploadTrigger.current = open;
    }
  }, [uploadTrigger, open]);

  useEffect(() => {
    if (acceptedFiles?.length || fileRejections?.length) {
      onUpload({
        acceptedFiles,
        rejectedFiles: fileRejections.map(file => {
          return {
            file: file.file,
            errors: file.errors?.map(err => {
              return {
                ...err,
                message: ERROR_CODE_MESSAGE_MAP[err.code] || err.message,
              };
            }),
          };
        }),
      });
    }
    // Adding onUpload to the dependency array causes loops that are hard to work around if they need to
    // set state for the parent based on this data - leaving it out works as long as the onUpload method doesn't need to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles, fileRejections]);

  return (
    <div {...getRootProps({ className: cx('FileUploader', { isDisabled: options?.disabled }) })}>
      <Icon size="3x" name="file-upload" className="FileUploaderIcon" />
      <span className="FileUploaderCTA">
        Drag and drop here, or <span className="FileUploaderCTAHighlight">pick from your files</span>
      </span>
      <span className="FileUploaderAdditionalInfo">
        Please note that you can upload up to {MAX_BULK_UPLOAD_FILE_COUNT} files at once.
      </span>
      <input {...getInputProps()} />
    </div>
  );
};

export default FileUploader;
