import { Tooltip } from '@hometap/htco-components';
import PropTypes from 'prop-types';

export const Content = ({ header, content, footer }) => {
  return (
    <div className="ValueTooltip">
      <h5>{header}</h5>
      <div className="ValueTooltipContent">{content}</div>
      {footer && <div className="ValueTooltipFooter">{footer}</div>}
    </div>
  );
};

const ValueTooltipInfo = ({ header, content, footer }) => {
  return (
    <span style={{ marginTop: '-4px' }}>
      <Tooltip content={<Content header={header} content={content} footer={footer} />} action="hover">
        <i className="icon htco-Icon icon-information-circle" />
      </Tooltip>
    </span>
  );
};

ValueTooltipInfo.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  footer: PropTypes.string,
};

export default ValueTooltipInfo;
