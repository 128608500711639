const LIEN_FIELDS = {
  LAST_KNOWN_CURRENT_BALANCE: 'last_known_current_balance',
  LIEN_KIND: 'lien_kind',
  LIEN_KIND_OTHER: 'lien_kind_other',
  LIEN_TYPE: 'lien_type',
  MONTHLY_PAYMENT: 'monthly_payment',
  RATE: 'rate',
  ORIGINATION_DATE: 'origination_date',
  EFFECTIVE_DATE: 'effective_date',
  MAX_WITHDRAWAL_AMOUNT: 'last_withdrawal_amount',
  ORIGINAL_BALANCE: 'original_balance',
  TERM_LENGTH_IN_MONTHS: 'term_length_in_months',
};

const ANNUAL_APPRECIATION_FIELDS = {
  APPRECIATED_HOME_VALUE: 'appreciated_home_value',
  MORTGAGE_BALANCE: 'mortgage_balance',
  YEAR: 'year',
};

export { LIEN_FIELDS, ANNUAL_APPRECIATION_FIELDS };
