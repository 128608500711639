import { Accordion, Paper } from '@hometap/htco-components';

import './AppraisalSelectionFAQ.scss';

const FAQ_DATA = [
  {
    heading: 'Why should I trust a virtual appraisal?',
    content: (
      <p>
        Hometap’s virtual appraisal uses a proprietary automated data model that obtains values from three independent
        and nationally-recognized AVM providers and verifies them through validation tests to produce a current home
        value that’s as accurate as possible. Data points include the age of a home, sales history of similar
        properties, prevailing market trends, and property features. Virtual appraisal values are comparable to a
        traditional appraisal when no major renovations or upgrades have been made recently.
      </p>
    ),
  },
  {
    heading: 'How involved is Hometap during the appraisal process?',
    content: (
      <p>
        Hometap contracts out home valuations to independent, third-party licensed appraisers. This ensures a current,
        fair, and unbiased valuation of the property.
      </p>
    ),
  },
  {
    heading: 'What happens if I disagree with my home valuation?',
    content: (
      <p>
        Since Hometap does not determine home value, we are not able to change the appraised value of your home. If you
        do not like the virtual appraisal value you have received initially, you are always able to opt for a
        traditional appraisal. If you initially chose to move forward with a traditional appraisal and you feel that the
        valuation was low, you can request a second appraisal to be completed. This will be an out-of-pocket cost to
        you. As long as both appraisals meet Hometap’s underwriting requirements, we will average the values of the two
        appraisals to come to your final home value. If you still feel that your home value is low, you are not
        obligated to accept the Hometap Investment offer and you will not be subject to any additional costs associated
        with the Investment.
      </p>
    ),
  },
  {
    heading: 'How long is an appraisal valuation good for?',
    content: (
      <p>
        Virtual appraisals are good for 60 days from the as-of date and traditional appraisals are good for 90 days from
        the as-of date.
      </p>
    ),
  },
];

const AppraisalSelectionFAQ = () => {
  return (
    <Paper className="AppraisalSelectionFAQContainer">
      <h2 className="AppraisalSelectionFAQHeading">FAQs</h2>
      {FAQ_DATA.map(({ heading, content }) => (
        <Accordion key={heading} header={<h3>{heading}</h3>}>
          {content}
        </Accordion>
      ))}
    </Paper>
  );
};

export default AppraisalSelectionFAQ;
