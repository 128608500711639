export const formatInlineAddress = (address = {}) => {
  const { street = '', unit, city, state = '', zip_code = '' } = address;
  return `${street}${unit && `, ${unit}`} | ${city && `${city},`} ${state} ${zip_code}`;
};

export const formatInlineAddressForMobile = (address, isScreenSize) => {
  const addressParts = address.split('|');
  return isScreenSize('sm') ? addressParts.map((part, i) => <p key={`${part}-${i}`}>{part}</p>) : address;
};

// eslint-disable-next-line max-params
export const formatFullAddress = (street, unit, city, state, zipCode) => {
  return `${street}${unit && unit.length > 0 ? `, ${unit}` : ''}, ${city}, ${state} ${zipCode}`;
};

export const formatFullAddressWithCommas = (address = {}) => {
  const { street = '', unit, city, state = '', zip_code = '' } = address;
  return `${street}${unit && unit.length > 0 ? `, ${unit}` : ''}, ${city && `${city},`} ${state}, ${zip_code}`;
};

export const formatOnlyStreetAddress = (address = {}) => {
  const { street = '', unit } = address;
  return `${street}${unit && unit.length > 0 ? `, ${unit}` : ''}`;
};
