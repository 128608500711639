import React from 'react';
import { useParams } from 'react-router-dom';
import { NotFoundBlock, useAsync } from '@hometap/htco-components';
import cx from 'classnames';

import { PREQUAL_TYPES } from 'apps/inquiry/constants/APIConstants';
import { fetchMultiplePrequalifications } from 'apps/inquiry/data/inquiryRequests';

import ReInquiryContentContainer from './ReInquiryContentContainer';
import ReInquiryOutcomeFailure from './outcome/ReInquiryOutcomeFailure';
import ReInquiryOutcomeSuccess from './outcome/ReInquiryOutcomeSuccess';
import useCurrentUser from 'hooks/useCurrentUser';
import { parsePrequalificationsToMap } from 'apps/inquiry/utils/parsePrequalificationOutcomes';

const ReInquiryOutcome = () => {
  const { identifier: inquiryId } = useParams();
  const { loading, error, results } = useAsync(fetchMultiplePrequalifications, {
    immediate: true,
    defaultLoading: true,
    params: [inquiryId, [PREQUAL_TYPES.INVESTMENT_AMOUNT, PREQUAL_TYPES.RISK]],
  });
  const { user } = useCurrentUser();

  const prequalMap = results ? parsePrequalificationsToMap(results) : {};
  const isSuccess = (prequalMap?.outcome === 'pass' || prequalMap?.outcome === 'undetermined') && !loading && !error;

  if (error) {
    return (
      <ReInquiryContentContainer pad={4}>
        <NotFoundBlock />
      </ReInquiryContentContainer>
    );
  }

  return (
    <ReInquiryContentContainer className={cx({ isSuccess })} loading={loading} pad={isSuccess ? 0 : 4}>
      {isSuccess ? (
        <ReInquiryOutcomeSuccess name={user.first_name} upToAmount={prequalMap.maxAmount} inquiryId={inquiryId} />
      ) : (
        <ReInquiryOutcomeFailure
          prequalifications={prequalMap?.prequalifications}
          firstName={user.first_name}
          lastName={user.last_name}
          ficoScore={prequalMap?.ficoScore}
        />
      )}
    </ReInquiryContentContainer>
  );
};

export default ReInquiryOutcome;
