import moment from 'moment';
import {
  formatAppreciationDataWithRenovations,
  formatExistingLienDataForApi,
  getCurrentEquity,
  getForecastedValueAndEquity,
  getRenovationSum,
  setLienData,
  updateInvestmentLiensWithCurrentAppreciatedValues,
  replaceMatchingLiens,
} from 'apps/dashboard/utils/dataUtils';
import { getYearFromDateString } from 'utils/date';
import sentry from 'utils/sentry';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import { getVisibleRenovations } from 'apps/dashboard/utils/renovationUtils';
import {
  getDefaultProduct,
  postAnnualAppreciation,
  postEquityScenario,
  postEquityScenarioLien,
  getEquityScenario,
  getSelfReportedLiens,
  getEquityScenarios,
} from 'apps/dashboard/data/homeEquityApi';

/*
  This file contains abstract data methods to account for home equity business logic
*/

export const fetchAndFormatAppreciationData = async (
  scenario,
  homeValuation,
  investment = null,
  firstVisitHEI = false,
) => {
  /*
    Every time there is an edit on the dashboard to the liens or scenario (excluding name),
    we need to get new appreciation data for the scenario. 
  */
  const liens = [...scenario?.liens];
  const renovationSum = getRenovationSum(getVisibleRenovations(scenario));
  const evalRenoSum = scenario?.initial_scenario ? 0 : renovationSum;

  try {
    const todayFormatted = moment().format('YYYY-MM-DD');
    const currentYear = new Date().getFullYear();
    const home_values = [];
    if (homeValuation) {
      home_values.push({
        as_of_date: todayFormatted,
        home_value: homeValuation.value + evalRenoSum,
      });
    }

    const visibleLiens = liens.filter(lien => !lien.hidden);
    let scenarioAndInvestmentLiens = [
      ...visibleLiens.map(lien =>
        formatExistingLienDataForApi({
          ...lien,
          as_of_date: lien.last_known_current_balance_as_of,
          current_balance: lien.last_known_current_balance,
        }),
      ),
    ];
    let hei;

    // Investment logic
    let investmentYear;
    let investmentLiens;
    if (investment) {
      hei = investment;
      home_values.push({
        as_of_date: investment.effective_date,
        home_value: investment.beginning_home_value,
      });
      investmentYear = getYearFromDateString(investment?.effective_date);
      investmentLiens = investment?.lien_reviews;
      if (investmentLiens.length) {
        if (firstVisitHEI) {
          // logic for first time HED users who have an HEI
          scenarioAndInvestmentLiens = investment.editedLienReviews;
        } else {
          // logic for return HED user with an HEI
          // we only want to show the user their investment liens if they also have that lien on their scenario
          scenarioAndInvestmentLiens = replaceMatchingLiens(scenarioAndInvestmentLiens, investment, investmentLiens);
        }
      }
    }

    let pastTimelineDiff = 0;
    if (currentYear >= investmentYear) {
      // if the user has an investment, we want to start the timeline from the investment year
      pastTimelineDiff = currentYear - investmentYear || 0;
    }

    const length = 10 + pastTimelineDiff;

    const appreciationRequestBody = {
      effective_period: {
        length,
        unit: 'years',
      },
      appreciation_percent: {
        annualized: true,
        value: scenario?.appreciation_rate,
      },
    };

    // Check if we have a HEI Lien
    const heiLien = visibleLiens.find(lien => {
      return lien.lien_type === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT;
    });

    // For hypothetical HEI's, we need to add the lien data to the hei body rather than the liens array
    if (heiLien) {
      const product = await getDefaultProduct();
      appreciationRequestBody.hei = {
        investment_amount: heiLien.original_balance,
        product_id: product?.id, //HOOT-2457, remove this when we make backend changes
        effective_date: new Date().toISOString().slice(0, 10),
      };
    }

    if (home_values.length) {
      appreciationRequestBody.home_values = home_values;
    }

    if (hei) {
      appreciationRequestBody.hei = hei;
    }

    if (scenarioAndInvestmentLiens.length) {
      // We don't want to include liens of HEI type in the appreciation request body
      appreciationRequestBody.liens = scenarioAndInvestmentLiens.filter(lien => {
        return lien.lien_type !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT;
      });
    }
    // get appreciation data for scenario
    const annualAppreciationResults = await postAnnualAppreciation(appreciationRequestBody);
    const annualAppreciation = annualAppreciationResults?.annual_data;
    scenario.annualAppreciation = formatAppreciationDataWithRenovations(annualAppreciation);

    // utils to get more equity data for dashboard
    const currentEquity = getCurrentEquity(homeValuation?.value, visibleLiens, scenario, investment);

    let forecastedAppreciationValues = {};
    if (annualAppreciation.length > 0) {
      forecastedAppreciationValues = annualAppreciation.map(appreciation => {
        return getForecastedValueAndEquity(appreciation, visibleLiens);
      });
    }

    scenario.valuations = {
      ...currentEquity,
      forecastedValues: forecastedAppreciationValues || [],
    };
    return { ...scenario };
  } catch (error) {
    const message = 'Failed to get annual appreciation data for scenario';
    console.error(message, error);
    sentry.logErrorWrapper(message, error);
    throw error;
  }
};

export const postScenarioAndLiens = async (homeId, scenarioData) => {
  const { liens } = scenarioData;
  try {
    const scenario = await postEquityScenario(homeId, scenarioData);

    const savedLiens = await Promise.all(
      liens.map(lien => {
        return postEquityScenarioLien(homeId, scenario?.id, lien);
      }),
    );

    scenario.liens = savedLiens;
    return scenario;
  } catch (error) {
    sentry.logErrorWrapper('Failed to save new scenario', error);
    throw error;
  }
};

/*
  EQUITY SCENARIO METHODS
*/

// Fetches existing equity scenarios
const fetchExistingEquityScenarios = async (homeId, chartData) => {
  try {
    const scenarios = await getEquityScenarios(homeId);
    if (scenarios?.length) {
      chartData.scenarios = scenarios;
      return scenarios;
    }
  } catch (error) {
    sentry.logErrorWrapper('Failed to fetch equity scenarios', error);
    throw error;
  }
};

const createEquityScenariosForHome = async (homeId, homeValuation, investment, chartData) => {
  if (investment) {
    try {
      const newScenario = await postEquityScenario(homeId, {
        name: 'Moderate Appreciation',
        appreciation_rate: '0.05',
        duration_months: 120,
      });

      setLienData(newScenario);
      newScenario.unsavedChanges = false;
      newScenario.renovations = [];
      newScenario.liens = [];

      // we call pricing endpoint now to get the current appreciated values for the investment liens
      // which we will use to create the scenario liens
      const newScenarioWithAppreciationRates = await fetchAndFormatAppreciationData(
        newScenario,
        homeValuation,
        investment,
        true,
      );
      const investmentLiensWithCurrentAppreciatedValues = updateInvestmentLiensWithCurrentAppreciatedValues(
        investment?.editedLienReviews,
        newScenarioWithAppreciationRates.annualAppreciation,
      );

      // Create liens on the scenario with the current appreciated values
      await Promise.all(
        investmentLiensWithCurrentAppreciatedValues.map(lien => {
          return postEquityScenarioLien(homeId, newScenario?.id, lien);
        }),
      );

      // Fetch new scenario with liens
      const newScenarioFetched = await getEquityScenario(homeId, newScenario?.id);
      chartData.scenarios = [newScenarioFetched];
    } catch (error) {
      const message = 'Failed to create equity scenario and liens for first time HEI user';
      console.error(message, error);
      sentry.logErrorWrapper(message, error);
    }
  } else {
    // this logic is for onboarding flow users
    try {
      const newScenario = await postEquityScenario(homeId, {
        name: 'Moderate Appreciation',
        appreciation_rate: '0.05',
        duration_months: 120,
      });
      // Attempt to fetch self reported liens (liens created from onboarding flow).
      const selfReportedLiens = await getSelfReportedLiens(homeId);
      if (selfReportedLiens?.length) {
        // Post new equity scenario liens from self reported liens
        await Promise.all(
          selfReportedLiens.map(selfReportedLien => {
            return postEquityScenarioLien(homeId, newScenario?.id, selfReportedLien);
          }),
        );
      }
      // Fetch new scenario with liens
      const newScenarioFetched = await getEquityScenario(homeId, newScenario?.id);
      chartData.scenarios = [newScenarioFetched];
    } catch (error) {
      const message = 'Failed to create initial equity scenario and lien';
      console.error(message, error);
      sentry.logErrorWrapper(message, error);
    }
  }
};

// Fetches or creates equity scenarios
const fetchOrCreateEquityScenarios = async (homeId, homeValuation, investment, chartData) => {
  const equityScenarios = await fetchExistingEquityScenarios(homeId, chartData);
  if (!equityScenarios?.length) {
    await createEquityScenariosForHome(homeId, homeValuation, investment, chartData);
  }
};

/*
  LIEN AND ANNUAL APPRECIATION
*/

const setLiensFetchAppreciationData = async (homeValuation, investment, chartData) => {
  if (homeValuation?.value && chartData.scenarios.length) {
    const scenariosWithAppreciationRates = await Promise.all(
      chartData.scenarios.map(scenario => {
        setLienData(scenario);
        scenario.unsavedChanges = false;
        return fetchAndFormatAppreciationData(scenario, homeValuation, investment, false);
      }),
    );
    chartData.scenarios = scenariosWithAppreciationRates;
  }
};

// Main function to fetch data for home equity tab on dashboard
export const fetchChartData = async ({ homeId, homeValuation, investment }) => {
  const chartData = {};

  await fetchOrCreateEquityScenarios(homeId, homeValuation, investment, chartData);
  await setLiensFetchAppreciationData(homeValuation, investment, chartData);

  return chartData;
};
