import { useAsync } from '@hometap/htco-components';
import { apiNoAuth } from '../../../utils/api';

const fetchStates = () => {
  return apiNoAuth.get(`/core/configurations/states/`);
};

const useStateInfo = () => {
  const { results: nodes } = useAsync(fetchStates, {
    immediate: true,
  });

  const operationalStateNames = nodes?.filter(state => state.status === 'Operational');

  const dropdownStates = nodes?.map(state => ({ label: state.abbreviation, value: state.abbreviation }));

  const stateMap = nodes?.reduce((stateNodes, state) => {
    stateNodes[state.abbreviation] = { status: state.status, name: state.name };
    return stateNodes;
  }, {});

  const abbreviationMap = nodes?.reduce((stateNodes, state) => {
    stateNodes[state.name] = state.abbreviation;
    return stateNodes;
  }, {});

  const isStateOperational = state => stateMap[state]?.status === 'Operational';

  return {
    states: nodes,
    operationalStateNames,
    dropdownStates,
    stateMap,
    isStateOperational,
    abbreviationMap,
  };
};

export default useStateInfo;
