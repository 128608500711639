import { useEffect, useMemo, useState } from 'react';
import HomeEquityHedData from './HomeEquityHedData';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import BuildProfileController from '../dashboard-build-profile/BuildProfileController';
import { Route, Switch, Redirect, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import EquityRenovationController from './equity-renovations/EquityRenovationController';
import { HOMEOWNER_DASHBOARD, buildHomeProfileUrl, editHomeProfileUrl, getNewHEDUrl } from 'utils/links';
import FinanceOptionsController from './equity-finance/FinanceOptionsController';
import { PROFILE_INSTANCES } from 'apps/dashboard/constants/buildYourProfile';
import FinanceGoalsController from '../dashboard-build-profile/finance-goals/FinanceGoalsController';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SmartCardCarousel from './smart-cards/SmartCardCarousel';
import Collapse from '@mui/material/Collapse';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import DashboardGoalsPage from 'apps/dashboard/components/dashboard-goals-page/DashboardGoalsPage';
import HouseCanaryLiensController from '../dashboard-house-canary-liens/HouseCanaryLiensController';
import useHouseCanaryLiens from 'apps/dashboard/hooks/useHouseCanaryLiens';
import { getHouseCanaryLienForms } from '../dashboard-house-canary-liens/utils';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import useHedPrequalRange from 'apps/dashboard/hooks/useHedPrequalRange';
import useSmartFacts from 'apps/dashboard/components/home-equity-controller/smart-cards/hooks/useSmartFacts';
import './HomeEquityController.scss';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper/SectionLoadingWrapper';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';
import useNavigatorContext from 'components/Layouts/DashboardLayout/hooks/useDashboardTabsContext';

const SMART_CARDS_TIMEOUT = 1000;

const HomeEquityController = () => {
  const [closeSmartCards, setCloseSmartCards] = useState(true);
  const [sentSmartCardsViewedEvent, setSentSmartCardsViewedEvent] = useState(false);
  const [sentSmartCardsCompletedEvent, setSentSmartCardsCompletedEvent] = useState(false);
  const { home } = useCurrentHome();
  const history = useHistory();
  const flags = useFlags();
  const { currentScenario, isLoadingHed, initialScenario, chartDataLoading } = useEquityScenarioContext();
  const { homeValuation } = useHomeValuation();

  const location = useLocation();
  const { setupProfileLater } = useDelayHomeEquityProfile();
  const { prequalRange } = useHedPrequalRange();
  const { hasHEDOnCurrentHome } = useNavigatorContext();
  const { smartFacts, setSmartCardsDate, updateSmartFacts, smartFactsLoading } = useSmartFacts();

  const hasConfirmedHomeDebts = home && home.hed_debts_confirmed;
  const { fetchAsync: fetchHCLiensAsync } = useHouseCanaryLiens({
    shouldFetch: !hasConfirmedHomeDebts && hasHEDOnCurrentHome,
  });

  const homeValueMissing = homeValuation && Object.keys(homeValuation).length <= 0;

  const HC_FLOW_ENDPOINT = 'accept-liens';
  const HC_FLOW_URL = getNewHEDUrl(`/${HC_FLOW_ENDPOINT}`);
  const matchObject = useRouteMatch({ path: `/dashboard/:currentTab/:page?` });

  const houseCanaryLiensData = fetchHCLiensAsync.results;
  const areScenariosLoading = chartDataLoading || !initialScenario;

  const houseCanaryLienForms = useMemo(() => {
    return getHouseCanaryLienForms(houseCanaryLiensData?.liens, initialScenario.liens);
  }, [houseCanaryLiensData?.liens, initialScenario.liens]);

  const hasRelevantHCLiens = houseCanaryLienForms.length && !areScenariosLoading;
  const shouldRedirectToHouseCanaryFlow =
    hasRelevantHCLiens &&
    !hasConfirmedHomeDebts &&
    matchObject?.params?.currentTab === HOMEOWNER_DASHBOARD.HOME_EQUITY &&
    matchObject?.params?.page !== HC_FLOW_ENDPOINT;

  useEffect(() => {
    if (shouldRedirectToHouseCanaryFlow) {
      return history.replace(HC_FLOW_URL);
    }
  }, [HC_FLOW_URL, history, shouldRedirectToHouseCanaryFlow]);

  // Closing smart card carousel timers
  let smartCardsTimeout;
  let setSmartCardDateTimeout;

  const setLocalStorageAndTimeout = () => {
    setCloseSmartCards(false);
    smartCardsTimeout = setTimeout(() => setSmartCardsDate(new Date()), SMART_CARDS_TIMEOUT);
  };

  const handleClosing = () => {
    if (!sentSmartCardsCompletedEvent) {
      browserTrack.smartCardsCompleted({
        version: getVersion(),
      });
      setSentSmartCardsCompletedEvent(true);
    }
    smartCardsTimeout = setTimeout(setLocalStorageAndTimeout, 4000);
  };

  useEffect(() => {
    // Timeout cleanup function
    return () => {
      if (smartCardsTimeout) {
        clearTimeout(smartCardsTimeout);
      }
      if (setSmartCardDateTimeout) {
        clearTimeout(setSmartCardDateTimeout);
      }
    };
    // eslint-disable-next-line
  }, []);

  // Smart cards viewed segment event logic
  useEffect(() => {
    const initialSmartFactId = smartFacts?.[0]?.id;
    if (initialSmartFactId && !sentSmartCardsViewedEvent) {
      browserTrack.smartCardViewed({
        card_id: initialSmartFactId,
        version: getVersion(),
      });
      setSentSmartCardsViewedEvent(true);
    }
    // eslint-disable-next-line
  }, [smartFacts]);
  const isLoading = isLoadingHed || fetchHCLiensAsync.loading || smartFactsLoading || areScenariosLoading;

  const renderDashboard = () => {
    return (
      <>
        {homeValuation && (
          <>
            {/* Smart Cards */}
            {flags?.showSmartCards && !homeValueMissing && smartFacts?.length > 1 && (
              <Collapse in={closeSmartCards} timeout={SMART_CARDS_TIMEOUT}>
                <div className="SmartCards">
                  <div className="PageBodyCenteredContainer">
                    <SmartCardCarousel cards={smartFacts} handleClosing={handleClosing} />
                  </div>
                </div>
              </Collapse>
            )}

            <div>
              <HomeEquityHedData
                updateSmartFacts={updateSmartFacts}
                currentEquity={currentScenario?.valuations?.currentEquity}
                tappableEquity={prequalRange?.max || 0}
                homeValueMissing={homeValueMissing}
              />
            </div>
            {homeValueMissing ? <Footer theme="light" /> : <Footer theme="light" />}
          </>
        )}
      </>
    );
  };

  return (
    <ScrollToTop>
      <SectionLoadingWrapper loading={isLoading}>
        <Switch>
          <Route path={HC_FLOW_URL}>
            <HouseCanaryLiensController lienForms={houseCanaryLienForms} asOfDate={houseCanaryLiensData?.as_of_date} />
          </Route>
          <Route exact path={getNewHEDUrl('/renovations/:project?/:renovationId?')}>
            <EquityRenovationController />
          </Route>
          <Route exact path={getNewHEDUrl('/goals')}>
            <DashboardGoalsPage />
          </Route>
          <Route exact path={getNewHEDUrl('/finance')}>
            <FinanceOptionsController prequalRange={prequalRange} />
          </Route>
          <Route path={editHomeProfileUrl()}>
            {home?.hed_debts_confirmed ? (
              <BuildProfileController initialEditConfig={location.state} instance={PROFILE_INSTANCES.EDIT_PROFILE} />
            ) : (
              <Redirect to={buildHomeProfileUrl()} />
            )}
          </Route>
          <Route exact path={getNewHEDUrl('/explore-financing')}>
            <FinanceGoalsController initialEditConfig={location.state} />
          </Route>
          <Route exact path={buildHomeProfileUrl()}>
            {!home?.hed_debts_confirmed || setupProfileLater ? (
              <BuildProfileController initialEditConfig={location.state} instance={PROFILE_INSTANCES.SETUP_PROFILE} />
            ) : (
              <Redirect to={getNewHEDUrl()} />
            )}
          </Route>

          <Route exact path={getNewHEDUrl()}>
            {home?.hed_debts_confirmed || setupProfileLater ? (
              renderDashboard()
            ) : (
              <Redirect to={buildHomeProfileUrl()} />
            )}
          </Route>
        </Switch>
      </SectionLoadingWrapper>
    </ScrollToTop>
  );
};

export default HomeEquityController;
