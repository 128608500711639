import PropTypes from 'prop-types';
import { Paper, IconButton } from '@hometap/htco-components';
import cx from 'classnames';
import { getAmountOfTasksCompleted } from '../../../utils/homeMaintenanceTasksUtils';

const SeasonChecklist = ({ checklist = {}, onSelectTask }) => {
  const tasksComplete = getAmountOfTasksCompleted(checklist?.tasks);
  const completeClassNames = done =>
    cx('TaskCompleted TaskIcon', {
      unchecked: !done,
    });

  return (
    <Paper className="SeasonChecklist">
      <div className="ChecklistTitle">
        <h4>{checklist?.season}</h4>
        <label>{tasksComplete}</label>
      </div>
      <div className="ChecklistTasks">
        {checklist?.tasks?.map(task => (
          <div key={task.label} className="TaskItem">
            <span className={completeClassNames(task.complete)}>
              {task.complete ? (
                <IconButton icon="check-mdc" theme="solid" onClick={() => onSelectTask(task?.name)} />
              ) : (
                <IconButton icon="circle-thin" onClick={() => onSelectTask(task?.name)} />
              )}
            </span>
            <a href={task.link} target="_blank" rel="noreferrer" className="TaskName">
              {task.label}
            </a>
          </div>
        ))}
      </div>
    </Paper>
  );
};

SeasonChecklist.propTypes = {
  onSelectTask: PropTypes.func,
  checklist: PropTypes.shape({
    season: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        complete: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
};

export default SeasonChecklist;
