import { Icon } from '@hometap/htco-components';

import './ApplicationNav.scss';
import ApplicationNavSection from '../ApplicationNavSection/ApplicationNavSection';

const ApplicationNav = ({ onCloseNav, sections }) => {
  return (
    <div className="ApplicationNavContent">
      <div className="ApplicationNavHeaderWrapper">
        <h2 className="ApplicationNavHeader">Application</h2>
        <button className="ApplicationNavMobileClose" onClick={onCloseNav}>
          <Icon name="icon-arrow-left" className="icon-2x " />
        </button>
      </div>

      {sections.map(({ sectionLabel, navItems, disabled, icon }, i) => {
        return (
          <ApplicationNavSection
            key={`applicationNav-${i}`}
            sectionLabel={sectionLabel}
            navItems={navItems}
            disabled={disabled}
            icon={icon}
          />
        );
      })}
    </div>
  );
};

export default ApplicationNav;
