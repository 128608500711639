import { Button } from '@hometap/htco-components';
import docHand from '../../../../images/document-hand.svg';
import { useRouteMatch, useHistory } from 'react-router-dom';

import './NoDocuments.scss';

const NoDocuments = () => {
  const history = useHistory();
  const match = useRouteMatch('/track/:trackId');

  return (
    <div className="NoDocuments">
      <figure>
        <img src={docHand} alt="Hand holding a document" />
      </figure>
      <div className="NoDocumentsCopy">
        <p className="NoDocumentsCopyHeading">No documents found.</p>
        <p className="NoDocumentsCopyDescription">You haven't uploaded any documents yet.</p>{' '}
        <p className="NoDocumentsCopyDescription">Once you do, you’ll see them here.</p>
      </div>
      <Button className="NoDocumentsBackButton" onClick={() => history.push(match.url)}>
        Back to Investment details
      </Button>
    </div>
  );
};

export default NoDocuments;
