export const DEBT_CALCULATOR_FAQS = [
  {
    fieldGroupName: 'faqs',
    faqGroup: {
      answer: (
        <>
          <p>
            Each of your debts likely has its own interest rate, monthly payment minimum, and principal. That’s why our
            debt payoff calculator allows you to select your type of debt, its interest rate, and your monthly payment
            so that you can see your payoff timeline for a single debt, or all of them combined. Types of debts you can
            choose from include credit card debt, school loan debt, auto loan debt, home equity line of credit (HELOC)
            debt, and other debt. You can adjust the interest rate and the monthly payment amount to help you determine
            how long it will take you to be debt free.
          </p>
          <p>
            Keep in mind that this calculator does not take into account amortized payments, or payments that are
            adjusted to account for interest and principal payments over a set period of time. Typically an amortized
            payment places the majority of the payment toward the interest at the beginning of the term, and then toward
            the principal payment as the loan period progresses.
          </p>
        </>
      ),
      fieldGroupName: 'faq_group',
      question: 'How Do You Calculate Debt Payments?',
    },
  },
  {
    fieldGroupName: 'faqs',
    faqGroup: {
      answer: (
        <p>
          If you discover that you’ll be paying off debts for longer than you expected, there’s good news. You can
          expedite your debt payoff timeline in several ways. Here are a few different strategies:
          <ul>
            <li>Consolidate your debt</li>
            <li>Debt snowball payoff</li>
            <li>Debt avalanche payoff</li>
            <li>Eliminate high-interest debt</li>
          </ul>
        </p>
      ),
      fieldGroupName: 'faq_group',
      question: 'How Long Will It Take to Pay Off Credit Card Debt?',
    },
  },
  {
    fieldGroupName: 'faqs',
    faqGroup: {
      answer: (
        <>
          <p>
            First, you need to understand the difference between good and bad debt. Good debts are those that help you
            achieve your goals, grow in value, or generate long-term wealth. Student loans and your mortgage are
            examples of good debt, because with higher education comes higher earning potential, and your home is a
            financial asset that’s likely to appreciate in value over time.
          </p>
          <p>
            Bad debts, then, are those that take away from your wealth rather than add to it, by lowering your credit
            score. Examples include high-interest credit cards and payday loans.
          </p>
          <p>
            Understanding the difference between the two can help you identify which debts to leverage and which ones to
            pay off faster.
          </p>
        </>
      ),
      fieldGroupName: 'faq_group',
      question: 'How to Consolidate Your Debt',
    },
  },
  {
    fieldGroupName: 'faqs',
    faqGroup: {
      answer: (
        <p>
          Following these five steps will help you pay off high-interest debt fast:
          <ul>
            <li>
              Understand how much you’re paying in interest for each debt. (The above Cost of Debt Calculator provides
              the average interest rate for each type of debt, but you can adjust them to reflect your own finances for
              an accurate look at how much you’re paying in interest.)
            </li>
            <li>Pay off your highest-interest debt, using the avalanche method.</li>
            <li>
              Consolidate debt by taking out a loan to repay your outstanding debt. This allows you to focus on paying
              off this new debt with one monthly payment. Debt consolidation often lowers the overall interest you’re
              paying, particularly if you have lots of high-interest credit card debt.
            </li>
            <li>
              You can also use a home equity investment to pay off the high-interest debt without adding another monthly
              payment.
            </li>
            <li>
              Avoid additional debt. Refrain from the temptation to put expenses on credit cards and pay for necessities
              using cash or debit cards instead.
            </li>
          </ul>
        </p>
      ),
      fieldGroupName: 'faq_group',
      question: 'How to Eliminate High-interest Debt',
    },
  },
  {
    fieldGroupName: 'faqs',
    faqGroup: {
      answer: (
        <>
          <p>
            It’s important to understand your debt ratio because the less bad debt you have, the better your credit. The
            better your credit, the more likely you are to be approved for home, car, or personal loans, and the
            likelier you are to have a lower interest rate on these loans.
          </p>
          <p>
            Use our cost of debt calculator to add up all of your debts, and divide the sum of your monthly debts by
            your monthly gross income (i.e., before taxes are deducted). Convert this number into a percentage by
            multiplying it by 100, and you have your debt-to-income ratio, or DTI.
          </p>
        </>
      ),
      fieldGroupName: 'faq_group',
      question: 'How Do You Calculate Your Debt Ratio?',
    },
  },
];
