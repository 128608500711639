import cx from 'classnames';
import PropTypes from 'prop-types';
import { SlideDown, Icon } from '@hometap/htco-components';

import { triggerClickOnKeyPress } from 'utils/a11y';

import NavImageAndTitle from '../NavImageAndTitle/NavImageAndTitle';
import useNavAccordion, { NavAccordionProvider } from '../hooks/useNavAccordion/useNavAccordion';

const NavAccordion = ({ children, open, onToggle, className, dataTestId, canExpand = true }) => {
  return (
    <NavAccordionProvider canExpand={canExpand} onToggle={onToggle} open={open}>
      <div className={className} data-testid={dataTestId}>
        <div className="w-full">{children}</div>
      </div>
    </NavAccordionProvider>
  );
};

const NavAccordionContent = ({ children, animationDuration }) => {
  const { open } = useNavAccordion();
  return (
    <SlideDown duration={animationDuration} visible={open}>
      {children}
    </SlideDown>
  );
};

const AccessibleTouchPoint = ({ onClick, onKeyDown, onPressProps }) => {
  return (
    <div
      className="absolute left-0 right-0 top-[50%] z-[1] h-full min-h-[40px] translate-y-[-50%] cursor-pointer focus:rounded focus:outline-none focus:outline-1 focus:outline-blue-100"
      onClick={open => {
        onClick(open);
      }}
      tabIndex="0"
      role="button"
      onKeyDown={onKeyDown}
      {...onPressProps}
    />
  );
};

const NavAccordionToggle = ({ title, titleIndicator, onPressProps }) => {
  const { open, onToggle, canExpand } = useNavAccordion();

  return (
    <div className="relative h-fit w-full">
      <AccessibleTouchPoint onClick={onToggle} onKeyDown={triggerClickOnKeyPress} onPressProps={onPressProps} />
      <div className="flex flex-1 cursor-pointer items-center justify-between">
        <NavImageAndTitle title={title} titleIndicator={titleIndicator} />
        {canExpand && (
          <div
            className={cx('transform pl-1 transition-transform duration-300', { 'rotate-180': open })}
            data-testid="nav-accordion-icon"
          >
            <Icon name="chevron2-down" />
          </div>
        )}
      </div>
    </div>
  );
};

NavAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  testid: PropTypes.string,
  onToggle: PropTypes.func,
  canExpand: PropTypes.bool,
};

NavAccordionToggle.propTypes = {
  title: PropTypes.node,
  titleIndicator: PropTypes.node,
  onPressProps: PropTypes.object,
};

NavAccordionContent.propTypes = {
  children: PropTypes.node.isRequired,
  animationDuration: PropTypes.number,
};

AccessibleTouchPoint.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPressProps: PropTypes.object,
};

NavAccordion.Toggle = NavAccordionToggle;
NavAccordion.Content = NavAccordionContent;
export default NavAccordion;
