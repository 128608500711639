import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Tooltip, useWindowSize } from '@hometap/htco-components';
import cx from 'classnames';
import './HomeEquityCard.scss';

const APPRECIATION_OPTIONS = {
  appreciation: {
    iconName: 'icon-arrow-increase-2',
    value: 'appreciation',
  },
  noChange: {
    iconName: 'icon-arrow-no-change-2',
    value: 'noChange',
  },
  depreciation: {
    iconName: 'icon-arrow-decrease-2',
    value: 'depreciation',
  },
};

const THEMES = {
  brightBlue: 'brightBlue',
  purple: 'purple',
  darkBlue: 'darkBlue',
};

const HomeEquityCard = ({
  title,
  value,
  percentage,
  className,
  theme = THEMES.brightBlue,
  ctaText,
  appreciationDirection,
  forecastLabel,
  forecastValue,
  toolTipContent,
  onLinkButtonClick,
  shouldPercentageDrop = false,
  ...props
}) => {
  const { screenWidth } = useWindowSize();
  const customStyles = {
    brightBlueTheme: theme === THEMES.brightBlue,
    purpleTheme: theme === THEMES.purple,
    darkBlueTheme: theme === THEMES.darkBlue,
  };

  const shouldDrop = shouldPercentageDrop && screenWidth <= 600;

  const percentageElement = percentage && (
    <div className="HomeEquityCardPercentage">
      {Object.values(APPRECIATION_OPTIONS).some(item => item.value === appreciationDirection) && (
        <Icon name={APPRECIATION_OPTIONS[appreciationDirection].iconName} size="lg" />
      )}
      <span>{percentage}%</span>
    </div>
  );

  const showForecastValue = forecastLabel && forecastValue;

  return (
    <div className={cx('HomeEquityCard', className, customStyles)} {...props}>
      <div className="HomeEquityCardDescription">
        <div className={cx('HomeEquityCardInfoContainer', [shouldDrop && 'isResponsive'])}>
          <h2>{title}</h2>
          <Tooltip content={toolTipContent} action="hover" position="bottom">
            <Icon name="icon-info" />
          </Tooltip>
        </div>
        {!shouldDrop && percentageElement}
      </div>
      <div className="HomeEquityCardBottom">
        <h2 className="HomeEquityCardValue">{value}</h2>
        {shouldDrop && percentageElement}
      </div>
      {showForecastValue && (
        <div className="HomeEquityCardForecast">
          {forecastLabel}
          <span>{forecastValue}</span>
        </div>
      )}
      <Button className="HomeEquityCardCTA" theme="link-alt" onClick={onLinkButtonClick}>
        {ctaText}
      </Button>
    </div>
  );
};

HomeEquityCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shouldPercentageDrop: PropTypes.bool,
  theme: PropTypes.oneOf([THEMES.brightBlue, THEMES.purple, THEMES.darkBlue]),
  appreciationDirection: PropTypes.oneOf([
    APPRECIATION_OPTIONS.appreciation.value,
    APPRECIATION_OPTIONS.noChange.value,
    APPRECIATION_OPTIONS.depreciation.value,
  ]),
  className: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  toolTipContent: PropTypes.node.isRequired,
  onLinkButtonClick: PropTypes.func.isRequired,
  forecastLabel: PropTypes.string,
  forecastValue: PropTypes.string,
};

export default HomeEquityCard;
