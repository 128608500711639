import React from 'react';
import { Checkbox } from '@hometap/htco-components';

import { applicationConsentLanguage } from '../../utils';
import { APPLICATION_FORM_FIELD } from '../../constants/formFields';
import useApplicationContext from '../../hooks/useApplicationContext';

import './SubmitApplicationForm.scss';

const SubmitApplicationForm = () => {
  const { application, registerApplicationFormField } = useApplicationContext();

  const hasConsentedApplication = application?.has_consented;

  return (
    <div className="SubmitApplicationForm ApplicationInfoFormFields">
      <Checkbox
        label="By checking this box:"
        checked={hasConsentedApplication}
        disabled={hasConsentedApplication}
        required={!hasConsentedApplication}
        {...registerApplicationFormField(APPLICATION_FORM_FIELD.consentGiven, 'checked')}
      />

      <ul className="SubmitApplicationFormTerms">
        {applicationConsentLanguage.map((detail, i) => (
          <React.Fragment key={`detail-${i}`}>{detail}</React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default SubmitApplicationForm;
