import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ProgressBar, useForm } from '@hometap/htco-components';
import { ONBOARD_FIELDS, ONBOARD_STEPS, ONBOARD_URL } from '../../constants';
import {
  OnboardingAddress,
  OnboardingGoals,
  OnboardingHomeownerInfo,
  OnboardingSignup,
  OnboardingVerify,
  OnboardingWelcome,
  OnboardTransition,
} from './components';
import { useHomeEquityQueryParamData } from 'hooks/useQueryParamData';
import useTrackingData from 'apps/dashboard/hooks/useTrackingData';
import { demoOnboardingData } from 'apps/dashboard/utils/onboardingDemoData';
import DemoButton from 'components/DemoButton/DemoButton';

const WELCOME_STEP = 0;
const ADDRESS_STEP = 1;

const PlannerOnboard = ({ stepOverride }) => {
  const { step: queryStep, ...initialFormData } = useHomeEquityQueryParamData(ONBOARD_FIELDS);
  const trackingData = useTrackingData();
  const [hasOtherGoal, setHasOtherGoal] = useState(false);
  const [forward, setForward] = useState(true);

  // Use the step override if it exists, otherwise use the query step or default to welcome page
  const initialStep = () => {
    if (stepOverride) return stepOverride;
    if (queryStep) {
      const step = parseInt(queryStep);
      // Step should not exceed address form
      if (step > ADDRESS_STEP) return ADDRESS_STEP;
      return step;
    }
    return WELCOME_STEP;
  };

  const [step, setStep] = useState(initialStep);

  const isProgressBarVisible = step !== 5;

  const onStep = i => step === i;

  const gotoStep = i => {
    const onboardStep = ONBOARD_STEPS[Math.floor(i)];
    if (!onboardStep) return;
    setForward(i > step);
    setTimeout(() => {
      setStep(() => {
        const query = window.location.search;
        const url = `${ONBOARD_URL}/${onboardStep}${query}`;
        window.history.pushState(null, onboardStep, url);
        return i;
      });
    }, 0);
  };
  const incrementStep =
    (i, stepIncrement = 1) =>
    () => {
      gotoStep(i + stepIncrement);
    };

  const decrementStep =
    (i, stepDecrement = 1) =>
    () => {
      gotoStep(i - stepDecrement);
    };

  const form = { ...useForm(initialFormData), tracking_data: trackingData };

  return (
    <>
      {isProgressBarVisible && <ProgressBar steps={5} curStep={step + 1} />}
      <AnimatePresence initial={false}>
        {onStep(0) && (
          <OnboardTransition key={0} forward={forward}>
            <OnboardingWelcome onNext={incrementStep(0)} />
          </OnboardTransition>
        )}
        {onStep(1) && (
          <OnboardTransition key={1} forward={forward}>
            <OnboardingAddress form={form} onPrev={decrementStep(1)} onNext={incrementStep(1)} />
          </OnboardTransition>
        )}
        {onStep(2) && (
          <OnboardTransition key={2} forward={forward}>
            <OnboardingGoals
              form={form}
              onPrev={decrementStep(2)}
              onNext={incrementStep(2)}
              hasOtherGoal={hasOtherGoal}
              setHasOtherGoal={setHasOtherGoal}
            />
          </OnboardTransition>
        )}
        {onStep(3) && (
          <OnboardTransition key={3} forward={forward}>
            <OnboardingHomeownerInfo form={form} onPrev={decrementStep(3)} onNext={incrementStep(3)} />
          </OnboardTransition>
        )}
        {onStep(4) && (
          <OnboardTransition key={4} forward={forward}>
            <OnboardingSignup form={form} onPrev={decrementStep(4)} onNext={incrementStep(4)} />
          </OnboardTransition>
        )}
        {onStep(5) && (
          <OnboardTransition key={5} forward={forward}>
            <OnboardingVerify form={form} />
          </OnboardTransition>
        )}
      </AnimatePresence>
      <DemoButton demoData={demoOnboardingData} onClickAction={form.updateFormData} />
    </>
  );
};

export default PlannerOnboard;
