import cx from 'classnames';
import { Link } from 'react-router-dom';

import NavAccordion from 'components/nav/NavAccordion/NavAccordion';
import NavIcon from '../NavIcon/NavIcon';
import useNavItem from 'components/nav/hooks/useNavItem/useNavItem';
import PropTypes from 'prop-types';

const NestedNavItem = ({ path, title, active = false }) => {
  return (
    <div className="text-blue-600 relative flex items-center py-[2.5px] pl-6 pr-1 font-semibold">
      {active && <span className="absolute left-0 h-full w-[3px] translate-x-[-2px] bg-blue-100" />}

      <Link
        className={cx(
          'htco- w-full text-sm hover:text-blue-100 hover:no-underline focus:rounded focus:outline-none focus:outline-1 focus:outline-blue-100',
          {
            'font-normal text-neutral-dark-100': !active,
            'font-bold ': active,
          },
        )}
        to={path}
      >
        {title}
      </Link>
    </div>
  );
};

const NavNestedItems = ({ active, open, navItems = [], title, icon, onToggle }) => {
  const { className, ...onPressProps } = useNavItem({ active, open });
  return (
    <NavAccordion
      open={open}
      className={className}
      onToggle={open => onToggle && onToggle(open)}
      dataTestId="nav-nested-items"
    >
      <NavAccordion.Toggle title={title} titleIndicator={<NavIcon icon={icon} />} onPressProps={onPressProps} />
      <NavAccordion.Content>
        <div className="pl-4">
          <div className="mt-6 flex flex-col gap-6 border-0 border-l border-solid border-blue-30">
            {navItems.map(item => (
              <NestedNavItem key={item.title} path={item.path} title={item.title} active={item.active} />
            ))}
          </div>
        </div>
      </NavAccordion.Content>
    </NavAccordion>
  );
};

export default NavNestedItems;

const NestedNavItemPropTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
};

const NavNestedItemsPropTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  onToggle: PropTypes.func,
  active: PropTypes.bool.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape(NestedNavItemPropTypes)).isRequired,
  icon: PropTypes.string.isRequired,
};

NestedNavItem.propTypes = NestedNavItemPropTypes;
NavNestedItems.propTypes = NavNestedItemsPropTypes;
