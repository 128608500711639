import ApplicationFormWrapper from './ApplicationFormWrapper/ApplicationFormWrapper';
import { useBoolState } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import { checkIsPrimaryApplicant, getInMemoryApplicants } from '../utils';
import { APPLICANT_FORM_FIELD } from '../constants/formFields';
import useApplicationContext from '../hooks/useApplicationContext';

import useTimedRender from 'hooks/useTimedRender';
import ApplicantSaveModal from '../components/modals/ApplicantSaveModal/ApplicantSaveModal';
import ApplicantInProgressWarningModal from '../components/modals/ApplicantInProgressWarningModal/ApplicantInProgressWarningModal';
import SuccessCheckMark from 'components/SuccessCheckMark/SuccessCheckMark';
import useApplicationNavContext from '../hooks/useApplicationNavContext';

import { APPLICATION_MODEL_FIELD } from '../constants/applicationDataFields';

const ApplicantFormSectionWrapper = ({ isSavePage, children }) => {
  const { applicantId = '' } = useParams();
  const isApplicantWarningModalOpen = useBoolState();
  const isApplicantSaveModalOpen = useBoolState();
  const [shouldRenderSuccessMessage, triggerSuccessMessageRender] = useTimedRender();

  const {
    applicants,
    addNewApplicant,
    getApplicantFormData,
    application,
    saveApplicant,
    isLoading,
    updateApplication,
  } = useApplicationContext();
  const { navigateToNextPage, navigateToNewApplicant, isLastPageInSection } = useApplicationNavContext();

  const currentApplicantFormData = getApplicantFormData(applicantId);

  const hasInMemoryApplicant = getInMemoryApplicants(applicants).length;
  const isPrimaryApplicant = checkIsPrimaryApplicant(currentApplicantFormData, applicants);

  const hasUpdatedPersistedApplicantForm = !!currentApplicantFormData?.dirtyFields && applicantId;
  const isApplicantsSubmissionPage =
    isSavePage && isLastPageInSection && !application[APPLICATION_MODEL_FIELD.hasSubmittedApplicants];
  const shouldSaveApplicant =
    ((isSavePage && !applicantId) || hasUpdatedPersistedApplicantForm) && !isApplicantsSubmissionPage;

  const handleSave = async () => {
    const savedApplicant = await saveApplicant(applicantId);

    if (!savedApplicant) return;

    if (!hasUpdatedPersistedApplicantForm) {
      navigateToNewApplicant({
        applicantId: savedApplicant?.id,
        pageKey: 'other-applicants',
      });
    }

    return triggerSuccessMessageRender();
  };

  const handleSaveConfirm = () => {
    handleSave();
    return isApplicantSaveModalOpen.setFalse();
  };

  const onProgressButtonClick = async () => {
    if (shouldSaveApplicant) {
      if (!isPrimaryApplicant) {
        return isApplicantSaveModalOpen.setTrue();
      }
      return handleSave();
    }

    if (isApplicantsSubmissionPage) {
      const updatedApplication = await updateApplication({
        [APPLICATION_MODEL_FIELD.hasSubmittedApplicants]: true,
      });

      if (!updatedApplication) return;
    }

    if (hasInMemoryApplicant && isLastPageInSection) {
      return isApplicantWarningModalOpen.setTrue();
    }

    if (currentApplicantFormData[APPLICANT_FORM_FIELD.hasOtherApplicants]) {
      addNewApplicant();
      return navigateToNewApplicant();
    }

    navigateToNextPage();
  };

  const isOnApplicantCreatePage = isSavePage && !applicantId;
  let buttonText;
  if (isOnApplicantCreatePage) {
    buttonText = 'Save Applicant';
  } else if (isApplicantsSubmissionPage) {
    buttonText = 'Submit Applicants';
  }

  return (
    <ApplicationFormWrapper
      onProgressButtonClick={onProgressButtonClick}
      buttonText={buttonText}
      isLoading={isLoading}
      isSavePage={shouldSaveApplicant || isApplicantsSubmissionPage}
      buttonDisabled={!currentApplicantFormData?.isCurrentFormValid || isLoading}
      buttonReplace={shouldRenderSuccessMessage && <SuccessCheckMark successText="Saved" />}
    >
      <ApplicantInProgressWarningModal
        applicant={getApplicantFormData()}
        isOpen={isApplicantWarningModalOpen.value}
        onClose={isApplicantWarningModalOpen.setFalse}
      />

      <ApplicantSaveModal
        applicant={currentApplicantFormData}
        isOpen={isApplicantSaveModalOpen.value}
        onClose={isApplicantSaveModalOpen.setFalse}
        onSave={handleSaveConfirm}
      />

      {children}
    </ApplicationFormWrapper>
  );
};

export default ApplicantFormSectionWrapper;
