import React from 'react';
import cx from 'classnames';
import { Accordion, Button, Paper } from '@hometap/htco-components';

import './FAQList.scss';

const FAQList = ({
  heading,
  faqs,
  includeSectionTitle,
  sectionTitle,
  showFaqsPageLink,
  useArrowTheme = false,
  theme = 'plain',
}) => {
  return (
    <Paper theme={theme}>
      <div
        className={cx('FAQContainer', {
          useArrowTheme: useArrowTheme,
          usePlusTheme: !useArrowTheme,
        })}
      >
        {(heading || sectionTitle) && (
          <h1
            className={cx('FAQHeading', {
              isTiempos: includeSectionTitle && !heading,
            })}
          >
            {heading || sectionTitle}
          </h1>
        )}
        {faqs.map(({ faqGroup }) => (
          <Accordion
            header={faqGroup.question}
            key={faqGroup.question}
            theme={useArrowTheme ? 'with-arrow' : 'with-plus'}
          >
            <span>{faqGroup.answer}</span>
          </Accordion>
        ))}
        {showFaqsPageLink && (
          <Button href="/faqs" theme="secondary" style={{ marginTop: '50px' }}>
            View All FAQs
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default FAQList;
