import { Loader, NotFoundBlock } from '@hometap/htco-components';

import './SectionLoadingWrapper.scss';

export const defaultErrorProps = {
  buttonLink: '/dashboard',
  errorHeading: "We're having trouble fetching this data. Please try refreshing",
  errorText: 'If the problem persists please reach out to your Investment Manager.',
  buttonLabel: 'Head on home',
};

const SectionLoadingWrapper = ({ loading, children, error, errorProps, className }) => {
  errorProps = { ...defaultErrorProps, ...(errorProps || {}) };
  if (error) {
    return (
      <NotFoundBlock
        error={`${error.response?.status !== 404 ? 'Whoops!' : 404}`}
        buttonLink={errorProps.buttonLink}
        title={errorProps.errorHeading}
        buttonLabel={errorProps.buttonLabel}
      >
        {errorProps.errorText}
      </NotFoundBlock>
    );
  }

  let content;
  if (loading) {
    content = (
      <div className="SectionLoadingWrapperLoading">
        <Loader type="dot-pulse" />
      </div>
    );
  } else if (className) {
    content = <div className={className}>{children}</div>;
  } else {
    content = children;
  }

  return content;
};

export default SectionLoadingWrapper;
