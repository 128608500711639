export const DEBT_TYPE_MAP = {
  creditCard: {
    icon: 'credit-card',
    title: 'Enter your credit card debt details',
    buttonText: 'Add credit card debt',
    label: 'Credit card debt',
    value: 'creditCard',
    listTitle: 'Credit Card Debt',
    xAxisLabel: 'Credit Card',
    colors: {
      balance: '#746BFF',
      interest: '#5342F7',
    },
  },
  autoLoan: {
    icon: 'car',
    title: 'Enter auto loan details',
    buttonText: 'Add auto loan debt',
    label: 'Auto loan',
    value: 'autoLoan',
    listTitle: 'Auto Loan Debt',
    xAxisLabel: 'Auto Loan',
    colors: {
      balance: '#00B5AF',
      interest: '#009490',
    },
  },
  studentLoans: {
    icon: 'loans',
    title: 'Enter student loan details',
    buttonText: 'Add student loan debt',
    label: 'Student loans',
    value: 'studentLoans',
    listTitle: 'Student Loan Debt',
    xAxisLabel: 'Student Loans',
    colors: {
      balance: '#6991F2',
      interest: '#1045C1',
    },
  },
  heloc: {
    icon: 'heloc',
    title: 'Enter your HELOC details',
    buttonText: 'Add HELOC debt',
    label: 'HELOC',
    value: 'heloc',
    listTitle: 'HELOC Debt',
    xAxisLabel: 'HELOC',
    colors: {
      balance: '#23BE8A',
      interest: '#18936A',
    },
  },
  other: {
    icon: 'wallet',
    title: 'Enter your details',
    buttonText: 'Add loan debt',
    label: 'Other',
    value: 'other',
    listTitle: 'Other Debt',
    xAxisLabel: 'Other',
    colors: {
      balance: '#70DAFF',
      interest: '#2AB4E4',
    },
  },
};
