import useApplicationContext from '../hooks/useApplicationContext';
import { APPLICATION_FORM_FIELD } from '../constants/formFields';
import BoolField from '../components/BoolField/BoolField';

const LiensFormFields = () => {
  const { registerApplicationFormField } = useApplicationContext();

  return (
    <div className="ApplicationInfoFormFields">
      <BoolField
        labelWidth="100%"
        labelGrow={false}
        required
        trueLabel="Yes, there are liens on my home."
        falseLabel="No, I own my home outright."
        baseRegisterField={registerApplicationFormField(APPLICATION_FORM_FIELD.hasLiens)}
      />
    </div>
  );
};

export default LiensFormFields;
