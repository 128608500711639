import React, { useEffect } from 'react';
import { NotFoundBlock } from '@hometap/htco-components';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { Routes, CompatRoute, Route as RouteV6 } from 'react-router-dom-v5-compat';
import { getDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import RundownController from './apps/rundowns/RundownController';
import PartnerInquiryController from './apps/partner/PartnerInquiryController';
import OfferController from './apps/offers/OfferController';
import SettlementCalculatorController from './apps/settlement/SettlementCalculatorController';
import { browserTrackPage } from 'utils/segment';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import ReInquiryRouter from 'apps/re-inquiry/ReInquiryRouter';
import InquiryAdverseActionRouter from './apps/inquiry/components/outcome/adverseAction/InquiryAdverseActionRouter';
import TrackDetailsRouter from 'components/TrackDetailsRouter/TrackDetailsRouter';
import { TrackProvider } from 'hooks/useTrack';
import FitQuiz from './apps/htdc/fit-quiz/FitQuiz';
import FitQuizResults from './apps/htdc/fit-quiz/FitQuizResults';
import DebtCalculator from 'apps/htdc/debt-calculator/DebtCalculator';
import DashboardProfile from 'apps/dashboard/components/dashboard-profile/DashboardProfile';
import DashboardSupport from 'apps/dashboard/components/dashboard-support-page/DashboardSupport';
import EquityPlannerRouter from 'apps/dashboard/components/equity-planner/EquityPlannerRouter';
import CommonLayout from 'components/Layouts/CommonLayout';
import ProgressiveInquiryController from 'apps/progressive-inquiry/ProgressiveInquiryController';
import ModalPageLayout from 'components/Layouts/ModalPageLayout/ModalPageLayout';
import DashboardRouter from 'apps/dashboard/components/router/DashboardRouter';

import env from 'utils/env';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import PrimaryLayout from 'components/Layouts/PrimaryLayout/PrimaryLayout';
import { HomeValuationProvider } from 'apps/dashboard/hooks/useHomeValuation';
import SecondaryLayout from 'components/Layouts/SecondaryLayout/SecondaryLayout';
import TrackDetailsRouterV6 from './components/TrackDetailsRouter/TrackDetailsRouterV6';
import TrackDetailsProviderWrapper from './components/TrackDetailsRouter/TrackDetailsProviderWrapper';

const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    browserTrackPage();
  }, [location]);

  const MOCK_PRIMARY_ROUTE_STYLES =
    'col-span-4 flex w-full items-center justify-center self-center justify-self-center sm:justify-content sm:col-start-6';

  return (
    <Switch>
      {!env.isProd() && (
        <Route exact path="/nav/secondary">
          <SecondaryLayout
            prevUrl="/nav"
            title="401 Jean Baptiste Point du Sable Lake Shore Drive, Chicago, IL 60611"
          />
        </Route>
      )}
      {!env.isProd() && (
        <Route path="/nav">
          <HomeValuationProvider>
            <Routes>
              <RouteV6 path="nav" element={<PrimaryLayout />}>
                <RouteV6
                  exact
                  path="investments"
                  element={
                    <PrimaryLayoutGrid>
                      <div className={MOCK_PRIMARY_ROUTE_STYLES}>nav investments</div>
                    </PrimaryLayoutGrid>
                  }
                />
                <RouteV6
                  exact
                  path="equity"
                  element={
                    <PrimaryLayoutGrid>
                      <div className={MOCK_PRIMARY_ROUTE_STYLES}>nav equity</div>
                    </PrimaryLayoutGrid>
                  }
                />
                <RouteV6 path="1" element={<div>nav 1</div>} />
                <RouteV6
                  path="2"
                  element={
                    <PrimaryLayoutGrid>
                      <div className={MOCK_PRIMARY_ROUTE_STYLES}>nav 2</div>
                    </PrimaryLayoutGrid>
                  }
                />
                <RouteV6
                  path="something"
                  element={
                    <PrimaryLayoutGrid>
                      <div className={MOCK_PRIMARY_ROUTE_STYLES}>Something</div>
                    </PrimaryLayoutGrid>
                  }
                />
                <RouteV6
                  path="something-else"
                  element={
                    <PrimaryLayoutGrid>
                      <div className={MOCK_PRIMARY_ROUTE_STYLES}>Something Else</div>
                    </PrimaryLayoutGrid>
                  }
                />
                <RouteV6
                  path="account-else"
                  element={
                    <PrimaryLayoutGrid>
                      <div className={MOCK_PRIMARY_ROUTE_STYLES}>Account Else</div>
                    </PrimaryLayoutGrid>
                  }
                />
                <RouteV6
                  path="account"
                  element={
                    <PrimaryLayoutGrid>
                      <div className={MOCK_PRIMARY_ROUTE_STYLES}>Account</div>
                    </PrimaryLayoutGrid>
                  }
                />
              </RouteV6>
            </Routes>
          </HomeValuationProvider>
        </Route>
      )}

      {/* IMPORTANT: Routes with custom layouts need remain above the CommonLayout routes (below). */}
      <Route path="/dashboard">
        <DashboardRouter />
      </Route>

      <PrivateRoute path="/track/:trackId">
        {
          // If there is a route that is track based it probably best to put it in the track details
          // router. This will allow useTrack() to be used without any further configuration
        }
        <TrackDetailsProviderWrapper>
          <TrackDetailsRouter />
          <TrackDetailsRouterV6 />
        </TrackDetailsProviderWrapper>
      </PrivateRoute>

      {
        // IMPORTANT: Whenever a Homeowner views a Rundown/Offer, make sure we make a POST to
        // `/api/v1/{rundowns|offers}/{id}/actions/view/`. This is necessary for legal audit trail
        // reasons.
      }
      <PrivateRoute path="/rundown/:id">
        <TrackProvider>
          <ModalPageLayout>
            <RundownController />
          </ModalPageLayout>
        </TrackProvider>
      </PrivateRoute>
      <PrivateRoute path="/offer/:id?">
        <TrackProvider>
          <ModalPageLayout>
            <OfferController />
          </ModalPageLayout>
        </TrackProvider>
      </PrivateRoute>

      <PrivateRoute exact path="/profile">
        <SecondaryLayout prevUrl={HOMEOWNER_DASHBOARD.ROOT} allowAccountDropdown={true}>
          <DashboardProfile />
        </SecondaryLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/support">
        <SecondaryLayout prevUrl={HOMEOWNER_DASHBOARD.ROOT} allowAccountDropdown={true}>
          <DashboardSupport />
        </SecondaryLayout>
      </PrivateRoute>

      <Route path="/">
        <CommonLayout>
          <Switch>
            <PrivateRoute path="/investments/:id/settlement-calculator">
              <SettlementCalculatorController />
            </PrivateRoute>
            <PrivateRoute path="/re-inquiry/:identifier">
              <ReInquiryRouter />
            </PrivateRoute>
            <Route path="/inquiries/:id/convert" exact>
              <PartnerInquiryController />
            </Route>
            <Route path="/inquiry">
              <ProgressiveInquiryController />
            </Route>
            <Route path="/adverse-action">
              <InquiryAdverseActionRouter />
            </Route>
            <Route path="/equity-planner">
              <EquityPlannerRouter />
            </Route>
            <Route path="/fit-quiz-results">
              <FitQuizResults />
            </Route>
            <Route path="/fit-quiz" exact>
              <FitQuiz />
            </Route>
            <CompatRoute path="/debt-calculator" exact>
              <DebtCalculator />
            </CompatRoute>
            <Route path="/refer-a-friend-hed" exact>
              {/* Friendbuy injects the iframe content into the div by id. (PAT-758) */}
              <div id="friendbuyhedlandingpage" />
            </Route>
            <Route path="/refer-a-friend" exact>
              {/* Friendbuy injects the iframe content into the div by id. (HOOT-1612) */}
              <div id="friendbuylandingpage" />
            </Route>
            <Route path="/friends" exact>
              {/* Friendbuy injects the iframe content into the div by id. (HOOT-1612) */}
              <div id="friendbuyfriendpage" />
            </Route>
            <Route path="/" exact>
              {() => {
                const currentParams = window.location.search;
                const redirectUrl = `${getDashboardUrl()}${currentParams}`;
                return <Redirect to={redirectUrl} />;
              }}
            </Route>
            <Route path="*">
              <div style={{ paddingTop: '50px' }}>
                <NotFoundBlock buttonLink="/" />
              </div>
            </Route>
          </Switch>
        </CommonLayout>
      </Route>
    </Switch>
  );
};

export default AppRouter;
