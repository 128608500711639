import { Link } from 'react-router-dom/';
import cx from 'classnames';

import NavImageAndTitle from '../NavImageAndTitle/NavImageAndTitle';
import NavIcon from '../NavIcon/NavIcon';

import useNavItem from '../hooks/useNavItem/useNavItem';
import PropTypes from 'prop-types';

const NavItemLink = ({ active, icon, title, to }) => {
  const { className, ...rest } = useNavItem({ active });

  return (
    <Link
      to={to}
      className={cx(
        className,
        'inline-block w-full outline-none hover:no-underline focus:outline-1 focus:outline-offset-[-6px] focus:outline-blue-100',
      )}
      data-testid="nav-item-link"
      {...rest}
    >
      <NavImageAndTitle titleIndicator={<NavIcon icon={icon} />} title={title} />
    </Link>
  );
};

export default NavItemLink;

NavItemLink.propTypes = {
  title: PropTypes.node,
  active: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
