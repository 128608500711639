import { Icon } from '@hometap/htco-components';
import PropTypes from 'prop-types';

const NavIcon = ({ icon }) => {
  return (
    <div data-testid="nav-icon" className="flex items-center text-2xl">
      <Icon name={icon} />
    </div>
  );
};

export default NavIcon;

NavIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};
