import sentry from 'utils/sentry';
import useSearchParams from 'hooks/useSearchParams';
import { apiWithAuth } from 'utils/api';

export const completeTask = async taskId => {
  const data = { resolution: 'completed' };
  return await apiWithAuth.v2.post(`/tasks/${taskId}/actions/complete`, data).catch(error => {
    sentry.logError('Failed to resolve task', {
      tags: {
        statusCode: error.response.status,
        statusText: error.response.statusText,
        taskId,
      },
      extra: {
        errorMessage: error.message,
        requestUrl: error.config?.url,
      },
    });
  });
};

export const completeDocUploadTask = async (taskId, uploaded_doc_ids = []) => {
  const data = { resolution: 'completed', uploaded_doc_ids: uploaded_doc_ids };
  return await apiWithAuth.v2.post(`/tasks/${taskId}/actions/complete-doc-upload`, data).catch(error => {
    sentry.logError('Failed to resolve task', {
      tags: {
        statusCode: error.response.status,
        statusText: error.response.statusText,
        taskId,
      },
      extra: {
        errorMessage: error.message,
        requestUrl: error.config?.url,
      },
    });
  });
};

const useTask = () => {
  const params = useSearchParams();
  const taskId = params.get('task_id');

  return { taskId, completeTask, completeDocUploadTask };
};
export default useTask;
