import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Icon, MuiTextInput, Tooltip, useAsync } from '@hometap/htco-components';
import birdhouseImg from 'images/inquiry/birdhouse.png';
import {
  CONTINUE_BUTTON_ID,
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import { ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import { patchInquiry } from '../../inquiry/data/inquiryRequests';
import './InquiryMortgageBalance.scss';

const InquiryMortgageBalance = ({ inquiryId, form, step, onNext }) => {
  const { registerField, handleSubmit, isFormValid, formData } = form;
  const { results, loading, execute: updateInquiry } = useAsync(patchInquiry);

  useEffect(() => {
    if (results) {
      onNext(step + 1);
    }
  }, [results, onNext, step]);

  const handleNoMortgage = () => {
    // HOOT-1764
    // Moved the logic to onsubmit, but keeping this function to increment the step
    onSubmit();
    onNext(step + 1);
  };

  const onSubmit = () => {
    // HOOT-1764
    // Here we check if form data has the mortgage_balance
    let { mortgage_balance } = formData;
    // If we don't have the mortgage data we need to set mortgage_balance
    if (mortgage_balance === undefined) {
      // we are setting it to the variable here because updateFormData isn't working
      // the field isn't showing up in form data for some reason.
      mortgage_balance = 0;
    }
    const updateApi = handleSubmit(() => {
      updateInquiry(inquiryId, { mortgage_balance });
    });
    updateApi();
  };

  const valid = () => {
    const { mortgage_balance } = formData;
    return isFormValid && mortgage_balance && mortgage_balance !== 0;
  };

  const canContinueToTheNextPage = valid();
  canContinueToTheNextPage && scrollIntoViewByElementId(CONTINUE_BUTTON_ID);

  return (
    <div className="InquiryMortgageBalance" data-testid="inquiry_mortgage_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.INFO]} />
      <ProgressiveUITemplate>
        <ContentPanel className="InquiryMortgageFormPanel">
          <div className="InquiryMortgageHeaderContainer">
            <h1>
              Do you have a mortgage or any other debts on your property?
              <Tooltip content="This will help us prepare the most accurate Investment Estimate possible.">
                <Icon name="question-circle" size="lg" />
              </Tooltip>
            </h1>
          </div>
          <p className="InquiryMortgageDescription">
            If yes, please provide the total balance below. If you own your home outright, skip to the next question.
          </p>
          <MuiTextInput
            label="Enter Total Debt Balance"
            type="number"
            inputMode="decimal"
            width="100%"
            precision={0}
            mask={[
              {
                mask: Number,
                signed: false,
                thousandsSeparator: ',',
              },
            ]}
            unmask="typed"
            startAdornment="$"
            maxLength={11}
            {...registerField(FIELDS.MORTGAGE_BALANCE)}
          />
          <div className="InquiryMortgageButtons">
            <Button id={CONTINUE_BUTTON_ID} disabled={!canContinueToTheNextPage} onClick={onSubmit} loading={loading}>
              Yes, I do
            </Button>
            <Button theme="link" onClick={handleNoMortgage} disabled={canContinueToTheNextPage}>
              No, I own my home outright
            </Button>
          </div>
        </ContentPanel>
        <ImageContentPanel imageSource={birdhouseImg} imgAlt="birds in a birdhouse" isCentered={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryMortgageBalance;
