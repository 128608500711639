import { Fragment, useState, useRef } from 'react';
import cx from 'classnames';
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { curveLinear } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { scaleLinear } from '@visx/scale';
import { localPoint } from '@visx/event';
import { useTooltipInPortal } from '@visx/tooltip';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { PatternLines } from '@visx/pattern';
import { extent, min, max, bisector } from 'd3-array';
import { currency, percent, roundToDecimal } from 'utils/numbers';
import { useOutsideClick, useWindowSize } from '@hometap/htco-components';
import { convertDateStringToHumanReadable } from 'utils/date';
import { format } from 'd3-format';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import { ValueChartRenovationIcon, ValueChartInvestmentIcon } from './ValueChartPin/ValueChartIcons';
import ValueChartPin from './ValueChartPin/ValueChartPin';

/*
  This file handles the visx chart

  Note: We originally planned on graphing each lien for a scenario with its own line, we have since moved away from that design and now have
  a single line which is the sum of all liens and pins notated on the chart for when individual liens are paid off. Product is unsure if we
  will possibly go mack to graphing each lien so that code ramains in this file commented out - wil the comment "MULI-LIENS"

  Note: we are now moving away from multi liens. more to come.
*/

const LIEN_TYPE_MAP = {
  [EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE]: {
    key: 'mortgage',
    title: 'Mortgage',
  },
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN]: {
    key: EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN,
    title: 'Home Equity Loan',
  },
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT]: {
    key: EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT,
    title: 'Home Equity Line of Credit',
  },
  [EQUITY_SCENARIO_LIEN_TYPE.OTHER]: {
    key: EQUITY_SCENARIO_LIEN_TYPE.OTHER,
    title: 'Other',
  },
};

const colorHometapBlue = '#366cef';
const colorHometapPurple = '#5830CB';
const colorWhite = '#fff';
const colorBlack = '#000';
const colorDarkGray = '#434c5e';
const colorMediumGray = '#687183';
const colorBlueFill = '#E6EBF9';
const colorBlueStroke = '#9BB6F6';
const $colorPatternLines = `#dce5ff`;
const TOOLTIP_WIDTH_MOBILE = 190;
const TOOLTIP_WIDTH_DESKTOP = 300;

const sumAndPayoffLiensForCurYear = (prevYearData, curYearData) => {
  const liensPaidOff = [];
  let lienSum = 0;
  let hasLienData = false;
  Object.values(EQUITY_SCENARIO_LIEN_TYPE).forEach(lienType => {
    if (curYearData.hasOwnProperty(lienType)) {
      lienSum = lienSum + curYearData[lienType];
      hasLienData = true;
    } else if (prevYearData && prevYearData.hasOwnProperty(lienType)) {
      liensPaidOff.push(lienType);
    }
  });
  return { lienSum, liensPaidOff, hasLienData };
};

const formatThousands = format('~s');
const formatValue = value => {
  if (value < 1000000) {
    return formatThousands(value);
  }
  return format('.2s')(value).replace('G', 'B');
};

const getDate = d => d?.x;
const getValue = d => d?.y;
const bisectDate = bisector(d => d?.x)?.left;
const valueFormat = (x, series) => {
  const curYear = series.find(obj => obj.x === x);
  if (curYear) {
    return currency(curYear.y);
  }
  return '$0';
};
const getDateValueFromXScale = (dateScale, tip) => Math.round(dateScale.invert(tip.x));

const valueFormatHometapShare = (x, homeValSeries, hometapShareSeries) => {
  const curHometapShare = hometapShareSeries.find(obj => obj.x === x);
  if (curHometapShare) {
    const curHomeVal = homeValSeries.find(homeVal => homeVal.x === curHometapShare.x);
    return currency(roundToDecimal(curHomeVal.y - curHometapShare.y));
  }
  return '$0';
};

const dateFormat = d => d;

const maxAppreciationPercent = 1.25;
const minLienPercent = 0.8;

const VisxChart = ({
  width: parentWidth,
  height = 390,
  homeAppreciationSeries = [],
  investment,
  forecast, // can only be 5 or 10 currently
  isScreenSmall = false,
  isScreenXs = false,
  hasRenos,
  isFutureView,
  showHEI,
}) => {
  const currentYear = new Date().getFullYear();
  const pastTimelineDiff = currentYear - homeAppreciationSeries?.[0]?.year || 0;
  const timelineLength = parseInt(forecast) + pastTimelineDiff + 1;
  const parentSvgEl = document.getElementById('ValueChartSvg');
  const width = parentSvgEl?.getBoundingClientRect()?.width || 1;

  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize('sm');
  const sideMargin = isScreenXs ? 16 : 32;
  const margin = { left: sideMargin, right: sideMargin, top: 0, bottom: 35 };

  // Main home appreciation series
  const homeValueDisplaySeries = homeAppreciationSeries
    .reduce((series, curValue) => {
      const existingValue = series.find(value => value.x === curValue.year);
      if (existingValue) {
        // If there is already a value for the given year, update it with the current value
        existingValue.y = curValue.appreciated_home_value_with_renos || curValue.appreciated_home_value;
        existingValue.appreciated_home_value = curValue.appreciated_home_value;
        existingValue.appreciated_home_value_with_renos = curValue.appreciated_home_value_with_renos;
      } else {
        // Otherwise, add the value to the series
        series.push({
          x: curValue.year,
          y: curValue.appreciated_home_value_with_renos || curValue.appreciated_home_value,
          appreciated_home_value: curValue.appreciated_home_value,
          appreciated_home_value_with_renos: curValue.appreciated_home_value_with_renos,
          ...curValue,
        });
      }
      return series;
    }, [])
    .slice(0, timelineLength);

  // Segments for home value series with renovations
  const priorHomeValueSegment = homeValueDisplaySeries
    .filter(v => v.year <= currentYear)
    .map(data => {
      return { ...data, y: data.appreciated_home_value };
    });

  const homeValueWithRenosSegment = homeValueDisplaySeries
    .filter(({ x }) => x >= currentYear)
    .map(data => {
      if (data.appreciated_home_value_with_renos) {
        return { ...data, y: data.appreciated_home_value_with_renos };
      }
      return data;
    });

  const currentYearData = homeValueDisplaySeries.find(data => data.x === currentYear);

  // Lien series
  let hasLiens = false;
  const liensEnd = [];
  const lienSeries = homeValueDisplaySeries
    .map((v, i) => {
      const prevYearData = i === 0 ? null : homeValueDisplaySeries[i - 1];
      const { lienSum, liensPaidOff, hasLienData } = sumAndPayoffLiensForCurYear(prevYearData, v);
      if (hasLienData) {
        hasLiens = true;
      }
      if (liensPaidOff.length) {
        liensPaidOff.forEach(lienType => {
          liensEnd.push({
            d: v.year,
            v: lienSum,
            lienType,
          });
        });
      }
      return { x: v.year, y: lienSum, liensPaidOff };
    })
    .slice(0, timelineLength)
    .filter(lien => {
      if (lien.y === 0 && !lien.liensPaidOff.length) {
        return false;
      }
      return true;
    });

  // Investment series logic
  let homeShareDisplaySeries = [];
  let shadingRefSeries = [];
  const investmentMaturityYear = investment?.maturity_date?.split('-')?.[0] || new Date().getFullYear() + 10;
  const investmentEffectiveYear = investment?.effective_date?.split('-')?.[0] || new Date().getFullYear();
  if (investment && showHEI) {
    shadingRefSeries = homeValueDisplaySeries
      .reduce((series, curValue) => {
        if (
          curValue.year <= investmentMaturityYear &&
          curValue.year >= investmentEffectiveYear &&
          curValue?.hometap_share?.dollars
        ) {
          series.push({
            x: curValue.year,
            y: curValue.y,
          });
        }
        return series;
      }, [])
      .slice(0, timelineLength);

    homeShareDisplaySeries = homeValueDisplaySeries
      .reduce((series, curValue) => {
        if (
          curValue.year <= investmentMaturityYear &&
          curValue.year >= investmentEffectiveYear &&
          curValue?.hometap_share?.dollars > 0
        ) {
          const hometapShare = curValue.appreciated_home_value - curValue?.hometap_share?.dollars;
          series.push({
            x: curValue.year,
            y: hometapShare,
          });
        }
        return series;
      }, [])
      .slice(0, timelineLength);
  }

  // nullSeries is used to scale the y axis in cases where we do not have other lines to scale against
  const nullSeries = homeValueDisplaySeries.map(v => ({ x: 0, y: 0 }));

  // let's scale the x axis
  // range: usable area in the canvas to draw the chart (pixels) (ex. [32, 268])
  // domain: range of values we wish to chart (years) (ex. [2022, 2032])
  const dateScale = scaleLinear({
    range: isScreenXs ? [5.5 * sideMargin, width - 2 * sideMargin] : [3.5 * sideMargin, width - 1.5 * sideMargin],
    domain: extent(homeValueDisplaySeries, d => d.x),
  });

  // let's scale the y axis
  // range: usable area in the canvas to draw the chart (pixels) (ex. [268, 0])
  // domain: range of values we wish to chart (usd) (ex. [324_000 * %80, 976_961 * 125%])
  const valueScale = scaleLinear({
    range: [height - margin.bottom, 20],
    domain: [
      min(lienSeries.length ? lienSeries : nullSeries, d => d.y) * minLienPercent,
      max(homeValueDisplaySeries, d => d.y) * maxAppreciationPercent,
    ],
    nice: true,
  });

  // logic for cursor position
  const [tip, setTip] = useState(null);
  const onTooltip = e => {
    const canvas = document.getElementById('ValueChartBar'); // Replace 'your-canvas-id' with the actual ID of your canvas element
    const canvasRect = canvas.getBoundingClientRect();
    const tooltipWidth = isMobile ? TOOLTIP_WIDTH_MOBILE : TOOLTIP_WIDTH_DESKTOP;
    const p = localPoint(e);
    if (isMobile && p.x + 16 < canvasRect.left) return;
    if (p) {
      const x0 = dateScale.invert(p.x);
      const xValue = Math.round(x0);

      // height of the home value circle for each data point on the chart
      p.i = bisectDate(homeValueDisplaySeries, x0 - 0.5);

      //height of the Hometap Share circle for each data point on the chart
      p.i2 = bisectDate(homeShareDisplaySeries, x0 - 0.5);

      if (p.i === homeValueDisplaySeries.length) {
        setTip(null);
        return;
      }

      if (p.i2 > homeShareDisplaySeries.length) {
        setTip(null);
        return;
      }

      p.d = dateScale(getDate(homeValueDisplaySeries[p.i]));
      p.v1 = valueScale(getValue(homeValueDisplaySeries[p.i]));
      p.v2 = valueScale(getValue(homeShareDisplaySeries[p.i2]));

      const tipLienData = lienSeries.find(lien => lien.x === xValue);
      if (tipLienData) {
        p.v3 = valueScale(tipLienData.y);
      } else {
        p.v3 = null;
      }

      const isRightOfCanvas = p.x + tooltipWidth >= canvasRect.width;
      if (!isMobile && isRightOfCanvas && p.d > tooltipWidth) {
        setTip({ ...p, tooltipLeft: p.d - tooltipWidth - 40 });
        return;
      }
    }
    setTip(p);
  };
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    debounce: 0,
    detectBounds: true,
    scroll: true,
  });
  const rectRef = useRef();
  useOutsideClick(rectRef.current, () => {
    setTip(null);
  });

  // have to put this logic here to follow hook rules
  if (homeValueDisplaySeries.length < 1) return null;

  // todays point on the chart where the house will be rendered
  const currentSeriesValue = homeValueDisplaySeries.find(data => data.x === currentYear);
  const todayDateData = {
    x: currentYear,
    y: currentSeriesValue.y,
  };
  const todayHomeValue = {
    d: dateScale(getDate(todayDateData)),
    v: valueScale(getValue(todayDateData)),
  };

  // investment points logic
  let investmentStart;
  let investmentEnd;
  if (investment) {
    investmentEnd = {
      d: dateScale(getDate(homeShareDisplaySeries[homeShareDisplaySeries.length - 1])),
      v: valueScale(getValue(homeShareDisplaySeries[homeShareDisplaySeries.length - 1])),
    };
    investmentStart = {
      d: dateScale(getDate(homeShareDisplaySeries[0])),
      v: valueScale(getValue(homeShareDisplaySeries[0])),
    };
  }

  const liensEndPoints = liensEnd.map(lien => ({
    d: dateScale(lien.d),
    v: valueScale(lien.v),
    lienType: lien.lienType,
  }));

  // tooltip info logic
  const legends = [
    {
      key: 'value',
      title: isFutureView ? 'Est. Future Home Value' : 'Est. Home Value',
      color: colorHometapBlue,
      value: x => valueFormat(x, homeValueDisplaySeries),
    },
  ];

  if (investment) {
    legends.push({
      key: 'share',
      title: 'Hometap Share',
      color: colorHometapPurple,
      value: x => valueFormatHometapShare(x, homeValueDisplaySeries, homeShareDisplaySeries),
    });
  }

  if (hasLiens) {
    legends.push({
      key: 'liens',
      title: 'Total Debts',
      color: colorBlack,
      value: x => valueFormat(x, lienSeries),
    });
  }

  const equityValueFormat = xValue => {
    const homeValueAtX = homeValueDisplaySeries.find(homeVal => homeVal.x === xValue);
    const sumOfLiensAtX = lienSeries.find(lienSum => lienSum.x === xValue) || { y: 0 };
    const hometapShare = homeValueAtX?.hometap_share?.dollars || 0;
    const equity = homeValueAtX?.y - sumOfLiensAtX?.y - hometapShare;
    const equityOverValue = equity / homeValueAtX?.y || 0;
    return `${currency(equity)} (${percent(equityOverValue)})`;
  };

  // x-axis label logic to force showing the first date and show less labels if there are a lot or
  // its rendering on mobile. This overrides the numTicks prop.
  const largeLabelCount = timelineLength >= 10;
  const smallLabelCount = timelineLength <= 4;
  const tickValues = homeValueDisplaySeries
    .filter((d, i) => {
      if (smallLabelCount && i % 1 === 0) {
        return true;
      } else if (isScreenSmall && !smallLabelCount && i % 3 === 0) {
        return true;
      } else if (largeLabelCount && !isScreenSmall && i % 2 === 0) {
        return true;
      } else if (!largeLabelCount && !isScreenSmall) {
        return true;
      }
      return false;
    })
    .map(d => d.x);

  // the first point in the chart / where the house will be rendered
  const homeValueOrigin = {
    d: dateScale(getDate(homeValueDisplaySeries[0])),
    v: valueScale(getValue(homeValueDisplaySeries[0])),
  };

  const homeValueEnd = {
    d: dateScale(currentYear),
    v: valueScale(getValue(currentYearData)),
  };

  const liensOrigin = {
    d: dateScale(getDate(lienSeries[0])),
    v: valueScale(getValue(lienSeries[0])),
  };

  let investmentOrigin;
  if (investment) {
    investmentOrigin = {
      d: dateScale(getDate(homeShareDisplaySeries[0])),
      v: valueScale(getValue(homeShareDisplaySeries[0])),
    };
  }

  let blackLineOrder;
  if (tip?.v3) {
    blackLineOrder = tip?.v3;
  } else if (tip?.v2) {
    blackLineOrder = tip?.v2;
  } else {
    blackLineOrder = tip?.v1;
  }

  return (
    <svg
      width={parentWidth}
      height={height}
      ref={containerRef}
      id="ValueChartSvg"
      onMouseLeave={() => {
        setTip(null);
      }}
    >
      <rect x={0} y={0} width={width} height={height} fill="white" ref={rectRef} />
      {/* filled area background */}
      <LinearGradient id="area-gradient" from={colorHometapPurple} to={colorHometapBlue} />
      {/* filled area between appreciation and liens (show the gradient with 10% opacity) */}
      <AreaClosed
        data={[...homeValueDisplaySeries]}
        x={d => dateScale(getDate(d))}
        y={d => valueScale(getValue(d))}
        yScale={valueScale}
        curve={curveLinear}
        fill={colorBlueFill}
      />

      {investment && (
        <>
          {/* fills below the appreciated home value line to the investment*/}
          <PatternLines
            id="pattern"
            width={15}
            height={15}
            stroke={$colorPatternLines}
            strokeWidth={1}
            orientation={['diagonal']}
          />
          <AreaClosed
            data={shadingRefSeries}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            yScale={valueScale}
            curve={curveLinear}
            fill={colorHometapBlue}
            fillOpacity={0.3}
          />
          <AreaClosed
            data={shadingRefSeries}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            yScale={valueScale}
            curve={curveLinear}
            fill="url('#pattern')"
          />

          {/* draws an invisible line to use for shading reference */}
          <LinePath
            data={shadingRefSeries}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            curve={curveLinear}
            stroke={colorHometapBlue}
            strokeWidth={0}
          />

          {/* fills below the investment line */}
          <AreaClosed
            data={homeShareDisplaySeries}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            yScale={valueScale}
            curve={curveLinear}
            fill={colorBlueFill}
          />

          {/* draws the line for the investment */}
          <LinePath
            data={homeShareDisplaySeries}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            curve={curveLinear}
            stroke={colorHometapPurple}
            strokeWidth={2}
          />
        </>
      )}

      {hasRenos ? (
        <>
          {priorHomeValueSegment.length > 0 && (
            <LinePath
              data={priorHomeValueSegment}
              x={d => dateScale(getDate(d))}
              y={d => valueScale(getValue(d))}
              curve={curveLinear}
              stroke={colorHometapBlue}
              strokeWidth={2}
            />
          )}

          <LinePath
            data={homeValueWithRenosSegment}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            curve={curveLinear}
            stroke={colorHometapBlue}
            strokeWidth={2}
          />
        </>
      ) : (
        <LinePath
          data={homeValueDisplaySeries}
          x={d => dateScale(getDate(d))}
          y={d => valueScale(getValue(d))}
          curve={curveLinear}
          stroke={colorHometapBlue}
          strokeWidth={2}
        />
      )}

      {hasLiens && (
        <>
          <AreaClosed
            data={lienSeries}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            yScale={valueScale}
            curve={curveLinear}
            fill={colorWhite}
          />
          <LinePath
            data={lienSeries}
            x={d => dateScale(getDate(d))}
            y={d => valueScale(getValue(d))}
            curve={curveLinear}
            stroke={colorBlack}
            strokeWidth={2}
          />
        </>
      )}

      {/* y axis labels */}
      <AxisLeft
        scale={valueScale}
        tickFormat={formatValue}
        hideAxisLine={true}
        hideTicks={true}
        numTicks={5}
        left={margin.left + 45}
        bottom={0}
        tickLabelProps={() => ({
          fill: colorDarkGray,
          fontSize: 12,
          textAnchor: 'end',
        })}
      />
      {/* y axis grid lines */}
      <GridRows
        left={margin.left + 45}
        scale={valueScale}
        width={width - margin.right - margin.left - 45}
        strokeDasharray="3,2"
        stroke={colorMediumGray}
        opacity={0.25}
        strokeWidth={1}
        numTicks={5}
      />
      {/* x axis labels */}
      <AxisBottom
        top={height - 20}
        left={-15}
        scale={dateScale}
        tickFormat={dateFormat}
        hideAxisLine={true}
        hideTicks={true}
        tickLabelProps={() => ({
          fill: colorDarkGray,
          fontSize: 12,
        })}
        tickValues={tickValues}
      />
      {/* rect that allows us to interact with the chart */}
      <Bar
        id={'ValueChartBar'}
        x={dateScale(getDate(homeValueDisplaySeries[0]))}
        y={0}
        width={width}
        height={height}
        fill="transparent"
        onTouchStart={onTooltip}
        onTouchMove={onTooltip}
        onMouseMove={onTooltip}
      />

      {/* pins */}
      {todayHomeValue?.v && todayHomeValue?.d && (
        <>
          {/* reno pin */}
          {hasRenos && (
            <ValueChartPin
              key={`investment-${todayHomeValue.x}${todayHomeValue.v}`}
              data={{ ...todayHomeValue, v: todayHomeValue.v }}
              height={height - 20}
              color={colorHometapBlue}
              hideLine
            >
              <ValueChartRenovationIcon />
            </ValueChartPin>
          )}

          {/* investment svg pin */}
          {investment &&
            investmentEnd?.d &&
            investmentMaturityYear <= homeValueDisplaySeries[homeValueDisplaySeries.length - 1]?.x && (
              <ValueChartPin
                key={`investment-${investmentEnd.x}${investmentEnd.v}`}
                data={{ ...investmentEnd, v: investmentEnd.v }}
                height={height - 20}
                color={colorHometapPurple}
              >
                <ValueChartInvestmentIcon />
              </ValueChartPin>
            )}

          {/* lien end date pins */}
          {hasLiens &&
            liensEndPoints.length &&
            liensEndPoints.map((lien, i) => (
              <ValueChartPin key={`lien-${i}_${lien.v}`} data={lien} height={height - 20} hideLine color="#152033">
                <ValueChartInvestmentIcon />
              </ValueChartPin>
            ))}
        </>
      )}

      {/* section is an svg group to render the line and circles between the liens and home value */}
      {tip && (
        <g>
          <Line
            from={{ x: tip.d, y: tip.v1 }}
            to={{ x: tip.d, y: blackLineOrder }}
            // to={{ x: tip.d, y: valueScale(0) }}
            stroke={colorBlack}
            strokeWidth={2}
            strokeOpacity={0.9}
          />
          {/* home value */}
          <circle cx={tip.d} cy={tip.v1} r={8} fill={colorBlack} strokeWidth={2} />
          <circle cx={tip.d} cy={tip.v1} r={6} fill={colorHometapBlue} stroke={colorWhite} strokeWidth={2} />

          {/* conditional to not render dot after the investment maturity date */}
          {investment &&
          legends?.[1]?.value(getDateValueFromXScale(dateScale, tip)) &&
          legends?.[1]?.value(getDateValueFromXScale(dateScale, tip)) !== '$0' ? (
            <>
              <circle cx={tip.d} cy={tip.v2} r={8} fill={colorBlack} fillOpacity={0.8} strokeWidth={2} />
              <circle cx={tip.d} cy={tip.v2} r={6} fill={colorHometapPurple} stroke={colorWhite} strokeWidth={2} />
            </>
          ) : null}

          {legends?.[investment ? 2 : 1]?.value(getDateValueFromXScale(dateScale, tip)) &&
          legends?.[investment ? 2 : 1]?.value(getDateValueFromXScale(dateScale, tip)) !== '$0' ? (
            <>
              <circle cx={tip.d} cy={tip.v3} r={8} fill={colorBlack} strokeWidth={2} />
              <circle cx={tip.d} cy={tip.v3} r={6} fill={colorBlack} stroke={colorWhite} strokeWidth={2} />
            </>
          ) : null}
        </g>
      )}
      {/* these are the dots at the line origins */}
      <g>
        <circle cx={homeValueOrigin.d} cy={homeValueOrigin.v} r={7} fill={colorHometapBlue} strokeWidth={2} />
        {!hasRenos && (
          <g>
            <g>
              <circle cx={homeValueEnd.d} cy={homeValueEnd.v} r={14} fill={colorBlueStroke} />
            </g>
            <g>
              <circle
                cx={homeValueEnd.d}
                cy={homeValueEnd.v}
                r={7}
                fill={colorHometapBlue}
                strokeWidth={2}
                stroke={colorWhite}
              />
            </g>
          </g>
        )}
        {investment && investmentOrigin?.d && (
          <>
            <circle cx={investmentOrigin.d} cy={investmentOrigin.v} r={7} fill={colorHometapPurple} strokeWidth={2} />
          </>
        )}
        {hasLiens && <circle cx={liensOrigin.d} cy={liensOrigin.v} r={7} fill={colorBlack} strokeWidth={2} />}
      </g>

      {/* the actual tooltip */}
      {tip && (
        <TooltipInPortal
          left={tip?.tooltipLeft || tip.d + 5}
          top={tip.v1}
          className={cx(isMobile ? 'equity-planner-chart-tooltip-mobile' : 'equity-planner-chart-tooltip-desktop')}
        >
          <div className="tooltip-body">
            <ul className="tooltip-legends">
              {legends.map(l => (
                <Fragment key={l.key}>
                  <li className={l.key}>
                    <span>
                      <span className="legend-symbol" style={{ backgroundColor: l.color }} />
                      {l.title}
                    </span>
                    <span className="legend-number">{l.value(getDateValueFromXScale(dateScale, tip))}</span>
                  </li>
                  {/* only show investment summary if investment exists and cursor is on the end date */}
                  {l.key === 'share' && tip.d === investmentEnd.d && (
                    <div className="tooltip-investment-summary">
                      Hometap Investment to be settled on or before{' '}
                      {convertDateStringToHumanReadable(investment.maturity_date)}
                    </div>
                  )}
                  {l.key === 'share' && tip.d === investmentStart.d && (
                    <div className="tooltip-investment-summary">
                      Hometap Investment was funded on {convertDateStringToHumanReadable(investment.effective_date)}
                    </div>
                  )}

                  {/* only show lien summary if liens exist and cursor is on the end date */}
                  {hasLiens &&
                    liensEndPoints.map((lienEndPoint, i) => {
                      return (
                        <Fragment key={lienEndPoint.lienType + i}>
                          {l.key === 'liens' && tip.d === lienEndPoint.d && (
                            <div className="tooltip-lien-summary">
                              {LIEN_TYPE_MAP[lienEndPoint.lienType]?.title} will be paid off in{' '}
                              {getDateValueFromXScale(dateScale, tip)}
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                </Fragment>
              ))}
            </ul>
          </div>
          <div className="tooltip-summary">
            <span className="summary-label">Equity</span>
            <span className="summary-number">{equityValueFormat(getDateValueFromXScale(dateScale, tip))}</span>
          </div>
        </TooltipInPortal>
      )}
    </svg>
  );
};

export default VisxChart;
