import moment from 'moment';
import numeral from 'numeral';

export const validateSSN = ssn => {
  const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
  const isValidSSN = ssnRegex.test(ssn);
  if (!isValidSSN) {
    return 'The number that you input was not in the right format (###-##-####)';
  }
};

const EMAIL_ERROR_MESSAGE = 'Please enter a valid email.';
export const emailValidator = (value, message = EMAIL_ERROR_MESSAGE) => {
  const regex =
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  if (!regex.test(String(value).toLowerCase())) {
    return message;
  }
};

const PHONE_ERROR_MESSAGE = 'Please enter a valid phone number.';
export const phoneValidator = (value, message = PHONE_ERROR_MESSAGE) => {
  // Length of 000-000-0000 format
  if (value.length !== 12) {
    return message;
  }
};

// Consider using a Date Picker instead.
const DATE_ERROR_MESSAGE = 'Please enter a valid date.';
export const dateValidator = (value, message = DATE_ERROR_MESSAGE) => {
  if (moment(value, 'M/D/YYYY', true).isValid() === false) {
    return message;
  }
};

const POSITIVE_VALUE_ERROR_MESSAGE = 'Please enter an amount greater than 0.';
export const positiveValueValidator = (value, message = POSITIVE_VALUE_ERROR_MESSAGE) => {
  if (numeral(value).value() <= 0) {
    return message;
  }
};
