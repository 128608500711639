import apiWithAuth from 'utils/api';

export const startApplication = ({ trackId }) => {
  return apiWithAuth.v1.post(`tracks/${trackId}/start-application/`);
};

export const fetchApplication = ({ applicationId }) => {
  return apiWithAuth.v1.get(`/applications/${applicationId}/`);
};

export const updateApplication = ({ applicationId, data }) => {
  return apiWithAuth.v1.patch(`/applications/${applicationId}/`, data);
};

export const fetchApplicationApplicants = ({ applicationId }) => {
  return apiWithAuth.v1.get(`/applications/${applicationId}/applicants/`);
};

export const createApplicant = ({ applicationId, data }) => {
  return apiWithAuth.v1.post(`applications/${applicationId}/applicants/`, data);
};

export const updateApplicant = ({ applicationId, applicantId, data }) => {
  return apiWithAuth.v1.patch(`applications/${applicationId}/applicants/${applicantId}/`, data);
};

export const deleteApplicant = ({ applicantId, applicationId }) => {
  return apiWithAuth.v1.delete(`applications/${applicationId}/applicants/${applicantId}/`);
};

export const createApplicationConsent = ({ applicationId }) => {
  return apiWithAuth.v1.post(`applications/${applicationId}/actions/consent/`);
};
