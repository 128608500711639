import { useCookies } from 'react-cookie';
import useSearchParams from '../../../hooks/useSearchParams';
import { EQUITY_DASHBOARD_TRACKING_DATA } from '../../inquiry/constants/inquiryConstants';

/*
  Returns an object with tracking data that prioritizes query params, and then cookie data
 */
const INQUIRY_CREATE_PREFIX = 'tracking_data_';
const INQUIRY_CONVERT_PREFIX = 'track_';

const useTrackingData = () => {
  const query = useSearchParams();
  const [cookies] = useCookies(['ht_utm_data']);
  const htUtmDataCookie = cookies?.ht_utm_data;

  const getUtmCookieData = () => {
    if (!htUtmDataCookie) return;

    return Object.keys(htUtmDataCookie).reduce((utmData, cookieKey) => {
      let key = cookieKey;
      const keyContainsPrefix = (key, prefix) => key.startsWith(prefix);

      if (keyContainsPrefix(cookieKey, INQUIRY_CREATE_PREFIX)) {
        // eslint-disable-next-line prefer-destructuring
        key = cookieKey.split(INQUIRY_CREATE_PREFIX)[1];
      }
      if (keyContainsPrefix(cookieKey, INQUIRY_CONVERT_PREFIX)) {
        // eslint-disable-next-line prefer-destructuring
        key = cookieKey.split(INQUIRY_CONVERT_PREFIX)[1];
      }

      if (EQUITY_DASHBOARD_TRACKING_DATA.includes(key)) {
        utmData[key] = htUtmDataCookie[cookieKey];
      }
      return utmData;
    }, {});
  };

  const getUrlParamData = () => {
    const paramData = {};
    for (const [paramKey, paramValue] of query.entries()) {
      if (EQUITY_DASHBOARD_TRACKING_DATA.includes(paramKey)) {
        paramData[paramKey] = paramValue;
      }
    }
    return paramData;
  };

  // TODO: Do we need to parse session data too, along with cookies?
  // const sessionData = getSessionStorageData();

  // Parse cookie data
  const cookieData = getUtmCookieData();

  // Parse URL params

  const urlParamData = getUrlParamData();
  const trackingData = { ...cookieData, ...urlParamData };

  return trackingData;
};

export default useTrackingData;
