import { Modal, InputMask, MuiTextInput, Button, useForm, MuiSelect } from '@hometap/htco-components';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';

import React, { useState } from 'react';
import { useHomeValuationMutation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';

const EditHomeValueModal = ({ on, onClose, initialHomeValue, updateSmartFacts }) => {
  const { home } = useCurrentHome();
  const update = useHomeValuationMutation(home);
  const { currentScenario } = useEquityScenarioContext();
  const { registerField, isFormValid, handleSubmit } = useForm(initialHomeValue || 0);
  const [selectedValue, setSelectedValue] = useState('');
  const [otherValue, setOtherValue] = useState('');

  const onSubmit = async data => {
    if (data?.homeowner_estimated_home_value) {
      await update.mutateAsync(Math.round(parseFloat(data.homeowner_estimated_home_value)));
      updateSmartFacts({ unhelpfulSmartFactsChanged: false, homeId: home?.id, scenarioId: currentScenario?.id });
    }
    onClose();
  };
  return (
    <div className="EditHomeValueModalWrapper">
      <Modal
        open={on}
        width={580}
        onClose={() => {
          onClose();
        }}
      >
        {() => {
          return (
            <form onSubmit={handleSubmit(onSubmit)} className="EditHomeValueModal">
              <h2>Edit Home Value</h2>
              <p>
                If you have another home value that you’ve been using to track your equity, you can use that here to see
                how it’ll grow over time.
              </p>
              <div className="ModalField">
                <MuiTextInput
                  name="homeValue"
                  label="Manual home value"
                  type="number"
                  inputMode="decimal"
                  data-testid="home-value"
                  mask={InputMask.MONEY_USD_MASK}
                  startAdornment="$"
                  unmask="typed"
                  width="100%"
                  data-lpignore="true"
                  {...registerField('homeowner_estimated_home_value')}
                  required
                />
              </div>
              <div className="ModalField">
                <MuiSelect
                  error=""
                  isSearchable
                  label="Select a reason"
                  onChange={setSelectedValue}
                  name="select a reason"
                  width="100%"
                  options={[
                    {
                      value: 'Curious',
                      label: 'Just exploring the tool',
                    },
                    {
                      value: 'renovation/remodel',
                      label: 'I recently renovated',
                    },
                    {
                      value: 'market fluctuation',
                      label: 'The value better reflects the market',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                    },
                  ]}
                  value={selectedValue}
                />
              </div>
              <div className="ModalField">
                {selectedValue === 'Other' && (
                  <MuiTextInput
                    label='If "other," share why'
                    maxLength={999}
                    name="OtherResponse"
                    onChange={setOtherValue}
                    type="text"
                    value={otherValue}
                    width="100%"
                  />
                )}
              </div>
              <div className="ModalField">
                <Button type="submit" disabled={!isFormValid} data-testid="submit-button">
                  Update value
                </Button>
              </div>
            </form>
          );
        }}
      </Modal>
    </div>
  );
};

export default EditHomeValueModal;
