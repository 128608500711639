import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button } from '@hometap/htco-components';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { STAGES } from 'apps/dashboard/constants';

export const useReferFriendCTA = () => {
  const history = useHistory();
  const flags = useFlags();
  const { tracks } = useUserTracksContext();

  return {
    shouldBeShown:
      flags.referFriend && tracks?.some(track => [STAGES.INVESTED, STAGES.SETTLED].includes(track.current_stage)),
    onClick() {
      history.push('/refer-a-friend');
    },
  };
};

const ReferFriendCTAButton = ({ onClick }) => {
  return (
    <Button
      className="z-2 fixed left-0 top-2/4 -mt-36 origin-bottom-left rotate-90 rounded-b-none shadow-[0_4px_28px_0px_rgba(32,53,104,0.18)] max-md:!hidden"
      onClick={onClick}
    >
      Refer a friend
    </Button>
  );
};

export default ReferFriendCTAButton;
