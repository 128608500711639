import React from 'react';
import FitQuizController from './controllers/FitQuizController';
import PageSEO from '../components/PageSEO';
import Layout from '../components/Layout';

const FitQuiz = () => {
  return (
    <PageSEO
      description="See if Hometap is a good fit by taking our quiz!"
      title="Fit Quiz"
      canonicalUrl="https://go.hometap.com/fit-quiz"
      opengraphTitle="Hometap | Is Hometap right for me?"
      opengraphDescription="See if Hometap is a good fit by taking our quiz!"
    >
      <Layout>
        <FitQuizController />
      </Layout>
    </PageSEO>
  );
};

export default FitQuiz;
