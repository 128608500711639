export const RESULT_MAYBE = {
  use_case_business: {
    title: `Hometap might be a fit to help you fund your business.`,
    text: `For some people, taking a home equity investment can be a smart way to add some financial security to their business and accelerate their growth. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_education: {
    title: `Hometap might be a fit to help you fund an education.`,
    text: `For some people, taking a home equity investment can be a smart way to pay for college without the burden of interest-heavy student loans. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_renovate: {
    title: `Hometap might be a fit to help you fund your home renovation.`,
    text: `For some people, taking a home equity investment can be a smart way to pay for that dream kitchen, new deck, or roof replacement. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_diversify: {
    title: `Hometap might be a fit to help you diversify your portfolio.`,
    text: `For some people, taking a home equity investment can be a smart way to access the wealth they’ve built up in their home to begin investing in other properties, asset classes, or the stock market. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_divorce: {
    title: `Hometap might be a fit to help you fund your divorce.`,
    text: `For some people, taking a home equity investment can be a smart way to pay off expensive legal fees or other unexpected costs of divorce. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_debts: {
    title: `Hometap might be a fit to help you pay off debt.`,
    text: `For some people, taking a home equity investment can be a smart way to pay off credit cards, student loans, or other high interest debts. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_buy_home: {
    title: `Hometap might be a fit to help you buy another property.`,
    text: `For some people, taking a home equity investment can be a smart way to afford a down payment on that rental property or vacation home they’ve always wanted. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_retirement: {
    title: `Hometap might be a fit to help you fund your retirement.`,
    text: `For some people, taking a home equity investment can be a smart way to boost your retirement savings so you can comfortably age in place. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_emergency: {
    title: `Hometap might be a fit to help you pay for emergency expenses.`,
    text: `For some people, taking a home equity investment can be a smart way to avoid the financial hardships that come with losing a job, medical emergencies, or other unexpected life events. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_finance_car: {
    title: `Hometap might be a fit to help you finance a car.`,
    text: `For some people, taking a home equity investment can be a smart way to afford a new or used car without the burden of interest-heavy auto loans. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_fund_family: {
    title: `Hometap might be a fit to help you fund your growing family.`,
    text: `For some people, taking a home equity investment can be a smart way to handle the financial needs of a new child. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
  use_case_other: {
    title: `Hometap might be a fit to help you achieve your financial goals.`,
    text: `For some people, taking a home equity investment can be a smart way to fund the financial goals or needs they have -- without having to sell their home or take on debt. Based on the information you provided, we think Hometap could be a good option for you to consider.`,
  },
};

export const RESULT_TERM_MAYBE = {
  never: {
    title: `You plan to stay in your home for more than 10 years.`,
    text: `Hometap Investments have a 10 year effective period, at which point our investment must be settled through a sale, with savings or by refinancing. Since you plan to stay in your home for more than 10 years, it’s important that you to take these settlement options into consideration before deciding if we’re a good fit for your financial needs.`,
  },
  dont_know: {
    title: `You’re not sure if you want to sell your home.`,
    text: `Hometap Investments have a 10 year effective period, at which point our investment must be settled through a sale, with savings or by refinancing. Since you plan to stay in your home for more than 10 years, it’s important that you to take these settlement options into consideration before deciding if we’re a good fit for your financial needs.`,
  },
};

export const RESULT_MONTHY_EXPENSE_MAYBE = {
  no: {
    title: `Monthly expenses aren’t stressing you out.`,
    text: `Sounds like you have enough cash flow to keep you living comfortably. That’s great news! That means you also probably have several financing options. But if you need a large sum of money that falls outside your typical monthly expenses, and you need it fast, Hometap could be a good way to fund your financial needs without taking on debt.`,
  },
};

export const RESULT_RAINY_DAY_FUND_MAYBE = {
  title: `You’ve got money saved up for unexpected expenses.`,
  text: `Congrats! Setting aside money is no easy feat. Since your "rainy day" fund is already in good shape, you may not need to tap into your home’s equity. But, if you have a financial emergency or opportunity that costs more than what you have saved, Hometap could be a smart way to fund your financial goals without taking on debt.`,
};
