import { formatPhoneNumberPortals } from './fitQuizDataUtils';
import { browserIdentify, browserTrack } from 'utils/segment';

export const trackQuizData = (step, data, result) => {
  const { utmData } = data;
  const segmentData = {
    form_name: 'fit quiz',
    version: '2.2',
    version_start_date: '09-21-2020',
    page_url: window.location.hostname,
    step: step,
    result: result,

    // Basic Form
    homeowner_status: data.homeowner_status,
    own_home: data.is_homeowner,
    state: data.state,
    state_status: data.stateStatus,

    // Use Case Form
    primary_use_case: data.use_case,

    // Finance Form
    ideal_investment_amount: data.ideal_investment_amount,
    alternatives_considered: data.alternatives_considered && data.alternatives_considered.join(', '),
    rainy_day_fund: data.rainy_day_fund,
    monthly_expenses_concern: data.monthly_expenses_concern ? data.monthly_expenses_concern === 'yes' : undefined,
    ten_year_duration_prediction: data.term,

    // Personal Form
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email?.toLowerCase(),
    phone: data.phone,

    // UTM Data
    fit_quiz_gclid: utmData.gclid,
    fit_quiz_ttclid: utmData.ttclid,
    fit_quiz_fbclid: utmData.fbclid,
    fit_quiz_referrer: utmData.referrer,
    fit_quiz_referring_domain: utmData.referring_domain,
    fit_quiz_utm_source: utmData.utm_source,
    fit_quiz_utm_campaign: utmData.utm_campaign,
    fit_quiz_utm_content: utmData.utm_content,
    fit_quiz_utm_medium: utmData.utm_medium,
    fit_quiz_utm_term: utmData.utm_term,
    fit_quiz_transaction_id: utmData.transaction_id,
    fit_quiz_offer_id: utmData.offer_id,
    fit_quiz_marketing_site_user_properties: utmData.marketing_site_user_properties,
    fit_quiz_goal_id: utmData.goal_id,
  };

  if (data?.email) {
    browserIdentify(data.email, {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
    });
  }

  browserTrack.engagementFormCompleted(segmentData, { integrations: { Salesforce: step === 5 } });
};

export const getFitQuizCookieData = data => {
  const now = new Date();
  const expiresOn = new Date(now);
  expiresOn.setDate(now.getDate() + 365);

  const formattedNumber = formatPhoneNumberPortals(data.phone);
  return {
    name: 'ht_fit_quiz_data',
    value: {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: formattedNumber == null ? undefined : formattedNumber,
      primary_use_case: data.use_case,
      state: data.state,
    },
    options: { path: '/' },
  };
};
