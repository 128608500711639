import { MuiTextInput } from '@hometap/htco-components';

import useApplicationContext from '../hooks/useApplicationContext';
import { APPLICATION_FORM_FIELD } from '../constants/formFields';

const OtherLienDetailsFormFields = () => {
  const { registerApplicationFormField } = useApplicationContext();

  return (
    <div className="ApplicationInfoFormFields">
      <MuiTextInput
        label="Other Lien Details"
        required
        {...registerApplicationFormField(APPLICATION_FORM_FIELD.otherLienDetails)}
      />
    </div>
  );
};

export default OtherLienDetailsFormFields;
