import React from 'react';

const HomeGraphHatchOverlay = ({ height }) => {
  return (
    <div className="relative" style={{ height }}>
      <svg className="absolute top-0 w-full opacity-[60%]" height="100%">
        <defs>
          <pattern
            id="diagonalHatch"
            patternUnits="userSpaceOnUse"
            width="5"
            height="7"
            patternTransform="rotate(-45 3 5)"
          >
            <path d="M -1,3 l 7,0" stroke="#fff" strokeWidth=".3" />
          </pattern>
        </defs>
        <rect height="100%" width="100%" fill="url(#diagonalHatch)" />
      </svg>
    </div>
  );
};

export default HomeGraphHatchOverlay;
