import { PLACEHOLDER_TODO, RESOURCE_TYPE, TODO_TIME_TO_COMPLETE } from '../constants';
import { getRundownOrOfferUrl } from 'utils/links';
import { APPRAISAL_TYPE } from 'utils/constants/appraisalTypes';
import { STAGES } from 'apps/dashboard/constants';
import { USER_ROLES } from 'utils/globalConstants';

const { TASK_DEFINITION_KEY } = require('utils/constants/taskDefinitionKeys');

const UPLOAD_DOC_CTA_TEXT = 'Upload';

const getTodoConfigForDefinitionKey = (track, definitionKey) => {
  const todoTypeConfig = {
    [TASK_DEFINITION_KEY.UPLOAD_FILE]: { cta: { text: UPLOAD_DOC_CTA_TEXT } },
    [TASK_DEFINITION_KEY.SELECT_APPRAISAL_TYPE]: {
      title: 'Choose your appraisal method.',
      subtitle: (
        <>
          Select from a virtual (online) or traditional (in-person) appraisal.{' '}
          <a
            className="TrackDetailsTodoCardLink"
            href="https://ucarecdn.com/fb653e07-91bd-4664-82a4-33ce426c3dcc/-/inline/yes/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download the guide
          </a>{' '}
          to learn more about each option.
        </>
      ),
      timeToComplete: '2 minutes',
      icon: 'house-value',
      cta: {
        text: 'Select method',
        to: `/track/${track.id}/appraisal-selection`,
      },
    },
  };

  return todoTypeConfig[definitionKey] || {};
};

export const mapAsyncTodoToConfig = (todo, track = {}) => {
  const todoBaseOverrides = {
    isActive: true,
    title: todo.name,
    timeToComplete: TODO_TIME_TO_COMPLETE.ASYNC,
    subtitle: todo.subtitle,
    icon: 'file-empty',
    cta: {
      text: todo.is_legacy_hub_task ? UPLOAD_DOC_CTA_TEXT : 'Complete',
    },
  };
  return { ...todo, ...todoBaseOverrides, ...getTodoConfigForDefinitionKey(track, todo?.task_definition_key) };
};

export const mapRundownToConfig = ({ rundown, placeholder, isOffer = false, track }) => {
  let isComplete;

  if (isOffer) {
    isComplete = track?.membership?.role === USER_ROLES.CO_APPLICANT && !!rundown?.last_viewed_by_client;
  } else {
    isComplete = !!rundown?.last_viewed_by_client;
  }

  return {
    ...rundown,
    ...placeholder,
    isComplete,
    isActive: true,
    icon: isOffer ? RESOURCE_TYPE.OFFER.icon : RESOURCE_TYPE.RUNDOWN.icon,

    cta: {
      text: `View ${isOffer ? 'Offer' : 'Estimate'}`,
      href: getRundownOrOfferUrl(rundown),
    },
  };
};

export const getMappedEstimateRundowns = ({ rundowns = [], track }) => {
  const configuredRundowns = rundowns?.map(rundown =>
    mapRundownToConfig({ rundown, placeholder: PLACEHOLDER_TODO.ESTIMATE, track }),
  );
  const incompleteRundowns = configuredRundowns.filter(config => !config.isComplete);
  if (!rundowns?.length) {
    return [PLACEHOLDER_TODO.ESTIMATE];
  }
  // all rundowns completed
  if (!incompleteRundowns.length) {
    return [];
  }
  return configuredRundowns;
};

export const getMappedOfferRundown = ({ offer, placeholderOverrides, track }) => {
  if (track?.current_stage === STAGES.OFFER_DECLINED) return;

  const hasOffer = !!Object.keys(offer || {}).length;
  const overridablePlaceholder = { ...PLACEHOLDER_TODO.OFFER, ...placeholderOverrides };
  const configuredOffer = mapRundownToConfig({
    track,
    rundown: offer,
    placeholder: overridablePlaceholder,
    isOffer: true,
  });

  const offerTodo = hasOffer ? configuredOffer : overridablePlaceholder;
  return offerTodo;
};

export const getAppraisalTypeTodoConfig = track => {
  const appraisalTypeMap = {
    [APPRAISAL_TYPE.VIRTUAL]: {
      title: 'Complete your virtual appraisal.',
      subtitle:
        'In the next 48 hours, you’ll get an email with instructions for your virtual appraisal. Please contact us if you have any questions.',
      icon: 'email',
      isActive: true,
      isComplete: false,
      cta: {
        text: 'Contact us',
        target: '_blank',
        rel: 'noopener noreferrer',
        href: track?.investment_manager ? track?.investment_manager?.scheduling_url : 'https://calendly.com/hometap',
      },
    },

    [APPRAISAL_TYPE.ASSISTED]: {
      title: 'We’re working to schedule your assisted virtual appraisal.',
      subtitle:
        'In the next few days, a photographer will reach out to schedule a visit. Once the photos are submitted, it typically takes about 5 business days for the full report to come back.',
      timeToComplete: '1-2 weeks',
      icon: 'traditional-appraisal',
      isActive: true,
      isComplete: false,
      cta: {
        text: 'Prepare your home',
        target: '_blank',
        rel: 'noopener noreferrer',
        href: 'https://www.hometap.com/blog/preparing-for-home-appraisal',
      },
    },

    [APPRAISAL_TYPE.TRADITIONAL]: {
      title: 'We’re working to schedule your traditional appraisal.',
      subtitle:
        'We’ll coordinate with the trusted third party we use for in-person appraisals, and they’ll be in touch within 7 business days to schedule an appointment.',
      timeToComplete: '2-3 weeks',
      icon: 'traditional-appraisal',
      isActive: track?.selected_appraisal_type === APPRAISAL_TYPE.TRADITIONAL,
      isComplete: false,
      cta: {
        text: 'Prepare your home',
        target: '_blank',
        rel: 'noopener noreferrer',
        href: 'https://www.hometap.com/blog/preparing-for-home-appraisal',
      },
    },
    lockedAVMEligible: {
      title: 'Complete your appraisal.',
      isActive: false,
      isComplete: false,
      subtitle: (
        <>
          A virtual appraisal can take as few as <strong>3 days</strong> while a traditional appraisal typically takes{' '}
          <strong>3–4 weeks</strong>.
        </>
      ),
    },
  };

  if (track?.selected_appraisal_type) {
    return appraisalTypeMap[track?.selected_appraisal_type];
  }

  const isAVMEligible = track?.relevant_home_valuation?.avm_eligibility === 'eligible';
  if (isAVMEligible) {
    return appraisalTypeMap.lockedAVMEligible;
  }

  return appraisalTypeMap[APPRAISAL_TYPE.TRADITIONAL];
};
