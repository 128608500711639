import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ModalConfirm } from '@hometap/htco-components';
import './RouterPrompt.scss';

export function RouterPrompt({ when, onOK, onCancel, onlyOnUnload = false, bodyText }) {
  const history = useHistory();
  const [blockedPath, setBlockedPath] = useState();
  const showPrompt = !!blockedPath && !onlyOnUnload;

  useEffect(() => {
    if (when || (!when && onlyOnUnload)) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [when, onlyOnUnload]);

  useEffect(() => {
    const unblock = history.block(location => {
      if (when && !location?.state?.force && !onlyOnUnload) {
        setBlockedPath(location.pathname);
        return false;
      }
      unblock();
    });
    return () => unblock();
    // Dependencies need blockedPath so this resets the history.block method
  }, [history, when, blockedPath, onlyOnUnload]);

  const handleNavigate = async () => {
    if (onOK) await onOK();
    history.push(blockedPath, { force: true });
    setBlockedPath();
  };

  const handleStay = async () => {
    if (onCancel) await onCancel();
    setBlockedPath();
  };

  return showPrompt ? (
    <ModalConfirm
      className="UnsavedChangesModal"
      open={true}
      width="580px"
      header="Are you sure you want to leave?"
      confirmText="No, continue to edit"
      cancelText="Yes, I want to leave the page"
      onCancel={handleNavigate}
      onClose={handleStay}
      onConfirm={handleStay}
    >
      {bodyText || 'You have unsaved changes, if you leave your updates will be lost.'}
    </ModalConfirm>
  ) : null;
}
