import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import env from 'utils/env';
import AudacyPixel from '../../../components/AudacyPixel/AudacyPixel';

const isFitQuizSuccessPage = href => {
  return href?.includes('fit-quiz-results') && href?.includes('?result=done');
};

const Pixels = () => {
  const location = useLocation();
  const isProduction = env.isProd();

  const audacyUuid = useMemo(() => new Date().getTime() + parseInt(Math.random() * 10_000_000_000_000), []);

  // If not hometap.com domain don't render pixels
  if (!isProduction) {
    return null;
  }

  return (
    <>
      <Helmet>
        {/* Conversion Pixel placed on success page of Fit Quiz for Pandora Ads */}
        {isFitQuizSuccessPage(location?.href) && (
          <noscript>
            {`
          <img
            src="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=signup&pixid=f253c2b4-aaa6-469e-a149-6cfa3498d797"
            width="1"
            height="1"
            border="0"
            style="display:none"
          ></img>
          `}
          </noscript>
        )}
        {/* End of Conversion Pixel */}

        {/* LiveConnectTag for advertisers */}
        <script type="text/javascript" src="//b-code.liadm.com/a-079p.min.js" async="true" charset="utf-8"></script>
        {/* END LiveConnectTag for advertisers */}
      </Helmet>
      {/* Audacy tracking pixel */}
      <AudacyPixel propertyId="18544" goalName="sitewide" goalId="42887" uuid={audacyUuid} />
    </>
  );
};

export default Pixels;
