import './TrackDetailsProgressStepper.scss';
import cx from 'classnames';

const TrackDetailsProgressStepper = ({ steps, activeIndex }) => {
  const gradients = [
    ['#70DD7D', '#70DBFF'],
    ['#70DBFF', '#B770FF'],
    ['#B770FF', '#7070FF'],
    ['#7070FF', '#3C4CFF'],
  ];

  return (
    <div className="TrackDetailsProgressStepperContainer">
      {steps.map((step, stepIndex) => {
        const isActive = stepIndex === activeIndex;
        const isDisabled = stepIndex > activeIndex;

        const formattedGradients = gradients[stepIndex % gradients.length]?.join(', ');
        const styleObj = {
          ...(!isDisabled && { backgroundImage: `linear-gradient(to right, ${formattedGradients})` }),
        };

        return (
          <div className={cx('TrackDetailsProgressStepperStepContainer', { isActive, isDisabled })} key={stepIndex}>
            <div className="TrackDetailsProgressStepperStepIndicator" style={styleObj} />
            <div className="TrackDetailsProgressStepperStepLabel">{step}</div>
          </div>
        );
      })}
    </div>
  );
};

export default TrackDetailsProgressStepper;
