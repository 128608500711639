import axios from 'axios';
import omit from 'lodash/omit';
import Qs from 'qs';
import endsWith from 'lodash/endsWith';
import env from './env';
import { getVersion } from './env';
import { getPortalsUrl } from './links';
import { API_VERSION } from './constants/APIConstants';

const handleTrailingSlash = config => {
  if (!endsWith(config.url, '/')) {
    config.url += '/';
  }
  return config;
};

const handleRequestFormatting = config => {
  return handleTrailingSlash(config);
};

// Return response data to make it quicker to access, but add full
// request object at __request in case it need to be accessed.
const handleResponseFormatting = response => {
  const data = response.data || {};
  if (typeof data === 'object' && data !== null) {
    data.__request = omit(response, 'data');
  }
  return data;
};

// Get API Base depending on current url
export const getApiBase = apiVersion => {
  const apiUrl = () => {
    switch (apiVersion) {
      case API_VERSION.V1:
        return 'api/v1';
      case API_VERSION.V2:
        return 'api/v2';
      default:
        return 'api/v1';
    }
  };

  if (process.env.REACT_APP_BACKEND_BASE) {
    return process.env.REACT_APP_BACKEND_BASE + apiUrl();
  }
  return env.getBackendUrlForEnv() + apiUrl();
};

// Setup global defaults for all axios instances
const portalsApiV1Defaults = {
  baseURL: getApiBase(API_VERSION.V1),
  withCredentials: true,
  xsrfCookieName: 'portalscsrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  headers: {
    'Content-Type': 'application/json',
    'HT-CLIENT-VERSION': `client_portal:${getVersion()}`,
  },
  // repeat params format: { a: ['b', 'c'] }
  paramsSerializer: params => {
    return Qs.stringify(params, { arrayFormat: 'repeat' });
  },
};

const portalsApiV2Defaults = {
  ...portalsApiV1Defaults,
  baseURL: getApiBase(API_VERSION.V2),
};

// Globally handle all unauthenticated errors - redirect to eng_portals homepage
// TODO: Should we always use getLoginUrl() instead of this? This uses getPortalsUrl() which
// isn't the actual login page. On prod, that page redirects to the login page but only when
// not logged in already (otherwise it redirects to the user's homepage).
const redirectToHomepage = error => {
  if (error?.response?.status === 403) {
    window.api.redirect(getPortalsUrl());
  }
  return Promise.reject(error);
};

// Axios instance for primarily un-authenticated requests (for example inquiry)
// OR authenticated requests where you need to specifically handle 403 errors
export const apiNoAuth = axios.create(portalsApiV1Defaults);
apiNoAuth.interceptors.request.use(handleRequestFormatting);
apiNoAuth.interceptors.response.use(handleResponseFormatting);

export const apiV2NoAuth = axios.create(portalsApiV2Defaults);
apiV2NoAuth.interceptors.request.use(handleRequestFormatting);
apiV2NoAuth.interceptors.response.use(handleResponseFormatting);

// Axios instance for authenticated requests (for example dashboard)
// 403 errors are assumed to require re-direct to login (which may be incorrect and then
// re-direct them to their dashboard)
const apiV1WithAuth = axios.create(portalsApiV1Defaults);
apiV1WithAuth.interceptors.request.use(handleRequestFormatting);
apiV1WithAuth.interceptors.response.use(handleResponseFormatting, redirectToHomepage);

const apiV2WithAuth = axios.create(portalsApiV2Defaults);
apiV2WithAuth.interceptors.request.use(handleRequestFormatting);
apiV2WithAuth.interceptors.response.use(handleResponseFormatting, redirectToHomepage);

export const apiWithAuth = {
  v1: apiV1WithAuth,
  v2: apiV2WithAuth,
};

export default apiWithAuth;
