import cx from 'classnames';
import { TYPE_TO_ICON_MAP } from 'apps/dashboard/constants/resources';
import './ResourcesFooter.scss';

const ResourcesFooter = ({ isLoading, featuredResource }) => {
  return (
    <div className={cx('FeaturedResourcesFooter', { isLoading })}>
      <img src={TYPE_TO_ICON_MAP[featuredResource.type]} alt={featuredResource.type} />
      <div className="FeaturedResourcesType">{featuredResource.type}</div>
      {featuredResource.length && (
        <>
          <div className="FeaturedResourcesBullet" />
          <div className="FeaturedResourcesReadLength">{featuredResource.length} min read</div>
        </>
      )}
    </div>
  );
};

export default ResourcesFooter;
