export const EQUITY_SCENARIO_LIEN_TYPE = {
  MORTGAGE: 'mortgage_loan',
  HOME_EQUITY_LOAN: 'home_equity_loan',
  HOME_EQUITY_CREDIT: 'home_equity_line_of_credit',
  HOME_EQUITY_INVESTMENT: 'home_equity_investment',
  OTHER: 'judgement_tax_or_other',
};

export const HOUSE_CANARY_LIEN_TYPE = {
  MORTGAGE: 'mortgage',
  HOME_EQUITY_LOAN: 'home_equity_loan',
  HOME_EQUITY_INVESTMENT: 'home_equity_investment',
  HELOC: 'heloc',
  JUDGEMENT_TAX_OTHER: 'judgement_tax_other',
};
