import React, { useState } from 'react';
import DebtForm from '../components/debtForm/DebtForm';
import DebtSelector from '../components/debtSelector/DebtSelector';
import { DEBT_TYPE_MAP } from '../constants/debtCalculator';

const DebtFormController = ({
  onDebtSubmit,
  title,
  buttonText,
  onSelectDebtType,
  onCancel,
  isModalOpen,
  allowCancel,
  debts,
}) => {
  const [typeOfDebt, setTypeOfDebt] = useState('');
  const details = DEBT_TYPE_MAP[typeOfDebt];

  const onDropdownSubmit = ({ typeOfDebt }) => {
    setTypeOfDebt(typeOfDebt);
  };

  const onDetailsSubmit = ({ balance, interestRate, monthlyPayment }) => {
    onDebtSubmit({ typeOfDebt, balance, interestRate, monthlyPayment, ...DEBT_TYPE_MAP[typeOfDebt] });
    if (isModalOpen) {
      onCancel();
    }
    setTypeOfDebt('');
  };

  return (
    <div>
      {typeOfDebt ? (
        <DebtForm
          icon={details.icon}
          title={details.title}
          buttonText={details.buttonText}
          onDetailsSubmit={onDetailsSubmit}
          typeOfDebt={typeOfDebt}
          debts={debts}
          onCancel={onCancel}
          allowCancel={allowCancel}
        />
      ) : (
        <div style={{ maxWidth: '460px', margin: '0 auto', width: '100%' }}>
          <DebtSelector
            title={title}
            onDropdownSubmit={onDropdownSubmit}
            buttonText={buttonText}
            onSelectDebtType={onSelectDebtType}
            onCancel={onCancel}
            allowCancel={allowCancel}
          />
        </div>
      )}
    </div>
  );
};

export default DebtFormController;
