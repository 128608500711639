import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiSelect, MuiSlider, InputMask, MuiTextInput } from '@hometap/htco-components';
import moment from 'moment';
import { FINANCE_CALCULATOR_TYPE } from './data/financeData';
import { currency, percent, percentToDecimal } from 'utils/numbers';
import { getDefaultProduct, postAnnualAppreciation } from 'apps/dashboard/data';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { formatExistingLienDataForApi } from 'apps/dashboard/utils/dataUtils';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import { useCurrentHome } from 'hooks/useCurrentHome';

const CalculatorHei = ({ data, onChange, checkCardFooterCopy }) => {
  const { home } = useCurrentHome();
  const { currentScenario } = useEquityScenarioContext();

  useEffect(() => {
    if (!home.operationalState) return;

    const getAnnualAppreciation = async () => {
      const product = await getDefaultProduct();
      const hei = {
        state: home.operationalState.abbreviation,
        investment_amount: data?.tappableAmount,
        product_id: product?.id,
        effective_date: moment().format('YYYY-MM-DD'),
      };

      const formattedLiens = currentScenario?.liens
        ? currentScenario.liens
            .filter(lien => lien.lien_type !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT) // Need to remove HEI's
            .map(lien =>
              formatExistingLienDataForApi({
                ...lien,
                as_of_date: lien.last_known_current_balance_as_of,
                current_balance: lien.last_known_current_balance,
              }),
            )
        : [];

      const appreciationRequestBody = {
        home_values: [
          {
            home_value: data?.estimatedHomeValue,
            as_of_date: moment().format('YYYY-MM-DD'),
          },
        ],
        effective_period: {
          length: data.term,
          unit: 'years',
        },
        hei,
        appreciation_percent: {
          annualized: true,
          value: percentToDecimal(data?.appreciationRate),
        },
        liens: formattedLiens,
      };
      const results = await postAnnualAppreciation(appreciationRequestBody);
      const annualData = results?.annual_data;
      const maturityYear = new Date().getFullYear() + data?.term;
      return annualData?.find(({ year }) => year === maturityYear)?.hometap_share;
    };
    if (data?.tappableAmount) {
      getAnnualAppreciation().then(hometapShare => {
        const shareText = `${currency(hometapShare?.dollars)} (${percent(hometapShare?.percent)})`;
        onChange('hometapShare', shareText);
      });
    }
  }, [
    currentScenario.liens,
    onChange,
    home,
    data?.estimatedHomeValue,
    data?.tappableAmount,
    data?.appreciationRate,
    data.term,
  ]);

  const DATA_DETAILS = [
    { label: 'Estimated home value', value: currency(data?.estimatedHomeValue) },
    { label: 'Tappable equity', value: currency(data?.tappableAmount) },
    {
      label: 'Investment length',
      value: `${data?.term} ${data?.term === 1 ? 'year' : 'years'}`,
    },
    { label: 'Monthly payment', value: currency(data?.monthlyPayment) },
    { label: 'Appreciation rate', value: `${data?.appreciationRate}%` },
    { label: 'Hometap’s share', value: data?.hometapShare || '-' },
  ];

  const tappableAmountSliderProps = {
    min: data?.tappableAmountMin,
    max: data?.tappableAmountMax,
    breakpoints: [
      {
        position: data?.tappableAmountMin,
        label: currency(data?.tappableAmountMin),
      },
      {
        position: data?.tappableAmountMax,
        label: currency(data?.tappableAmountMax),
      },
    ],
  };

  const termSliderProps = {
    min: 1,
    max: 10,
    breakpoints: [
      {
        position: 1,
        label: '1 year',
      },
      {
        position: 2,
      },
      {
        position: 3,
      },
      {
        position: 4,
      },
      {
        position: 5,
      },
      {
        position: 6,
      },
      {
        position: 7,
      },
      {
        position: 8,
      },
      {
        position: 9,
      },
      {
        position: 10,
        label: '10 years',
      },
    ],
  };

  const appreciationRateSliderProps = {
    min: 2,
    max: 7,
    breakpoints: [
      {
        position: 2,
        label: '2%',
      },
      {
        position: 3,
      },
      {
        position: 4,
      },
      {
        position: 5,
      },
      {
        position: 6,
      },
      {
        position: 7,
        label: '7%',
      },
    ],
  };

  const [tappableAmount, setTappableAmount] = useState(data?.tappableAmount);
  const [term, setTerm] = useState(data?.term);
  const [appreciationRate, setAppreciationRate] = useState(data?.appreciationRate);

  // When we calculate the tappable amount, update the state
  useEffect(() => {
    setTappableAmount(data?.tappableAmount);
  }, [data?.tappableAmount]);

  return (
    <>
      <div className="FinancingCalculatorSliderWrapper">
        <MuiSlider
          title="Tappable equity"
          value={tappableAmount}
          onRelease={() => onChange('tappableAmount', tappableAmount)}
          onChange={newValue => setTappableAmount(newValue)}
          step={1000}
          min={tappableAmountSliderProps.min}
          max={tappableAmountSliderProps.max}
          tooltipContent="This refers to the total amount of equity you’re able to access through a home equity investment. You can reduce the amount to see outcomes with different Investment length and appreciation rates."
          breakPoints={tappableAmountSliderProps.breakpoints}
          valueRenderer={value => currency(value)}
        />
        <MuiSlider
          title="Investment length"
          value={term}
          onRelease={() => onChange('term', term)}
          onChange={newValue => setTerm(newValue)}
          step={1}
          min={termSliderProps.min}
          max={termSliderProps.max}
          tooltipContent="This is the effective period or term for the investment. While the length varies by provider, the 10-year maximum is based on the length of a Hometap Investment."
          breakPoints={termSliderProps.breakpoints}
          valueRenderer={value => `${value} ${value === 1 ? 'year' : 'years'}`}
        />
        <MuiSlider
          title="Appreciation rate"
          value={appreciationRate || data?.appreciationRate || 5}
          onRelease={() => onChange('appreciationRate', appreciationRate)}
          onChange={newValue => setAppreciationRate(newValue)}
          step={1}
          min={appreciationRateSliderProps.min}
          max={appreciationRateSliderProps.max}
          tooltipContent="Appreciation refers to the increase in your home's value over time. While there are several factors that influence appreciation, and rates can vary, the 2–7% range provides a baseline from which to explore future financing outcomes."
          breakPoints={appreciationRateSliderProps.breakpoints}
          valueRenderer={value => `${value}%`}
        />
      </div>

      <div className="FinanceValuesCheckCardContainer">
        {DATA_DETAILS.map(item => (
          <div className="FinanceValuesCheckCard" key={item.label}>
            <div className="label">{item.label}</div>
            <span className="value">{item.value ? item.value : '-'}</span>
          </div>
        ))}
        {checkCardFooterCopy && <p className="FinanceValuesCheckCardFooter">{checkCardFooterCopy}</p>}
      </div>
    </>
  );
};

const CalculatorHelocOrHel = ({
  data,
  checkCardFooterCopy,
  onChange,
  setShouldButtonDisabled,
  loanDetails,
  setLoanDetails,
}) => {
  const formatValue = (value, formatter) => (value ? formatter(value) : null);

  useEffect(() => {
    const { monthlyPayment, totalCost } = loanDetails;
    setShouldButtonDisabled(!monthlyPayment || !totalCost);
  }, [loanDetails, setShouldButtonDisabled]);

  useEffect(() => {
    const calculatedLoanDetails = calculateHomeEquityLoanPayment(
      data.loanAmount,
      parseFloat(data.averageInterestRate),
      data.term,
    );
    setLoanDetails(calculatedLoanDetails);
  }, [data, setLoanDetails]);

  const calculateHomeEquityLoanPayment = (principal, annualInterestRate, loanTermInYears) => {
    if (annualInterestRate && principal) {
      // Convert annual interest rate to monthly interest rate
      const monthlyInterestRate = annualInterestRate / 12;

      // Convert loan term from years to months
      const loanTermInMonths = loanTermInYears * 12;

      // Calculate the monthly payment
      const monthlyPayment =
        (principal * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTermInMonths))) /
        (Math.pow(1 + monthlyInterestRate, loanTermInMonths) - 1);

      // Calculate the total cost of the loan
      const totalCost = monthlyPayment * loanTermInMonths;

      return {
        monthlyPayment: monthlyPayment.toFixed(2),
        totalCost: totalCost.toFixed(2),
      };
    }

    return {};
  };

  const termValueOptions = [
    {
      label: '10 years ',
      value: 10,
    },
    {
      label: '15 years',
      value: 15,
    },
    {
      label: '20 years',
      value: 20,
    },
    {
      label: '30 years',
      value: 30,
    },
  ];

  const DATA_DETAILS = [
    { label: 'Estimated home value', value: currency(data?.estimatedHomeValue) },
    { label: 'Loan amount', value: currency(data?.loanAmount) },
    { label: 'Term length', value: `${data?.term} ${data?.term === 1 ? 'year' : 'years'}` },
    { label: 'Interest rate', value: percent(data?.averageInterestRate, '0.[000]') },
    { label: 'Monthly payment', value: formatValue(loanDetails?.monthlyPayment, currency) },
    { label: 'Estimated payback cost', value: formatValue(loanDetails?.totalCost, currency) },
  ];

  return (
    <>
      <div className="FinancingCalculatorSliderWrapper">
        <MuiTextInput
          label="Loan amount"
          // Show placeholder for 0 amount
          value={data.loanAmount || ''}
          inputMode="decimal"
          type="number"
          startAdornment="$"
          mask={InputMask.MONEY_USD_MASK}
          unmask="typed"
          onChange={newValue => onChange('loanAmount', newValue)}
        />

        <MuiSelect
          label="Term length"
          width="100%"
          value={data?.term}
          options={termValueOptions}
          onChange={newValue => onChange('term', newValue)}
        />

        <MuiTextInput
          label="Interest rate"
          // Show placeholder for 0 rate
          value={data.averageInterestRate || ''}
          type="number"
          inputMode="decimal"
          endAdornment="%"
          mask={InputMask.PERCENT_MASK}
          unmask="typed"
          onChange={newValue => {
            onChange('averageInterestRate', newValue);
          }}
        />
      </div>

      <div className="FinanceValuesCheckCardContainer">
        {DATA_DETAILS.map(item => (
          <div className="FinanceValuesCheckCard" key={item.label}>
            <div className="label">{item.label}</div>
            <span className="value">{item.value || '-'}</span>
          </div>
        ))}
        {checkCardFooterCopy && <p className="FinanceValuesCheckCardFooter">{checkCardFooterCopy}</p>}
      </div>
    </>
  );
};

const SelectedFinanceCalculator = ({
  calculatorData,
  currentCalculator,
  onCalculatorDataChange,
  setShouldButtonDisabled,
  loanDetails,
  setLoanDetails,
}) => {
  const { value: selectedType, calculatorCardFooterCopy } = currentCalculator;

  const financeCalculatorUI = {
    [FINANCE_CALCULATOR_TYPE.HEI]: (
      <CalculatorHei
        data={calculatorData}
        checkCardFooterCopy={calculatorCardFooterCopy}
        onChange={onCalculatorDataChange}
      />
    ),
    [FINANCE_CALCULATOR_TYPE.HELOC]: (
      <CalculatorHelocOrHel
        data={calculatorData}
        checkCardFooterCopy={calculatorCardFooterCopy}
        setShouldButtonDisabled={setShouldButtonDisabled}
        onChange={onCalculatorDataChange}
        loanDetails={loanDetails}
        setLoanDetails={setLoanDetails}
      />
    ),
    [FINANCE_CALCULATOR_TYPE.HEL]: (
      <CalculatorHelocOrHel
        data={calculatorData}
        checkCardFooterCopy={calculatorCardFooterCopy}
        setShouldButtonDisabled={setShouldButtonDisabled}
        onChange={onCalculatorDataChange}
        loanDetails={loanDetails}
        setLoanDetails={setLoanDetails}
      />
    ),
  };

  return financeCalculatorUI[selectedType];
};

CalculatorHei.propTypes = {
  data: PropTypes.object.isRequired,
  checkCardFooterCopy: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CalculatorHelocOrHel.propTypes = {
  data: PropTypes.object.isRequired,
  checkCardFooterCopy: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setShouldButtonDisabled: PropTypes.func.isRequired,
};

SelectedFinanceCalculator.propTypes = {
  calculatorData: PropTypes.object.isRequired,
  currentCalculator: PropTypes.shape({
    calculatorCardFooterCopy: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onCalculatorDataChange: PropTypes.func.isRequired,
  setShouldButtonDisabled: PropTypes.func,
};

export default SelectedFinanceCalculator;
