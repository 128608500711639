import { useLocation } from 'react-router-dom';

export const toQueryString = (object = {}) => {
  const params = new URLSearchParams();
  Object.keys(object).forEach(key => {
    if (typeof object[key] === 'object') {
      params.set(key, JSON.stringify(object[key]));
    } else {
      params.set(key, object[key]);
    }
  });
  return params.toString();
};

export const fromQueryString = string => {
  const params = new URLSearchParams(string);
  const data = {};

  params.forEach((value, key) => {
    try {
      data[key] = JSON.parse(value);
    } catch (e) {
      data[key] = value;
    }
  });

  return data;
};

export const useQueryParams = () => {
  const location = useLocation();
  const params = location ? fromQueryString(location.search) : {};
  return params;
};
