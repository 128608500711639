import React from 'react';
import TrackButton from '../../../components/TrackButton/TrackButton';
import '../PartnerInquiry.scss';

const PartnerInquiryUnsuccessful = ({ header, subText, buttonText, buttonHref }) => {
  return (
    <div className="PartnerInquiryUnsuccessfulContainer">
      <h1>{header}</h1>
      <div className="PartnerInquiryUnsuccessfulSubtitle">{subText}</div>
      <div className="PartnerInquiryUnsuccessfulButton">
        <TrackButton className="PartnerInquiryUnsuccessfulButton" href={buttonHref}>
          {buttonText}
        </TrackButton>
      </div>
    </div>
  );
};

export default PartnerInquiryUnsuccessful;
