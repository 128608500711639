import React from 'react';
import { Paper } from '@hometap/htco-components';

import './RundownFooter.scss';

const footerNotes = [
  {
    title: 'Hometap Share',
    body: "The Hometap Share is the amount of the home's value we will receive when you sell your home, refinance, or buy us out by the end of the effective period. If your home loses value, we reduce the percentage we receive. No matter what, the Hometap Share is limited to an annual 20% rate of return, which we call the Hometap Cap.",
  },
  {
    title: 'Appraisals',
    body: "We may require either a virtual appraisal or physical appraisal performed by an independent appraiser in order to determine the property's current value. The cost of either a physical or virtual appraisal will be deducted from the Investment Amount at signing. You will receive a copy of the physical appraisal, if one is required.",
  },
  {
    title: 'Fees',
    body: 'The costs and fees disclosed above may be incurred before or at signing. If you receive an Investment, these costs are deducted from the final amount you receive.',
  },
  {
    title: 'Homeowners Insurance',
    body: 'You are required to maintain homeowners insurance on the property, which you may obtain from a company of your choice that we find acceptable. Your insurance policy must name Hometap as a mortgagee, loss payee, or additional insured. We can help you with getting Hometap added to the policy.',
  },
  {
    title: 'Mortgages, Taxes, & Other Payments',
    body: 'You’re responsible for paying your secured loan payments, property taxes, HOA fees, maintenance fees, mortgage insurance, and any other payment you must make in connection with the ownership of your property.',
  },
  {
    title: 'Loss of Property',
    body: 'If you do not fulfill your obligations during the Effective Period, you may lose this property (for example, failure to maintain homeowners insurance, pay property taxes, make payments on any loan secured by the property, or failure to settle your Hometap Investment at the end of the term).',
  },
];

const RundownFooter = () => {
  return (
    <Paper theme="plain" className="RundownFooter">
      <div className="RundownFooterContainer">
        <h2 className="RundownFooterTitle">A few things to note...</h2>
        <div className="RundownFooterNotesContainer">
          {footerNotes.map(note => {
            return (
              <div key={note.title} className="RundownFooterNoteBlock">
                <h4 className="RundownFooterNoteTitle">{note.title}</h4>
                <p className="RundownFooterNoteBody">{note.body}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Paper>
  );
};

export default RundownFooter;
