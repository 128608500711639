import React from 'react';
import { Container, Badge, Button } from '@hometap/htco-components';
import { STAGES } from 'apps/dashboard/constants/trackConstants';
import './InvestmentSummaryCard.scss';
import { getPrimaryAppTrackDetailsUrl } from 'utils/links';
import { convertDateStringToHumanReadable } from 'utils/date';
import { UI_STAGE, TRACK_STAGE_TO_UI_STAGE } from 'apps/track-details/utils/constants';
import { currency } from 'utils/numbers';
import { isTrackFunded } from 'apps/track-details/utils/config';
import { isTrackExpiredOrClosed } from 'utils/tracksUtils';

const FUNDED_ON_LABEL = 'FUNDED ON';
const SETTLED_ON_LABEL = 'SETTLED ON';
const STARTED_ON_LABEL = 'STARTED ON';

// Refactor: Move getInvestmentAmountLabel somewhere where it makes sense to be shared
const INVESTED_AMOUNT_LABEL = 'INVESTMENT AMOUNT';
const INVESTMENT_ESTIMATE_LABEL = 'ESTIMATED MAX. INVESTMENT AMOUNT';
const OFFER_AMOUNT_LABEL = 'OFFER AMOUNT';

export const getInvestmentAmountLabel = stage => {
  if (!stage) {
    return INVESTMENT_ESTIMATE_LABEL;
  }
  const STAGE_TO_AMOUNT_LABEL_MAPPING = {
    [STAGES.APP_READY]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.APP_IN_PROGRESS]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.APP_IN_REVIEW]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.APP_APPROVED]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.OFFER_PREPARED]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.OFFER_READY]: OFFER_AMOUNT_LABEL,
    [STAGES.CONDITIONS_IN_REVIEW]: INVESTED_AMOUNT_LABEL,
    [STAGES.CLOSING_IN_PROGRESS]: INVESTED_AMOUNT_LABEL,
    [STAGES.INVESTED]: INVESTED_AMOUNT_LABEL,
    [STAGES.SETTLED]: INVESTED_AMOUNT_LABEL,
    [STAGES.CLOSED_LOST]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.OFFER_DECLINED]: OFFER_AMOUNT_LABEL,
  };

  return STAGE_TO_AMOUNT_LABEL_MAPPING[stage];
};

const InvestmentSummaryCard = ({ track }) => {
  const { id, current_stage, effective_date, settlement_date, investment_amount } = track;

  const getTimeStatus = () => {
    const status = (label, date) => {
      return { label, date: date ? convertDateStringToHumanReadable(date, true) : 'Invalid date' };
    };
    if (current_stage === STAGES.INVESTED) {
      return status(FUNDED_ON_LABEL, effective_date);
    } else if (current_stage === STAGES.SETTLED) {
      return status(SETTLED_ON_LABEL, settlement_date);
    }
    return status(STARTED_ON_LABEL, track.inquiry_created_date);
  };

  let ctaCopy;

  if (isTrackFunded(track)) {
    ctaCopy = 'View details';
  } else {
    ctaCopy = 'Check status';
  }

  const renderCtaButton = () => {
    return (
      <Button className="InvestmentCardCTA" href={getPrimaryAppTrackDetailsUrl(id)}>
        {ctaCopy}
      </Button>
    );
  };

  const mapUIStageBadgeConfig = () => {
    if (!isTrackFunded(track) && !isTrackExpiredOrClosed(track)) {
      return {
        label: 'In Progress',
        theme: 'neutralLight50',
      };
    }

    const uiStage = TRACK_STAGE_TO_UI_STAGE[current_stage]?.key;
    if (uiStage === UI_STAGE.Invested.key) {
      return {
        label: uiStage,
        theme: 'secondaryGreyOutlined',
      };
    }

    return {
      label: uiStage,
      theme: 'neutralDark50',
    };
  };

  const { label, date } = getTimeStatus();
  const { label: stageLabel, theme } = mapUIStageBadgeConfig();

  return (
    <Container className="InvestmentSummaryCardContainer">
      {/* Status and Investment ID */}
      <div className="InvestmentCardStatus">
        <Badge className="InvestmentSummaryCardBadgeNew" label={stageLabel} theme={theme} />
        <div className="InvestmentCardStatusId InvestmentCardKeyValue">
          <div className="InvestmentSummaryCardLabel InvestmentSummaryId">INVESTMENT ID</div>
          <div className="InvestmentSummaryCardId">{track.friendly_id}</div>
        </div>
      </div>

      {/* Date */}
      <div className="InvestmentCardDate InvestmentCardKeyValue">
        <div className="InvestmentSummaryCardLabel">{label}</div>
        <div className="InvestmentSummaryCardValue">{date}</div>
      </div>

      {/* Investment amount */}
      <div className="InvestmentCardAmount InvestmentCardKeyValue">
        <div className="InvestmentSummaryCardLabel">{getInvestmentAmountLabel(current_stage)}</div>
        <div className="InvestmentSummaryCardValue">
          {investment_amount ? currency(investment_amount) : 'More info needed'}
        </div>
      </div>

      {/* CTA */}
      {renderCtaButton()}
    </Container>
  );
};

export default InvestmentSummaryCard;
